import React, { useState,useContext, useEffect} from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  Fade,
  FormControlLabel,Checkbox,
  CircularProgress
}from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import moment from "moment";
import Dropzone from 'react-dropzone-uploader'
import { useHistory } from 'react-router';
import DownloadIcon from '@mui/icons-material/Download';
import { Preview } from "../variables/variables";

function FormBienesTransp(props) {
  var user = JSON.parse((localStorage.getItem('userData')))
  var rol = localStorage.getItem('permissions')

  const history = useHistory();
  var classes = useStyles();

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState('');
  
  
  //var userDispatch = useUserDispatch();
  var [fechaAlta, setFechaAlta] = useState(moment().format('YYYY-MM-DD'))
  var [fechaVencimiento,setFechaVencimiento] = useState(moment().add(1,'year').format('YYYY-MM-DD'))
  // local
  var [nombreCompania, setNombreCompania] = useState(()=>{if(user.razonS!=null){return user.razonS}else{ return ''}});
  var [email, setEmail] = useState(()=>{if(user.email!=null && user.rol==4){return user.email}else{ return ''}});

  var [cuit, setCuit]= useState(()=>{if(user.cuit!=null){return user.cuit}else{ return ''}});
  var [controlHabilitacionCamiones, setControlHabilitacionCamiones] = useState("");
  var [fecha_de_controlHabilitacionCamiones, setFecha_de_controlHabilitacionCamiones] = useState(moment().format('YYYY-MM-DD'));
  var [controlEstadoCamion, setControlEstadoCamion]= useState("");
  var [descripcionControlEstadoCamion, setDescripcionControlEstadoCamion]= useState('');
  var [cantidadYEstadoAnimales, setCantidadYEstadoAnimales]= useState("");
  var [avisoAPlanta, setAvisoAPlanta]= useState("");
  var [protocoloEmergencias, setProtocoloEmergencias]= useState("");
  var [descripcionProtocoloEmergencias, setDescripcionProtocoloEmergencias]= useState("");
  var [capacitacionChoferes, setCapacitacionChoferes]= useState("");
  var [fecha_de_controlCapacitacionChoferes, setFecha_de_controlCapacitacionChoferes]= useState(moment().format('YYYY-MM-DD'));
  
  //manejo de tiempos del servidor
  let [errorServidor, setErrorServidor] = useState(null);

  let [isLoading2, setIsLoading2] = useState(false);
  let [desplegables, setDespletables] = useState([]);
  let [sumarImg,setSumarImg]= React.useState(false);
  let [Image, setImage] = React.useState([]);
  var [valorInicial, setValorInicial]= useState('');

  var [edit,setEdit]= useState(false);
  var [ID,setID]= useState();


  const [declaracion,setDeclaracion]=useState(false)
  const [declaracionI,setDeclaracionI]=useState(false)

  const [nombreUsuario, setNombreUsuario] = useState('')
  const [posicionUsuario, setPosicionUsuario] = useState('')


  const setDate = (event) => {
    setFechaAlta(moment(event).format("YYYY-MM-DD"))   
    setFechaVencimiento(moment(event).add(1,'year').format("YYYY-MM-DD"))  
  }



  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
    
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {   
    if (status === 'headers_received') {

    setImage(file)
    setSumarImg(false) }
  
  }
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files,allFiles) => {

    const fotos = []
    let newData = {  title: "",
    description: "",
    image_url: "", };
  
    files.map(f => fotos.push({
      title: f.meta.name,
    description: f.meta.type,
    image_url: f.meta.previewUrl,
    img:f.file
    }))

    //console.log(fotos)
    setImage(fotos)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false)
    allFiles.forEach(f => f.remove())
    //setDisableGuardar(false)

  }
  
  //funcion para guardar doc
  const guardarDocumento = async ()=>{
    setErrorServidor(false)
    setError('')
    if(edit==false){
      await UserService.postBienestarAnimalesTransporte(fechaAlta,fechaVencimiento,cuit,nombreCompania,controlHabilitacionCamiones,fecha_de_controlHabilitacionCamiones,controlEstadoCamion,
        descripcionControlEstadoCamion,
        cantidadYEstadoAnimales,avisoAPlanta,protocoloEmergencias,descripcionProtocoloEmergencias,
        capacitacionChoferes,fecha_de_controlCapacitacionChoferes,email,declaracion,'xx',1,'FormAnimalTransporte', nombreUsuario, posicionUsuario).then(
          response=>{
  
            if(response.status==201){
              history.push('/app/gestionDoc')
              props.actualizar()

              props.cerrar()

            }
            if(response.status==403){
              setErrorServidor(true)
              setError(<p>Ya hay cargado un documento con el CUIT-RENSPA todavia esta vigente</p>)}
            },
            error=>{  
              if(error.response.status==403){
                setErrorServidor(true)
                setError(<p>Ya hay cargado un documento con el CUIT todavia esta vigente</p>)
              }else{
                setErrorServidor(true)
                setError(<p>Por favor complete todos los campos</p>)
              }
              
            
    
            }
        ) 
    }else{

      await UserService.putBienestarAnimalesTransporte(ID, fechaAlta,fechaVencimiento,cuit,nombreCompania,controlHabilitacionCamiones,fecha_de_controlHabilitacionCamiones,controlEstadoCamion,
        descripcionControlEstadoCamion,
        cantidadYEstadoAnimales,avisoAPlanta,protocoloEmergencias,descripcionProtocoloEmergencias,
        capacitacionChoferes,fecha_de_controlCapacitacionChoferes,email,declaracion,'xx',1,'FormAnimalTransporte', nombreUsuario, posicionUsuario).then(
          response=>{
  
            if(response.status==200){
              history.push('/app/gestionDoc')
              props.actualizar()

              props.cerrar()
              
            }
            if(response.status==403){
              setErrorServidor(true)
              setError(<p>Ya hay cargado un documento con el CUIT-RENSPA todavia esta vigente</p>)}
            },
            error=>{  
              if(error.response.status==403){
                setErrorServidor(true)
                setError(<p>Ya hay cargado un documento con el CUIT todavia esta vigente</p>)
              }else{
                setErrorServidor(true)
                setError(<p>Por favor complete todos los campos</p>)
              }
              
            
    
            }
        ) 
    }

   
  }


   //Genero PDF
   const imprimirPDF = ()=>{
    let form_data = new FormData();

  const info = ({fechaAlta,fechaVencimiento,'cuitProveedor':cuit,nombreCompania,controlHabilitacionCamiones,fecha_de_controlHabilitacionCamiones,controlEstadoCamion,
    descripcionControlEstadoCamion,
    cantidadYEstadoAnimales,avisoAPlanta,protocoloEmergencias,descripcionProtocoloEmergencias,
    capacitacionChoferes,fecha_de_controlCapacitacionChoferes,'email':'ariel@ariel.com','doc_url':'','codigo':'xx','revision':1,'titulo':'FormAnimalTransporte'})

      for (const [key, value] of Object.entries(info)) {
        form_data.append(key,value)
      }

      UserService.generarPDF(form_data,'FormAnimalTransporte')
  }
  useEffect(() => {
      setCuit(user.cuit)
      //listado proveedores
      UserService.getListProveedor().then(
        response => {
        if(response.status==200){
          const dataTropaConsig = response.data.users.map(a =>({cuit: a.cuit, label:a.razonS + '-' + a.cuit, email:a.email}) )
          setDespletables(dataTropaConsig) 
          let dataInicial = props.dataServidor
        if (dataInicial!=undefined){
          setID(dataInicial['id'])
          setFechaAlta(dataInicial['fecha_de_alta'])
          setFechaVencimiento(dataInicial['fecha_de_vencimiento'])
          setNombreCompania(dataInicial['nombreCompania'])
          setCuit(dataInicial['cuitProveedor'])
          setControlHabilitacionCamiones(dataInicial['controlHabilitacionCamiones'])
          setFecha_de_controlHabilitacionCamiones(dataInicial['fecha_de_controlHabilitacionCamiones'])
          setControlEstadoCamion(dataInicial['controlEstadoCamion'])
          setDescripcionControlEstadoCamion(dataInicial['descripcionControlEstadoCamion'])
          setCantidadYEstadoAnimales(dataInicial['cantidadYEstadoAnimales'])
          setAvisoAPlanta(dataInicial['avisoAPlanta'])
          setProtocoloEmergencias(dataInicial['protocoloEmergencias'])
          setDescripcionProtocoloEmergencias(dataInicial['descripcionProtocoloEmergencias'])
          setCapacitacionChoferes(dataInicial['capacitacionChoferes'])
          setFecha_de_controlCapacitacionChoferes(dataInicial['fecha_de_controlCapacitacionChoferes'])
          setDeclaracion(dataInicial['firmo'])
          setEmail(dataInicial['email'])
          //defino la declaracion inicial para luego
          setDeclaracionI(dataInicial['firmo'])
          setNombreUsuario(dataInicial['nombre_usuario'])
          setPosicionUsuario(dataInicial['posicion_usuario'])

          const index = dataTropaConsig.findIndex(x=>x.label===dataInicial['nombreCompania'])

          setValorInicial(dataTropaConsig[index])
          
         setEdit(true)
        }
        }
        },
        error => {
          if(error.response.status==401){}
          }
        );
        
    }, []);

    const handleChangeProveedor = (e, value) => {
      if (value && value.cuit!=undefined) {
        setNombreCompania(value.label.split('-')[0]);
        setCuit(value.cuit.toString());
        setEmail(value.email.toString())
      } else {
        setNombreCompania('');
        setCuit('');
        setEmail('')}
      }

  
  
  return (
    <>
      
            <div className={classes.marginNormal}>
            <Fade in={errorServidor}>
                  <Typography color="secondary" className={classes.errorMessage}>
                      {error}
                  </Typography>
                </Fade> 
            </div>

            <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item container spacing={2} lg={12} xs={12} sm={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}> 
                <Typography variant="h4" align="center" gutterBottom>
                  Bienestar Animal Empresas Transportistas
                </Typography>
                </Grid>
                {rol!=4 && (
                <Grid item lg={6} md={6} sm={12} xs={12}> 
                  <InputLabel id="type">Fecha de Carga</InputLabel>
                      <ResponsiveDatePickers
                      value={fechaAlta}
                      label="Date desktop"
                      inputFormat="dd-MMMM-yyyyy"
                      setValorFecha={setDate}
                      
                      ></ResponsiveDatePickers> 
                </Grid>
                )}
                {rol!=4 ? ( 
                <Grid item lg={6} xs={12} sm={12}>                
                  <InputLabel id="razonSocial">Proveedor</InputLabel>

                  <Autocomplete
                    disablePortal
                    noOptionsText={'Sin opciones'}
                    id="combo-box-demo"
                    options={desplegables}
                    value={nombreCompania}
                    //autoSelect={true}
                    clearOnBlur={false} 

                    onChange={handleChangeProveedor}
                    renderInput={(params) =>(
                      <TextField 
                      {...params} 
                        type="text"
                        variant="outlined"
                        error={nombreCompania==''?true:false}

                      
                          />)}
                  />
                  
                </Grid> ):(
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                  <Grid item lg={6} xs={12} sm={12}>
                  <TextField
                  autoFocus
                  id="razonSocial"
                  variant="outlined"
                  label="Razon Social"
                  disabled
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={nombreCompania}

                  onChange={e => setNombreCompania(e.target.value)}
                  margin="normal"
                  placeholder="Razon Social"
                  type="text"
                  fullWidth
                  required/>
                </Grid>
                </Grid>
                )}
                
                <Grid item lg={4} xs={12} sm={6}>
                  <InputLabel id="politica">1.	El Control de habilitación de camiones. Están vigentes? </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"
                    error={controlHabilitacionCamiones==''?true:false}

                    id="sector"
                    value={controlHabilitacionCamiones}
                    label="Completar"
                    onChange={(e)=>{setControlHabilitacionCamiones(e.target.value)}}
                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                   <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                  <InputLabel id="type">Última fecha de habilitación:</InputLabel>
                  <ResponsiveDatePickers
                      value={fecha_de_controlHabilitacionCamiones}
                      label="Date desktop"
                      inputFormat="dd-MMMM-yyyyy"
                      setValorFecha={(e)=>{setFecha_de_controlHabilitacionCamiones(moment(e).format('YYYY-MM-DD'))}}
                      
                      ></ResponsiveDatePickers> 
                </Grid>      
                <Grid item lg={6} xs={12} sm={6}>
                  <InputLabel id="capacitacion">2.	Estado del mantenimiento y limpieza del transporte es controlado?   </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"
                    error={controlEstadoCamion==''?true:false}

                    id="sector"
                    value={controlEstadoCamion}
                    label="Completar"
                    onChange={(e)=>{setControlEstadoCamion(e.target.value)}}
                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                    <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                  <TextField id="capacitacion" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  error={descripcionControlEstadoCamion==''?true:false}
                  InputLabelProps={{ shrink: true }}

                  value={descripcionControlEstadoCamion}
                  onChange={e => setDescripcionControlEstadoCamion(e.target.value)}
                  margin="normal"
                  placeholder="Breve descripción del procedimiento:"
                  variant="outlined"
                  label="Breve descripción del procedimiento:"
                  type="text"
                  fullWidth/>
                
                </Grid> 
                <Grid item container  lg={6} xs={12} sm={6}>
                  <InputLabel id="capacitacion">3.	La cantidad de animales a cargar según categorías, pesos y capacidad de camiones es controlada? </InputLabel>
                  <Select
                      labelId="type"
                      variant="outlined"
                      margin="normal"
                      id="sector"
                      value={cantidadYEstadoAnimales}
                      error={cantidadYEstadoAnimales==''?true:false}

                      label="Completar"
                      onChange={(e)=>{setCantidadYEstadoAnimales(e.target.value)}}
                      required
                    // disabled={compradorEst()}
                    className={classes.marginNormal}
                    
                    >
                      <MenuItem value={'SI'}>Si</MenuItem>
                      <MenuItem value={'NO'}>No</MenuItem>
                    </Select>
                   
                </Grid> 
                <Grid item lg={6} xs={12} sm={6}>
                  <InputLabel id="capacitacion">4.	Se da aviso de horario aproximado de arribo a la planta?  </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"
                    id="sector"
                    value={avisoAPlanta}
                    error={avisoAPlanta==''?true:false}

                    label="Completar"
                    onChange={(e)=>{setAvisoAPlanta(e.target.value)}}
                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  > 
                    <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                </Grid> 
                <Grid item lg={5} xs={12} sm={6}>
                  <InputLabel id="capacitacion">5.	Protocolo ante accidentes o emergencias durante el transporte. Tiene?  </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"
                    error={protocoloEmergencias==''?true:false}

                    id="sector"
                    value={protocoloEmergencias}
                    label="Completar"
                    onChange={(e)=>{setProtocoloEmergencias(e.target.value)}}
                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                    <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                  <TextField id="capacitacion" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={descripcionProtocoloEmergencias}
                  error={descripcionProtocoloEmergencias==''?true:false}
                  InputLabelProps={{ shrink: true }}

                  onChange={e => setDescripcionProtocoloEmergencias(e.target.value)}
                  margin="normal"
                  placeholder="Breve Descripción:"
                  variant="outlined"
                  label=""
                  type="text"
                  fullWidth/>
                </Grid> 
                <Grid item lg={7} xs={12} sm={6}>
                  <InputLabel id="capacitacion">5.	Se realizan capacitaciones a los choferes y personal de carga y/o descarga sobre Bienestar animal? </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"
                    error={capacitacionChoferes==''?true:false}

                    id="sector"
                    value={capacitacionChoferes}
                    label="Completar"
                    onChange={(e)=>{setCapacitacionChoferes(e.target.value)}}
                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                    <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                  <InputLabel id="type">Ultima fecha de capacitación:</InputLabel>
                  <ResponsiveDatePickers
                      value={fecha_de_controlCapacitacionChoferes}
                      label="Date desktop"
                      inputFormat="dd-MMMM-yyyyy"
                      setValorFecha={(e)=>{setFecha_de_controlCapacitacionChoferes(moment(e).format('YYYY-MM-DD'))}}                     
                      ></ResponsiveDatePickers> 
                </Grid> 
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="nombre_usuario" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={nombreUsuario}
                    error={nombreUsuario==''?true:false}

                    onChange={e => setNombreUsuario(e.target.value)}
                    margin="normal"
                    placeholder="Nombre"
                    variant="outlined"
                    label="Nombre"
                    type="text"
                    fullWidth/>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="posicion_usuario" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={posicionUsuario}
                    error={posicionUsuario==''?true:false}

                    onChange={e => setPosicionUsuario(e.target.value)}
                    margin="normal"
                    placeholder="Cargo que ocupa en la empresa"
                    variant="outlined"
                    label="Cargo"
                    type="text"
                    fullWidth/>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="email_usuario" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={email}
                    error={email==''?true:false}

                    onChange={e => setEmail(e.target.value)}
                    margin="normal"
                    placeholder="Email"
                    variant="outlined"
                    label="Email"
                    type="text"
                    fullWidth/>
                </Grid>
                <Grid>
                <p><strong>COMPROMISO.</strong></p>
                <p><span >Al completar este formulario declaro que la empresa transportista se compromete con la comunicaci&oacute;n y capacitaci&oacute;n de su personal en Bienestar animal, prohibiendo cualquier tipo de conducta o acto voluntario de abuso o maltrato, como por ejemplo:</span></p>
                <p><span >1). arrastre de animales conscientes ca&iacute;dos; 
                  2). uso de picanas el&eacute;ctricas en zonas sensibles como ojos, o&iacute;dos, nariz, ano, test&iacute;culos, </span><span >ubre, perin&eacute;</span><span> o panza; 3) golpearlos con las puertas intencionalmente; 4) manejo malicioso del ganado por encima de otros ya sea en forma manual o con equipo motorizado; 5) intencionalmente descargar los animales del cami&oacute;n sin una rampa adecuada; 6) golpear o patear un animal</span></p>
                <p>&nbsp;</p>
                </Grid>
                <Grid item lg={12} xs={12} sm={12}>
                  <FormControlLabel
                      value="consgi"
                      control={<Checkbox color="primary"       checked={declaracion}/>}
                      className={classes.control}
                      onClick={()=>{setDeclaracion(value => !value)}}

                      label="Al guardar declaro bajo juramento que los datos consignados en la presente declaracion jurada son veridicos y me hago responsable respecto de la veracidad de los mismos"
                    />
                </Grid>
                {/* <Grid item lg={10} xs={12} sm={12}>
                  <p>Al guardar declaro bajo juramento que los datos consignados en la presente declaracion jurada son veridicos y me hago responsable respecto de la veracidad de los mismos</p>
                </Grid> */}
                {/* <Grid item lg={6} xs={12} sm={12}>
                  <FormControlLabel
                    value="consgi"
                    control={<DownloadIcon/>}  
                    className={classes.control}

                    label="Descargar Documento"
                    onClick={()=>{imprimirPDF()}}
                  />
          
                  </Grid>
        

                  {!sumarImg&& 
                  <Grid item lg={6} xs={12} sm={12}>
                  <FormControlLabel
                  value="consgi"
                  control={<AddCircleIcon/>}  
                  className={classes.control}

                  label="Cargar Certificado"

                  onClick={()=>{setSumarImg(value=>!value)}}
                />
                
                  </Grid> }
                  {sumarImg&& 

                  <Grid item lg={12} xs={12} sm={12}>
                  <Dropzone
                    classNames={classes.dropZone}
                    styles={{ dropzone: { overflow: 'hidden', margin:'10px 0px'}, submitButton: {padding:'15px',
                    fontSize: '1.2em',}   }}
                    /* getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    onSubmit={handleSubmit}
                    PreviewComponent={Preview} 
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}


                    accept="image/*,.pdf"
                    inputContent='Arrastra tus archivos o hace click aqui'
                   /*  submitButtonContent="Guardar Datos"
                    inputWithFilesContent='Sumar otro archivo' 
                      /> 
                  </Grid>  }  */}
              </Grid>    
              <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12} style={{display:'flex', justifyContent: 'center'}}>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    fullWidth

                     onClick={() => 
                      //UserService.generatePDF()
                      guardarDocumento(props.history)
                      
                     
                     /*(fecha_de_alta,fecha_de_vencimiento,cuitProveedor,nombreCompania,provincia,localidad,politicaBienestar,descPoliticaBienestar,
                        capacitacionPersonal,fecha_de_capacitacionPersonal,
                        manga,corralCierre,rampa,manejoDeAnimales,descripcionManejoAnimales,manejodeCarga,descripcionManejoCarga, email, doc_url) */
                    }  
/* 
                    disabled={
                      titulo.length === 0 ||
                      descripcion.length === 0 ||
                      proveedor.length === 0 ||
                      Image.length === 0 

                    }  */
                    size="large"
                    variant="contained"
                    color="primary"
                    
                    className={classes.createAccountButton}
                    //component={Link} to={`/app/gestionDoc`}
                    >

                  Guardar
                  </Button> 
                </Grid>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    className={classes.createAccountButton}
                    size="large"
                    variant="contained"
                    color="primary" 
                    fullWidth
                    component={Link} to={`/app/gestionDoc`}
                    onClick={props.cerrar}>

                    Cancelar
                  </Button>  
                </Grid>       
              </Grid>      
            </Grid>    
            </div>  
          
    </>    
  );
}
export default FormBienesTransp;