import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel, Checkbox
} from "@material-ui/core";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import NuevoUsuarioDeCarga from "../nuevoUsuarioDeCarga/NuevoUsuarioDeCarga";
import NuevoUsuarioFeedlot from "../nuevoUsuarioFeedlot/NuevoUsuarioFeedlot";
import NuevoUsuarioFaena from "../nuevoUsuarioFaena/NuevoUsuarioFaena";
import UserService from "../../context/UserService";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const ToggleButton = styled(MuiToggleButton)(({ selectedColor, borderColorCustom }) => ({
  color: 'inherit',
  borderColor: borderColorCustom,
  borderRadius: '15px',
  maxHeight: '60px',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: selectedColor,
  },
}));

// funcion General para editar campos
function Editar(props) {
  var classes = useStyles();
  const { editUser } = useContext(UserStateContext);
  const theme = useTheme();
  const { user } = useContext(UserStateContext);

  //accedo a la informacion de usuarios ya descargada.
  const datatableData = JSON.parse(localStorage.getItem('datatableData'));

  // saco indice para la informacion de usuarios. 
  const params = window.location.href
  // numero de indice 
  const idDataTable = params.split('/')[6]
  /*   useEffect(() => {
      if (datatableData[idDataTable]['type']=='Proveedor') {
        setHide(true);
        setAlignment('Proveedor')
      }else{
        setAlignment('Rioplatense')
  
        setHide(false);
      }
    }, []); */
  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  // local
  var [id, setId] = useState()
  var [nameValue, setNameValue] = useState();
  var [lastNameValue, setlastNameValue] = useState();
  var [tipo, setTipo] = React.useState();
  var [legajo, setLegajo] = useState();
  var [rol, setRol] = useState();
  var [sector, setSector] = useState();
  var [bp, setBp] = useState();
  var [razonSocial, setRazonSocial] = useState();
  var [cuit, setCUIT] = useState();
  var [email, setEmail] = useState();
  var [passwordValue, setPasswordValue] = useState("");
  var [passwordValue2, setPassword2Value] = useState("");
  var [active, setActive] = useState();
  var [telefono, setTelefono] = useState();
  var [enviarEmail, setEnviarEmail] = useState(false)
  var [alignment, setAlignment] = React.useState([]);
  var [dataUser, setDataUser] = React.useState([]);
  const [selectedGrupoCompra, setSelectedGrupoCompra] = React.useState(null);
  const [grupoCompras, setGrupoCompras] = React.useState([]);
  const [isFletero, setIsFletero] = useState(false);

  var [showPassword1, setShowPassword1] = useState(false);
  var [showPassword2, setShowPassword2] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  async function getInitialData() {
    try {
      const responses = await Promise.all([
        UserService.getUserInfo(idDataTable),
        UserService.getGrupoCompras(),
      ]);
      if (responses[0].status === 200) {
        const response = responses[0];
        setDataUser(response.data[0])
        setId(idDataTable)
        setNameValue(response.data[0]['username'])
        setlastNameValue(response.data[0]['last_name'])
        setTipo(response.data[0]['type'])
        setAlignment(response.data[0]['type'])
        if (response.data[0]['telefono'] != undefined) {
          setTelefono(response.data[0]['telefono'])
        } else {
          setTelefono(0)
        }
        if (response.data[0]['type'] == 'Proveedor') {
          setHide(true);
        } else {
          setHide(false);

        }
        setLegajo(response.data[0]['legajo'])
        setRol(response.data[0]['rol'])
        setSector(response.data[0]['sector'])
        setBp(response.data[0]['sector'])
        setRazonSocial(response.data[0]['razonS'])
        setCUIT(response.data[0]['cuit'])
        setEmail(response.data[0]['email'])
        setActive(response.data[0]['is_active'])
        setIsFletero(response.data[0]['is_fletero'])
        if (response.data[0]['grupo_compras']) {
          setSelectedGrupoCompra(response.data[0]['grupo_compras'][0].codigo)
        }
      }
      if (responses[1].status === 200) {
        if (responses[0].data[0]['grupo_compras']) {
          const newArray = [...responses[0].data[0]['grupo_compras'], ...responses[1].data];
          setGrupoCompras(newArray);
        } else {
          setGrupoCompras(responses[1].data);
        }
      }
    } catch (error) {
      console.log(error);
      setError('Error al cargar la información');
    }
  }

  useEffect(() => {
    getInitialData();
  }, [])


  const [hide, setHide] = useState(false);

  const handleDisable = () => {
    if (tipo == 'Rioplatense') {
      return (
        (passwordValue != passwordValue2) || (!email)
      )
    } else if (tipo == 'Proveedor') {
      return (passwordValue != passwordValue2)
    } else {
      return false
    }

  }

  useEffect(() => {
    if (!email) {
      setActive(false)
    }
  }, [email])

  const handleCheck = (value) => {
    setTipo(value);
    if (value == 'Proveedor') {
      clearState()
      setRol('4')

      setHide(true);
    } else {
      setHide(false);

    }
  };

  const handleShowPassword1 = () => {
    setShowPassword1((prev) => !prev);
  }

  const handleShowPassword2 = () => {
    setShowPassword2((prev) => !prev);
  }

  const clearState = () => {
    setLegajo('')
    setBp('')
    setRazonSocial('')
    setCUIT('')
    setRol('')


  }
  const handleChangeSector = (e) => {
    setSector(e.target.value);
  };
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleChangeRol = (e) => {
    setRol(e.target.value);
  };

  const handleTipoCompra = (e) => {
    setActive(value => !value)
  }

  const putUser = async (history) => {
    const response = await editUser(
      id,
      nameValue,
      passwordValue,
      passwordValue2,
      email,
      nameValue,
      lastNameValue,
      cuit,
      razonSocial,
      rol,
      tipo,
      sector,
      legajo,
      bp,
      telefono,
      active,
      enviarEmail,
      history,
      setIsLoading,
      setError,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      selectedGrupoCompra,
      null,
      isFletero
    )
    /* if( response.status=200){
      history.push(`/app/tables`)
    } */
  }

  return (
    <>
      {/* <PageTitle title="Editar Usuario" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div>
              <Typography color="secondary" className={classes.errorMessage}>
                {error}
              </Typography>
            </div>
            <div className={classes.form}>

              <Grid container spacing={2}>
                {!isSmallScreen && (
                  <Grid item lg={12} xs={12} sm={6}>
                    <InputLabel id="type" style={{ marginBottom: '3px' }}>Tipo de Usuario</InputLabel>
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      disabled
                      onChange={handleAlignment}
                    >
                      {user.permissions.usuarios_can_create_intern && (
                        <ToggleButton value="Rioplatense" aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { handleCheck('Rioplatense') }}>Rioplatense</ToggleButton>
                      )}
                      {user.permissions.usuarios_can_create_external && (
                        <ToggleButton value="Proveedor" aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { handleCheck('Proveedor') }}>Proveedor</ToggleButton>
                      )}
                      {user.permissions.usuarios_can_create_external && (
                        <ToggleButton value="Contacto_de_carga" aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { handleCheck('ContactoCarga') }}>Contacto de Carga</ToggleButton>
                      )}
                      {user.permissions.usuarios_can_create_external && (
                        <ToggleButton value="FeedLot" aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { handleCheck('FeedLot') }}>FeedLot</ToggleButton>
                      )}
                      {user.permissions.usuarios_can_create_intern && (
                        <ToggleButton value="usuarioFaena" aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { handleCheck('usuarioFaena') }}>Usuario Faena</ToggleButton>
                      )}

                    </ToggleButtonGroup>
                  </Grid>
                )}
                {isSmallScreen && (
                  <Grid item lg={12} md={6} sm={12} xs={12} style={{ marginTop: '15px' }}>
                    <FormControl fullWidth>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Tipo de usuario
                      </InputLabel>
                      <Select
                        defaultValue={alignment}

                        native
                        disabled
                        value={alignment}
                        margin="normal"
                        inputProps={{
                          name: 'tipo_usuario',
                          id: 'uncontrolled-native',
                        }}
                        sx={{
                          color: theme.palette.text.primary,
                          icon: {
                            color: theme.palette.icons.color
                          }
                        }}
                      >
                        {user.permissions.usuarios_can_create_intern && (<option value={"Rioplatense"}>Interno</option>)}
                        {user.permissions.usuarios_can_create_external && (<option value={"Proveedor"}>Proveedor</option>)}
                        {user.permissions.usuarios_can_create_external && (<option value={"Contacto_de_carga"}>Contacto de Carga</option>)}
                        {user.permissions.usuarios_can_create_external && (<option value={"FeedLot"}>Feedlot</option>)}
                        {user.permissions.usuarios_can_create_intern && (<option value={"usuarioFaena"}>Usuario de Faena</option>)}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {(tipo == 'Rioplatense' || tipo == 'Proveedor') && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoFocus
                        id="name"
                        variant="outlined"
                        label="Nombre"

                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={nameValue}
                        onChange={e => setNameValue(e.target.value)}
                        margin="normal"
                        placeholder="Nombre"
                        type="text"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField id="lastName" InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                        value={lastNameValue}
                        onChange={e => setlastNameValue(e.target.value)}
                        margin="normal"
                        placeholder="Apellido"
                        variant="outlined"
                        label="Apellido"
                        type="text"
                        fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="email"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="Email"
                        placeholder="Email Adress"
                        type="email"
                        fullWidth
                        required />
                    </Grid>
                    {hide && (
                      <Grid item lg={6} xs={12} sm={6}>
                        <TextField
                          id="razonS"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={razonSocial}
                          onChange={e => setRazonSocial(e.target.value)}
                          margin="normal"
                          variant="outlined"
                          label="Razón Social"
                          placeholder="Razón Social"
                          type="text"
                          fullWidth
                          disabled
                        />
                      </Grid>)}
                    {!hide && (
                      <Grid item lg={6} xs={12} sm={12}>

                        <InputLabel id="type">Sector</InputLabel>
                        <Select
                          labelId="type"
                          variant="outlined"
                          id="sector"
                          value={sector}
                          label="Tipo de usuario"
                          onChange={handleChangeSector}
                          fullWidth
                        >
                          <MenuItem value={'Recursos Humanos'}>Recursos Humanos</MenuItem>
                          <MenuItem value={'SegHigiene'}>Seguridad e Higiene</MenuItem>
                          <MenuItem value={'Corrales'}>Corrales</MenuItem>
                          <MenuItem value={'Vigilancia'}>Vigilancia</MenuItem>
                          <MenuItem value={'Ventas Consumo'}>Ventas Consumo</MenuItem>
                          <MenuItem value={'Exportacion'}>Exportación</MenuItem>
                          <MenuItem value={'Compras de Hacienda'}>Compras de Hacienda</MenuItem>
                          <MenuItem value={'Compras Genenerales'}>Compras Generales</MenuItem>
                          <MenuItem value={'Impuestos'}>Impuestos</MenuItem>
                          <MenuItem value={'Administración'}>Administración</MenuItem>
                          <MenuItem value={'Gerencia'}>Gerencia</MenuItem>
                          <MenuItem value={'Sistemas'}>Sistemas</MenuItem>
                          <MenuItem value={'Produccion'}>Producción</MenuItem>
                          <MenuItem value={'Pycp'}>PyCP</MenuItem>
                          <MenuItem value={'Calidad'}>Calidad</MenuItem>
                          <MenuItem value={'Control De Gestion'}>Control de Gestión</MenuItem>
                          <MenuItem value={'Rendering'}>Rendering</MenuItem>



                        </Select>
                      </Grid>)}
                    <Grid item xs={12} sm={6}>
                      <TextField id="contraseña"
                        value={passwordValue}
                        onChange={e => setPasswordValue(e.target.value)}
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword1}
                                edge="end"
                              >
                                {showPassword1 ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                        }}
                        margin="normal"
                        variant="outlined"
                        label="Contraseña"
                        placeholder="Contraseña"
                        type={showPassword1 ? "text" : "password"}
                        fullWidth
                        required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField id="contraseña2"
                        value={passwordValue2}
                        onChange={e => setPassword2Value(e.target.value)}
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword2}
                                edge="end"
                              >
                                {showPassword2 ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                        }}
                        margin="normal"
                        variant="outlined"
                        label="Repita Contraseña"
                        placeholder="Repita Contraseña"
                        type={showPassword2 ? "text" : "password"}
                        fullWidth
                        required />
                    </Grid>
                    {!hide && (
                      <Grid item lg={6} xs={12} sm={12}>

                        <InputLabel id="type">ROL</InputLabel>
                        <Select
                          labelId="type"
                          variant="outlined"
                          id="type"
                          value={rol}
                          label="ROL"
                          onChange={handleChangeRol}
                          fullWidth
                        >
                          <MenuItem value={'1'}>Comprador full</MenuItem>
                          <MenuItem value={'2'}>Comprador estándar</MenuItem>
                          <MenuItem value={'3'}>Corrales</MenuItem>
                          {hide && (
                            <MenuItem value={'4'}>Proveedor</MenuItem>)}
                          <MenuItem value={'5'}>Interno</MenuItem>
                          <MenuItem value={'10'}>Interno con Compras</MenuItem>
                          <MenuItem value={'6'}>Administrador</MenuItem>
                        </Select>
                      </Grid>)}
                    {!hide && (
                      <Grid item lg={6} xs={12} sm={6}>
                        <TextField
                          id="legajo"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={legajo}
                          onChange={e => setLegajo(e.target.value)}
                          margin="normal"
                          variant="outlined"
                          label="Legajo"
                          placeholder="Legajo"
                          type="text"
                          fullWidth />
                      </Grid>)}
                    {(!hide && tipo == "Rioplatense" && (rol == "1" || rol == "2")) && (
                      <Grid item lg={6} md={12} xs={12} sm={12}>
                        <InputLabel id="gc">Grupo de Compras*</InputLabel>
                        <Select
                          labelId="gc"
                          id="gc-select"
                          label="Grupo de Compras"
                          variant="outlined"
                          error={selectedGrupoCompra === null}
                          fullWidth
                          value={selectedGrupoCompra}
                          onChange={(e) => { setSelectedGrupoCompra(e.target.value) }}
                        >
                          {grupoCompras.length > 0 && grupoCompras.map((grupo) => (
                            <MenuItem key={grupo.codigo} value={grupo.codigo}>{grupo.codigo} - {grupo.descripcion}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}

                    {hide && (
                      <Grid item lg={6} xs={12} sm={6}>
                        <TextField
                          id="vp"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={bp}
                          onChange={e => setBp(e.target.value)}
                          margin="normal"
                          variant="outlined"
                          disabled
                          label="BP"
                          placeholder="BP"
                          type="text"
                          fullWidth />
                      </Grid>)}
                    {hide && (
                      <Grid item lg={6} xs={12} sm={6}>
                        <TextField
                          id="vp"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={telefono}
                          onChange={e => setTelefono(e.target.value)}
                          margin="normal"
                          variant="outlined"
                          label="Telefono"
                          placeholder="BP"
                          type="number"
                          fullWidth />
                      </Grid>
                    )}
                    <Grid item lg={6} xs={12} sm={12}>
                      <InputLabel id="type">Estado de Usuario</InputLabel>
                      <RadioGroup aria-label="tipo" row name="tipo" value={active} onChange={handleTipoCompra} >
                        <FormControlLabel value="true" control={<Radio />} label="Activo" />
                        <FormControlLabel value="false" control={<Radio />} label="Inactivo" />
                      </RadioGroup>
                    </Grid>
                    {hide && (
                    <Grid item lg={6} xs={12} sm={12} style={{ display: "flex", alignItems: 'center' }}>
                        <InputLabel id="is-fletero">Es Fletero: </InputLabel>
                        <Checkbox color="primary" onChange={() => { setIsFletero(value => !value) }} checked={isFletero}/>
                    </Grid>
                    )}
                    {(passwordValue.length != 0 && passwordValue2.length != 0) && (
                      <Grid item lg={6} xs={12} sm={12} style={{ display: "flex", alignItems: 'center' }}>
                        <InputLabel id="type">Enviar email con nueva contraseña:  </InputLabel>
                        <Checkbox label="Activo" color="primary" disabled={passwordValue.length == 0 || passwordValue2.length == 0} onChange={() => { setEnviarEmail(value => !value) }} />
                      </Grid>
                    )}

                    <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Grid item lg={3} xs={12} sm={12}>

                        <Button
                          onClick={() => {
                            putUser(props.history)
                          }
                          }

                          disabled={

                            handleDisable()
                          }
                          //component={Link} to={`/app/tables`}

                          size="large"
                          variant="contained"
                          color="primary"

                          className={classes.createAccountButton}
                        >
                          GUARDAR
                        </Button>
                      </Grid>
                      <Grid item lg={3} xs={12} sm={12}>

                        <Button
                          className={classes.createAccountButton}
                          size="large"
                          variant="contained"
                          color="primary"

                          component={Link} to={`/app/tables`}>

                          CANCELAR
                        </Button>
                      </Grid>
                    </Grid>



                    {/*  <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12}>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    fullWidth

                    onClick={() =>
                      
                      register(
                        nameValue,
                        passwordValue,
                        passwordValue2,
                        email,
                        nameValue,
                        lastNameValue,
                        cuit,
                        razonSocial,
                        rol,
                        tipo,
                        sector,
                        legajo,
                        bp,
                        telefono,
                        props.history,
                        setIsLoading,
                        setError,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                      ) 
                    }

                    disabled={
                      passwordValue.length === 0 ||
                      passwordValue2.length === 0 ||
                      email.length === 0
                    } 
                    size="large"
                    variant="contained"
                    color="primary"
                    
                    className={classes.createAccountButton}
                    >
                  GUARDAR
                  </Button> 
                </Grid>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    className={classes.createAccountButton}
                    size="large"
                    variant="contained"
                    color="primary" 
                    fullWidth
                    component={Link} to={`/app/tables`}>
                  
                    CANCELAR
                  </Button>  
                </Grid>       
              </Grid>  */}
                  </>
                )
                }
                {tipo == 'Contacto_de_carga' && (
                  <NuevoUsuarioDeCarga
                    value={dataUser}
                  ></NuevoUsuarioDeCarga>
                )}
                {tipo == 'FeedLot' && (
                  <NuevoUsuarioFeedlot
                    value={dataUser}
                  ></NuevoUsuarioFeedlot>
                )}
                {tipo == 'usuarioFaena' && (
                  <NuevoUsuarioFaena
                    value={dataUser}
                  ></NuevoUsuarioFaena>
                )}
                {/*  
               <Grid item xs={12} sm={6}>
                <TextField
                autoFocus
                id="name"
                variant="outlined"
                label="Nombre"

                InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },}}
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                margin="normal"
                placeholder="Nombre"
                type="text"
                fullWidth
                required/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField id="lastName" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },}}
                value={lastNameValue}
                onChange={e => setlastNameValue(e.target.value)}
                margin="normal"
                placeholder="Apellido"
                variant="outlined"
                label="Apellido"
                type="text"
                fullWidth/>
              </Grid> 
                
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  label="Email"
                  placeholder="Email Adress"
                  type="email"
                  fullWidth
                  required/>
              </Grid>
              {hide && (
              <Grid item lg={6} xs={12} sm={6}>
                <TextField
                  id="razonS"
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={razonSocial}
                  onChange={e => setRazonSocial(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  label="Razón Social"
                  placeholder="Razón Social"
                  type="text"
                  fullWidth
                  disabled  
                  />
              </Grid>)}
              {!hide && (
              <Grid item lg={6} xs={12} sm={12}>
              
                <InputLabel id="type">Sector</InputLabel>
                <Select
                  labelId="type"
                  variant="outlined"
                  id="sector"
                  value={sector}
                  label="Tipo de usuario"
                  onChange={handleChangeSector}
                  fullWidth
                >
                  <MenuItem value={'Recursos Humanos'}>Recursos Humanos</MenuItem>
                  <MenuItem value={'SegHigiene'}>Seguridad e Higiene</MenuItem>    
                  <MenuItem value={'Corrales'}>Corrales</MenuItem>
                  <MenuItem value={'Vigilancia'}>Vigilancia</MenuItem>    
                  <MenuItem value={'Ventas Consumo'}>Ventas Consumo</MenuItem>
                  <MenuItem value={'Exportacion'}>Exportación</MenuItem>    
                  <MenuItem value={'Compras de Hacienda'}>Compras de Hacienda</MenuItem>    
                  <MenuItem value={'Compras Genenerales'}>Compras Generales</MenuItem>
                  <MenuItem value={'Impuestos'}>Impuestos</MenuItem>    
                  <MenuItem value={'Administarcion'}>Administración</MenuItem>    
                  <MenuItem value={'Gerencia'}>Gerencia</MenuItem>
                  <MenuItem value={'Sistemas'}>Sistemas</MenuItem>    
                  <MenuItem value={'Produccion'}>Producción</MenuItem> 
                  <MenuItem value={'Pycp'}>PyCP</MenuItem> 
                  <MenuItem value={'Calidad'}>Calidad</MenuItem> 
                  <MenuItem value={'Control De Gestion'}>Control de Gestión</MenuItem> 
                  <MenuItem value={'Rendering'}>Rendering</MenuItem> 



                </Select>
              </Grid>)}
              <Grid item xs={12} sm={6}>
                <TextField id="contraseña" InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },}}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                variant="outlined"
                label="Contraseña"
                placeholder="Contraseña"
                type="password"
                fullWidth
                required/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField id="contraseña2" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={passwordValue2}
                  onChange={e => setPassword2Value(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  label="Repita Contraseña"
                  placeholder="Repita Contraseña"
                  type="password"
                  fullWidth
                  required/>
              </Grid>
              {!hide && (
              <Grid item lg={6} xs={12} sm={12}>
              
                <InputLabel id="type">ROL</InputLabel>
                <Select
                  labelId="type"
                  variant="outlined"
                  id="type"
                  value={rol}
                  label="ROL"
                  onChange={handleChangeRol}
                  fullWidth
                >
                  <MenuItem value={'1'}>Comprador full</MenuItem>
                  <MenuItem value={'2'}>Comprador estándar</MenuItem>    
                  <MenuItem value={'3'}>Corrales</MenuItem>
                  {hide && (
                  <MenuItem value={'4'}>Proveedor</MenuItem>    )}
                  <MenuItem value={'5'}>Interno</MenuItem>
                  <MenuItem value={'6'}>Administrador</MenuItem>    
                </Select>
              </Grid>)}
              {!hide && (
              <Grid item lg={6} xs={12} sm={6}>
                <TextField
                  id="legajo"
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={legajo}
                  onChange={e => setLegajo(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  label="Legajo"
                  placeholder="Legajo"
                  type="text"
                  fullWidth/>
              </Grid>)}
              
              {hide && (
              <Grid item lg={6} xs={12} sm={6}>
                <TextField
                  id="vp"
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={bp}
                  onChange={e => setBp(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  disabled
                  label="BP"
                  placeholder="BP"
                  type="text"
                  fullWidth/>
              </Grid>)}
              {hide && (
              <Grid item lg={12} xs={12} sm={6}>
                <Grid item lg={6} xs={12} sm={6}>
                <TextField
                  id="vp"
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={telefono}
                  onChange={e => setTelefono(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  label="telefono"
                  placeholder="BP"
                  type="number"
                  fullWidth/>
              </Grid>
              </Grid>
              
              )}
              
              {hide && (
              <Grid item lg={6} xs={12} sm={6}>
                <TextField
                  id="cuit"
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={cuit}
                  onChange={e => setCUIT(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  label="CUIT"
                  placeholder="CUIT"
                  type="text"
                  disabled

                  fullWidth/>
              </Grid>)}
              */}


              </Grid>
            </div>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
export default Editar;