import { makeStyles, Theme } from "@material-ui/core/styles"; import { darken, lighten } from '@mui/material/styles';

export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  rem: {
    fontSize: '0.1rem',

  },

  dropZone: {
    "&:dropzone": { overflow: 'hidden' }

  },
  buttonModal: {
    margin: '10px'
  },
  containerLogin: {
    width: "40%", display: "flex",
  },
  imgen: {
    width: "100%"
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  buttonSubmit: {
    padding: '15px',
    fontSize: '1.2em',
    marginTop: '17px'
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
  totales: {
    '& td': { backgroundColor: theme.palette.primary.main },
  },
  normal: {
    '& td': {
      [theme.breakpoints.down("md")]: {
        fontSize: '0.7rem',
        padding: '0'
      },

    },
    '& th': {
      [theme.breakpoints.down("md")]: {
        fontSize: '0.7rem',
        padding: '0'
      },
    }
  },
  tableOverflow: {
    overflow: 'auto'
  },
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    maxWidth: '70%',
    maxHeight: '70%',
    padding: '10px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: 1,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center',
  },
  formButtons: {
    width: "50%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonSubmit: {
    padding: '15px',
    fontSize: '1.2em',
    marginTop: '17px'

  },

  footerCell: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: "none",
    color: "white",
    fontSize: 14,
    fontWeight: "bolder"
  },
  rojoCircle: {
    color: 'red',
    padding: 0
  },
  verde: {
    color: 'green',
    padding: 0

  },
  yellow: {
    color: 'yellow',
    padding: 0
  },
  purple: {
    color: 'purple',
    padding: 0
  },
  blue: {
    color: 'blue',
    padding: 0
  },
  centerContent: {
    textAlign: 'center',
    justifyContent: 'center'
  }

}));
