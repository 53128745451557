import React, { useState,useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,  
}from "@material-ui/core";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import jwt_decodde from "jwt-decode";

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext,{ signOut }  from "../../context/UserContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useStyles from "./styles";


import UserService from "../../context/UserService";
//modal stylio
/* const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign:'center',

};   */

// funcion General para editar campos
function CambiarPass(props) {
  var classes = useStyles();
  const { editUser } = useContext(UserStateContext);

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  
  // local
  var [id, setId] = useState('');
  var [nameValue, setNameValue] = useState('');
  var [lastNameValue, setlastNameValue] = useState('');
  var [tipo, setTipo] = useState('');
  var [legajo, setLegajo] = useState('');
  var [rol, setRol] = useState('');
  var [sector, setSector] = useState('');
  var [bp, setBp] = useState('');
  var [razonSocial, setRazonSocial] = useState('');
  var [cuit, setCUIT] = useState('');
  var [email, setEmail] = useState('');
  var [passwordValue, setPasswordValue] = useState("");
  var [passwordValue2, setPassword2Value] = useState("");
  var [active, setActive] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  var [telefono, setTelefono] = useState(0);
  var [enviarEmail, setEnviarEmail] = useState(false)
  const [hide, setHide] = useState(false);
  var [feedlot, setFeedlot] = useState(null);
  var [Renspa, setRenspa] = useState("");
  var [titular, setTitular]= useState("");
  var [Localidad, setLocalidad] = useState("");
  var [Partido, setPartido] = useState("");
  var [Provincia, setProvincia] = useState("");
  var [Destino, setDestino] = useState("");

  // info de usuario
  var user = JSON.parse((localStorage.getItem('userData')))
  //var user = 41
  const nombreCompleto = () =>{
      if (nameValue && lastNameValue && tipo == 'Rioplatense')
      return (nameValue+ "  "+ lastNameValue)
      else if (nameValue && lastNameValue && tipo == 'Proveedor' )
      return(razonSocial)
      else if (cuit && tipo == 'FeedLot' )
      return(cuit)
      else if (cuit && tipo == 'Contacto_de_carga' )
      return(cuit)

  }
    
  useEffect(() => {
    UserService.getUserInfo(user['user_id']).then(
      response => {
        setId(user['user_id'])
        setNameValue(response.data[0]['username'])
        setlastNameValue(response.data[0]['last_name'])
        setTipo(response.data[0]['type'])
        if( response.data[0]['telefono']!=undefined){
          setTelefono(response.data[0]['telefono'])

        }
        if (response.data[0]['type']=='Proveedor'){
          setHide(true);   

        }else{
          setHide(false);
    
        }
        setLegajo(response.data[0]['legajo'])
        setRol(response.data[0]['rol'])
        setSector(response.data[0]['sector'])
        setBp(response.data[0]['sector'])
        setRazonSocial(response.data[0]['razonS'])
        setCUIT(response.data[0]['cuit'])
        setEmail(response.data[0]['email'])
        setActive(response.data[0]['is_active'])
        
      setFeedlot(response.data[0]['feedlot']["id"])
      setRenspa(response.data[0]['Renspa'])
      setTitular(response.data[0]['titular'])
      setLocalidad(response.data[0]['Localidad'])
      setPartido(response.data[0]['Partido'])
      setProvincia(response.data[0]['Provincia'])
      setDestino(response.data[0]['Destino'])

        

      },
      error => {
        }
      );
      if (user.last_login=='None'){
        setOpen(true)
      }
    }, []);

  
  const handleCheck = (event) => {
    setTipo(event.target.value);

    if (event.target.value=='Proveedor'){
      setHide(true);
      setRol('4')

    }else{
      setHide(false);

    }
    
  };
  
  const postEdit = async (history)=>{
    const origen = 'cambiarPass'
    const response = await editUser(
      id,
      nameValue,
      passwordValue,
      passwordValue2,
      email,
      nameValue,
      lastNameValue,
      cuit,
      razonSocial,
      rol,
      tipo,
      sector,
      legajo,
      bp,
      telefono,
      active,
      enviarEmail,
      history,
      setError,
      setIsLoading,
      origen,
      feedlot,
      Renspa,
      titular,
      Localidad,
      Partido,
      Provincia,
      Destino,
    )
    if ( response.status==200){
      signOut(history)
    }
  }

  return (
    <>
      <PageTitle title="Cambiar Contraseña" />
      <Grid justify="center" container spacing={4}>
        <Grid justify="center" item lg={10} md={12} sm={12} xs={12}>
          <Widget 
            title={nombreCompleto()}
            disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
              >
            <div>   
            <Typography color="secondary" className={classes.errorMessage}>
              {error}
            </Typography>   
            </div> 
            <div className={classes.form}>

            <Grid justify="center" lg={12} container spacing={2}>
              
              
             
              <Grid container  lg={12} spacing={2}>
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="contraseña" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={passwordValue}
                  onChange={e => setPasswordValue(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  label="Contraseña"
                  placeholder="Contraseña"
                  type="password"
                  fullWidth
                  required/>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="contraseña2" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={passwordValue2}
                    onChange={e => setPassword2Value(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    label="Repita Contraseña"
                    placeholder="Repita Contraseña"
                    type="password"
                    fullWidth
                    required/>
                </Grid>
             
              
              
              
              <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12} style={{display:'flex', justifyContent:'center'}}>
                <Grid item lg={3} xs={8} sm={8} style={{display:'flex', justifyContent:'center'}}>
                <Modal
                  hideBackdrop
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description">
                  <Box className={classes.Modal }>

                    <h3 id="child-modal-description">
                    Por favor cambia tu contraseña, luego de cambiarla se te va a dirigir al login nuevamente para iniciar sesión con tu nueva contraseña. 
                    </h3>
                    <Button
                          variant="contained"
                          color="primary"
                          size='medium'
                          onClick={()=>setOpen(false)}
                          className={classes.buttonModal}
                          >Continuar</Button>
                  </Box>
                </Modal>
                  <Button
                    onClick={() =>
                     postEdit(props.history)
                    }
                    //component={Link} to={`/app/home`}

                    disabled={
                    
                      passwordValue.length === 0 || 
                      passwordValue2.length === 0 ||
                      passwordValue !== passwordValue2
                    } 
                    size="medium"
                    variant="contained"
                    color="primary" 
                                      

                    className={classes.createAccountButton}
                    >
                  GUARDAR
                  </Button> 
                </Grid>
                
                </Grid>  
              </Grid>   
              </Grid>     
        
            </div>  
           
          </Widget> 
        </Grid> 
      </Grid>   
    </>    
  );
}
export default CambiarPass;