import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField, Link,
  InputLabel, ListItemText,
  Typography, useMediaQuery,
  FormControlLabel, Checkbox
} from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import { Autocomplete } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import UserService from '../../context/UserService';
import useStyles from "./styles";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UserStateContext from '../../context/UserContext';
import Box from '@mui/material/Box';
import Dropzone from 'react-dropzone-uploader'
import { set } from 'date-fns';
import ResponsiveDatePickers from "../dateRange/datePicker";
import moment from "moment";
import FormBienAnimal from "../formBienestAnimal/FormBienAnimal";
import FormBienesTransp from "../FormBienesTransp/FormBienesTransp";
import FormGrainFeed from "../formGrainFeed/FormGrainFeed";
import FormNatural from "../formNatural/FormNatural";
import FormDeclaracionGeneral from "../FormDeclaracionGeneral/FormDeclaracionGeneral";
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import FormOrganicRenspa from "../../components/FormOrganicRenspa/FormOrganicRenspa";
import FormOrganicTropa from "../../components/FormOrganicTropa/FormOrganicTropa";
import { useTheme } from "@material-ui/styles";
import FormControl from '@mui/material/FormControl';



const ToggleButton = styled(MuiToggleButton)(({ selectedColor, borderColorCustom }) => ({
  color: 'inherit',
  borderColor: borderColorCustom,
  borderRadius: '15px',
  maxHeight: '60px',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: selectedColor,
  },
}));
export default function EditDoc(props) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

  var classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  // local
  var [tipo, setTipo] = useState('');

  const [id, setID] = useState("");
  let [dataForm, setDataForm] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [proveedor, setProveedor] = useState("");

  //manejo de tiempos del servidor
  const [errorServidor, setErrorServidor] = useState(null);
  const [isLoading2, setIsLoading2] = useState(false);
  const [desplegables, setDespletables] = useState([]);
  const [sumarImg, setSumarImg] = useState(true);
  const [Image, setImage] = useState([]);
  const [fechaAlta, setFechaAlta] = useState()
  const [fechaVencimiento, setFechaVencimiento] = useState()
  const [alignment, setAlignment] = React.useState('');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    //console.log(props.dataRow)
    if (props.dataRow[0] == 'Check List de Bienestar Animal para campos') {
      setTipo('FormBienAnimal')
      setAlignment('FormBienAnimal')
    } else if (props.dataRow[0] == 'Check List Bienestar Animal Empresas Transportistas') {
      setTipo('FormAnimalTransporte')
      setAlignment('FormAnimalTransporte')
    } else if (props.dataRow[0] == 'Declaracion General'){
      setTipo('Declaracion General')
      setAlignment('Declaracion General')
    } else {
      //console.log(props.dataRow[0])
      setTipo(props.dataRow[0])
      setAlignment(props.dataRow[0])

    }

    UserService.getInfoDocumento(props.dataRow[9], props.dataRow[0]).then(
      response => {

        if (response.status == 200) {

          const info = response.data[0]
          setDataForm(info)

        }

      }

    )

  }, []);


  const setDate = (event) => {
    setFechaAlta(moment(event).format("YYYY-MM-DD"))
    setFechaVencimiento(moment(event).add(1, 'year').format("YYYY-MM-DD"))

    //console.log(fecha)
  }

  const setDateVencimiento = (event) => {
    setFechaVencimiento(moment(event).format("YYYY-MM-DD"))
    //console.log(fecha)
  }
  const handleOpenDrop = (value) => { setOpen(true) }

  const handleCloseDrop = () => setOpen(false);
  //var tropa = localStorage.getItem('filterList')


  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {//setDisableGuardar(true) /* console.log(status, meta, file) */ }
  }

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files, allFiles) => {

    const fotos = []
    let newData = {
      title: "",
      description: "",
      image_url: "",
    };

    files.map(f => fotos.push({
      title: f.meta.name,
      description: f.meta.type,
      image_url: f.meta.previewUrl,
      img: f.file
    }))

    //console.log(fotos)
    setImage(fotos)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false)
    allFiles.forEach(f => f.remove())
    //setDisableGuardar(false)

  }



  // data tables



  //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = (event) => { setOpen(true); };

  /*  const guardarStock = async (history)=>{
    
       const valEspecie= especie.length>1?especie.join('/'):especie[0]
       const valRaza= raza.length>1?raza.join('/'):raza[0]
       const valConf= conf.length>1?conf.join('/'):conf[0]
       const valGordura= Gordura.length>1?Gordura.join('/'):Gordura[0] 
       const img = Image.length>0?'si':null
       const status= 'Realizado'
       if(props.origen=='editar'){
         const response = await UserService.putStock(id,fecha,valorTropa,corral,cabezas,status,valEspecie,valRaza,valConf,valGordura,obs,img)
         if( response.status==200){
           if (Image.length>0){UserService.postImagen(valorTropa,Image)}
           props.cargarData()
           setOpen(false)
         }else{
             const data=[]
     
             for (const [key, value] of Object.entries(response.data)){
               data.push(
                 <p>{value}</p>
               )
           }
           setError(data)
     
           }
       }else{
         //console.log(obs)
         const response = await UserService.postStock(fecha,valorTropa,corral,cabezas,status,valEspecie,valRaza,valConf,valGordura,obs,img)
 
         if( response.status==201){
           if (Image.length>0){UserService.postImagen(valorTropa,Image)}
           props.cargarData()
           setOpen(false)
         }else{
             const data=[]
     
             for (const [key, value] of Object.entries(response.data)){
               data.push(
                 <p>{value}</p>
               )
           }
           setError(data)
     
           }
 
       }
       
 
   
   } */

  const handleClose = () => {
    setOpen(false);

  };


  return (
    <>
      <IconButton variant="text" color='primary' onClick={handleClickOpen}>
        <EditIcon color='primary' style={{ color: theme.palette.primary.main }} />
      </IconButton>
      <UserStateContext.Consumer>
        {({ darkMode }) => (
          darkMode == true ? (
            <Dialog
              fullScreen
              maxWidth={true}
              open={open}
              scroll='body'
              onClose={handleClose}
              className={classes.overflow}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                style: {
                  '& .MuiPaper-root': { bgcolor: "pink", maxWidth: "revert", width: 1200 },
                  "&$.MuiOutlinedInput-root.Mui-disabled": {
                    borderColor: "white",
                  },
                  color: 'white', borderColor: "white",

                  '&$.MuiPaper-root-MuiDialog-paper': {
                    backgroundColor: theme.palette.background.main//'rgb(35, 35, 45)',

                  },
                  backgroundColor: theme.palette.background.main//'rgb(35, 35, 45)',



                },
              }}
            >
              <Toolbar>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <Button autoFocus color="inherit" onClick={handleClose}>
                  Cerrar
                </Button>
                <Grid container>


                </Grid>

              </Toolbar>


              <DialogContent
                //theme={theme}
                className={classes.overflow}
                sx={{ overflowY: 'initial' }}
              >

                <Typography color="secondary" className={classes.errorMessage}>
                  {errorServidor}
                </Typography>
                <Box sx={{ height: '24rem', width: '100%', }}>
                  <Grid container spacing={2}>
                    <Grid item lg={12}>
                      <Typography variant='h5'>
                        Editar Documento
                      </Typography>
                    </Grid>

                    <Grid container spacing={2}>
                      {!isSmallScreen && (
                      <Grid item lg={12} xs={12} sm={6}>
                        <InputLabel id="type">Tipo de Formulario</InputLabel>
                        <ToggleButtonGroup
                          value={alignment}
                          exclusive
                          onChange={handleAlignment}
                        >
                          <ToggleButton value="FormBienAnimal" disabled={tipo != 'FormBienAnimal'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('FormBienAnimal') }}>Bienestar Animal Campo</ToggleButton>
                          <ToggleButton value="FormAnimalTransporte" disabled={tipo != 'FormAnimalTransporte'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('FormAnimalTransporte') }}>Bienestar Animal Transportista</ToggleButton>
                          <ToggleButton value="Declaracion General" disabled={tipo != 'Declaracion General'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('DeclaracionGeneral') }}>Declaración General</ToggleButton>
                          <ToggleButton value="Natural" disabled={tipo != 'Natural'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('Natural') }}>Natural</ToggleButton>
                          <ToggleButton value="Grain Feed" disabled={tipo != 'Grain Feed'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('GrainFed') }}>Grain Fed</ToggleButton>
                          <ToggleButton value="Organic-Renspa" disabled={tipo != 'OrganicRenspa'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('OrganicRenspa') }}>Organic Renspa</ToggleButton>
                          <ToggleButton value="Organic-Tropa" disabled={tipo != 'OrganicTropa'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('OrganicTropa') }}>Organic Tropa</ToggleButton>

                        </ToggleButtonGroup>
                      </Grid>
                      )}
                      {isSmallScreen && (
                        <Grid item lg={12} xs={12} md={12} sm={12}>
                          <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Formulario
                            </InputLabel>
                            <Select
                              defaultValue={tipo}
                              onChange={(e) => { setTipo(e.target.value) }}
                              native
                              value={tipo}
                              margin="normal"
                              inputProps={{
                                name: 'formulario',
                                id: 'uncontrolled-native',
                              }}
                              sx={{
                                color: theme.palette.text.primary,
                                icon: {
                                  color: theme.palette.icons.color
                                }
                              }}
                            >
                              <option disabled={tipo != 'FormBienAnimal'} value={"FormBienAnimal"}>Bienestar Animal Campo</option>
                              <option disabled={tipo != 'FormAnimalTransporte'} value={"FormAnimalTransporte"}>Bienestar Animal Transportista</option>
                              <option disabled={tipo != 'Declaracion General'} value={"DeclaracionGeneral"}>Declaración General</option>
                              <option disabled={tipo != 'Natural'} value={"Natural"}>Natural</option>
                              <option disabled={tipo != 'Grain Feed'} value={"GrainFed"}>Grain Fed</option>
                              <option disabled={tipo != 'Organic-Renspa'} value={"Organic-Renspa"}>Certificado Organico Renspa</option>
                              <option disabled={tipo != 'Organic-Tropa'} value={"Organic-Tropa"}>Certificado Organico Tropa</option>
                            </Select>
                          </FormControl>
                        </Grid>
                          )}
                      {tipo == 'FormBienAnimal' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormBienAnimal
                            dataServidor={dataForm}
                            actualizar={props.cargarData}
                            cerrar={handleClose}
                          ></FormBienAnimal>
                        </Grid>
                      }
                      {tipo == 'Grain Feed' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormGrainFeed
                            cerrar={handleClose}
                            actualizar={props.cargarData}

                            dataServidor={dataForm}
                          ></FormGrainFeed>
                        </Grid>
                      }
                      {tipo == 'Natural' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormNatural
                            cerrar={handleClose}
                            actualizar={props.cargarData}

                            dataServidor={dataForm}
                          ></FormNatural>
                        </Grid>
                      }
                      {tipo == 'Declaracion General' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormDeclaracionGeneral
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}
                          ></FormDeclaracionGeneral>
                        </Grid>
                      }
                      {tipo == 'FormAnimalTransporte' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormBienesTransp
                            cerrar={handleClose}
                            actualizar={props.cargarData}

                            dataServidor={dataForm}
                          ></FormBienesTransp>
                        </Grid>
                      }
                      {tipo == 'Organic-Renspa' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormOrganicRenspa
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}
                          ></FormOrganicRenspa>
                        </Grid>
                      }
                      {tipo == 'Organic-Tropa' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormOrganicTropa
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}
                          ></FormOrganicTropa>
                        </Grid>
                      }

                    </Grid>


                  </Grid>

                </Box>
              </DialogContent>

            </Dialog>) : (
            <Dialog
              fullScreen
              maxWidth={true}
              open={open}
              scroll='body'
              onClose={handleClose}
              className={classes.overflow}
              aria-labelledby="responsive-dialog-title"

            >
              <Toolbar>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <Button autoFocus color="inherit" onClick={handleClose}>
                  Cerrar
                </Button>
                <Grid container>


                </Grid>

              </Toolbar>


              <DialogContent
                //theme={theme}
                className={classes.overflow}
                sx={{ overflowY: 'initial' }}
              >

                <Typography color="secondary" className={classes.errorMessage}>
                  {errorServidor}
                </Typography>
                <Box sx={{
                  height: '24rem', width: '100%', /* "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: 'blue',
        }, */
                  /*  '& .MuiFormLabel-root.Mui-disabled': {
                     color: 'blue',
                   }, */

                }}>
                  <Grid container spacing={2}>
                    <Grid item lg={12}>
                      <Typography variant='h5'>
                        Editar Documento
                      </Typography>
                    </Grid>

                    <Grid container spacing={2}>
                      {!isSmallScreen && (
                      <Grid item lg={12} xs={12} sm={6}>
                        <InputLabel id="type">Tipo de Formulario</InputLabel>
                        <ToggleButtonGroup
                          value={alignment}
                          exclusive
                          
                          onChange={handleAlignment}
                        >
                          <ToggleButton value="FormBienAnimal" disabled={tipo != 'FormBienAnimal'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('FormBienAnimal') }}>Bienestar Animal Campo</ToggleButton>
                          <ToggleButton value="FormAnimalTransporte" disabled={tipo != 'FormAnimalTransporte'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('FormAnimalTransporte') }}>Bienestar Animal Transportista</ToggleButton>
                          <ToggleButton value="Declaracion General" disabled={tipo != 'Declaracion General'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('DeclaracionGeneral') }}>Declaración General</ToggleButton>
                          <ToggleButton value="Natural" disabled={tipo != 'Natural'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('Natural') }}>Natural</ToggleButton>
                          <ToggleButton value="Grain Feed" disabled={tipo != 'Grain Feed'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('GrainFed') }}>Grain Fed</ToggleButton>
                          <ToggleButton value="Organic-Renspa" disabled={tipo != 'OrganicRenspa'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('OrganicRenspa') }}>Organic Renspa</ToggleButton>
                          <ToggleButton value="Organic-Tropa" disabled={tipo != 'OrganicTropa'} aria-label="left aligned" borderColorCustom={theme.palette.tables.tableBorderColor} selectedColor={theme.palette.primary.main} classes={{ selected: classes.selected }} onClick={() => { setTipo('OrganicTropa') }}>Organic Tropa</ToggleButton>

                        </ToggleButtonGroup>
                      </Grid>
                      )}
                      {isSmallScreen && (
                        <Grid item lg={12} xs={12} md={12} sm={12}>
                          <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Formulario
                            </InputLabel>
                            <Select
                              defaultValue={tipo}
                              onChange={(e) => { setTipo(e.target.value) }}
                              native
                              margin="normal"
                              inputProps={{
                                name: 'formulario',
                                id: 'uncontrolled-native',
                              }}
                              sx={{
                                color: theme.palette.text.primary,
                                icon: {
                                  color: theme.palette.icons.color
                                }
                              }}
                            >
                              <option disabled={tipo != 'FormBienAnimal'} value={"FormBienAnimal"}>Bienestar Animal Campo</option>
                              <option disabled={tipo != 'FormAnimalTransporte'} value={"FormAnimalTransporte"}>Bienestar Animal Transportista</option>
                              <option disabled={tipo != 'Declaracion General'} value={"DeclaracionGeneral"}>Declaración General</option>
                              <option disabled={tipo != 'Natural'} value={"Natural"}>Natural</option>
                              <option disabled={tipo != 'Grain Feed'} value={"GrainFed"}>Grain Fed</option>
                              <option disabled={tipo != 'Organic-Renspa'} value={"Organic-Renspa"}>Certificado Organico Renspa</option>
                              <option disabled={tipo != 'Organic-Tropa'} value={"Organic-Tropa"}>Certificado Organico Tropa</option>
                            </Select>
                          </FormControl>
                        </Grid>
                          )}
                      {tipo == 'FormBienAnimal' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormBienAnimal
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}
                          ></FormBienAnimal>
                        </Grid>
                      }
                      {tipo == 'Grain Feed' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormGrainFeed
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}
                          ></FormGrainFeed>
                        </Grid>
                      }
                      {tipo == 'Natural' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormNatural
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}
                          ></FormNatural>
                        </Grid>
                      }
                      {tipo == 'Declaracion General' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormDeclaracionGeneral
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}
                          ></FormDeclaracionGeneral>
                        </Grid>
                      }
                      {tipo == 'FormAnimalTransporte' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormBienesTransp
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}
                          ></FormBienesTransp>
                        </Grid>
                      }
                      {tipo == 'Organic-Renspa' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormOrganicRenspa
                            cerrar={handleClose}
                            actualizar={props.cargarData}


                            dataServidor={dataForm}

                          ></FormOrganicRenspa>
                        </Grid>
                      }
                      {tipo == 'Organic-Tropa' &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormOrganicTropa
                            cerrar={handleClose}
                            actualizar={props.cargarData}
                            dataServidor={dataForm}
                          ></FormOrganicTropa>
                        </Grid>
                      }

                    </Grid>


                  </Grid>



                </Box>
              </DialogContent>

            </Dialog>)
        )}
      </UserStateContext.Consumer>

    </>
  );
}


