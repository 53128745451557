import React, { useState, useEffect, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { withRouter } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useStyles from "./styles";
import UserService from "../../context/UserService";
import { Link } from "react-router-dom";
import { Button, MuiThemeProvider, createMuiTheme, } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import UserStateContext, { signOut } from "../../context/UserContext";
import { Grid } from "@mui/material";
import jwt_decodde from "jwt-decode";
import moment from "moment";
import clsx from 'clsx';
import DownloadIcon from '@mui/icons-material/Download';
import { optionsNormal } from "../variables/variables";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import DeleteIcon from '@mui/icons-material/Delete';
import EditDoc from "../editDoc/EditDoc";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import UploadIcon from '@mui/icons-material/Upload';
import Dropzone from 'react-dropzone-uploader'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Preview } from "../variables/variables";
import { CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";


function GestionDocList(props) {
  //funcion para borrar usuario  
  const API_URL = window.config.API_URL;

  const { deleteUser } = useContext(UserStateContext);
  const { user } = useContext(UserStateContext);

  const isInternal = user.type === "Rioplatense"
  const isAuthorizedToDownload = user.permissions.gestionDoc_can_download === true
  const isAuthorizedToDelete = user.permissions.gestionDoc_can_delete === true
  const isAuthorizedToEdit = user.permissions.gestionDoc_can_edit === true

   //clases de stylos
  var classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [openSubirArchivo, setOpenSubirArchivo] = React.useState(false);
  const [dataTables, setdataTables] = useState([])
  const [mensaje, setMensaje] = useState('')
  const [cuit, setCuit] = useState('')
  const [valor, setValor] = useState('')
  const [id, setId] = useState()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tipoDedoc, setTipoDeDoc] = useState()
  const [desplegables, setDespletables] = useState([])
  const [file, setFile] = useState('')
  const [certificado, setCertificado] = useState('')
  const [tipoCertificado, setTipoCertificado] = useState('')
  const [isLoadingInfo, setIsLoadingInfo] = useState(false)
  const [downloadingId, setDownloadingId] = useState('');



  // funcion para manejar data para la tabla
  const dataParaTablas = (data) => {
    let ArrayData = []
    for (const [llave, valor] of Object.entries(data)) {
      if (llave == 'Bienestar') {
        for (const [key, value] of Object.entries(valor)) {
          if (value['fecha_de_alta'] != undefined) {
            let fecha = moment().format('YYYY-MM-DD')
            let venc = value['fecha_de_vencimiento']
            ArrayData.push(['Check List de Bienestar Animal para campos', value['nombreCompania'], '', '',
              value['fecha_de_alta'], value['fecha_de_vencimiento'],
              ((fecha > venc && value['fecha_de_alta'] != null) ? 'Vencido' : (fecha < venc && value['fecha_de_alta'] != null && (value['firmo'] == true)) ? 'Vigente' : (fecha < venc && (value['firmo'] == false)) ? 'Pendiente' : ''),
              ((fecha > venc && value['fecha_de_alta'] != null) ? 'Vencido' : (fecha < venc && value['fecha_de_alta'] != null && (value['firmo'] == true)) ? 'Vigente' : (fecha < venc && (value['firmo'] == false)) ? 'Pendiente' : ''),

              value['firmo'], value['id']])


          }
        }

      }

      else if (llave == 'Transporte') {
        for (const [key, value] of Object.entries(valor)) {
          if (value['fecha_de_alta'] != undefined) {
            let fecha = moment().format('YYYY-MM-DD')
            let venc = value['fecha_de_vencimiento']
            ArrayData.push(['Check List Bienestar Animal Empresas Transportistas', value['nombreCompania'], '', '',
              value['fecha_de_alta'], value['fecha_de_vencimiento'],
              ((fecha > venc && value['fecha_de_alta'] != null) ? 'Vencido' : (fecha < venc && value['fecha_de_alta'] != null && (value['firmo'] == true)) ? 'Vigente' : (fecha < venc && (value['firmo'] == false)) ? 'Pendiente' : ''),
              ((fecha > venc && value['fecha_de_alta'] != null) ? 'Vencido' : (fecha < venc && value['fecha_de_alta'] != null && (value['firmo'] == true)) ? 'Vigente' : (fecha < venc && (value['firmo'] == false)) ? 'Pendiente' : ''),

              value['firmo'], value['id']])

          }
        }

      } else {
        for (const [key, value] of Object.entries(valor)) {

          if (value['fecha_de_alta'] != undefined) {
            let fecha = moment().format('YYYY-MM-DD')
            let venc = value['fecha_de_vencimiento']
            ArrayData.push([value['titulo'], value['razonS'], value['renspa'], value['tropa'] != 'No Aplica' ? value['tropa'] : '',
            value['fecha_de_alta'], value['fecha_de_vencimiento'],
            ((fecha > venc && value['fecha_de_alta'] != null) ? 'Vencido' : (fecha < venc && value['fecha_de_alta'] != null && (value['firmo'] == true)) ? 'Vigente' : (fecha < venc && (value['firmo'] == false)) ? 'Pendiente' : 'Vigente'),
            ((fecha > venc && value['fecha_de_alta'] != null) ? 'Vencido' : (fecha < venc && value['fecha_de_alta'] != null && (value['firmo'] == true)) ? 'Vigente' : (fecha < venc && (value['firmo'] == false)) ? 'Pendiente' : 'Vigente'),

            value['firmo'], value['id']])

          }
        }
      }


    }
    return ArrayData

  }

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  // called every time a file's `status` changes
  const handleChangeStatus = async ({ meta, file }, status) => {
    if (status === 'headers_received') {

      setFile(file)
      await UserService.putCertificado(file, certificado, tipoCertificado)

      //await UserService.postImagen(fotos)
      //setOpen(false)
      setOpenSubirArchivo(false)
      servidorGET(cuit)

    }

  }

  //imprimir el Certificado 
  const imprimirDocumento = (id, titulo) => {
    setIsLoadingInfo(true)
    setDownloadingId(id)
    UserService.getInfoDocumento(id, titulo).then(
      response => {

        if (response.status == 200) {

          const info = response.data[0]

          if (info.titulo === 'Organic-Tropa' || info.titulo === 'Organic-Renspa'){
            onDownload(info.doc_url, info.titulo, info.razonS, info.fecha_de_alta)
          } else {

          UserService.generarPDF(info, titulo)
          setIsLoadingInfo(false)
          setDownloadingId('')
          }
          setIsLoadingInfo(false)
          setDownloadingId('')

        }

      }

    )
  }

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files, allFiles) => {

    const archivo = []
    /* let newData = {  title: "",
    description: "",
    image_url: "", }; */

    files.map(f => archivo.push({
      title: f.meta.name,
      description: f.meta.type,
      image_url: f.meta.previewUrl,
      img: f.file
    }))

    setFile(archivo)

    // le mando el numero de certificado y el certificado a subir
    await UserService.putCertificado(archivo, certificado, tipoCertificado)
    //setOpen(false)
    //setSumarImg(false)
    allFiles.forEach(f => f.remove())
    setOpenSubirArchivo(false)
    servidorGET(cuit)


  }


  // abrir modal
  const handleOpen = (value, tipo) => {
    // fila de la los datos para dsp buscar el ID
    setId(value)
    setTipoDeDoc(tipo)
    setMensaje(`Borrar documento?`)
    setOpen(true)

  }


  //var rol = localStorage.getItem('permissions')

  // Saco la info del servidor nuevamente 
  const servidorGET = async (cuit) => {
    setdataTables([])
    if (cuit == '') {
      await UserService.getDocumento().then(
        response => {
          if (response.status == 200) {
            let Info = dataParaTablas(response.data)
            setdataTables(Info)
          }
        },
        error => {

          if (error.response.status == 401) { signOut(props.history) }
        }
      )

    } else {
      await UserService.getDocumento(cuit).then(
        response => {
          if (response.status == 200) {
            let Info = dataParaTablas(response.data)

            setdataTables(Info)
          }
        },
        error => {
          if (error.response.status == 401) { signOut(props.history) }
        }
      )

    }

  }

  // descargar documento firmado
  const onDownload = async (val, nombreARchivo, proveedor, fecha) => {
    //const API_URL = window.config.API_URL;
    let blob = await fetch(val).then(r => r.blob());
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Certificado_${nombreARchivo}_${proveedor}_${fecha}.pdf`;
    link.click();

  };

  // info inciail 
  useEffect(() => {
    var user = JSON.parse((localStorage.getItem('userData')))
    if (user.cuit != null) {
      setCuit(user.cuit)
      servidorGET(user.cuit)

    } else {
      servidorGET()

    }

  }, []);

  // cerrar Modal
  const handleClose = () => { setOpen(false); };
  // borrar documento
  const deleteDOC = async () => {
    await UserService.deleteCert(id, tipoDedoc);
    servidorGET(cuit)
    handleClose()
  }
  //funcion para 
  const descargarNuevamenteDoc = async (id, tipo) => {

    await UserService.getInfoDocumento(id, tipo).then(
      response => {
        if (response.status == 200) {

          const info = response.data[0]
          let form_data = new FormData();
          //form_data.append(info)
          for (const [key, value] of Object.entries(info)) {
            form_data.append(key, value)
          }
          UserService.generarPDF(form_data, info['titulo'])
        }

      }

    )

  }


  //array con la data separada  
  let ComprasSinDte = []

  const options = {
    pagination: true,
    /*   setRowProps: (row, dataIndex, rowIndex) => {
         return {
          className: clsx({
            [classes.rojo]: row[5] === 'Vencido',
          
  
          }),
        }; 
      },   */
  }
  const optionsNormal = {
    responsive: 'standard',
    fixedSelectColumn: true,
    fixedHeader: true,
    filterType: 'multiselect',
    pagination: true,
    sort: false,
    filter: false,
    download: isInternal,
    print: isInternal,
    viewColumns: isInternal,
    search: isInternal,
  
    textLabels: {
      body: {
        noMatch: "No hay datos",
        toolTip: "Ordenar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros de tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) Elegidas",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
    selectableRowsHideCheckboxes: true,
  };


  return (
    <>

      <Grid item lg={12} md={12} xs={12} sm={12}>

        {/* <MuiThemeProvider theme={getMuiTheme()}>  */}
        <MUIDataTable
          // title={<span>{'Listado Documentos proveedores'}</span>}
          data={dataTables}
          options={Object.assign(optionsNormal, options)}
          columns={["Titulo", "Proveedor",
          {
            name: "Renspa",
            options: {
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: {} }),
              setCellProps: () => ({ style: { textAlign: 'center' } })
            },
          },
          {
            name: 'Tropa',
            options: {
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: {} }),
              setCellProps: () => ({ style: { textAlign: 'center' } })
            },
          },
          {
            name: "Fecha de alta",
            options: {
              filter: true,
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: {} }),
              setCellProps: () => ({ style: { textAlign: 'center' } })
            },
          },
            {
              name: "Fecha de vencimiento",
              options: {
                filter: true,
                /* sort: false,
                empty: false, */
                setCellHeaderProps: () => ({ style: {} }),
                setCellProps: () => ({ style: { textAlign: 'center' } })
              },
            }
            ,
            {
              name: "Estado",
              options: {
                /* sort: false,
                empty: false, */
                setCellHeaderProps: () => ({ style: {} }),
                setCellProps: () => ({ style: { textAlign: 'center' } })
              },
            },
            {
              name: "",
              options: {
                filter: true,
                display: true,
                setCellProps: () => ({
                  textAlign: 'center',
                  alignItems: 'center'

                }),

                customBodyRender: (valor, dataIndex, updateValue) => {

                  return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {valor == 'Vencido' ? <CircleRoundedIcon className={classes.rojoCircle} /> :
                        valor == "Vigente" ? <CircleRoundedIcon className={classes.verde} /> :
                          valor == "Pendiente" ? <CircleRoundedIcon className={classes.yellow} /> :

                            ''}
                    </div>


                  )
                }
              },
            },


            {
              name: "Doc",
              options: {
                filter: false,
                display: isAuthorizedToDownload,
                /* sort: false,
                empty: false, */
                setCellHeaderProps: () => ({ className: classes.centeredHeader, }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),

                customBodyRender: (value, dataIndex, updateValue) => {
                  if (value == true) {
                    // compruebo que no sea el usuario logueado

                    return (
                      <>
                        {(isLoadingInfo && downloadingId === dataIndex.rowData[9])? (
                          <CircularProgress className={classes.loginLoader} />
                        ) : (
                          <Button aria-label="edit" size="medium"
                            color='primary'
                            //component={Link} 
                            //to={API_URL.concat(value)}
                            download="How-to-download-file.pdf"
                            onClick={() => { imprimirDocumento(dataIndex.rowData[9], dataIndex.rowData[0]) }}
                          >
                            <DownloadIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                          </Button>
                        )}
                      </>

                    )

                  }



                }
                //corchete de options de la ultima coluimna
              }
            }, {
              name: "Acciones",
              options: {
                display: isAuthorizedToEdit || isAuthorizedToDelete,
                filter: true,
                /* sort: false,
                empty: false, */
                setCellHeaderProps: () => ({ className: classes.centeredHeader, }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),

                customBodyRender: (value, dataIndex, updateValue) => {
                 
                  // mando el valor de ID para la funcion de borrar documento y abro el modal. 
                  return (
                    <>
                      <div className={classes.contained}>

                        <>
                          {((isAuthorizedToEdit) && dataIndex.rowData[0] != 'Organic-Tropa') &&
                            <EditDoc
                              style={{ color: theme.palette.primary.main }}
                              color="primary"
                              dataRow={dataIndex.rowData}
                              cargarData={servidorGET}
                              origen={'editar'}>
                            </EditDoc>}
                          {isAuthorizedToDelete && (
                          <IconButton aria-label="edit" size="medium" style={{ color: theme.palette.primary.main }}
                            color='primary'
                            onClick={() => handleOpen(value, dataIndex.rowData[0])}>
                            <DeleteIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                          </IconButton>
                          )}
                        </>


                      </div>
                      <Modal
                        hideBackdrop
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                      >
                        <Box className={classes.Modal}>
                          <h2 id="child-modal-description">
                            {mensaje}
                          </h2>


                          <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            onClick={() => { deleteDOC() }}
                            className={classes.buttonModal}
                          >Borrar
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            onClick={() => { handleClose() }}
                            className={classes.buttonModal}
                          >Cerrar
                          </Button>
                        </Box>
                      </Modal>
                      <Modal
                        hideBackdrop
                        open={openSubirArchivo}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                      >
                        <Box className={classes.Modal}>
                          <Dropzone
                            classNames={classes.dropZone}
                            styles={{
                              dropzone: { overflow: 'hidden', margin: '10px 0px' }, submitButton: {
                                padding: '15px',
                                fontSize: '1.2em',
                              }
                            }}
                            /* getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            maxFiles={1}
                            onSubmit={handleSubmit}*/
                            PreviewComponent={Preview}
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}


                            accept="image/*,.pdf"
                            inputContent='Arrastra tus archivos o hace click aqui'
                          /*  submitButtonContent="Guardar Datos"
                            inputWithFilesContent='Sumar otro archivo' */
                          />


                          <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            //onClick={()=>{deleteDOC()}}
                            className={classes.buttonModal}
                          >Guardar
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            onClick={() => { setOpenSubirArchivo(false) }}
                            className={classes.buttonModal}
                          >Cerrar
                          </Button>
                        </Box>
                      </Modal>
                    </>)


                }
                //corchete de options de la ultima coluimna
              }
            },]} // aca terina Columns
        />
        {/* </MuiThemeProvider> */}
      </Grid>
      <Grid item lg={12} sm={12}>

      </Grid>
    </>
  )

}
export default withRouter(GestionDocList)