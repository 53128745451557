import React,{useContext} from "react";
//import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { createRoot } from "react-dom/client";
import {Box,TextField,MenuItem,Checkbox} from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import UserStateContext from "./context/UserContext";

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import BrushIcon from '@mui/icons-material/Brush';
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);




root.render(

  <LayoutProvider>
    <UserProvider >
        <App />      
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
