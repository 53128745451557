import React, { useState,useEffect,useContext  } from "react";
import MUIDataTable from "mui-datatables";
import MenuItem from '@mui/material/MenuItem';  
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import useStyles from "./styles";
import UserService from "../../context/UserService";
import { Link } from "react-router-dom";
import { Button, MuiThemeProvider, createMuiTheme,  } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import UserStateContext,{ signOut } from "../../context/UserContext";
import { Grid } from "@mui/material";
import jwt_decodde from "jwt-decode";

import { optionsNormal } from "../variables/variables";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'


// estilo modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 1,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign:'center',

};
const breakpoints = createBreakpoints({})
const getMuiTheme = () =>
      createMuiTheme({
       
        overrides: {
          MUIDataTableBodyCell: {
            root: {
              minWidth: 'max-content',
              minHeight:'1.3rem',
              padding:'0px 3px 0px 3px',
              alignSelf:'center'
            }
          },
          MuiTableRow:{
            root:
            {
              verticalAlign: 'baseline'
            }
          },
          MuiTableCell: {
            head: {
                fontWeight: "bold"
            }
        },
        MuiPaper:{
          root:{
            color:'#fff',
            backgroundColor:'rgb(35, 35, 45)'
          },},

          MUIDataTableToolbar: {
            
            filterPaper:{

              [breakpoints.down("xs")]: {
                  maxWidth: '100%'
              }
            },
            actions: {
              display: "flex",
              flex: "initial",
              // move all icons to the right
              "& > span, & > button": {
                order: 99
              },
              // target the custom toolbar icon
              "& > span:last-child, & > button:last-child": {
                order: 1
              },
              // target any icon
              "& > span:nth-child(4), & > button:nth-child(4)": {
                order: 2
              }
            }
          }
        },
        shadows: ['none']


      });

export default function ComprasList(props) {

  //funcion para borrar usuario  
  const { deleteUser } = useContext(UserStateContext);



//clases de usuario
  var classes = useStyles();

  const [open, setOpen] = React.useState(false);

  // funcion para manejar el modal. 
  const handleOpen = (value) => {
    //mensaje modal
    setMensaje(`Confirma borrar la compra?`)
    // fila de la los datos para dsp buscar el ID
    setFila(value)
    // abro el modal 
    setOpen(true)}

  const handleClose = () => setOpen(false);
  const [dataTables, setdataTables] = useState('')
  const [mensaje, setMensaje]= useState('')
  const [valor, setValor]= useState('')
  const [fila, setFila]=useState()
  const [anchorEl, setAnchorEl] = React.useState(null);

  //borrar usuario
  const borrarCompra = async(value,history)=>{
    const id = dataTables[fila]['id']
    //funcion para borrar compra. Sino esta logueado hace signout. 
    await UserService.deleteCompra(id,history).catch(error => {console.log(error)})
    .then(function(response){ 
      if(response.status==401){signOut(history)}
    })
    await setTimeout(5000)
    setOpen(false)
    // pedir nuevamente la info del servidor
    servidorGET()
  }
  var rol = localStorage.getItem('permissions')
  var user = (localStorage.getItem('userData')).user_id

  // Saco la info del servidor nuevamente 
  const servidorGET= () =>{
    UserService.getComprasList().then(response => {setdataTables(response.data.data);},
    )
    .catch(error => {console.log(error.response ); if(error.response.status==401){signOut(props.history)}})
    
    }  

  useEffect(() => {
    UserService.getComprasList().then(
      response => {
      setdataTables(response.data.data
      );
      },
      error => {
        if(error.response.status==401){signOut(props.history)}
        }
      );
    }, []);
  
    let datatableData = []
        
    for (const [key, value] of Object.entries(dataTables)) {
      /*  "tipo_de_compra","fecha_de_compra", "fecha_de_carga","fecha_de_faena","fecremitenteha_de_negocio","comprador","status","remitente","consignatario",
        "contacto_de_carga", "lugar_de_carga","feedlot","cantidad","peso","precio","cuota",            "cuota_Inverada": null,
"plazo","comisionista","comision","desbaste","flete","kilómetros_aproximados",
        "jaula_simple","jaula_doble","planta","raza","especie", "programa","cabezas_compradas","observaciones" */
      datatableData.push(
      [value['tipo_de_compra'], value['fecha_de_compra'],value['fecha_de_carga'],value['fecha_de_faena'],value['fecha_de_negocio'],value['comprador'],value['status'],value['remitente'],value['consignatario'],value['destino_Comercial'],
      value['contacto_de_Carga'],value['lugar_de_carga'],value['feedlot'],value['cantidad'],value['peso'],value['precio'],value['cuota'],value['plazo'],value['cuota_Inverada'],value['comisionista'],value['comision'],value['desbaste'],value['flete'],value['kilómetros_aproximados'],
      value['jaula_simple'],value['jaula_doble'],value['planta'],value['raza'],value['especie'],value['programa'],value['cabezas_compradas'],value['observaciones'],value['programa_real'],value['hqf'],key])
      }
// defino la variable para sacar los datos en edit. 
    localStorage.setItem("datatableCompras", JSON.stringify(dataTables));
     
    return (
      //<MuiThemeProvider theme={getMuiTheme()}> 

     <>
     
        <MUIDataTable
        data={datatableData}
        options={optionsNormal}
        responsive= "scroll"
        columns={["Tipo de Compra","Fecha de Compra", "Fecha de Carga","Fecha de Faena","Fecha de Negocio","Comprador","Status","Remitente","Consignatario","Destino Comercial",
        "Contacto de Carga", "Lugar de Carga","Feedlot","Cantidad","Peso","Precio","Cuota","Plazo","Cuota Invernada","Comisionista","Comision","Desbaste","Flete","Kilómetros aproximados",
        "Jaula simple","Jaula Doble","Planta","Raza","Especie", "Programa","Cabezas Compradas","Observaciones",{
          name: "programaReal",
        options: {
          filter: true,
          display:false,
        }}
          
          ,{
            name: "hqf",
          options: {
            filter: true,
            display:false,
          }}
            
            ,
          
          {
          name: "Acciones",
          options: {
            filter: true,
            /* sort: false,
            empty: false, */
            setCellHeaderProps: () => ({ style: { minWidth: "120px"}}),
            setCellProps: () => ({ style: { minWidth: "120px"}}),

            customBodyRender: (value, dataIndex, rowIndex) => {
              if (datatableData.length>0){
                // compruebo que no sea el usuario logueado
                  return (
                    <>
                    <div className={classes.contained}>
                    {(rol== 6 || rol== 1 || rol== 2 )  &&
                      <IconButton aria-label="edit" size="medium"
                        color='primary'
                        component={Link} to={`/app/editCompra/${value}`
                        }>
                        <EditIcon color='primary' fontSize="small" />
                      </IconButton>}
                      {(rol== 6 || rol== 1)  &&
                      <IconButton aria-label="edit" size="medium"
                        color='primary'
                        onClick={()=>handleOpen(value)}>
                        <DeleteIcon color='primary' fontSize="small" />
                      </IconButton> }
                      </div>
                  <Modal
                      hideBackdrop
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="child-modal-title"
                      aria-describedby="child-modal-description"
                    >
                      <Box className={classes.Modal }>
                        <h2 id="child-modal-description">
                        {mensaje}

                        </h2>
                        <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            className={classes.buttonModal}
                            onClick={()=>borrarCompra(value, props.history)}
                            >Confirmar
                          </Button>
                        
                          <Button
                              variant="contained"
                              color="primary"
                              size='large'
                              onClick={handleClose}
                              className={classes.buttonModal}
                              >Cancelar</Button>
                      </Box>
                    </Modal>
                  
                  </>)
                
               
              }
               
                
                
                  
                
            }
          }
        },
      ]}
       
      />
      </>
     // </MuiThemeProvider>

    )
    
}
