import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import jwt_decode from "jwt-decode";

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext, { signOut } from "../../context/UserContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import moment from "moment";

import UserService from "../../context/UserService";


// funcion General para editar campos
function CambioStatusCompra(props) {
  var classes = useStyles();
  const { editUser } = useContext(UserStateContext);

  var [mensaje, setMensaje] = useState()
  var [mensaje2, setMensaje2] = useState()





  useEffect(() => {


    const params = window.location.href.split('/')[5]
    // numero de indice 
    const token = jwt_decode(params)
    const tomorrow = moment().add(1, 'days')
    const fechaValidacion = moment(token.validation)





    if (token != undefined && token.status != '' && token.compraID != '') { //&&tomorrow>fechaValidacion
      UserService.putStatusCompraLink(token.compraID, token.status).then(response => {

        if (response.status == 200) {
          if (token.status == 'postergaCarga') {
            setMensaje('Se postergo la fecha de Faena para la compra.')
          }
          else if (token.status == 'confirmado') {
            setMensaje2('Se confirmo la fecha de Faena de la compra.')
          }
        } else if (response.status == 400) {
          setMensaje('El link que esta utilizando se encuentra expirado, por favor comuniquese con la empresa para notificar el cambio.')
        } else {
          setMensaje('No se pudo cambiar el status de la compra por favor vuelva a intentar en unos minutos o comuniquese con la empresa para notificar el cambio.')
        }


      },
        error => {
          if (error) { setMensaje('No se pudo cambiar el status de la compra por favor vuelva a intentar en unos minutos o comuniquese con la empresa para notificar el cambio.') }
        }

      )

    } else {
      setMensaje('El link no se encuentra habilitado, por favor comuniquese con la empresa para notificar el cambio.')


    }



  }, []);

  return (
    <>
      <PageTitle title="" />
      <Grid justify="center" container spacing={4}>
        <Grid justify="center" item lg={6} md={12} sm={12} xs={12}>
          <Widget
            disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div>

            </div>
            <div className={classes.form}>

              <Grid justify="center" lg={12} container >
                <Typography variant='h4' color="secondary" className={classes.errorMessage}>
                  {mensaje}
                </Typography>
                <Typography variant='h4' className={classes.green}>
                  {mensaje2}
                </Typography>



              </Grid>

            </div>

          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
export default CambioStatusCompra;