import React, { useState, useEffect } from "react";
import {
  TableFooter,
  TableRow, TextField,
  Box, MenuItem, Select, Checkbox, ListItemText, IconButton, useMediaQuery
} from "@material-ui/core";
import {
  Grid, Button, ButtonBaseTypeMap, MuiThemeProvider, TableCell,

  createMuiTheme, CircularProgress, Typography, Fade, InputLabel
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
// 
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { LocalizationProvider } from "@mui/x-date-pickers";
import SaveIcon from '@mui/icons-material/Save';
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import MUIDataTable from "mui-datatables";
import { optionsNormal } from "../../components/variables/variables";
import UserService from "../../context/UserService";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import moment from "moment";
import { el } from "date-fns/locale";
import { isLeapYear } from "date-fns";
import clsx from 'clsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import useStyles from "./styles";
import EditStock from "../../components/editStock/EditStock";
import CardImage from "../../components/cardImage/CardImage";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import FormControl from "@mui/material/FormControl";
import { signOut } from "../../context/UserContext";
import { useTheme } from "@material-ui/styles";
import { useContext } from "react";
import UserStateContext from "../../context/UserContext";


const breakpoints = createBreakpoints({})
/*  const getMuiTheme = () =>
      createMuiTheme({
       
        overrides: {
          MUIDataTableBodyCell: {
            root: {
              fontSize:'5px'
            }
          },
          MuiTableRow:{
            root:
            {
              verticalAlign: 'baseline'
            }
          },
          MuiTableCell: {
            head: {
                fontWeight: "bold"
            }
        }}
        

      });  */

export default function Checklist(props) {
  const [fecha, setFecha] = useState(moment().format("YYYY-MM-DD"));
  const [status, setStatus] = useState([])
  const [especie, setEspecie] = useState([])
  const [raza, setRaza] = useState([])
  const [conf, setConf] = useState([])
  const [Gordura, setGordura] = useState([])
  const [cargaImg, setCargaImg] = useState(true)
  const [valor, setValor] = useState([])
  const [open, setOpen] = React.useState(false);
  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [isLoading2, setIsLoading2] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [Image, setImage] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [tropa, setTropa] = useState('');
  const [openBorrar, setOpenBorrar] = useState(false)
  const [mensaje, setMensaje] = useState(false)
  // filtro usuario verdadero 
  const [filtro, setFiltro] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [desplegableUsuarioVerd, setDesplegableUsuarioVerd] = useState([])
  const theme = useTheme();
  const { user } = useContext(UserStateContext);
  const rol = user.rol

  // Authotization
  const isAuthorizedToEdit = user.permissions.checklist_can_edit === true

  var isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))


  const [dataGuardar, setDataGuardar] = useState([]);

  const classes = useStyles();
  const handleOpenBorrar = (value) => {
    //mensaje modal
    setMensaje(`Confirma borrar el stock?`)
    // fila de la los datos para dsp buscar el ID
    setTropa(value)
    // abro el modal 
    setOpenBorrar(true)
  }
  const handleCloseBorrar = () => setOpenBorrar(false);

  const handleOpen = async (value, fecha) => {
    if (value != undefined) {
      const fecha_corregida = moment(fecha, "DD/MM/YY").format("YYYY-MM-DD")
      const ImgData = await UserService.getImage(value, fecha_corregida)
      setCardData(ImgData.data)
      setTropa(value)
    }

    setOpen(true)
  }

  const pedirImagen = async (array) => {
    if (array.length > 0) {
      const ImgData = await UserService.getImage(array[0].tropa, array[0].fecha)
      if (ImgData.data.length > 0) {
        setCardData(ImgData.data)
        setTropa(tropa)
      } else {
        cargarData()
        setOpen(false)
      }
    } else {
      cargarData()
      setOpen(false)
    }

  }
  const handleClose = () => setOpen(false);
  //var tropa = localStorage.getItem('filterList')



  const handlefiltro = (e) => {

    // setSelectedFilter(e.target.value);
    let filterList = [];
    if (e.target.value !== filtro) {
      setFilterList([e.target.value]);
      setFiltro(e.target.value)

    } else if (e.target.value == filtro) {
      setFilterList([])
      setFiltro([])

    }

  }
  var optionsFooter = {
    customHeadRender: () => null,
    filter: true,
    responsive: 'standard',
    search: true,
    viewColumns: false,
    fixedHeader: true,
    tableBodyHeight: '500px',
    print: false,
    download: true,
    sort: false,
    pagination: false,
    selectableRowsHideCheckboxes: true,

    onFilterChange: (columnChanged, filterList, type) => {
      const filtros = filterList.filter(x => x.length > 0)
      if (type == 'chip' && filtros.length > 0) {
        setFiltro([])
        setFiltro(filtros[0])
      } else {
        setFiltro([])

      }
    },

    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
    },
    onChangePage(page) {
      setPage(page);
    },


    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: clsx({
          [classes.totales]: row[1] == undefined,
          [classes.normal]: row[1] != undefined,

        }),
      };
    },


  }
  /* 
  const ordenar = (data)=>{
    const merged = data.reduce((r, { tipostock, tropa, usuverddesc,remitente, c_481,cabstock, consig,kgrec,corral,hilton,no_hilton }) => {
      const key = `${usuverddesc}.${tipostock}`;
      if (!r[key]){
  
        r[key] = {usuverddesc:'Subtotal '+usuverddesc,tipostock:tipostock,cabstock:0, kgrec:0,count:0};}
      
        r[key]['kgrec'] += parseInt(kgrec)
  
        r[key]['cabstock'] += parseInt(cabstock)
        r[key]['count'] +=1
      return r;
  
      
    }, {}) 
    const c_481 = data.reduce((r, { tipostock, tropa, usuverddesc,remitente, c_481,cabstock, consig,kgrec,corral,hilton,no_hilton }) => {
      const key = `${usuverddesc}-c_481.${tipostock}`;
      if (!r[key]){
        r[key] = {usuverddesc:'Subtotal c_481',tipostock:tipostock,cabstock:0, kgrec:0,count:0};}
        if(c_481==true ){
          r[key]['kgrec'] += parseInt(kgrec)
  
          r[key]['cabstock'] += parseInt(cabstock)
          r[key]['count'] +=1
        }
       
      return r;
    }, {}) 
    const hilton = data.reduce((r, { tipostock, tropa, usuverddesc,remitente, c_481,cabstock, consig,kgrec,corral,hilton,no_hilton }) => {
      const key = `${usuverddesc}-hilton.${tipostock}`;
      if (!r[key] ){
        r[key] = {usuverddesc:'Subtotal hilton',tipostock:tipostock,cabstock:0, kgrec:0,count:0};}
        if(hilton==true){
  
          r[key]['kgrec'] += parseInt(kgrec)
  
          r[key]['cabstock'] += parseInt(cabstock)
          r[key]['count'] +=1
        }
       
      return r;
    }, {}) 
    const no_hilton = data.reduce((r, { tipostock, tropa, usuverddesc,remitente, c_481,cabstock, consig,kgrec,corral,hilton,no_hilton }) => {
      const key = `${usuverddesc}-no_hilton.${tipostock}`;
      if (!r[key]){
        r[key] = {usuverddesc:'Subtotal no_hilton',tipostock:tipostock,cabstock:0, kgrec:0,count:0};}
        if(no_hilton==true ){
          r[key]['kgrec'] += parseInt(kgrec)
  
          r[key]['cabstock'] += parseInt(cabstock)
          r[key]['count'] +=1
        }
       
      return r;
    }, {}) 
  
    const TOTAL = data.reduce((r, { tipostock, tropa, usuverddesc,remitente, c_481,cabstock, consig,kgrec,corral,hilton,no_hilton }) => {
      const key= tipostock
      if (!r[key]){
  
        r[key] = {usuverddesc:'TOTAL ',tipostock:tipostock,cabstock:0, kgrec:0,count:0};}
      
        r[key]['kgrec'] += parseInt(kgrec)
        r[key]['cabstock'] += parseInt(cabstock)
  
        r[key]['count'] +=1
      return r;
  
     
   }, {})
    
    const ordenado = [
      ...data.filter(x => x.usuverddesc=='Exportación'&& x.c_481==true),
      ...data.filter(x => x.usuverddesc=='Exportación' && x.hilton==true),
      ...data.filter(x => x.usuverddesc=='Exportación' && x.no_hilton==true),
  
      ...data.filter(x => x.usuverddesc=='Otros Paises'),
      ...data.filter(x => x.usuverddesc=='Consumo'),
      ...data.filter(x => x.usuverddesc=='Conserva'),
      ...data.filter(x => x.usuverddesc=='Inc')
  
    ];
  
    for (const [key, valor] of Object.entries(merged)) {
      const pieces = key.split('.')
      const usuario =  pieces[0]
      const tipo =  pieces[1]
      const index = ordenado.findLastIndex(v =>v.usuverddesc == usuario && v.tipostock==tipo )
      ordenado.splice((index+1), 0, valor);
    }
    ordenado.join()
    for (const [key, valor] of Object.entries(c_481)) {
      const pieces = key.split('.')
      const usuario = key.split('-')[0]
  
      const tipo =  pieces[1]
      if(usuario=='Exportación' && valor['cabstock']>0){
        const index = ordenado.findLastIndex(v =>v.c_481 == true && v.tipostock==tipo && v.usuverddesc=='Exportación')
        ordenado.splice((index+1), 0, valor)
      }
    }
    ordenado.join()
    for (const [key, valor] of Object.entries(hilton)) {
      const pieces = key.split('.')
      const usuario = key.split('-')[0]
      const tipo =  pieces[1]
      if(usuario=='Exportación' && valor['cabstock']>0  ){
        const index = ordenado.findLastIndex(v =>v.hilton == true && v.tipostock==tipo && v.usuverddesc=='Exportación')
        ordenado.splice((index+1), 0, valor)
      }
  
    }
    ordenado.join()
    for (const [key, valor] of Object.entries(no_hilton)) {
      const pieces = key.split('.')
      const usuario = key.split('-')[0]
      const tipo =  pieces[1]
      if(usuario=='Exportación' && valor['cabstock']>0){
        const index = ordenado.findLastIndex(v =>v.no_hilton == true && v.tipostock==tipo && v.usuverddesc=='Exportación')
        ordenado.splice((index+1), 0, valor)
      }
      
    }
    ordenado.join()
    for (const [key, valor] of Object.entries(TOTAL)) {
      ordenado.push(valor)
  
    } 
    return ordenado
  }
   */
  const setDate = (event) => {
    setFecha(moment(event).format("YYYY-MM-DD"))
    //console.log(fecha)
  }
  // funcion para sacar info del servidor
  const cargarData = async () => {
    setIsLoading2(true)
    setErrorServidor(false)
    setValor([])
    setDesplegableUsuarioVerd([])


    UserService.getStock(moment(fecha).format("YYYY-MM-DD")).then(
      response => {
        if (response.status == 200) {
          // const ordenado = ordenar(response.data)
          const dataUsuarioVerdadero = [...new Set(response.data.map(x => x.usuverd))]
          setDesplegableUsuarioVerd(dataUsuarioVerdadero)
          setValor(response.data);
          setIsLoading2(false)

        } else {

        }
      },
      error => {
        setValor('');
        setErrorServidor(true)
        setIsLoading2(false)
        if (error.response.status == 401) { signOut(props.history) }

      }
    );

  }

  useEffect(() => {
    cargarData()
  }, []);


  const updateStockStatus = (id, status) => {
    UserService.updateStockStatus(id, status)
    cargarData()
  }

  // guardar Stock en DB
  const anularStock = (fila, status2) => {
    // chequeo que la fila tenga info
    if (fila != undefined) {
      const fecha = fila[1]
      const tropa = fila[2]
      const corral = fila[3]
      const cabezas = fila[4]
      const usuarioVerd = fila[5]
      const status = status2
      const KGVIVO = fila[11] == undefined ? 0 : fila[11]
      const aptitud = fila[12] == undefined ? 0 : fila[12]

      const proc = fila[13] == undefined ? '' : fila[13]
      const especie = ''
      const raza = ''
      const conf = ''
      const gordura = ''
      const obs = ''
      const programa = ''
      const caja = ''


      const img = null


      const fecha_argumentos = fecha.split('/')
      const fecha_corregida = '20' + fecha_argumentos[2] + '-' + fecha_argumentos[1] + '-' + fecha_argumentos[0]
      /* const programa= fila[6]
      const KGVIVO= fila[8]==undefined?0:fila[8]
      const aptitud= fila[9]
      const proc= fila[10]==undefined?'':fila[10]
      const especie= fila[11]==undefined?'':fila[11]
      const raza= fila[12]==undefined?'':fila[12]
      const conf= fila[13]==undefined?'':fila[13]
      const gordura= fila[14]==undefined?'':fila[14]
      const obs= fila[15]==undefined?'':fila[15]
      const img= ''
      const id = fila[17] */

      //console.log(fecha,tropa,corral,cabezas,status,especie,raza,conf,gordura,obs)
      UserService.postStock(fecha_corregida, tropa, corral, KGVIVO, proc, cabezas, status, especie, raza, conf, gordura, obs, programa, caja, aptitud, img, false, usuarioVerd)
      //UserService.putStock(id,fecha,tropa,corral,cabezas,status,especie,raza,conf,gordura,obs,programa,caja,aptitud,img,false)

      //UserService.postStock(fecha,valorTropa,corral,KGvivo,proc,cabezas,status,valEspecie,valRaza,valConf,valGordura,obs,programa,img,true)
      cargarData()

    }

  }

  const cambiarCaja = (fila, caja) => {
    // chequeo que la fila tenga info

    if (fila != undefined) {
      const fecha = fila[1]
      const tropa = fila[2]
      const corral = fila[3]
      const cabezas = fila[4]
      const usuverd = fila[5]
      const status = 'Realizado'
      const programa = fila[8]
      const KGVIVO = fila[9] == undefined ? 0 : fila[9]
      const aptitud = fila[12]
      const proc = fila[13] == undefined ? '' : fila[13]
      const especie = fila[14] == undefined ? '' : fila[14]
      const raza = fila[15] == undefined ? '' : fila[15]
      const conf = fila[16] == undefined ? '' : fila[16]
      const gordura = fila[17] == undefined ? '' : fila[17]
      const obs = fila[18] == undefined ? '' : fila[18]
      const img = ''
      const id = fila[0]

      const fecha_argumentos = fecha.split('/')
      const fecha_corregida = '20' + fecha_argumentos[2] + '-' + fecha_argumentos[1] + '-' + fecha_argumentos[0]

      //console.log(fecha,tropa,corral,cabezas,status,especie,raza,conf,gordura,obs)
      UserService.putStock(id, fecha_corregida, tropa, corral, cabezas, status, especie, raza, conf, gordura, obs, programa, caja, aptitud, img, false, usuverd)


      // UserService.putStock(id,fecha,valorTropa,corral,cabezas,status,valEspecie,valRaza,valConf,valGordura,obs,programa,img,false)
      cargarData()

    }

  }
  //borrar Stock
  const deleteStock = (id) => {
    UserService.deteleStockLinea(id)
    //UserService.deteleStockImage(id)
    handleCloseBorrar()

    cargarData()
  }

  const aptitud = (val) => {
    let valor = val['hilton'] == true ? 'H' :
      val['no_hilton'] == true ? 'NH' :
        val['c_481'] == true ? '481' :
          (val['usuverd'] == '30' || val['usuverd'] == '11') ? 'OP' :
            val['usuverd'] == '01' ? 'CMO' :
              val['usuverd'] == '40' ? 'Inc' : ''

    return valor
  }
  const datatableData1 = []


  for (const [key, value] of Object.entries(valor)) {
    if (value['tipostock']) {
      datatableData1.push([, moment(value['ecstock']).format('DD/MM/YY'), value['tropa'], value['corral'], value['cabstock'], value['usuverd'], 'No Realizado', 'No Realizado', value['programa'], "", value["remitente"],value['KGVivosProm'], aptitud(value), value['procedencia'], especie, raza, conf, Gordura, , ])
    }
    else {
      datatableData1.push([value['id'], moment(value['fecha']).format('DD/MM/YY'), value['tropa'], value['corrales'], value['cabezas'], value['usuverd'], value['status'], value['cambioCC'] == true ? 'cambiado' : value['status'], value['programa'], value['caja'], value["remitente"],value['kgVivoProm'],value['aptitud'], value['procedencia'], value['especie'], value['raza'], value['conformacion'], value['gordura'], value['observaciones'], value['img']])
    }

  }
  return (
    <>
      {/* <PageTitle title="Checklist" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>


          {/* <Widget disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          > */}
          <Grid container
            alignItems='center'
            spacing={4}>
            <Grid item lg={2} md={4} sm={12} xs={12} style={{ marginTop: '15px' }}>
              {/* <InputLabel id="type">Seleccione Fecha</InputLabel> */}

              <ResponsiveDatePickers
                value={fecha}
                label="Date desktop"
                inputFormat="DD/MM/YY"
                setValorFecha={setDate}

              ></ResponsiveDatePickers>
            </Grid>
            {desplegableUsuarioVerd.length > 0 &&
              <Grid item lg={2} md={4} sm={12} xs={12} >
                <InputLabel id="UsuarioVerd">Usuario Verdadero</InputLabel>
                <Select
                  labelId="UsuarioVerd"
                  variant="outlined"
                  multiple
                  id="UsuarioVerd"
                  value={filtro}
                  label="UsuarioVerdl"
                  onChange={handlefiltro}
                  renderValue={(selected) => selected.join(', ')}
                  fullWidth
                >
                  {desplegableUsuarioVerd.map(val => {
                    return <MenuItem key={val} value={val}>
                      <Checkbox color="primary" checked={filtro.indexOf(val) > -1} />
                      <ListItemText color="primary" primary={val} />

                    </MenuItem>
                  })}

                </Select>
              </Grid>}
            <Grid item
              lg={1} md={4} sm={12} xs={12} alignItems="center" justify="center"
            >
              {isLoading2 ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  className={classes.buttonSubmit}

                  onClick={() =>
                    cargarData()}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Buscar
                </Button>
              )}

            </Grid>
            <Fade in={errorServidor}>
              <Typography color="secondary" className={classes.errorMessage}>
                Por favor vuelva a intentarlo.
              </Typography>
            </Fade>

            {/* {desplegableUsuarioVerd.length > 0 &&
                <Grid container lg={6} md={12} sm={12} xs={12} alignItems="left" justify='flex-end'>
                  <Grid item lg={12} md={12} sm={12} xs={12} >


                    <InputLabel id="type">Usuario Verdadero</InputLabel>
                    <Select
                      labelId="UsuarioVerd"
                      variant="outlined"
                      multiple
                      id="UsuarioVerd"
                      value={filtro}
                      label="UsuarioVerdl"
                      onChange={handlefiltro}
                      renderValue={(selected) => selected.join(', ')}
                      fullWidth
                    >
                      {desplegableUsuarioVerd.map(val => {
                        return <MenuItem key={val} value={val}>
                          <Checkbox color="primary" checked={filtro.indexOf(val) > -1} />
                          <ListItemText color="primary" primary={val} />

                        </MenuItem>
                      })}

                    </Select>
                  </Grid>

                </Grid>} */}
          </Grid>

          <MUIDataTable
            title={'Checklist'}
            data={datatableData1}
            // options={Object.assign(optionsFooter, optionsNormal, {rowsPerPage : 15})}
            options={optionsFooter}
            columns={[{
              name: 'Acciones',
              options: {
                filter: true,
                display : isAuthorizedToEdit,

                customBodyRender: (valor, dataIndex, updateValue) => {
                  // info de fila
                  const dataRow = dataIndex.rowData
                  if (dataRow[6] != 'No Realizado') {
                    return (
                      <div className={classes.centerContent}>
                        <EditStock
                          dataRow={dataIndex.rowData}
                          cargarData={cargarData}
                          origen={'editar'}
                          fontSize="small"
                        ></EditStock>
                        <IconButton aria-label="edit" size="small"
                          color='primary'
                          onClick={() => handleOpenBorrar(dataIndex.rowData[0])}
                        >
                          <DeleteIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                      </div>


                    )
                  } else {
                    return (
                      <div className={classes.centerContent} >
                        <EditStock
                          dataRow={dataIndex.rowData}
                          cargarData={cargarData}
                          origen={'nuevo'}
                          fontSize="small"
                        ></EditStock>
                      </div>)
                  }


                }

              }
            }, {
              name: 'Fecha',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                minWidth: 60
              }
            },
            {
              name: 'Tropa',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                minWidth: 70
              }
            }, {

              name: 'Cor',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
              }
            }, {
              name: 'Cab',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
              }
            },
            {
              name: 'Usuario',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                filterList: filtro

              }
            },

            {
              name: 'Status',
              options: {
                filter: true,
                customBodyRender: (valor, dataIndex, updateValue) => {
                  return (

                    <div className={classes.contained} style={{ textAlign: 'center', justifyContent: 'center', }}>
                      {/* <FormControl size="small"> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={valor}
                        label="status"
                        variant="standard"
                        disabled={!isAuthorizedToEdit}
                        disableUnderline
                        style={{ maxHeight: '8px' }}
                        classes={classes.rem}
                        onChange={(e) => {
                          updateValue(e.target.value)
                          // si tiene id, es un put, sino es un post
                          if (dataIndex.rowData[0] != undefined) {
                            updateStockStatus(dataIndex.rowData[0], e.target.value)
                          } else {
                          anularStock(dataIndex.rowData, e.target.value)
                        }
                          

                          //cargarData()

                        }}
                      ><MenuItem value={'Pendiente Caja'}>
                          <ListItemText
                            primary={!isSmallScreen ? 'Pendiente Caja' : "PC"} />
                        </MenuItem>
                        <MenuItem value={'No Realizado'}>
                          <ListItemText
                            primary={!isSmallScreen ? 'No Realizado' : "NR"} />
                        </MenuItem>
                        <MenuItem value={'No Aplica'}>
                          <ListItemText primary={!isSmallScreen ? 'No Aplica' : 'N/A'} />
                        </MenuItem>
                        <MenuItem value={'Realizado'}>
                          <ListItemText primary={!isSmallScreen ? 'Realizado' : 'R'} />
                        </MenuItem>


                      </Select>
                      {/* </FormControl> */}
                    </div>
                  )

                }

              }
            }, {
              name: "",
              options: {
                filter: true,
                display: true,
                setCellProps: () => ({
                  textAlign: 'center',
                  alignItems: 'center'

                }),
                customBodyRender: (valor, dataIndex, updateValue) => {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {valor == 'No Realizado' ? <CircleRoundedIcon className={classes.yellow} /> :
                        valor == "Realizado" ? <CircleRoundedIcon className={classes.verde} /> :
                          valor == "No Aplica" ? <CircleRoundedIcon className={classes.purple} /> :
                            valor == "cambiado" ? <CircleRoundedIcon className={classes.rojoCircle} /> :
                              valor == "Pendiente Caja" ? <CircleRoundedIcon className={classes.blue} /> :
                                ''}
                    </div>
                  )
                }
              },
            }, {
              name: 'Programa',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center', maxWidth: isSmallScreen? "80px" : "", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              }
            },

            {
              name: 'Caja',
              options: {
                filter: true,

                customBodyRender: (valor, dataIndex, updateValue) => {
                  return (
                    <>
                      <div className={classes.contained} style={{ textAlign: 'center', justifyContent: 'center', maxWidth: "70px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap",}}>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={valor}
                          label="status"
                          classes={classes.rem}
                          disableUnderline
                          style={{ maxHeight: '8px', }}
                          disabled={(dataIndex.rowData[6] == 'No Realizado' || dataIndex.rowData[6] == 'No Aplica' || !isAuthorizedToEdit) ? true : false}
                          onChange={(e) => {
                            updateValue(e.target.value)
                            cambiarCaja(dataIndex.rowData, e.target.value)
                            //cargarData()

                          }}
                        >

                          <MenuItem value={'Negra'}>
                            <ListItemText primary={'Negra'} />
                          </MenuItem>
                          <MenuItem value={'Estandar'}>
                            <ListItemText primary={!isSmallScreen ? 'Estandar' : 'Std.'} />
                          </MenuItem>
                          <MenuItem value={'Gold'}>
                            <ListItemText primary={'Gold'} />
                          </MenuItem>


                        </Select>
                      </div>
                    </>)

                }

              }
            },
            {
              name: 'Remitente',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center', maxWidth: isSmallScreen? "120px" : "", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              }
            },
            {
              name: 'Vivo Prom.',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center', maxWidth: "80px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              }
            },
            {
              name: 'Aptitud',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center', maxWidth: "50px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              }
            },
            {
              name: 'Proc',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center', maxWidth: "80px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              }
            },
            {
              name: 'Esp',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
              }
            },
            {
              name: 'Raza',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
              }
            },
            {
              name: 'Conf',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
              }
            },
            {
              name: 'Gord',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center' } }),
              }
            }, {

              name: 'Obs',
              options: {
                setCellProps: () => ({ style: { maxWidth: '200px', wordWrap: "break-word" } }),
                //customBodyRender: (value) => (<Typography>{value}</Typography>)
              }
            }, {
              name: 'Imagen',
              options: {
                filter: true,

                customBodyRender: (valor, dataIndex, updateValue) => {
                  if (valor != undefined) {
                    return (
                      <>
                        <IconButton aria-label="edit" size="medium"
                          color='primary'
                          onClick={() => handleOpen(dataIndex.rowData[2], dataIndex.rowData[1])}
                        >
                          <PhotoSizeSelectActualIcon fontSize="small" />
                        </IconButton>


                      </>)
                  }

                }

              }
            }
            ]}
          >
          </MUIDataTable>


          <Modal
            hideBackdrop
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className={classes.Modal}>

              <Grid container>
                {cardData.map(val => {
                  return <CardImage
                    tropa={val}
                    Imgdata={cardData}
                    cambiarState={cargarData}
                    stateImage={pedirImagen}
                  ></CardImage>

                })}
              </Grid>




              <>

                <Button
                  variant="contained"
                  color="primary"
                  size='large'
                  onClick={() => { handleClose() }}
                  className={classes.buttonModal}
                >Cerrar
                </Button>
              </>

            </Box>
          </Modal>
          <Modal
            hideBackdrop
            open={openBorrar}
            onClose={handleCloseBorrar}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className={classes.Modal}>

              <h2 id="child-modal-description">
                {mensaje}

              </h2>




              <>
                <Button
                  variant="contained"
                  color="primary"
                  size='large'
                  onClick={() => { deleteStock(tropa) }}
                  className={classes.buttonModal}
                >Borrar
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size='large'
                  onClick={() => { handleCloseBorrar() }}
                  className={classes.buttonModal}
                >Cerrar
                </Button>
              </>

            </Box>
          </Modal>
          {/* </Widget> */}
        </Grid>
      </Grid>

    </>
  );
}
