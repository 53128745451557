import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  IconButton
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import {
  Home as HomeIcon,
  Person as AccountIcon,

  NotificationsNone as NotificationsIcon,
  //FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  //BorderAll as TableIcon,
  //QuestionAnswer as SupportIcon,
  //LibraryBooks as LibraryIcon,
  //HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  CameraRear,
} from "@material-ui/icons";
import PieChartIcon from '@mui/icons-material/PieChart';

import InventoryIcon from '@mui/icons-material/Inventory';
import BarChartIcon from '@mui/icons-material/BarChart';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';


// styles
import useStyles from "./styles";

// components
//import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
//import Table from "./components/Table/Table";
//import UserStateContext from "../../context/UserContext";
//import jwt_decode from "jwt-decode";
import GradingIcon from '@mui/icons-material/Grading';




const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];
// rol usuario


export default function HomeIndex(props) {
  var classes = useStyles();
  var theme = useTheme();
  
  var rol = localStorage.getItem('permissions')
    // local
  var [mainChartState, setMainChartState] = useState("monthly");

  return (
    <>
      <PageTitle title="Inicio"  />
    
       <Grid container spacing={4}>
      {/*  <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            upperTitle
            disableWidgetMenu
            noHeaderPadding

            noBodyPadding
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container className={classes.height100} item alignItems={"center"}>
                <Grid item className={classes.height100} lg={12} xs={12}>
                <Button fullwidth labelStyle={{ fontSize: '200%' }}
                    classes={{ root:classes.boton, label: classes.label }}
                >
                  <BarChartIcon primary className={classes.arrowUp}/>
                  <Typography size="xl" weight="medium" noWrap >
                   Dashboard
                  </Typography>
                </Button>

                 
                </Grid>
              
                
              </Grid>
            </div>
            
          </Widget>
        </Grid>  */}
        {(rol== 6 || rol== 1 || rol== 2 )  &&

        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget
            upperTitle
            disableWidgetMenu
            noHeaderPadding

            noBodyPadding
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container className={classes.height100} item alignItems={"center"}>
              
                <Grid item className={classes.height100} lg={12} xs={12}>
                <Button fullwidth 
                  href="#/app/tables"

                    classes={{ root:classes.boton, label: classes.label }}
                >
                  <AccountIcon primary className={classes.arrowUp}/>
                  <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                   Usuarios
                  </Typography>
                </Button>

                 
                </Grid>
              
                
              </Grid>
            </div>
            
          </Widget>
        </Grid>}
         {(rol== 6 || rol== 1|| rol== 2 )  &&
        <Grid item lg={4} md={6} sm={6} xs={12}>
        <Widget
          upperTitle
          disableWidgetMenu
          noHeaderPadding

          noBodyPadding
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
        <div className={classes.visitsNumberContainer}>
          <Grid container className={classes.height100} item alignItems={"center"}>
          
                <Grid item className={classes.height100} lg={12} xs={12}>
                <Button fullwidth 
                  href="#/app/Dashboard"

                    classes={{ root:classes.boton, label: classes.label }}
                >
                  <PieChartIcon primary className={classes.arrowUp}/>
                  <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                  Dashboard
                  </Typography>
                </Button>

                
                </Grid>
              
                
              </Grid>
            </div>
            
          </Widget>
        </Grid>} 
        {/* {(rol!=3 && rol!=5 && rol!=4  )  &&
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              upperTitle
              disableWidgetMenu
              noHeaderPadding

              noBodyPadding
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.height100} item alignItems={"center"}>
              
                  <Grid item className={classes.height100} lg={12} xs={12}>
                  <Button fullwidth labelStyle={{ fontSize: '200%' }}
                      classes={{ root:classes.boton, label: classes.label }}
                      href="#/app/usuariosDeCarga"

                  >
                    <ContactPageIcon primary className={classes.arrowUp}/>
                    <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                    CONTACTOS DE CARGA
                    </Typography>
                  </Button>

                  
                  </Grid>
                
                  
                </Grid>
              </div>
              
            </Widget>
          </Grid>} */}
         {/*  {( rol!=5 && rol!=4  )  && */}
          {(rol== 6 || rol== 1 || rol== 2 || rol== 3)  &&

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              upperTitle
              disableWidgetMenu
              noHeaderPadding

              noBodyPadding
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.height100} item alignItems={"center"}>
              
                  <Grid item className={classes.height100} lg={12} xs={12}>
                  <Button fullwidth labelStyle={{ fontSize: '200%' }}
                      classes={{ root:classes.boton, label: classes.label }}
                      href="#/app/estadoCompras"

                  >
                    <ShoppingCartIcon primary className={classes.arrowUp}/>
                    <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                    Rec. Programadas
                    </Typography>
                  </Button>

                  
                  </Grid>
                
                  
                </Grid>
              </div>
              
            </Widget>
          </Grid>}
          {/* {(rol!=4 )  && */}
        {(rol== 6 || rol== 1 || rol== 2 || rol== 3|| rol== 5)  &&

        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget
            upperTitle
            disableWidgetMenu
            noHeaderPadding
            noBodyPadding
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container className={classes.height100} item alignItems={"center"}>
                <Grid item className={classes.height100} lg={12} xs={12}>
                <Button fullwidth labelStyle={{ fontSize: '200%' }}
                    classes={{ root:classes.boton, label: classes.label}}
                    href="#/app/stock"
                >
                  <InventoryIcon primary className={classes.arrowUp}/>
                  <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                   STOCK
                  </Typography>
                </Button>

                 
                </Grid>
              
                
              </Grid>
            </div>
            
          </Widget>
        </Grid>}
        {(rol!=3 && rol!=7 && rol!=8 )  &&

        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Widget
            upperTitle
            disableWidgetMenu
            noHeaderPadding

            noBodyPadding
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container className={classes.height100} item alignItems={"center"}>
             
                <Grid item className={classes.height100} lg={12} xs={12}>
                <Button fullwidth labelStyle={{ fontSize: '200%' }}
                    classes={{ root:classes.boton, label: classes.label }}
                    href="#/app/romaneo"

                >
                  <FoodBankIcon primary className={classes.arrowUp}/>
                  <Typography size="xl" weight="medium" className={classes.text} noWrap>
                   FAENA
                  </Typography>
                </Button>

                 
                </Grid>
              
                
              </Grid>
            </div>
            
          </Widget>
        </Grid>}
        {(rol== 6 || rol== 1|| rol== 2 )  &&
        <Grid item lg={4} md={6} sm={6} xs={12}>
        <Widget
          upperTitle
          disableWidgetMenu
          noHeaderPadding

          noBodyPadding
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
        <div className={classes.visitsNumberContainer}>
          <Grid container className={classes.height100} item alignItems={"center"}>
          
                <Grid item className={classes.height100} lg={12} xs={12}>
                <Button fullwidth 
                  href="#/app/dte"

                    classes={{ root:classes.boton, label: classes.label }}
                >
                  <PlaylistAddIcon primary className={classes.arrowUp}/>
                  <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                  Dte
                  </Typography>
                </Button>

                
                </Grid>
              
                
              </Grid>
            </div>
            
          </Widget>
        </Grid>}
        {(rol== 6 || rol== 1|| rol== 2|| rol== 5)  &&
        
          <Grid item lg={4} md={4} sm={6} xs={12}>
            
            <Widget
              upperTitle
              disableWidgetMenu
              noHeaderPadding

              noBodyPadding
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.height100} item alignItems={"center"}>
              
                  <Grid item className={classes.height100} lg={12} xs={12}>
                  <Button fullwidth labelStyle={{ fontSize: '200%' }}
                      classes={{ root:classes.boton, label: classes.label }}
                      href="#/app/checkList"

                  >
                    <GradingIcon primary className={classes.arrowUp}/>
                    <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                    
                    CHECKLIST
                    </Typography>
                  </Button>

                  
                  </Grid>
                
                  
                </Grid>
              </div>
              
            </Widget>
          </Grid>   
          }
          {(rol!=3 && rol!=7 && rol!=8)  &&

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              upperTitle
              disableWidgetMenu
              noHeaderPadding

              noBodyPadding
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Grid container className={classes.height100} item alignItems={"center"}>
              
                  <Grid item className={classes.height100} lg={12} xs={12}>
                  <Button fullwidth labelStyle={{ fontSize: '200%' }}
                      classes={{ root:classes.boton, label: classes.label }}
                      href="#/app/gestionDoc"

                  >
                    <AssignmentIcon primary className={classes.arrowUp}/>
                    <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                    
                    Gestión Documental
                    </Typography>
                  </Button>

                  
                  </Grid>
                
                  
                </Grid>
              </div>
              
              
            </Widget>
          </Grid>
          }
           
           {(rol!= 3 &&  rol!= 4 && rol!=5)&&
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Widget
                upperTitle
                disableWidgetMenu
                noHeaderPadding

                noBodyPadding
                bodyClass={classes.fullHeightBody}
                className={classes.card}
              >
                
                <div className={classes.visitsNumberContainer}>
                  <Grid container className={classes.height100} item alignItems={"center"}>
                
                    <Grid item className={classes.height100} lg={12} xs={12}>
                    <Button fullwidth labelStyle={{ fontSize: '200%' }}
                        classes={{ root:classes.boton, label: classes.label }}
                        href="#/app/Feedlot"

                    >
                      <RestaurantMenuIcon primary className={classes.arrowUp}/>
                      <Typography size="xl" weight="medium" className={classes.text}  noWrap>
                      
                      FeedLot
                      </Typography>
                    </Button>

                    
                    </Grid>
                  
                    
                  </Grid>
                </div>
                
                
              </Widget>
            </Grid>}
            
        
      
    
      
        </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
