import React, { useState, useContext, useEffect } from "react";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns, } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale'
import { parseISO } from 'date-fns';
import moment from 'moment';
import { Alert, Collapse, IconButton, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import UserStateContext from "../../context/UserContext";
import { useTheme } from "@material-ui/styles";
import { ThemeProvider } from '@mui/material/styles';


const styles = {
  date: {
    color: 'black'
  },
}


export default function ResponsiveDatePickers(props) {
  const dateFormat = "YYYY-MM-DD";

  /// fechas en año -mes dia
  const [value, setValue] = React.useState();
  const [tipoDeInput, setTipoDeInput] = React.useState();
  const [open, setOpen] = React.useState(false);
  const { darkMode } = useContext(UserStateContext);
  const theme = useTheme();
  const notRequired = props.required === false;

  const handleLangChange = (event) => {
    setValue(moment(event).format("YYYY-MM-DD"))
    props.setValorFecha(moment(event).format("YYYY-MM-DD"))
  }
  useEffect(() => {

    setValue((props.value));
    setTipoDeInput(props.tipo)
  }, [props.value]);

  // useEffect(() => {
  //   if (tipoDeInput == 'Faena') {
  //     /* console.log(value)
  //     console.log(moment().format("YYYY-MM-DD")) */
  //     /*  if (value!=''&& value<moment().format("YYYY-MM-DD") ){
  //        setOpen(true);
  //      } */

  //   }



  // }, [value]);

  const isWeekend = (date) => {
    if (date) {

      const day = moment(date).day();

      return day === 0 || day === 6;
    }
    //const color = "#c44242";
  };
  return (
    <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <ThemeProvider theme={theme}>
          <DatePicker
            inputFormat="dd-MM-yyyy"
            disabled={props.disabled}
            openTo="day"
            views={['year', 'month', 'day']}
            value={parseISO(value)}
            onChange={handleLangChange}
            shouldDisableDate={props.dontDisabledWeekend === true ? false : isWeekend}
            sx={{
              'svg': { color: 'inherit' },

            }}
            PaperProps={{
              sx: {
                svg: { color: theme.palette.text.primary }, span: { color: theme.palette.text.primary },
              },
              
            }}
            renderInput={(params) => <TextField
              // sx={darkMode ? {
              //   '.MuiInputBase-input': { color: 'inherit' },
              //   '.MuiIconButton-root': { color: 'inherit' },
              //   '.MuiInputBase-root': { color: 'inherit', borderRadius: '15px' },

              //   '.MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.tables.tableBorderColor },
              // } : {
              //   '.MuiInputBase-input': { color: 'inherit' },
              //   '.MuiIconButton-root': { color: 'inherit' },
              //   '.MuiInputBase-root': { color: 'inherit', borderRadius: '15px' },
              //   '.MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.tables.tableBorderColor },

              // }}
              sx={{
                '.MuiInputBase-input': { color: 'inherit' },
                '.MuiIconButton-root': { color: 'inherit' },
                '.Mui-disabled': { color: `${theme.palette.text.disabled} !important`, '-webkit-text-fill-color': `${theme.palette.text.disabled} !important` },
                '.MuiInputBase-root': { color: 'inherit', borderRadius: '15px' },
                '.MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.tables.tableBorderColor },
              }}
              {...params}
              error={!notRequired && value === ""}
            />
          }
          />
        </ThemeProvider>
      </Stack>
    </LocalizationProvider>
  );
}