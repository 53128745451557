import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Grid,
  IconButton,
  Button,
  Tooltip,
  FormLabel,
  ListItemText,
  FormControl,
  Slider,
  FormControlLabel,
  CircularProgress,
  Fade,
  Typography,
  TextField,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Link,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { useTheme } from "@material-ui/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import ResponsiveDatePickers from "../../components/dateRangePicker/dateRangePicker";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import UserService from "../../context/UserService";
import moment from "moment";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import ResponsiveDialog from "../../components/informe/Informe";
import { signOut } from "../../context/UserContext";
import { CustomSearchButton } from "../../components/search-button/search-button";
import FilterListIcon from "@mui/icons-material/FilterList";
import useMediaQuery from "@mui/material/useMediaQuery";
import MarcaComercialAgrup from "../../components/MarcaComercialAgrup/MarcaComercialAgrup";
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PrintIcon from '@mui/icons-material/Print';
import { getWeeksInMonth, getISOWeek } from "date-fns";
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import UserStateContext from "../../context/UserContext";


const ToggleButton = styled(MuiToggleButton)(
  ({ textColor, selectedColor, borderColor, defaultColor }) => ({
    borderColor: borderColor,
    color: defaultColor,
    borderRadius: "15px",
    textTransform: 'capitalize',
    maxHeight: "60px",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: textColor,
      backgroundColor: selectedColor,
    },
  }),
);

export default function Romaneo(props) {
  // rol de usuario
  //var rol = localStorage.getItem("permissions");
  const API_URL = window.config.API_URL;
  const theme = useTheme();
  const { user } = React.useContext(UserStateContext);
  //var rol = user.rol

  const isXsOrSm = useMediaQuery(theme.breakpoints.down("md"));

  const [openSubirArchivo, setOpenSubirArchivo] = React.useState(false);
  const [columna, setColumna] = useState("");
  const [ebeln, setEbeln] = useState("");
  const [open, setOpen] = React.useState(false);

  // usuario
  //var user = JSON.parse(localStorage.getItem("userData"));
  let isInternal = user.type === "Rioplatense"
  let isProveedor = user.type === "Proveedor"

  // permisos
  const canViewReporteFotosFaena = user.permissions?.faena_reporte_fotos_faena_can_view === true;
  const canViewReporteFotosGolpeados = user.permissions?.faena_reporte_fotos_golpeados_can_view === true;


  // state para ver si mostrar filas
  const [mostrarCosto, setMostrarCosto] = useState(false);
  const [mostrarMarbling, setMostrarMarbling] = useState(false);
  const [mostrarArea, setMostrarArea] = useState(false);
  const [mostrarColor, setMostrarColor] = useState(false);
  const [mostrarTropa, setMostrarTropa] = useState(false);
  const [remitente, setRemitente] = useState(() => {
    if (user.cuit != undefined) {
      return user.razonS;
    } else {
      return "";
    }
  });
  const [consignatario, setConsignatario] = useState(() => {
    if (user.cuit != undefined) {
      return user.razonS;
    } else {
      return "";
    }
  });
  // state de filtros
  const [filtro, setfiltro] = useState(true);
  const [checked, setChecked] = React.useState(true);
  // valores inicios
  const [dataRomaneo, setdataRomaneo] = useState("");
  const [dataNumero, setdataNumero] = useState("");
  // valor tabla Tropa
  const [dataTropa, setdataTropa] = useState("");
  // valor tabla romaneo
  const [valor, setValor] = useState("");
  //valor fecha para buscar
  const [fechaStart, setFechaStart] = useState(moment().format("YYYY-MM-DD"));
  const [fechaEnd, setFechaEnd] = useState(moment().format("YYYY-MM-DD"));
  // valor del check
  const [check, setCheck] = useState("Nomeclador");
  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [isLoading2, setIsLoading2] = useState(false);
  var [isLoadingInfo, setIsLoadingInfo] = useState(false);

  const [isLoadingDownloadExcelT1, setIsLoadingDownloadExcelT1] = useState(false);
  const [isLoadingDownloadExcelT2, setIsLoadingDownloadExcelT2] = useState(false);
  const [isLoadingDownloadExcelT3, setIsLoadingDownloadExcelT3] = useState(false);

  const [isLoadingDownloadPdfT1, setIsLoadingDownloadPdfT1] = useState(false);
  const [isLoadingDownloadPdfT2, setIsLoadingDownloadPdfT2] = useState(false);
  const [isLoadingDownloadPdfT3, setIsLoadingDownloadPdfT3] = useState(false);

  const [errorDownloadT1, setErrorDownloadT1] = useState(false);
  const [errorDownloadT2, setErrorDownloadT2] = useState(false);
  const [errorDownloadT3, setErrorDownloadT3] = useState(false);

  // busqueda personalizada.
  var [searchValue, setSearchValue] = useState();
  var [origen, setOrigen] = useState("");
  //estados para ver ciertos campos
  var [tropa, setTropa] = useState(() => {
    // if (rol == 4) {
    //   return true
    // } else {
    //   return false
    // }
    return true;
  });

  //Ocultar o mostrar filtros
  const [showFilters, setShowFilters] = useState(false);

  const handleFilters = () => {
    setShowFilters(!showFilters);
  };

  // cerrar Modal
  const handleClose = () => {
    setOpen(false);
  };

  // desplegables
  const [despMarcaComercial, setDespMarcaComercial] = useState([]);
  const [despEspecie, setDespEspecie] = useState([]);
  const [despTipodeCompra, setDespTipodeCompra] = useState([]);
  const [despUsuarioFaena, setDespUsuarioFaena] = useState([]);

  // filtros personalizados
  const [filtroMarcaComercial, setFiltroMarcaComercial] = useState([]);
  const [filtroEspecie, setFiltroEspecie] = useState([]);
  const [filtroTipoDeCompra, setFiltroTipoDeCompra] = useState([]);
  const [filtroUsuarioFaena, setFiltroUsuarioFaena] = useState([]);
  // autocomplete
  const [autocompleteRemitente, setAutocompleteRemitente] = useState([]);
  const [autocompleteConsig, setAutocompleteConsig] = useState([]);
  const [autocompleteComisionista, setAutocompleteComisionista] = useState([]);
  const [autocompleteTropas, setAutocompleteTropas] = useState([]);

  // Busqueda de tropa
  const [tropaToSearch, setTropaToSearch] = useState("");
  const [tempTropaToSearch, setTempTropaToSearch] = useState("");
  const [tropaToSearchError, setTropaToSearchError] = useState(false);

  // Busqueda por Remitente / Consignatario en la tabla
  const [proveedorIsBeingSearched, setProveedorIsBeingSearched] = useState(false);

  // filtros slider
  const [numero, setNumero] = useState([0]);
  const [cabezas, setCabezas] = useState([0]);
  const [kilosV, setKilosV] = useState([0]);
  const [ratioT, setRatioT] = useState([0]);
  const [ratioG, setRatioG] = useState([0]);

  const filterTropa = localStorage.getItem("filterListTropa");
  const filterFaena = localStorage.getItem("filterListFaena");
  const [filterListRomaneo, setFilterListRomaneo] = useState([]);
  const [filterListTropa, setFilterListTropa] = useState([]);
  const [alignment, setAlignment] = React.useState("left");
  const [file, setFile] = useState("");

  const [openConfirmMail, setopenConfirmMail] = React.useState(false);
  const [IndexRows, setIndexRows] = useState("");
  const [dataEmails, setDataEmails] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [marcasAgrupadasExpo, setMarcasAgrupadasExpo] = useState({});
  const [marcasAgrupadasConsumo, setMarcasAgrupadasConsumo] = useState({});
  const flagMarcaAgrupada = user.permissions.faena_marcas_can_view ? true : false;

  const [mostrarMarcas, setMostrarMarcas] = useState(false);
  const [mostrarMarcasConsumo, setMostrarMarcasConsumo] = useState(false);
  const [mostrarMarcasExpo, setMostrarMarcasExpo] = useState(false);

  //Mostrar tabla Nomenclador / Marca solo si hay datos
  const [mostrarTablaResumen, setMostrarTablaResumen] = useState(false);

  //Loading para reporte
  const [loadingReporte, setLoadingReporte] = useState('');

  //para tabla agrupada por usuarios 
  const [datosTablaAgrupadaUsuarios, setDatosTablaAgrupadaUsuarios] = useState([]);

  // para tabla de faena agrupada
  const [datosTablaFaenaAgrupadaNomenclador, setDatosTablaFaenaAgrupadaNomenclador] = useState([]);
  const [datosTablaFaenaAgrupadaMarcas, setDatosTablaFaenaAgrupadaMarcas] = useState([]);

  const [mostrarTablaUsuarios, setMostrarTablaUsuarios] = useState(false);

  // para mostrar titulo resumen de busqueda
  const [mostrarTitulo, setMostrarTitulo] = useState(false);
  const [titulo, setTitulo] = useState("Buscando");

  // para busqueda por anio, mes y semana
  const [anio, setAnio] = useState(() => {
    return new Date().getFullYear();
  });
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedYearsSet, setSelectedYearsSet] = useState(() => (new Set()))
  const [yearsRange, setYearsRange] = useState(() => {
    const currentYear = new Date().getFullYear();
    return [currentYear, currentYear];
  })

  const [establecimientos, setEstablecimientos] = useState([]);
  const [selectedEstablecimientos, setSelectedEstablecimientos] = useState([]);

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const [mes, setMes] = useState(() => {
    //const currentMonth = new Date().getMonth();
    //return currentMonth;
    return "";
  })
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedMonthsSet, setSelectedMonthsSet] = useState(() => (new Set()))

  const [monthList, setMonthList] = useState(() => {
    const currentMonth = new Date().getMonth();
    return months.slice(0, currentMonth + 1);
  })

  const [week, setWeek] = useState(() => {
    //return getISOWeek(new Date());
    return "";
  })

  const [weekList, setWeekList] = useState(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const firstWeek = getISOWeek(new Date(currentYear, currentMonth, 1));
    const numWeeks = getWeeksInMonth(new Date(currentYear, currentMonth));
    return Array.from({ length: numWeeks }, (_, i) => firstWeek + i);
  })

  const currentYear = new Date().getFullYear();
  const yearsList = Array.from({ length: currentYear - 2021 }, (_, i) => 2022 + i);

  //console.log(yearsList, monthList, weekList);

  const handleYearChange = useCallback((event) => {
    //setAnio(event.target.value);
    const selectedYearsLength = event.target.value.length;
    setSelectedYears(event.target.value);
    if (selectedYearsLength > 0) {
      setSelectedYearsSet(new Set(event.target.value));
      const lowerYear = selectedYearsLength > 0 ? Math.min(...event.target.value) : undefined;
      const upperYear = selectedYearsLength > 1 ? Math.max(...event.target.value) : lowerYear;
      setYearsRange([lowerYear, upperYear]);
      const currentYear = new Date().getFullYear();
      const isCurrentYearOnly = selectedYearsLength === 1 && lowerYear === currentYear;
      // setear meses en funcion de los anios -> recortar si solo se selecciono el actual
      if (isCurrentYearOnly) {
        const currentMonth = new Date().getMonth();
        setMonthList(months.slice(0, currentMonth + 1));
      } else {
        setMonthList(months);
      }

      // setear fecha desde y fecha hasta
      //const currentMonth = new Date().getMonth();
      const firstDayYear = new Date(lowerYear, 0, 1);
      const lastDayYear = !isCurrentYearOnly ? new Date(upperYear, 11, 31) : new Date();
      setFechaStart(moment(firstDayYear).format("YYYY-MM-DD"));
      setFechaEnd(moment(lastDayYear).format("YYYY-MM-DD"));

      // resetear mes y semana
      if (mes != "") {
        setMes("");
        setSelectedMonths([]);
      }
      if (week != "") {
        setWeek("");
      }

      setMostrarTitulo(true);
    }
  }, [mes, week])

  const handleMonthChange = useCallback((event) => {

    if (event.target.value.length > 2) {
      // como maximo 2
      return
    }
    const selectedMonthsLength = event.target.value.length;

    setSelectedMonths(event.target.value);
    setSelectedMonthsSet(new Set(event.target.value))
    let monthsString = "";
    if (event.target.value.length > 0) {
      const lowerMonth = selectedMonthsLength > 0 ? Math.min(...event.target.value) : undefined;
      const upperMonth = selectedMonthsLength > 1 ? Math.max(...event.target.value) : undefined;
      setWeek("");
      setMostrarTitulo(true);
      
      if (lowerMonth != undefined && upperMonth != undefined && lowerMonth != upperMonth) {
        monthsString = months[lowerMonth] + '-' + months[upperMonth]
      } else if (upperMonth === undefined) {
        monthsString = months[lowerMonth]
      } 

      // Setear semanas si solo se selecciono un anio
      if (selectedYears.length === 1) {
        const firstWeekLower = getISOWeek(new Date(yearsRange[0], lowerMonth, 1));
        const numWeeksLower = getWeeksInMonth(new Date(yearsRange[0], lowerMonth));
        const weeksLower = Array.from({ length: numWeeksLower }, (_, i) => firstWeekLower + i);

        let weeksUpper = []
        if (upperMonth != undefined) {
          const firstWeekUpper = getISOWeek(new Date(yearsRange[1], upperMonth, 1));
          const numWeeksUpper = getWeeksInMonth(new Date(yearsRange[1], upperMonth));
          weeksUpper = Array.from({ length: numWeeksUpper }, (_, i) => firstWeekUpper + i);
        }
        //setWeekList(weeks);
        setWeekList(weeksLower.concat(weeksUpper));
      }

      // Setear mes actual
      const firstDay = new Date(yearsRange[0], lowerMonth, 1);
      const lastDay = new Date(yearsRange[1], upperMonth != undefined ? upperMonth + 1 : lowerMonth + 1, 0);
      setFechaStart(moment(firstDay).format("YYYY-MM-DD"));
      setFechaEnd(moment(lastDay).format("YYYY-MM-DD"));
    }
    setMes(monthsString);
  }, [week, selectedYears, yearsRange])

  const handleWeekChange = useCallback((event) => {
    setWeek(event.target.value);
    setMostrarTitulo(true);

    // setear semana actual
    const firstDay = moment().year(anio).week(event.target.value).startOf('week').format('YYYY-MM-DD');
    const lastDay = moment().year(anio).week(event.target.value).endOf('week').format('YYYY-MM-DD');
    setFechaStart(firstDay);
    setFechaEnd(lastDay);
  }, [anio])

  const innerTitle = useCallback((key, value) => {
    return (
      <>
        <>{" | "}</><strong>{key + ": "}</strong><>{value}</>
      </>
    )
  }, [])

  const handleEstablecimientoChange = useCallback((event) => {
    setSelectedEstablecimientos(event.target.value);
  }, [])

  // use effect para efectuar busqueda cuando se clickea un remitente o consignatario
  useEffect(() => {
    if (proveedorIsBeingSearched) {
      setProveedorIsBeingSearched(false);
      cargarData();
    }
  }, 
  [proveedorIsBeingSearched])

  const handleCleanFilters = useCallback(() => {
    setMes("");
    setWeek("");
    setRemitente("");
    setConsignatario("");
    setFiltroMarcaComercial([]);
    setFiltroEspecie([]);
    setFiltroTipoDeCompra([]);
    setFiltroUsuarioFaena([]);
    setTropaToSearch("");
    setSelectedEstablecimientos([]);
  }, [])

  const handleFechaChange = useCallback(() => {
    setMes("");
    setWeek("");
  }, [])


  const getTitleBusqueda = useCallback(() => {
    const fechaInicio = moment(fechaStart).format("DD/MM/YY");
    const fechaFin = moment(fechaEnd).format("DD/MM/YY");
    return (
      <>
        {titulo}<strong>{" Fechas:"}</strong>{" "}{fechaInicio}{" - "}{fechaFin}
        {mes != "" && innerTitle("Mes", mes)}
        {week != "" && innerTitle("Semana", week)}
        {remitente != "" && innerTitle("Remitente", remitente)}
        {consignatario != "" && innerTitle("Consignatario", consignatario)}
        {filtroUsuarioFaena.length > 0 && innerTitle("Usuario Faena", filtroUsuarioFaena.join(", "))}
        {filtroTipoDeCompra.length > 0 && innerTitle("Tipo de Compra", filtroTipoDeCompra.join(", "))}
        {filtroMarcaComercial.length > 0 && innerTitle("Marca Comercial", filtroMarcaComercial.join(", "))}
        {filtroEspecie.length > 0 && innerTitle("Especie", filtroEspecie.join(", "))}
        {tropaToSearch != "" && innerTitle("Tropa", tropaToSearch)}
      </>
    )
  }, [titulo, fechaStart, fechaEnd, mes, week, remitente, consignatario, filtroUsuarioFaena, filtroTipoDeCompra, filtroMarcaComercial, filtroEspecie, tropaToSearch])

  useEffect(() => {
    setTitulo("Buscando")
  }, [
    filtroUsuarioFaena,
    filtroTipoDeCompra,
    filtroMarcaComercial,
    filtroEspecie,
    remitente,
    consignatario,
    mostrarMarbling,
    mostrarArea,
    mostrarColor,
    mostrarCosto,
    tropaToSearch,
    mes,
    week,
  ])


  // const getUploadParams = ({ meta }) => {
  //   return { url: "https://httpbin.org/post" };
  // };

  // called every time a file's `status` changes
  // const handleChangeStatus = ({ meta, file }, status) => {
  //   /* console.log(status, meta, file) */
  // };

  // receives array of files that are done uploading when submit button is clicked
  // const handleSubmit = async (files, allFiles) => {
  //   const archivo = [];
  //   /* let newData = {  title: "",
  //   description: "",
  //   image_url: "", }; */

  //   files.map((f) =>
  //     archivo.push({
  //       title: f.meta.name,
  //       description: f.meta.type,
  //       image_url: f.meta.previewUrl,
  //       img: f.file,
  //     }),
  //   );

  //   setFile(archivo);

  //   // le mando el numero de certificado y el certificado a subir
  //   await UserService.putCertificadoTropa(archivo, ebeln, columna);
  //   //setOpen(false)
  //   //setSumarImg(false)
  //   allFiles.forEach((f) => f.remove());
  //   setOpenSubirArchivo(false);
  //   getDataTropa();
  // };

  // cargo los estados de los filtros
  useEffect(() => {

    if (!user.permissions.faena_can_view) {
      signOut(props.history);
    }



    if (user.type === "Proveedor" || user.type === "usuarioFaena") {
      setMostrarTropa(true);
      getDataTropa();
    }
    const dataTropa = localStorage.getItem("Tropa");
    if (dataTropa) {
      setdataTropa(JSON.parse(dataTropa));
    }
    const dataRomaneo = localStorage.getItem("Romaneo");
    if (dataRomaneo) {
      setValor(JSON.parse(dataRomaneo));
    }

    UserService.getFiltros().then(
      (response) => {
        if (response.status == 200) {
          var filtroMarca = response.data.dataMarcaComercial;
          //console.log(filtroMarca)
          filtroMarca.sort((a, b) =>
            parseInt(a.marca_comercial) > parseInt(b.marca_comercial)
              ? 1
              : parseInt(b.marca_comercial) > parseInt(a.marca_comercial)
                ? -1
                : 0,
          );

          setDespMarcaComercial(response.data.dataMarcaComercial);
          setDespEspecie(response.data.dataEspecie);
          setDespTipodeCompra(response.data.dataTiposDeCompra);
          setDespUsuarioFaena(response.data.dataUsuariosFaena);

        }

        if (response.status == 401) {
          signOut(props.history);
        }
      },
      (error) => {
        setErrorServidor(true);
        if (error.response.status == 401) {
          signOut(props.history);
        }
      },
    );
    UserService.getListConsigRemitTropa().then(
      (response) => {
        if (response.status == 200) {
          const dataTropaConsig = response.data.dataConsig.map((a, index) => {
            if (a.rs_consig != null)
              return { cuit: index, label: a.rs_consig.toString() };
          });
          const dataTropaRemit = response.data.dataREmitente.map((a, index) => {
            if (a.rs_produc != null)
              return { cuit: index, label: a.rs_produc.toString() };
          });
          const dataC = dataTropaConsig.filter(function (element) {
            return element !== undefined;
          });
          const dataR = dataTropaRemit.filter(function (element) {
            return element !== undefined;
          });
          setAutocompleteConsig(dataC);
          setAutocompleteRemitente(dataR);
          setAutocompleteComisionista(dataC);
        }
      },
      (error) => {
        setErrorServidor(true);
      },
    );
    UserService.getEstablecimientos().then(
      (response) => {
        if (response.status == 200) {
          setEstablecimientos(response.data);
        }
      }
    )
  }, []);

  useEffect(() => {
    if (filterTropa != null) {
      const filtrost = filterTropa.split(",");
      const filtrosFinalTropa = filtrost.map((val) => (val != "" ? [val] : []));
      setFilterListTropa(filtrosFinalTropa);
    }
    {
      setFilterListTropa([]);
    }
    if (filterFaena != null) {
      const filtrosF = filterFaena.split(",");
      const filtrosFinalFaena = filtrosF.map((val) => (val != "" ? [val] : []));
      setFilterListRomaneo(filtrosFinalFaena);
    } else {
      setFilterListRomaneo([]);
    }

    cargarData();
  }, [filterTropa, filterFaena]);

  // definir FEcha
  const setDate = (event) => {
    setMes("");
    setWeek("");
    setFechaEnd(moment(event.endDate).format("YYYY-MM-DD"));
    setFechaStart(moment(event.startDate).format("YYYY-MM-DD"));
  };

  const getDataTropa = useCallback(async () => {

    return await UserService.getTropa(
      fechaStart,
      fechaEnd,
      filtroUsuarioFaena,
      filtroTipoDeCompra,
      filtroMarcaComercial,
      filtroEspecie,
      remitente,
      consignatario,
      mostrarMarbling,
      mostrarArea,
      mostrarColor,
      mostrarCosto,
      tropaToSearch,
      selectedEstablecimientos
    )
  }, [fechaStart,
    fechaEnd,
    filtroUsuarioFaena,
    filtroTipoDeCompra,
    filtroMarcaComercial,
    filtroEspecie,
    remitente,
    consignatario,
    mostrarMarbling,
    mostrarArea,
    mostrarColor,
    mostrarCosto,
    tropaToSearch,
    selectedEstablecimientos]);

  const clearLoadingAndErrorStates = useCallback(() => {
    setIsLoadingDownloadExcelT1(false);
    setIsLoadingDownloadExcelT2(false);
    setIsLoadingDownloadExcelT3(false);
    setIsLoadingDownloadPdfT1(false);
    setIsLoadingDownloadPdfT2(false);
    setIsLoadingDownloadPdfT3(false);
    setErrorDownloadT1(false);
    setErrorDownloadT2(false);
    setErrorDownloadT3(false);
  }, [])

  const handleFaenaResponse = useCallback((response) => {
    setMostrarTablaResumen(true);
    setMostrarTablaUsuarios(true);
    // Marcas agrupadas
    if (flagMarcaAgrupada) {
      if (response.data.marcas != null) {
        if ("Exportacion" in response.data.marcas) {
          setMarcasAgrupadasExpo(response.data.marcas.Exportacion);
        }
        if ("Consumo" in response.data.marcas) {
          setMarcasAgrupadasConsumo(response.data.marcas.Consumo);
        }
      }
    };
    setDatosTablaFaenaAgrupadaNomenclador(response.data.dataNomeclador);
    setDatosTablaFaenaAgrupadaMarcas(response.data.dataMarcas);
  }, [flagMarcaAgrupada])

  const handleTropaResponse = useCallback((reponse) => {
    setMostrarTropa(true);
    localStorage.removeItem("Tropa");
    //const info = reponse.data.dataTropa;

    //localStorage.setItem('Tropa',JSON.stringify(responseTropa.data.dataTropa))
    // data de informacion
    //const dataTropas = responseTropa.data.dataTropa
    const dataTropas = reponse.data.dataTropa; //info.map((value) => JSON.parse(value));
    //console.log(dataTropas)
    // prueba para sacar informe general mas rapido
    const Tropas = [...new Set(dataTropas.map((v) => v.ebeln))];
    localStorage.removeItem("tropasParaInforme");

    localStorage.setItem("tropasParaInforme", JSON.stringify(Tropas));

    // lista de ebelns - tropa 
    const ebelnList = dataTropas.map(value => ({ ebeln: value.ebeln, tropa: value.tropa }));
    localStorage.removeItem("ebelnList");
    localStorage.setItem("ebelnList", JSON.stringify(ebelnList));

    dataTropas.sort((a, b) =>
      parseInt(a.tropa) > parseInt(b.tropa)
        ? 1
        : parseInt(b.tropa) > parseInt(a.tropa)
          ? -1
          : 0,
    );
    //  let largoTotal = dataTropas.length;

    // subtotales
    const merged = dataTropas.reduce(
      (
        r,
        {
          peso_total,
          usuariodescver,
          cabezas,
          vivofr,
          Rinde,
          TotKilos,
          SELEC,
          CHOICE,
          STANDARD,
          PRIME,
          costo,
        },
      ) => {
        const key = `${usuariodescver}`;
        if (!r[key]) {
          r[key] = {
            usuariodescver: usuariodescver,
            tropa: "",
            costo: 0,
            peso_total: 0,
            Rinde: 0,
            vivofr: 0,
            cabezas: 0,
            SELEC: 0,
            CHOICE: 0,
            STANDARD: 0,
            PRIME: 0,
            TotKilos: 0,
            count: 0,
          };
        }

        r[key]["peso_total"] += parseInt(peso_total);

        if (costo != null) {
          r[key]["costo"] += parseInt(costo);
        }

        if (cabezas != null) {
          r[key]["cabezas"] += parseInt(cabezas);
        }
        if (TotKilos != null) {
          r[key]["TotKilos"] += parseInt(TotKilos);
        }
        if (Rinde != null) {
          r[key]["rinde_porct"] += parseInt(Rinde);
        }
        if (vivofr != null) {
          r[key]["vivofr"] += parseInt(vivofr);
        }

        r[key]["SELEC"] += parseInt(SELEC);
        r[key]["CHOICE"] += parseInt(CHOICE);
        r[key]["STANDARD"] += parseInt(STANDARD);
        r[key]["PRIME"] += parseInt(PRIME);
        r[key]["count"] += 1;
        return r;
      },
      {},
    );
    //totales

    // let totKilosFix = 0;
    // for (let i = 0; i < dataTropas.length; i++) {
    //   if (dataTropas[i].peso_total !== undefined) {
    //     totKilosFix += parseInt(dataTropas[i].peso_total);
    //   }
    // }

    const TOTAL = dataTropas.reduce(
      (
        r,
        {
          peso_total,
          usuariodescver,
          cabezas,
          vivofr,
          Rinde,
          TotKilos,
          SELEC,
          CHOICE,
          STANDARD,
          PRIME,
          costo,
        },
      ) => {
        const key = 0;
        if (!r[0]) {
          r[0] = {
            usuariodescver: "Total ",
            peso_total: 0,
            tropa: "",
            Rinde: 0,
            vivofr: 0,
            cabezas: 0,
            SELEC: 0,
            CHOICE: 0,
            STANDARD: 0,
            PRIME: 0,
            TotKilos: 0,
            costo: 0,
            count: 0,
          };
        }

        r[0]["peso_total"] += parseInt(peso_total);

        if (costo != null) {
          r[0]["costo"] += parseInt(costo);
        }

        if (cabezas != null) {
          r[0]["cabezas"] += parseInt(cabezas);
        }
        if (TotKilos != null) {
          r[0]["TotKilos"] += parseInt(TotKilos);
        }
        if (Rinde != null) {
          r[0]["rinde_porct"] += parseInt(Rinde);
        }
        if (vivofr != null) {
          r[0]["vivofr"] += parseInt(vivofr);
        }
        r[0]["SELEC"] += parseInt(SELEC);
        r[0]["CHOICE"] += parseInt(CHOICE);
        r[0]["STANDARD"] += parseInt(STANDARD);
        r[0]["PRIME"] += parseInt(PRIME);
        r[0]["count"] += 1;
        return r;
      },
      {},
    );


    const usuarios = [
      "FR-Exportación",
      "FR-Otros Países",
      "FR-Consumo",
      "FR-Carne Cocida",
      'FR-41',
      "Inc",
    ];

    const ordenado = [
      ...dataTropas.filter((x) => x.usuariodescver == "FR-Exportación"),
      ...dataTropas.filter((x) => x.usuariodescver == "FR-Otros Países"),
      ...dataTropas.filter((x) => x.usuariodescver == "FR-Consumo"),
      ...dataTropas.filter((x) => x.usuariodescver == "FR-Carne Cocida"),
      ...dataTropas.filter((x) => x.usuariodescver == "FR-41"),
      ...dataTropas.filter((x) => x.usuariodescver == "Inc"),
      ...dataTropas.filter((x) => {
        if (!usuarios.includes(x.usuariodescver)) return x.usuariodescver;
      }),
    ];

    const usuariosTable = [];

    for (const [key, valor] of Object.entries(merged)) {

      // para tabla de usuarios
      usuariosTable.push(valor);

      const index = ordenado.findLastIndex(
        (v) => v.usuariodescver == key,
      );
      ordenado.splice(index + 1, 0, valor);
    }
    ordenado.join();

    for (const [key, valor] of Object.entries(TOTAL)) {
      ordenado.push(valor);
      usuariosTable.push(valor);
    }

    setdataTropa(ordenado);
  }, [])

  const getFaena = async () => {
    return UserService.getFaena(
      fechaStart,
      fechaEnd,
      check,
      filtroUsuarioFaena,
      filtroTipoDeCompra,
      filtroMarcaComercial,
      filtroEspecie,
      remitente,
      consignatario,
      flagMarcaAgrupada,
      tropaToSearch,
      selectedEstablecimientos
    )
  }

  const handleSuccess = useCallback(() => {
    setIsLoading2(false);
    setTitulo("Resultados");
    setMostrarTitulo(true);
  }, [])

  const handleError = useCallback(() => {
    setErrorServidor(true);
    setIsLoading2(false);
    setMostrarTitulo(false);
  }, [])

  // SACAR data servidor
  const cargarData = async () => {
    setFilterListTropa([]);
    setValor([]);
    setdataTropa([]);
    setDatosTablaAgrupadaUsuarios([]);
    setDatosTablaFaenaAgrupadaMarcas([]);
    setDatosTablaFaenaAgrupadaNomenclador([]);
    setMarcasAgrupadasExpo({});
    setMarcasAgrupadasConsumo({});
    setMostrarTablaUsuarios(false);
    setMostrarTablaResumen(false);
    setIsLoading2(true);
    localStorage.removeItem("Romaneo");

    clearLoadingAndErrorStates();

    setErrorServidor(false);
    if (check == "Marca") {
      setfiltro(false);
    } else {
      setfiltro(true);
    }

    if (isInternal) {


      if (tropaToSearch.length > 0) {
        await UserService.getTropaSearch(tropaToSearch)
          .then((response) => {
            if (response.status == 200) {
              setFechaStart(response.data.start_date);
              setFechaEnd(response.data.end_date);
            }
          })
          .catch((error) => {
            handleError();
          })
      }
      if (tropa == true) {
        try {
          const [faenaResult, tropaResult] = await Promise.all([getFaena(), getDataTropa()])
          if (faenaResult.status == 200 && tropaResult.status == 200) {
            handleFaenaResponse(faenaResult);
            handleTropaResponse(tropaResult);
            handleSuccess();
          } else {
            setMostrarTablaResumen(false);
            setMostrarTablaUsuarios(false);
            setValor("");
            handleError();
          }
        }
        catch (error) {
          handleError();
        }


      } else {
        try {
          const [faenaResult] = await Promise.all([getFaena()])
          if (faenaResult.status == 200) {
            handleFaenaResponse(faenaResult);
            handleSuccess();
          } else {
            handleError();
          }
        }
        catch (error) {
          handleError();
        }


      }
    } else {
      try {
        const [tropaResult] = await Promise.all([getDataTropa()])
        if (tropaResult.status == 200) {
          handleTropaResponse(tropaResult);
          setMostrarTablaResumen(false);
          setMostrarTablaUsuarios(false);
          setValor("");
          handleSuccess();
        } else {
          handleError();
        }
      }
      catch (error) {
        handleError();
      }

    }
  };

  const handleClickConsignatario = (valor) => {
    setConsignatario(valor.toString().toUpperCase()); // Aca se actualiza el valor del consognatario al hacer clic en el Link
    setProveedorIsBeingSearched(true);
  };

  const handleClickRemitente = (valor) => {
    setRemitente(valor.toString().toUpperCase()); // Aca se actualiza el valor del remitente al hacer clic en el Link
    setProveedorIsBeingSearched(true);
  };
  // cambio de valor de filtro
  // const display = () => {
  //   if (rol != 5 && mostrarCosto == true) return true;
  //   else return false;
  // };

  //funcion para imprimir informe general
  const imprimirInformeGeneral = () => {
    setIsLoadingInfo(true);
    UserService.ImprimirInformeGeneral(Tropas, fechaStart, fechaEnd, tropaToSearch).then(
      (response) => {
        setIsLoadingInfo(false);
      },
    );
  };

  // para descargar tropas en excel
  const descargarTropasExcel = () => {
    setIsLoadingDownloadExcelT3(true);
    setErrorDownloadT3(false);
    UserService.getTropaExcel(
      fechaStart,
      fechaEnd,
      filtroUsuarioFaena,
      filtroTipoDeCompra,
      filtroMarcaComercial,
      filtroEspecie,
      remitente,
      consignatario,
      mostrarMarbling,
      mostrarArea,
      mostrarColor,
      mostrarCosto,
      tropaToSearch,
      false,
      selectedEstablecimientos

    ).then((response) => {
      if (response.status == 200) {
        setIsLoadingDownloadExcelT3(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Resumen por Tropa.xlsx');
        document.body.appendChild(link);
        link.click();
      } else {
        setIsLoadingDownloadExcelT3(false);
        setErrorDownloadT3(true);
      }
    })
  }
  const descargarTropasPdf = () => {
    setIsLoadingDownloadPdfT3(true);
    setErrorDownloadT3(false);
    UserService.getTropaExcel(
      fechaStart,
      fechaEnd,
      filtroUsuarioFaena,
      filtroTipoDeCompra,
      filtroMarcaComercial,
      filtroEspecie,
      remitente,
      consignatario,
      mostrarMarbling,
      mostrarArea,
      mostrarColor,
      mostrarCosto,
      tropaToSearch,
      true,
      selectedEstablecimientos

    ).then((response) => {
      if (response.status == 200) {
        setIsLoadingDownloadPdfT3(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Resumen por Tropa.pdf');
        document.body.appendChild(link);
        link.click();
      } else {
        setIsLoadingDownloadPdfT3(false);
        setErrorDownloadT3(true);
      }
    })
  }

  const descargarFaena = (pdf, totales, show_especie) => {
    if (pdf) {
      setIsLoadingDownloadPdfT2(true);
    } else {
      setIsLoadingDownloadExcelT2(true);
    }
    setErrorDownloadT2(false);
    UserService.getFaenaDownloadReport(
      fechaStart,
      fechaEnd,
      check,
      filtroUsuarioFaena,
      filtroTipoDeCompra,
      filtroMarcaComercial,
      filtroEspecie,
      remitente,
      consignatario,
      flagMarcaAgrupada,
      tropaToSearch,
      pdf,
      totales,
      show_especie,
      selectedEstablecimientos
    ).then((response) => {
      if (response.status == 200) {
        if (pdf) {
          setIsLoadingDownloadPdfT2(false);
        } else {
          setIsLoadingDownloadExcelT2(false);
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pdf === true ? 'Resumen de Faena.pdf' : 'Resumen de Faena.xlsx');
        document.body.appendChild(link);
        link.click();
      }

    }).catch((error) => {
      if (pdf) {
        setIsLoadingDownloadPdfT2(false);
      } else {
        setIsLoadingDownloadExcelT2(false);
      }
      setErrorDownloadT2(true);
    })

  }

  const descargarFaenaT1 = (pdf, totales, show_especie) => {
    if (pdf) {
      setIsLoadingDownloadPdfT1(true);
    } else {
      setIsLoadingDownloadExcelT1(true);
    }
    setErrorDownloadT1(false);
    UserService.getFaenaDownloadReport(
      fechaStart,
      fechaEnd,
      check,
      filtroUsuarioFaena,
      filtroTipoDeCompra,
      filtroMarcaComercial,
      filtroEspecie,
      remitente,
      consignatario,
      flagMarcaAgrupada,
      tropaToSearch,
      pdf,
      totales,
      show_especie,
      selectedEstablecimientos
    ).then((response) => {
      if (response.status == 200) {
        if (pdf) {
          setIsLoadingDownloadPdfT1(false);
        } else {
          setIsLoadingDownloadExcelT1(false);
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pdf === true ? 'Resumen de Faena.pdf' : 'Resumen de Faena.xlsx');
        document.body.appendChild(link);
        link.click();
      }

    }).catch((error) => {
      if (pdf) {
        setIsLoadingDownloadPdfT1(false);
      } else {
        setIsLoadingDownloadExcelT1(false);
      }
      setErrorDownloadT1(true);
    })

  }


  const classes = useStyles();
  // opciones personalizadas
  const options = {
    responsive: "standard",
    fixedSelectColumn: true,
    filterType: "checkbox",
    fixedHeader: true,
    filter: true,
    pagination: false,
    sort: false,
    viewColumns: isInternal,
    print: false,
    download: isInternal,
    search: isInternal,

    onFilterChange: (columnChanged, filterList, type) => {
      localStorage.removeItem("filterListFaena", filterList);
      localStorage.setItem("filterListFaena", filterList);
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      const subtotales = ['Total Exportación', 'Total Otros Países', 'Total Consumo', 'Total Inc', 'Total Exportacion', "Total Carne Cocida", 'Total FR-41']
      return {
        className: clsx({
          [classes.totales]: row[0] !== undefined ? (row[0].trim() == "Total") : false,
          [classes.subtotales]: subtotales.includes(row[0]),
        }),
      };
    },

    textLabels: {
      body: {
        noMatch: "No hay datos",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar Por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros de tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) Elegidas",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
    selectableRowsHideCheckboxes: true,
  };

  const optionsTablaUsuario = {
    responsive: "standard",
    fixedSelectColumn: true,
    filterType: "checkbox",
    fixedHeader: true,
    filter: true,
    pagination: false,
    sort: false,
    viewColumns: user.type === "Rioplatense" ? true : false,
    print: false,
    download: false,//['1','2','5','6','10'].includes(rol) ? true : false,
    search: user.type === "Rioplatense" ? true : false,

    onFilterChange: (columnChanged, filterList, type) => {
      localStorage.removeItem("filterListFaena", filterList);
      localStorage.setItem("filterListFaena", filterList);
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      const subtotales = ['Total Exportación', 'Total Otros Países', 'Total Consumo', 'Total Inc', 'Total Exportacion', 'Total Carne Cocida', 'Total FR-41']
      return {
        className: clsx({
          [classes.totales]: row[0] !== undefined ? (row[0].trim() == "Total") : false,
          [classes.subtotales]: subtotales.includes(row[0]),
        }),
      };
    },
    customToolbar: (displayData) => {
      if (isInternal) {
        return (
          <>
            {(!isLoadingDownloadExcelT2) ? (
              <Tooltip title="Descargar a excel">
                <IconButton onClick={() => { descargarFaena(false, true, false) }}>
                  <CloudDownloadIcon></CloudDownloadIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <CircularProgress size={20} style={{ marginTop: '8px' }} />
            )}
            {(!isLoadingDownloadPdfT2) ? (
              <Tooltip title="Descargar a pdf">
                <IconButton onClick={() => { descargarFaena(true, true, false) }}>
                  <PrintIcon></PrintIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <CircularProgress size={20} style={{ marginTop: '8px' }} />
            )}
            {(errorDownloadT2) && (
              <Fade in={errorDownloadT2}>
                <Typography
                  color="secondary"
                  variant="p"
                  style={{ marginTop: '8px' }}
                  className={`${classes.errorMessage}`}
                >
                  Ocurrió un error
                </Typography>
              </Fade>
            )}
          </>

        );
      }
    },

    textLabels: {
      body: {
        noMatch: "No hay datos",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar Por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros de tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) Elegidas",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
    selectableRowsHideCheckboxes: true,
  };
  const optionsTablaFaenaAgrupada = {
    responsive: "standard",
    fixedSelectColumn: true,
    filterType: "checkbox",
    fixedHeader: true,
    filter: true,
    pagination: false,
    sort: false,
    viewColumns: isInternal,
    print: false,
    download: false,//['1','2','5','6','10'].includes(rol) ? true : false,
    search: isInternal,

    onFilterChange: (columnChanged, filterList, type) => {
      localStorage.removeItem("filterListFaena", filterList);
      localStorage.setItem("filterListFaena", filterList);
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      const subtotales = ['Total Exportación', 'Total Otros Países', 'Total Consumo', 'Total Inc', 'Total Exportacion', 'Total Carne Cocida', 'Total FR-41']
      return {
        className: clsx({
          [classes.totales]: row[0] !== undefined ? (row[0].trim() == "Total") : false,
          [classes.subtotales]: subtotales.includes(row[0]),
        }),
      };
    },
    customToolbar: (displayData) => {
      if (isInternal && check !== "Marca") {
        return (
          <>
            {(!isLoadingDownloadExcelT1) ? (
              <Tooltip title="Descargar a excel">
                <IconButton onClick={() => { descargarFaenaT1(false, false, true) }}>
                  <CloudDownloadIcon></CloudDownloadIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <CircularProgress size={20} style={{ marginTop: '8px' }} />
            )}
            {(!isLoadingDownloadPdfT1) ? (
              <Tooltip title="Descargar a pdf">
                <IconButton onClick={() => { descargarFaenaT1(true, false, true) }}>
                  <PrintIcon></PrintIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <CircularProgress size={20} style={{ marginTop: '8px' }} />
            )}
            {(errorDownloadT1) && (
              <Fade in={errorDownloadT1}>
                <Typography
                  color="secondary"
                  variant="p"
                  style={{ marginTop: '8px' }}
                  className={`${classes.errorMessage}`}
                >
                  Ocurrió un error
                </Typography>
              </Fade>
            )}
          </>

        );
      }
    },
    textLabels: {
      body: {
        noMatch: "No hay datos",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar Por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros de tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) Elegidas",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
    selectableRowsHideCheckboxes: true,
  };

  const handleOpenConfirmMail = (selectedRows, displayData) => {
    setMensaje("¿Confirma enviar enviar reporte de Tropa al remitente?");
    setIndexRows(selectedRows);
    setDataEmails(displayData);
    setopenConfirmMail(true);
  };
  const sendEmail = (dataIndex, dataGeneral) => {
    // saco todos los index de la informacion
    let dataIndices = dataIndex.data;
    let dataMails = [];

    for (let i = 0; i < dataIndices.length; i++) {
      const index = dataIndices[i].index;
      let remitente = dataGeneral[index].data[7];
      let tropa = dataGeneral[index].data[0].props.children.props.value;
      let ebeln = dataGeneral[index].data[26];
      dataMails.push([remitente, tropa, ebeln]);
    }
    UserService.emailSendTropa(dataMails);
    setopenConfirmMail(false);
    setMensaje("");
  };
  const handleCloseopenConfirmMail = () => setopenConfirmMail(false);



  // funcion para descargar archivos
  const onDownload = async (val, tropa, ejercicio) => {
    setLoadingReporte(val);
    const nombre = val.split("/");
    const largo = nombre.length;
    const API_URL = window.config.API_URL;
    let blob = await fetch(API_URL + val).then((r) => r.blob());
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${nombre[largo - 1]}`;
    link.click();
    setLoadingReporte('');
  };

  // filtros personalizados
  function valueLabelFormat(value) {
    return value;
  }

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleCheck = (event) => {
    setCheck(event.target.value);
  };

  const handlefiltroUsuarioFaena = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroUsuarioFaena(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handlefiltroEspecie = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroEspecie(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleTropaToSearch = (event) => {
    const pattern = /^\d+\/\d{2}$/;
    const newTropa = event.target.value;
    setTempTropaToSearch(newTropa);
    if (pattern.test(newTropa)) {
      setTropaToSearch(newTropa);
      setTropaToSearchError(false)
    }
    if (newTropa != "" && !pattern.test(newTropa)) {
      setTropaToSearchError(true);
    }
    if (newTropa == "") {
      setTropaToSearch("")
      setTropaToSearchError(false);
    }
  };
  const handleFiltroDeCompra = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroTipoDeCompra(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handlefiltro = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroMarcaComercial(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  let dataTableTropa = [];
  let dataTableRomaneo = [];
  let dataTableUsuarios = [];
  let dataTableFaenaAgrupada = [];

  let Tropas = [];

  const usuariosList = [
    "FR-Exportación",
    "FR-Otros Países",
    "FR-Consumo",
    'FR-41',
    'FR-Carne Cocida'
  ]

  const SubUsuariosList = {
    "Total FR-Exportación": 'Total Exportación',
    "Total FR-Otros Países": 'Total Otros Países',
    "Total FR-Consumo": 'Total Consumo',
    "Total FR-Carne Cocida": "Total Carne Cocida",
    "Total FR-41": "Total FR-41"
  }

  const SubUsuariosAgrup = {
    "Total FR-Exportación": 'Exportación',
    "Total FR-Otros Países": 'Otros Países',
    "Total FR-Consumo": 'Consumo',
    'Total FR-Carne Cocida': "Carne Cocida",
    "Total FR-41": "FR-41",
    "Total Inc": 'Inc',
    "Total": "Total"
  }

  // para tabla faena agrupada por nomenclador o marca
  if (check !== "Marca") {
    for (const [key, value] of Object.entries(datosTablaFaenaAgrupadaNomenclador)) {

      dataTableFaenaAgrupada.push([
        usuariosList.includes(value['usuario']) ? value['usuario'].split('-')[1] : Object.keys(SubUsuariosList).includes(value["usuario"]) ? SubUsuariosList[value["usuario"]] : value["usuario"],
        value['especie'],
        value['cabezas'] !== undefined && value['cabezas'] !== null ? value['cabezas'].toLocaleString("es") : null,
        value['kg_vivo'] !== undefined && value['kg_vivo'] !== null ? Math.round(value['kg_vivo']).toLocaleString("es") : null,
        value['kg_carne'] !== undefined && value['kg_carne'] !== null ? value['kg_carne'].toLocaleString("es") : null,
        value['rinde'] !== undefined && value['rinde'] !== null ? (value['rinde'] * 100).toFixed(2) + '%' : null,
        value['conformacion'] !== undefined && value['conformacion'] !== null ? value['conformacion'].toFixed(1) : null,
        value['gordura'] !== undefined && value['gordura'] !== null ? value['gordura'].toFixed(1) : null,
        //Math.round(value["costo_total"]) != 0 &&  value["costo_total"] !== undefined  && value["costo_total"] !== null ? "$" + Math.round(value["costo_total"]).toLocaleString("es") : "",
      ])
    }
  } else {
    for (const [key, value] of Object.entries(datosTablaFaenaAgrupadaMarcas)) {
      dataTableFaenaAgrupada.push([
        value['usuario'],
        value['area'],
        value['cabezas'] !== undefined && value['cabezas'] !== null ? value['cabezas'].toLocaleString("es") : null,
        value['kg_vivo'] !== undefined && value['kg_vivo'] !== null ? Math.round(value['kg_vivo']).toLocaleString("es") : null,
        value['kg_carne'] !== undefined && value['kg_carne'] !== null ? value['kg_carne'].toLocaleString("es") : null,
        value['rinde'] !== undefined && value['rinde'] !== null ? (value['rinde'] * 100).toFixed(2) + '%' : null,
        value['conformacion'] !== undefined && value['conformacion'] !== null ? value['conformacion'].toFixed(1) : null,
        value['gordura'] !== undefined && value['gordura'] !== null ? value['gordura'].toFixed(1) : null,
        //Math.round(value["costo_total"]) != 0 &&  value["costo_total"] !== undefined  && value["costo_total"] !== null ? "$" + Math.round(value["costo_total"]).toLocaleString("es") : "",
      ])
    }
  }

  // para tabla de usuarios a partir de subtotales y totales de usuario-especie
  if (check === 'Nomeclador') {
    for (const [key, value] of Object.entries(datosTablaFaenaAgrupadaNomenclador)) {
      if (value["usuario"].includes("Total") && Object.keys(SubUsuariosAgrup).includes(value["usuario"])) {
        dataTableUsuarios.push([
          SubUsuariosAgrup[value["usuario"]],
          value['cabezas'] !== undefined && value['cabezas'] !== null ? value['cabezas'].toLocaleString("es") : null,
          value['kg_vivo'] !== undefined && value['kg_vivo'] !== null ? Math.round(value['kg_vivo']).toLocaleString("es") : null,
          value['kg_carne'] !== undefined && value['kg_carne'] !== null ? value['kg_carne'].toLocaleString("es") : null,
          value['rinde'] !== undefined && value['rinde'] !== null ? (value['rinde'] * 100).toFixed(2) + '%' : null,
          value['conformacion'] !== undefined && value['conformacion'] !== null ? value['conformacion'].toFixed(1) : null,
          value['gordura'] !== undefined && value['gordura'] !== null ? value['gordura'].toFixed(1) : null,
        ])
      }
    }
  }


  //para tabla de usuarios
  // for (const [key, value] of Object.entries(datosTablaAgrupadaUsuarios)) {
  //   dataTableUsuarios.push([
  //     usuariosList.includes(value["usuariodescver"]) ? value["usuariodescver"].split('-')[1] : value["usuariodescver"],
  //     value["cabezas"].toLocaleString("es"),
  //     parseInt(value["peso_total"]).toLocaleString("es"),
  //     parseInt(value["TotKilos"]).toLocaleString("es"),
  //     (value["TotKilos"] / value["peso_total"] * 100).toFixed(2) + "%",
  //     Math.round(value["costo"]) != 0 ? "$" + Math.round(value["costo"]).toLocaleString("es") : "",
  //   ]);
  // }

  // poner data nomeclador
  for (const [key, value] of Object.entries(valor)) {
    if (value["count"] && value["nomeclador"] !== null) {
      // marca Nomeclador
      dataTableRomaneo.push([
        value["nomenclador"],
        value["numero"],
        value["espvacu"],
        value["cabezas"].toLocaleString("es"),
        parseInt(value["total_kilo"]).toLocaleString("es"),
        (
          Math.round((value["tipificPro"] / value["count"]) * 100) / 100
        ).toFixed(1),
        (Math.round((value["gordura"] / value["count"]) * 100) / 100).toFixed(
          1,
        ),
        Math.round(value["costo"]) != 0 ? "$" + Math.round(value["costo"]).toLocaleString("es") : "",
      ]);
    } else if (value["nomeclador"] !== null) {
      // marca comercial
      dataTableRomaneo.push([
        value["nomenclador"],
        value["numero"],
        value["espvacu"],
        value["cabezas"].toLocaleString("es"),
        parseInt(value["total_kilo"]).toLocaleString("es"),
        (Math.round(parseFloat(value["tipificPro"]) * 100) / 100).toFixed(1),
        (Math.round(value["gordura"] * 100) / 100).toFixed(1),
        Math.round(value["costo"]) != 0 ? "$" + Math.round(value["costo"]).toLocaleString("es") : "",
      ]);
    } else {
      dataTableRomaneo.push([
        "Totales",
        ,
        ,
        ,
        (
          Math.round(parseFloat(value["tipificPro"] / value["count"]) * 100) /
          100
        ).toFixed(1),
        (Math.round(value["gordura"] * 100) / 100).toFixed(1),
        Math.round(value["costo"]) != 0 ? "$" + Math.round(value["costo"]).toLocaleString("es") : "",
      ]);
    }
  }
  //console.log(dataTropa)
  // poner data TROPA

  for (const [key, value] of Object.entries(dataTropa)) {
    // en caso que sea proveedor chequeo
    if (isProveedor && user.user) {
      if (value["cconsig"] == user.cuit) {
        dataTableTropa.push([
          value["tropa"],
          value["tcompra"],
          value["establecimiento"],
          value["usuariodescver"],
          value["usuario_verd"],
          value["fecha_rec"],
          value["rs_consig"],
          value["rs_produc"],
          value["cabezas"].toLocaleString("es"),
          value["peso_total"].toLocaleString("es"),
          Math.round(value["vivofr"]).toLocaleString("es"),
          (value["rinde_porct"] * 100).toFixed(2) + "%",
          value["TotKilos"].toLocaleString("es"),
          Math.round((value["TotKilos"] / value["cantCabezas"]) * 2),
          (Math.round(value["tipificacion"] * 100) / 100).toFixed(1),
          (Math.round(value["totgordura"] * 100) / 100).toFixed(1),
          value["SELEC"],
          value["CHOICE"],
          value["STANDARD"],
          value["PRIME"],
          (Math.round(value["AOB"] * 100) / 100).toFixed(2),
          (Math.round(value["totColor"] * 100) / 100).toFixed(2),
          value["dias_plazo"],
          value["vencimiento"],
          value["notificado"],
          value["ebeln"],
          value["ebeln"],
          null,
          "$" + Math.round(value["costo"]).toLocaleString("es"),
          value["ejercicio"],
          value["status_faena"]
        ]);
      }
    } else if (value["cabezas"] != null && value["tropa"] != "") {
      Tropas.push({ tropa: value["tropa"], especie: filtroEspecie });
      dataTableTropa.push([
        value["tropa"],
        value["tcompra"],
        value["establecimiento"],
        usuariosList.includes(value["usuariodescver"]) ? value["usuariodescver"].split('-')[1] : value["usuariodescver"],
        value["usuario_verd"],
        value["tropa"] != ""
          ? moment(value["fecha_rec"]).format("DD/MM/YY")
          : "",
        value["rs_consig"],
        value["rs_produc"],
        value["esp_concat"],
        value["cabezas"].toLocaleString("es"),
        value["peso_total"].toLocaleString("es"),
        Math.round(value["vivofr"]).toLocaleString("es"),
        (value["rinde_porct"] * 100).toFixed(2) + "%",
        value["TotKilos"].toLocaleString("es"),
        Math.round((value["TotKilos"] / value["cantCabezas"]) * 2),
        value["tipificacion"] != 'None' ? (Math.round(value["tipificacion"] * 100) / 100).toFixed(1) : Math.round(0).toFixed(1),
        (Math.round(value["totgordura"] * 100) / 100).toFixed(1),
        value["SELEC"] != 0 ? value["SELEC"] : "",
        value["CHOICE"] != 0 ? value["CHOICE"] : "",
        value["STANDARD"] != 0 ? value["STANDARD"] : "",
        value["PRIME"] != 0 ? value["PRIME"] : "",
        value["AOB"] > 0 ? (Math.round(value["AOB"] * 100) / 100).toFixed(2) : "",
        value["totColor"] > 0 ? (Math.round(value["totColor"] * 100) / 100).toFixed(2) : "",
        value["dias_plazo"],
        value["vencimiento"] != 0 ? value["vencimiento"] : "",
        value["notificado"],
        value["ebeln"],
        value["doc_1"],
        value["doc_2"],
        Math.round(value["costo"]) != 0 ? "$" + Math.round(value["costo"]).toLocaleString("es") : "",
        value["ejercicio"],
        value["status_faena"]
      ]);
    } else if (value["tropa"] == "") {
      //totales
      dataTableTropa.push([
        ,
        ,
        ,
        usuariosList.includes(value["usuariodescver"]) ? value["usuariodescver"].split('-')[1] : value["usuariodescver"],
        value["usuario_verd"],
        value["tropa"] != ""
          ? moment(value["fecha_rec"]).format("DD/MM/YY")
          : "",
        value["rs_consig"],
        value["rs_produc"],
        ,
        value["cabezas"].toLocaleString("es"),
        // value["count"]
        //   ? Math.round(value["peso_total"] / value["count"]).toLocaleString(
        //       "es",
        //     )
        //   : "",
        Math.round(value["peso_total"]).toLocaleString("es"),
        Math.round(value["vivofr"] / value["count"]).toLocaleString("es"),
        ((value["TotKilos"] / value["peso_total"]) * 100).toFixed(2) + "%",
        value["TotKilos"].toLocaleString("es"),
        // Math.round((value["TotKilos"] / value["cabezas"]) * 2).toLocaleString("es",),
        ,
        ,
        ,
        value["SELEC"] != 0 ? value["SELEC"] : "",
        value["CHOICE"] != 0 ? value["CHOICE"] : "",
        value["STANDARD"] != 0 ? value["STANDARD"] : "",
        value["PRIME"] != 0 ? value["PRIME"] : "",
        ,
        value["dias_plazo"],
        value["vencimiento"],
        ,
        ,
        ,
        ,
        ,
        (value["costo"] != null && value["costo"] > 0) ? "$" + Math.round(value["costo"]).toLocaleString("es") : "",
        ,
        value["status_faena"]
      ]);
    } else {
      Tropas.push({ tropa: value["tropa"], especie: filtroEspecie });
      dataTableTropa.push([
        value["tropa"],
        value["tcompra"],
        value["establecimiento"],
        value["usuariodescver"],
        value["usuario_verd"],
        value["tropa"] != ""
          ? moment(value["fecha_rec"]).format("DD/MM/YY")
          : "",
        value["rs_consig"],
        value["rs_produc"],
        value["esp_concat"],
        value["cabezas"],
        value["peso_total"],
        Math.round(value["vivofr"]),
        (value["rinde_porct"] * 100).toFixed(2) + "%",
        value["TotKilos"],
        Math.round((value["TotKilos"] / value["cantCabezas"]) * 2),
        (Math.round(value["tipificacion"] * 100) / 100).toFixed(1),
        (Math.round(value["totgordura"] * 100) / 100).toFixed(1),
        value["SELEC"],
        value["CHOICE"],
        value["STANDARD"],
        value["PRIME"],
        (Math.round(value["AOB"] * 100) / 100).toFixed(2),
        (Math.round(value["totColor"] * 100) / 100).toFixed(2),
        value["dias_plazo"],
        value["vencimiento"],
        value["notificado"],
        value["ebeln"],
        value["doc_1"],
        value["doc_2"],
        "$" + Math.round(value["costo"]).toLocaleString("es"),
        ,
        value["status_faena"]
      ]);
    }
  }
  const optionsConSearch = {
    responsive: "standard",
    virtualized: true,
    fixedSelectColumn: false,
    caseSensitive: false,
    tableBodyHeight: dataTableTropa.length > 28 ? "540px" : (dataTableTropa.length * 22 + 132).toString() + "px", //rol != 4 ? "600px" : "",
    sort: false,
    toolbar: isInternal,
    filter: isInternal,
    viewColumns: isInternal,
    download: false,
    print: false,
    search: isInternal,
    selectableRows: (user.permissions.faena_emails_can_send) ? "" : "none",
    pagination: dataTableTropa.length > 70 ? true : false,//(rol != 4 && rol != 9) ? false : true,
    rowsPerPage: 15,//(rol != 4 && rol != 9) ? "" : 15,
    rowsPerPageOptions: [15, 50],
    fixedHeader: true,
    filterList: localStorage.getItem("filterList"),
    onFilterChange: (columnChanged, filterList, type) => {
      localStorage.removeItem("filterListTropa", filterList);
      localStorage.setItem("filterListTropa", filterList);
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <div>
        Enviar email con romaneo
        <IconButton>
          <EmailIcon
            onClick={() => handleOpenConfirmMail(selectedRows, displayData)}
            filled
            fontSize="small"
          />
        </IconButton>
      </div>
    ),
    customToolbar: (displayData) => {
      if (isInternal) {
        return (
          <>
            {(!isLoadingDownloadExcelT3) ? (
              <Tooltip title="Descargar a excel">
                <IconButton onClick={descargarTropasExcel}>
                  <CloudDownloadIcon></CloudDownloadIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <CircularProgress size={20} style={{ marginTop: '8px' }} />
            )}
            {(!isLoadingDownloadPdfT3) ? (
              <Tooltip title="Descargar a pdf">
                <IconButton onClick={descargarTropasPdf}>
                  <PrintIcon></PrintIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <CircularProgress size={20} style={{ marginTop: '8px' }} />
            )}
            {(errorDownloadT3) && (
              <Fade in={errorDownloadT3}>
                <Typography
                  color="secondary"
                  variant="p"
                  style={{ marginTop: '8px' }}
                  className={`${classes.errorMessage}`}
                >
                  Ocurrió un error
                </Typography>
              </Fade>
            )}
          </>

        );
      }
    },

    customFilterListRender: (v) => {
      return v !== undefined && v.length > 0 && v[0] !== "" ? "Filter" : false;
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: clsx({
          [classes.subtotales]:
            row[1] === undefined && !row[3].includes("Total"),
          [classes.totales]: row[3] && row[3].includes("Total"),
        }),
      };
    },
    isRowSelectable: (dataIndex, selectedRows) => {
      if (dataTableTropa[dataIndex][0]) {
        return true;
      }
    },
    searchText: searchValue,
    // customSearch: (searchQuery, currentRow, colums) => {
    //   let isFound = false;
    //   if (origen == 'consignatario' && currentRow[5] != undefined) {

    //     if (currentRow[5].toString().toLowerCase().indexOf(searchQuery) >= 0) {
    //       isFound = true;
    //     }
    //   } else if (origen == 'remitente' && currentRow[6] != undefined) {
    //     if (currentRow[6].toString().toLowerCase().indexOf(searchQuery) >= 0) {
    //       isFound = true;
    //     }

    //   }

    //   ;
    //   return isFound;
    // },

    textLabels: {
      body: {
        noMatch: "No hay datos",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar Por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar:
        isInternal
          ? {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver columnas",
            filterTable: "Filtros de tabla",
          }
          : {},
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) Elegidas",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
    selectableRowsHideCheckboxes: false,
  };

  const faenaTitle = () => {
    if (filtroEspecie.length > 0 && filtroMarcaComercial.length > 0) {
      return (
        <span>
          {"Resultados de Faena: "}{" "}
          <strong>{moment(fechaStart).format("DD/MM/YY")}</strong>
          {" "}{"-"}{" "}
          <strong>{moment(fechaEnd).format("DD/MM/YY")}</strong>
          {" "}{"-"}{" "}
          <strong style={{ color: '#a02725' }}>
            {filtroEspecie.length == 1 ? "Tropas que contienen la especie" : "Tropas que contienen las especies"}
            {" "}
            {filtroEspecie.join(',')}{" "}
            {filtroMarcaComercial.length == 1 ? 'y la marca' : 'y las marcas'}{" "}{filtroMarcaComercial.join(',')}</strong>
        </span>
      )
    } else if (filtroEspecie.length > 0 && filtroMarcaComercial.length == 0) {
      return (
        <span>
          {"Resultados de Faena: "}{" "}
          <strong>{moment(fechaStart).format("DD/MM/YY")}</strong>
          {" "}{"-"}{" "}
          <strong>{moment(fechaEnd).format("DD/MM/YY")}</strong>
          {" "}{"-"}{" "}
          <strong style={{ color: '#a02725' }}>{filtroEspecie.length == 1 ? "Tropas que contienen la especie" : "Tropas que contienen las especies"}{" "}{filtroEspecie.join(',')}</strong>
        </span>
      )
    } else if (filtroEspecie.length == 0 && filtroMarcaComercial.length > 0) {
      return (
        <span>
          {"Resultados de Faena: "}{" "}
          <strong>{moment(fechaStart).format("DD/MM/YY")}</strong>
          {" "}{"-"}{" "}
          <strong>{moment(fechaEnd).format("DD/MM/YY")}</strong>
          {" "}{"-"}{" "}
          <strong style={{ color: '#a02725' }}>{filtroEspecie.length == 1 ? "Tropas que contienen la marca" : "Tropas que contienen las marcas"}{" "}{filtroMarcaComercial.join(',')}</strong>
        </span>
      )
    } else {
      return (
        <span>
          {"Resultados de Faena: "}{" "}
          <strong>{moment(fechaStart).format("DD/MM/YY")}</strong>
          {" "}{"-"}{" "}
          <strong>{moment(fechaEnd).format("DD/MM/YY")}</strong>
        </span>
      )
    }
  }

  return (
    <>
      {/* <PageTitle title="Resultado de Faena" /> */}
      <Grid container spacing={4} lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* <Widget
            disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          > */}
          <Grid container alignItems="center" justifyContent="center">
            <Grid container item alignItems="center" spacing={2}>
              <Grid
                item
                lg={2}
                md={4}
                sm={12}
                xs={12}
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: "15px",
                  justifyContent: "center",
                }}
              >
                {/* <InputLabel id="type">Selecciona Rango de Fechas</InputLabel> */}

                <ResponsiveDatePickers
                  setValorFecha={setDate}
                  fechaInicial={fechaStart}
                  fechaFinal={fechaEnd}
                  onChange={handleFechaChange}
                ></ResponsiveDatePickers>
              </Grid>

              {isInternal && (
                <>

                  <Grid item lg={1} md={6} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <FormControl fullWidth>
                      <InputLabel id="year-input">Año</InputLabel>
                      <Select
                        labelId="year-input"
                        id="year-input-select"
                        value={selectedYears}
                        label="Año"
                        multiple
                        onChange={handleYearChange}
                      >
                        {yearsList.map((year) => (
                          <MenuItem 
                          key={year} 
                          value={year}
                          disabled={selectedYears.length > 1 && !selectedYearsSet.has(year)}
                          >
                            {year}
                            </MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={1} md={6} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <FormControl fullWidth>
                      <InputLabel id="month-input">Mes</InputLabel>
                      <Select
                        labelId="month-input"
                        id="month-input-select"
                        value={selectedMonths}
                        label="Mes"
                        multiple
                        onChange={handleMonthChange}
                      >
                        {monthList.map((index, month) => {
                          return (
                            <MenuItem 
                              key={index} 
                              value={month} 
                              disabled={selectedMonths.length > 1 && !selectedMonthsSet.has(month)}>
                                {index}
                            </MenuItem>)
                        })}

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={1} md={6} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <FormControl fullWidth>
                      <InputLabel id="week-input">Semana</InputLabel>
                      <Select
                        labelId="week-input"
                        id="week-input-select"
                        value={week}
                        disabled={mes === "" || selectedYears.length > 1}
                        label="Semana"
                        onChange={handleWeekChange}
                      >
                        {weekList.map((week) => {
                          return (
                            <MenuItem key={week} value={week}>{week}</MenuItem>)
                        })}

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={1} md={6} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <FormControl fullWidth>
                      <InputLabel id="year-input">Establecimiento</InputLabel>
                      <Select
                        labelId="year-input"
                        id="year-input-select"
                        value={selectedEstablecimientos}
                        label="Año"
                        multiple
                        onChange={handleEstablecimientoChange}
                      >
                        {establecimientos.map((est) => (
                          <MenuItem 
                          key={est.id} 
                          value={est.id}
                          >
                            {est.nombre}
                            </MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={6}
                    sm={12}
                    xs={12}
                    justify="center"
                    alignItems="center"
                  >
                    {/* <RadioGroup aria-label="tipo" row name="tipo" value={check} onChange={handleCheck}>
                    <FormControlLabel value='Nomeclador' control={<Radio icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>} />} label="Nomeclador" />
                    <FormControlLabel value="Marca" control={<Radio  icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}/>} label="Marca Comercial" />
                  </RadioGroup>   */}
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      fullWidth
                      onChange={handleAlignment}
                      className={classes.buttonSubmit}
                    >
                      <ToggleButton
                        value="left"
                        aria-label="left aligned"
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.primary.main}
                        selectedColor={theme.palette.background.custom}
                        defaultColor={theme.palette.text.primary}
                        classes={{ selected: classes.selected }}
                        onClick={() => {
                          setCheck("Nomeclador");
                        }}
                      >
                        Nomenclador
                      </ToggleButton>
                      <ToggleButton
                        value="right"
                        aria-label="left aligned"
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.primary.main}
                        selectedColor={theme.palette.background.custom}
                        defaultColor={theme.palette.text.primary}
                        classes={{ selected: classes.selected }}
                        onClick={() => {
                          setCheck("Marca");
                        }}
                      >
                        Marca{" "}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>

                  {!showFilters && (
                    <>
                      <Grid
                        container
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        justify={isXsOrSm && "center"}
                        alignItems={isXsOrSm && "center"}
                      >
                        <Tooltip title="Mostrar Filtros">
                          <IconButton onClick={handleFilters}>
                            <FilterListIcon
                              sx={{ color: theme.palette.primary.main }}
                              fontSize="large"
                            ></FilterListIcon>
                          </IconButton>
                        </Tooltip>
                        {(mes != "" || week != "") && (
                          <Tooltip title="Limpiar Filtros">
                            <IconButton onClick={handleCleanFilters}>
                              <FilterAltOffIcon
                                sx={{ color: theme.palette.primary.main }}
                                fontSize="large"
                              ></FilterAltOffIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                        {isLoading2 ? (
                          <CircularProgress
                            size={26}
                            style={{ marginTop: "20px" }}
                          />
                        ) : (
                          // <Button

                          //   className={classes.buttonSubmit}
                          //   onClick={() => cargarData()}
                          //   variant="contained"
                          //   color="primary"
                          //   fullWidth
                          // >
                          //   Buscar
                          // </Button>
                          <CustomSearchButton
                            onClick={cargarData}
                            color={theme.palette.primary.main}
                          />
                        )}
                        {(!showFilters && errorServidor) && (

                          <Fade in={errorServidor}>
                            <Typography
                              color="secondary"
                              className={`${classes.errorMessage} ${classes.buttonSubmit}`}
                            >
                              No hay informacion en el servidor
                            </Typography>
                          </Fade>

                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
              {(!isInternal) && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  justify="left"
                  alignItems="center"
                  spacing={2}
                  style={{ display: "flex" }}
                >
                  {isLoading2 ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    // <Button

                    //   className={classes.buttonSubmit}
                    //   onClick={() => cargarData()}
                    //   variant="contained"
                    //   color="primary"
                    //   fullWidth
                    // >
                    //   Buscar
                    // </Button>
                    <CustomSearchButton
                      onClick={cargarData}
                      color={theme.palette.primary.main}
                    />
                  )}
                  {!showFilters && (
                    <Fade in={errorServidor}>
                      <Typography
                        color="secondary"
                        className={`${classes.errorMessage} ${classes.buttonSubmit}`}
                      >
                        No hay informacion en el servidor
                      </Typography>
                    </Fade>
                  )}
                </Grid>
              )}
            </Grid>

            {showFilters && (
              <>
                <Grid container spacing={2}>
                  <Grid
                    item
                    alignItems="center"
                    container
                    spacing={2}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    {isInternal && (
                      // con estos cambio el valor de filtro (true false) y cambio el filtro segun valor

                      <>
                        <Grid item lg={2} md={6} sm={12} xs={12}>
                          <InputLabel id="type">Marca Comercial</InputLabel>
                          <Select
                            labelId="type"
                            variant="outlined"
                            multiple
                            id="Marcacomercial"
                            value={filtroMarcaComercial}
                            label="Marca Comercial"
                            onChange={handlefiltro}
                            renderValue={(selected) => selected.join(", ")}
                            fullWidth
                          >
                            {despMarcaComercial.map((val) => {
                              return (
                                <MenuItem
                                  key={val.marca_comercial}
                                  value={val.marca_comercial}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      filtroMarcaComercial.indexOf(
                                        val.marca_comercial,
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={val.marca_comercial} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item lg={2} md={6} sm={12} xs={12}>
                          <InputLabel id="type">Usuario de Faena</InputLabel>
                          <Select
                            labelId="type"
                            variant="outlined"
                            multiple
                            id="Marcacomercial"
                            value={filtroUsuarioFaena}
                            label="Marca Comercial"
                            onChange={handlefiltroUsuarioFaena}
                            renderValue={(selected) => selected.join(", ")}
                            fullWidth
                          >
                            {despUsuarioFaena.map((val) => {
                              return (
                                <MenuItem
                                  key={val.usuariodescver}
                                  value={val.usuariodescver}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      filtroUsuarioFaena.indexOf(
                                        val.usuariodescver,
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={val.usuariodescver} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item lg={2} md={6} sm={12} xs={12}>
                          <InputLabel id="type">Tipo de Compra</InputLabel>
                          <Select
                            labelId="type"
                            variant="outlined"
                            multiple
                            id="Marcacomercial"
                            value={filtroTipoDeCompra}
                            label="Marca Comercial"
                            onChange={handleFiltroDeCompra}
                            renderValue={(selected) => selected.join(", ")}
                            fullWidth
                          >
                            {despTipodeCompra.map((val) => {
                              return (
                                <MenuItem key={val.clave} value={val.clave}>
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      filtroTipoDeCompra.indexOf(val.clave) > -1
                                    }
                                  />
                                  <ListItemText primary={val.clave} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item lg={2} md={6} sm={12} xs={12}>
                          <InputLabel id="Especie">Especie </InputLabel>
                          <Select
                            labelId="Especie"
                            multiple
                            id="Especie"
                            input={<OutlinedInput label="Especie" />}
                            value={filtroEspecie}
                            onChange={handlefiltroEspecie}
                            renderValue={(selected) => selected.join(", ")}
                            fullWidth
                          >
                            {despEspecie.map((val) => {
                              return (
                                <MenuItem key={val.especie} value={val.especie}>
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      filtroEspecie.indexOf(val.especie) > -1
                                    }
                                  />
                                  <ListItemText primary={val.especie} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item lg={2} md={6} sm={12} xs={12}>
                          <InputLabel id="type">Consignatario</InputLabel>
                          {/* <TextField 
                    type="text"
                    variant="outlined"
                    onChange={(e, value) =>{ handleSearchChange(e, value);setOrigen('consignatario')}}
                    /> */}
                          <Autocomplete
                            disablePortal
                            noOptionsText={"Sin opciones"}
                            id="combo-box-demo"
                            options={autocompleteConsig}
                            value={consignatario}
                            onChange={(e, value) => {
                              value && value.label != undefined
                                ? setConsignatario(value.label.toString())
                                : setConsignatario("");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type="text"
                                variant="outlined"

                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={2} md={6} sm={12} xs={12}>
                          <InputLabel id="combo-box-demo">Remitente</InputLabel>
                          <Autocomplete
                            disablePortal
                            noOptionsText={"Sin opciones"}
                            id="combo-box-demo"
                            options={autocompleteRemitente}
                            value={remitente}
                            onChange={(e, value) => {
                              value && value.label != undefined
                                ? setRemitente(value.label.toString())
                                : setRemitente("");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type="text"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={2} md={6} sm={12} xs={12}>
                          <InputLabel id="combo-box-demo">
                            Comisionista
                          </InputLabel>
                          <Autocomplete
                            disablePortal
                            noOptionsText={"Sin opciones"}
                            id="combo-box-demo"
                            options={autocompleteComisionista}
                            onChange={(e, value) => {
                              value && value.label != undefined
                                ? setRemitente(value.label.toString())
                                : setRemitente("");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type="text"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={1} md={6} sm={12} xs={12}>

                          <InputLabel id="tropa-search">Tropa</InputLabel>


                          <TextField
                            disablePortal
                            variant="outlined"
                            type="search"
                            id="tropa-search"
                            value={tempTropaToSearch}
                            onChange={handleTropaToSearch}
                            error={tropaToSearchError}
                          />

                        </Grid>
                        {isInternal && (
                          <Grid
                            container
                            item
                            alignItems="center"
                            lg={5}
                            md={12}
                            sm={12}
                            xs={12}
                            justify="center"
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  style={{ color: theme.palette.primary.main }}
                                  onClick={() => {
                                    setTropa((value) => !value);
                                  }}
                                />
                              }
                              label="Tropa"
                              checked={tropa}
                            />
                            {flagMarcaAgrupada && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    style={{ color: theme.palette.primary.main }}
                                    onClick={() => {
                                      setMostrarMarcas((value) => !value);
                                    }}
                                  />
                                }
                                label="Marcas Agrupado"
                                checked={mostrarMarcas}
                              />
                            )}
                            {user.permissions.faena_marbling_can_view && (
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  style={{ color: theme.palette.primary.main }}
                                  onClick={() => {
                                    setMostrarMarbling((value) => !value);
                                  }}
                                />
                              }
                              label="Marbling"
                              checked={mostrarMarbling}
                            />
                            )}
                            {user.permissions.faena_marbling_can_view && (
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  style={{ color: theme.palette.primary.main }}
                                  onClick={() => {
                                    setMostrarArea((value) => !value);
                                  }}
                                />
                              }
                              label="Area"
                              checked={mostrarArea}
                            />
                            )}
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  style={{ color: theme.palette.primary.main }}
                                  onClick={() => {
                                    setMostrarColor((value) => !value);
                                  }}
                                />
                              }
                              label="Color"
                              checked={mostrarColor}
                            />
                            {user.permissions.faena_costos_can_view && (
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  style={{ color: theme.palette.primary.main }}
                                  onClick={() => {
                                    setMostrarCosto((value) => !value);
                                  }}
                                />
                              }
                              label="Costos"
                              checked={mostrarCosto}
                            />
                            )}
                          </Grid>
                        )}
                      </>
                    )}
                    {showFilters && (
                      <>
                        <Grid
                          container
                          item
                          lg={4}
                          md={12}
                          sm={12}
                          xs={12}
                          justify={isXsOrSm && "center"}
                          alignItems={isXsOrSm && "center"}
                          style={{ display: 'flex' }}
                        >
                          <Tooltip title="Ocultar Filtros">
                            <IconButton onClick={handleFilters}>
                              <CloseIcon
                                sx={{ color: theme.palette.primary.main }}
                                fontSize="large"
                              ></CloseIcon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Limpiar Filtros">
                            <IconButton onClick={handleCleanFilters}>
                              <FilterAltOffIcon
                                sx={{ color: theme.palette.primary.main }}
                                fontSize="large"
                              ></FilterAltOffIcon>
                            </IconButton>
                          </Tooltip>
                          {isLoading2 ? (
                            <CircularProgress
                              size={26}
                              className={classes.loginLoader}
                            />
                          ) : (
                            // <Button

                            //   className={classes.buttonSubmit}
                            //   onClick={() => cargarData()}
                            //   variant="contained"
                            //   color="primary"
                            //   fullWidth
                            // >
                            //   Buscar
                            // </Button>

                            <CustomSearchButton
                              onClick={cargarData}
                              color={theme.palette.primary.main}
                            />
                          )}
                          {(showFilters && errorServidor) && (

                            <Fade in={errorServidor}>
                              <Typography
                                color="secondary"
                                className={`${classes.errorMessage} ${classes.buttonSubmit}`}
                              >
                                No hay informacion en el servidor
                              </Typography>
                            </Fade>

                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {user.type === "Rioplatense" && (
          <Slide direction="up" in={mostrarTitulo} mountOnEnter unmountOnExit>
            <Grid container style={{ marginTop: '20px' }}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginBottom: '5px' }}>
                  <Typography variant="h5">{getTitleBusqueda()}</Typography>
                </div>
                <Divider component="div" role="presentation" style={{ backgroundColor: '#272d33' }}></Divider>
              </Grid>
            </Grid>
          </Slide>
          )}
          {mostrarMarcas &&
            isInternal &&
            (marcasAgrupadasExpo != null || marcasAgrupadasConsumo != null) && (
              <Grid container spacing={3} style={{ justifyContent: "center" }}>
                <Grid
                  item
                  lg={12}
                  spacing={3}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setMostrarMarcasExpo(!mostrarMarcasExpo);
                      setMostrarMarcasConsumo(false);
                    }}
                    variant="contained"
                    color="primary"
                    disabled={marcasAgrupadasExpo == null}
                    style={{
                      backgroundColor: theme.palette.background.custom,
                      color: theme.palette.primary.main,
                      border: mostrarMarcasExpo
                        ? "2px solid" + theme.palette.primary.main
                        : "",
                      marginRight:
                        marcasAgrupadasConsumo != null ? "10px" : "0px",
                    }}
                  >
                    {" "}
                    Exportacion
                  </Button>
                  <Button
                    onClick={() => {
                      setMostrarMarcasConsumo(!mostrarMarcasConsumo);
                      setMostrarMarcasExpo(false);
                    }}
                    variant="contained"
                    color="primary"
                    disabled={marcasAgrupadasConsumo == null}
                    style={{
                      backgroundColor: theme.palette.background.custom,
                      color: theme.palette.primary.main,
                      border: mostrarMarcasConsumo
                        ? "2px solid" + theme.palette.primary.main
                        : "",
                    }}
                  >
                    {" "}
                    Consumo
                  </Button>
                  <Tooltip title="Cerrar">
                    <IconButton onClick={() => { setMostrarMarcas(false) }}>
                      <CloseIcon
                        sx={{ color: theme.palette.primary.main }}
                        fontSize="medium"
                      >
                      </CloseIcon>
                    </IconButton>
                  </Tooltip>

                  {/*   //<Button onClick={()=> ordenarPorPeso()} variant='contained' color='primary'> Ordenar Por Peso</Button> */}
                </Grid>
                {((mostrarMarcasExpo && "Apto" in marcasAgrupadasExpo) ||
                  (mostrarMarcasConsumo &&
                    "Apto" in marcasAgrupadasConsumo)) && (
                    <Grid
                      className={classes.container}
                      item
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                    >
                      <MarcaComercialAgrup
                        theme={theme}
                        headerName={mostrarMarcasExpo ? "Novillo Apto" : "Apto"}
                        data={
                          mostrarMarcasExpo
                            ? marcasAgrupadasExpo.Apto
                            : marcasAgrupadasConsumo.Apto
                        }
                      />
                    </Grid>
                  )}
                {((mostrarMarcasExpo && "Rechazo" in marcasAgrupadasExpo) ||
                  (mostrarMarcasConsumo &&
                    "Rechazo" in marcasAgrupadasConsumo)) && (
                    <Grid
                      className={classes.container}
                      item
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                    >
                      <MarcaComercialAgrup
                        theme={theme}
                        headerName={mostrarMarcasExpo ? "Novillo Rechazo" : 'Rechazo'}
                        data={
                          mostrarMarcasExpo
                            ? marcasAgrupadasExpo.Rechazo
                            : marcasAgrupadasConsumo.Rechazo
                        }
                      />
                    </Grid>
                  )}
                {((mostrarMarcasExpo && "Revisar" in marcasAgrupadasExpo) ||
                  (mostrarMarcasConsumo &&
                    "Revisar" in marcasAgrupadasConsumo)) && (
                    <Grid
                      className={classes.container}
                      item
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                    >
                      <MarcaComercialAgrup
                        theme={theme}
                        headerName="Revizar"
                        data={
                          mostrarMarcasExpo
                            ? marcasAgrupadasExpo.Revisar
                            : marcasAgrupadasConsumo.Revisar
                        }
                      />
                    </Grid>
                  )}
                {((mostrarMarcasExpo && "Vaca" in marcasAgrupadasExpo) ||
                  (mostrarMarcasConsumo &&
                    "Vaca" in marcasAgrupadasConsumo)) && (
                    <Grid
                      className={classes.container}
                      item
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                    >
                      <MarcaComercialAgrup
                        theme={theme}
                        headerName="Vaca"
                        data={
                          mostrarMarcasExpo
                            ? marcasAgrupadasExpo.Vaca
                            : marcasAgrupadasConsumo.Vaca
                        }
                      />
                    </Grid>
                  )}
              </Grid>
            )}

          <Grid container>
            {isInternal && mostrarTablaResumen && dataTableFaenaAgrupada.length > 0 && (
              <Grid
                className={classes.container}
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <MUIDataTable
                  title={
                    <span>
                      {check === "Marca"
                        ? "Resumen por Marca Comercial: "
                        : "Resumen por Usuario-Especie: "}
                      <strong>{moment(fechaStart).format("DD/MM/YY")}</strong>
                      {" - "}
                      <strong>{moment(fechaEnd).format("DD/MM/YY")}</strong>
                    </span>

                  }
                  // data={dataTableRomaneo}
                  data={dataTableFaenaAgrupada}
                  options={optionsTablaFaenaAgrupada}
                  columns={[
                    {
                      name: check === 'Marca' ? "Marca" : "Usuario",
                      options: {
                        setCellProps: () => ({
                          style: { textAlign: check === 'Marca' ? "center" : '' },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: check === 'Marca' ? "Apto" : 'Especie',
                      options: {
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Cabezas",
                      options: {
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Kilos Vivos",
                      options: {
                        filter: true,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Kilos Carne",
                      options: {
                        filter: true,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Rinde",
                      options: {
                        filter: true,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Ratio Conformación",
                      options: {
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Ratio Gordura",
                      options: {


                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),

                      },
                    },
                  ]}
                ></MUIDataTable>
              </Grid>
            )}

            {isInternal && mostrarTablaUsuarios && dataTableUsuarios.length > 0 && (
              <Grid className={classes.container} item lg={12} md={12} sm={12} xs={12}>
                <MUIDataTable
                  title={
                    <span>
                      {"Resumen por usuario: "}
                      <strong>{moment(fechaStart).format("DD/MM/YY")}</strong>
                      {" - "}
                      <strong>{moment(fechaEnd).format("DD/MM/YY")}</strong>
                    </span>
                  }
                  data={dataTableUsuarios}
                  options={optionsTablaUsuario}
                  columns={
                    [
                      {
                        name: "Usuario",
                      },
                      {
                        name: "Cabezas",
                        options: {
                          setCellProps: () => ({
                            style: { textAlign: "center" },
                          })
                        }
                      },
                      {
                        name: "Kilos Vivos",
                        options: {
                          setCellProps: () => ({
                            style: { textAlign: "center" },
                          })
                        }
                      },
                      {
                        name: "Kilos Carne",
                        options: {
                          setCellProps: () => ({
                            style: { textAlign: "center" },
                          })
                        }
                      },
                      {
                        name: "Rinde",
                        options: {
                          setCellProps: () => ({
                            style: { textAlign: "center" },
                          })
                        }
                      },
                      {
                        name: "Ratio Conformación",
                        options: {
                          setCellProps: () => ({
                            style: { textAlign: "center" },
                          })
                        }

                      },
                      {
                        name: "Ratio Gordura",
                        options: {
                          setCellProps: () => ({
                            style: { textAlign: "center" },
                          })
                        }

                      }
                    ]
                  }
                >
                </MUIDataTable>
              </Grid>
            )}

            {mostrarTropa && dataTableTropa.length > 0 && (
              <Grid
                className={classes.container}
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <MUIDataTable
                  title={
                    faenaTitle()
                  }
                  data={dataTableTropa}
                  options={optionsConSearch}

                  columns={[
                    {
                      name: "Tropa",
                      options: {
                        filter: true,
                        filterType: "multiselect",

                        filterList:
                          filterListTropa[0] && filterListTropa[0].length
                            ? filterListTropa[0]
                            : null,

                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        customBodyRender: (value, dataIndex, rowIndex) => {
                          if (value) {
                            return (
                              <div
                                key={value.split("/")[0] + value.split("/")[1]}
                              >
                                <ResponsiveDialog
                                  value={value}
                                  key={
                                    value.split("/")[0] + value.split("/")[1]
                                  }
                                  start_date={fechaStart}
                                  end_date={fechaEnd}
                                  especie={filtroEspecie}
                                  theme={theme}
                                  ebeln={dataIndex.rowData[26]}
                                  tropaToSearch={tropaToSearch}
                                //className={classes.buttonSubmit}
                                >
                                  {value.split("/")[0] + value.split("/")[1]}
                                </ResponsiveDialog>
                              </div>
                            );
                          }
                        },
                      },
                    },
                    {
                      name: "Tipo de Compra",
                      options: {
                        filter: true,
                        filterType: "multiselect",
                        display: false,

                        filterList:
                          filterListTropa[1] && filterListTropa[1].length
                            ? filterListTropa[1]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center", maxWidth: "60px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                          style: { maxWidth: "60px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" },
                        }),
                      },
                    },
                    {
                      name:'Est.',
                      options: {
                        setCellProps: () => ({ style: { textAlign: "left", maxWidth: "100px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      }
                    },
                    {
                      name: "Usuario",
                      options: {
                        filter: true,
                        filterType: "multiselect",

                        filterList:
                          filterListTropa[2] && filterListTropa[2].length
                            ? filterListTropa[2]
                            : null,

                        setCellProps: () => ({ style: { textAlign: "left", maxWidth: "100px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Usu. Verd.",
                      options: {
                        filter: true,
                        display: false,
                        filterList:
                          filterListTropa[3] && filterListTropa[3].length
                            ? filterListTropa[3]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center", maxWidth: "50px" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                          style: { maxWidth: "50px" },
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `N° Usuarios hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Usuario Verdadero</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Fecha Rec.",
                      options: {
                        filter: true,
                        filterType: "multiselect",

                        filterList:
                          filterListTropa[4] && filterListTropa[4].length
                            ? filterListTropa[4]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Consignatario",
                      options: {
                        filter: true,
                        filterType: "multiselect",

                        filterList:
                          filterListTropa[5] && filterListTropa[5].length
                            ? filterListTropa[5]
                            : null,
                        // setCellProps: () => ({ style: { textAlign: 'left' } }),
                        // setCellHeaderProps: () => ({ className: classes.centeredHeader, })
                        setCellHeaderProps: () => {
                          if (isXsOrSm) {
                            return {
                              style: {
                                maxWidth: "170px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            };
                          }
                        },
                        setCellProps: () => {
                          if (isXsOrSm) {
                            return {
                              style: {
                                maxWidth: "170px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            };
                          }
                        },
                        customBodyRender: (
                          valor,
                          dataIndex,
                          rowIndex,
                        ) => {
                            return (
                              <div className={classes.contained}>
                                {isInternal ? (
                                <Link
                                  className={classes.customLink}
                                  onClick={() => handleClickConsignatario(valor)}
                                >
                                  {valor}
                                </Link>) : (
                                  <>{valor}</>
                                )}
                              </div>
                            );
                          },
                        },
                      },
                    {
                      name: "Remitente",
                      options: {
                        filter: true,
                        filterType: "multiselect",

                        filterList:
                          filterListTropa[6] && filterListTropa[6].length
                            ? filterListTropa[6]
                            : null,
                        setCellHeaderProps: () => {
                          if (isXsOrSm) {
                            return {
                              style: {
                                maxWidth: "170px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            };
                          }
                        },
                        setCellProps: () => {
                          if (isXsOrSm) {
                            return {
                              style: {
                                maxWidth: "170px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            };
                          }
                        },
                        customBodyRender: (
                          valor,
                          dataIndex,
                          rowIndex,
                        ) => {
                            return (
                              <div className={classes.contained}>
                                {isInternal ? (
                                <Link
                                  className={classes.customLink}
                                  onClick={() => handleClickRemitente(valor)}
                                >
                                  {valor}
                                </Link>) : (
                                  <>{valor}</>
                                )}
                              </div>
                            );
                          },
                      },
                    },
                    {
                      name: "Especie",
                      options: {


                        setCellProps: () => ({ style: { textAlign: "center", maxWidth: "140px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Cabezas",
                      options: {
                        filter: true,
                        filterList:
                          filterListTropa[7] && filterListTropa[7].length
                            ? filterListTropa[7]
                            : null,

                        customBodyRender: (value, dataIndex, rowIndex) => {
                          //console.log(dataIndex)
                          if (dataIndex.rowData[31] === 'P') {
                            return (
                              <div style={{ color: '#a02725' }}>{value}</div>
                            )
                          } else {
                            return (
                              <div>{value}</div>
                            )
                          }
                        },
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `Cabezas hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Cabezas</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Kilos pie",
                      options: {
                        filter: true,
                        filterList:
                          filterListTropa[8] && filterListTropa[8].length
                            ? filterListTropa[8]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `"Kilos pie hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Kilos pie</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Kg. Pie Prom.",
                      options: {
                        filter: true,
                        filterList:
                          filterListTropa[9] && filterListTropa[9].length
                            ? filterListTropa[9]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `"Kilos pie promedio hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Kilos pie promedio</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Rinde %",
                      options: {
                        filter: true,
                        filterList:
                          filterListTropa[10] && filterListTropa[10].length
                            ? filterListTropa[10]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `"Rinde % hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Rinde %</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Kilos carne",
                      options: {
                        filter: true,
                        filterList:
                          filterListTropa[11] && filterListTropa[11].length
                            ? filterListTropa[11]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `"Kilos carne hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Kilos carne</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Kg. Carne Prom.",
                      options: {
                        filter: true,
                        filterList:
                          filterListTropa[12] && filterListTropa[12].length
                            ? filterListTropa[12]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `Kilos carne promedio hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Kilos carne promedio</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Rat. Conf",
                      options: {
                        filter: true,
                        filterList:
                          filterListTropa[13] && filterListTropa[13].length
                            ? filterListTropa[13]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `Ratio Conformación hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Ratio Conformación</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Rat Gg",
                      options: {
                        filter: true,
                        filterList:
                          filterListTropa[14] && filterListTropa[14].length
                            ? filterListTropa[14]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `Ratio Gordura hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Ratio Gordura</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "SELECT",
                      options: {
                        filter: true,
                        display: mostrarMarbling,
                        viewColumns: false,
                        filterList:
                          filterListTropa[15] && filterListTropa[15].length
                            ? filterListTropa[15]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `SELECT:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>SELECT</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "CHOICE",
                      options: {
                        filter: true,
                        display: mostrarMarbling,
                        viewColumns: mostrarMarbling,
                        filterList:
                          filterListTropa[16] && filterListTropa[16].length
                            ? filterListTropa[16]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `CHOICE:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>CHOICE</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "STANDARD",
                      options: {
                        filter: true,
                        display: mostrarMarbling,
                        viewColumns: mostrarMarbling,
                        filterList:
                          filterListTropa[17] && filterListTropa[17].length
                            ? filterListTropa[17]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `Marbling:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>STANDARD</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "PRIME",
                      options: {
                        filter: true,
                        display: mostrarMarbling,
                        viewColumns: mostrarMarbling,
                        filterList:
                          filterListTropa[18] && filterListTropa[18].length
                            ? filterListTropa[18]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `PRIME:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>PRIME</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },

                    {
                      name: "Area Ojo de Bife",
                      options: {
                        display: mostrarArea,
                        filter: true,
                        viewColumns: mostrarArea,
                        filterList:
                          filterListTropa[19] && filterListTropa[19].length
                            ? filterListTropa[19]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `Area Ojo de Bife hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Area Ojo de Bife</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "Color",
                      options: {
                        filter: true,
                        display: mostrarColor,
                        viewColumns: mostrarColor,
                        filterList:
                          filterListTropa[20] && filterListTropa[20].length
                            ? filterListTropa[20]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `Color hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Color</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "DiasPlazo",
                      options: {
                        filter: true,
                        display: mostrarCosto,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },

                    {
                      name: "Vencimiento",
                      options: {
                        filter: true,
                        display: mostrarCosto,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "Romaneo enviado",
                      options: {
                        filter: true,
                        display: user.permissions.faena_emails_can_send,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        customBodyRender: (valor, dataIndex, updateValue) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {valor == false ? (
                                <CircleRoundedIcon
                                  className={classes.rojoCircle}
                                />
                              ) : valor == true ? (
                                <CircleRoundedIcon className={classes.verde} />
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        },
                      },
                    },
                    {
                      name: "ebeln",
                      options: {
                        filter: true,
                        display: false,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },

                    {
                      name: "R. Faena",
                      options: {
                        display: canViewReporteFotosFaena,
                        filter: true,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        customBodyRender: (value, dataIndex, updateValue) => {
                          if (value != undefined) {
                            // compruebo que no sea el usuario logueado
                            return (
                              <>

                                <div className={classes.contained}>
                                  {loadingReporte == value ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <Button
                                      aria-label="edit"
                                      size="medium"
                                      color="primary"
                                      //component={Link}
                                      //to={API_URL.concat(value)}
                                      download="How-to-download-file.pdf"
                                      onClick={() => {
                                        onDownload(
                                          value,
                                          dataIndex.rowData[0],
                                          dataIndex.rowData[29],
                                        );
                                      }}
                                    >
                                      <DownloadIcon fontSize="small" />
                                    </Button>
                                  )}
                                </div>
                              </>
                            );
                          }

                          /* else{
                          return (
                <Button aria-label="edit" size="medium"
                  color='primary'
                  //component={Link} 
                  //to={API_URL.concat(value)}
                  download="How-to-download-file.pdf"
                  // abro el modal, guardo el ebeln para editar y selecciono que columna voy a cambiar para la base de datos
                  onClick={() => { setOpenSubirArchivo(true); setEbeln(dataIndex.rowData[23]); setColumna('doc_url') }}
                >
                  <UploadFileIcon fontSize="small" />
                </Button>)
                        } */
                        },
                      },
                    },
                    {
                      name: "R.Golpeados",
                      options: {
                        display: canViewReporteFotosGolpeados,
                        filter: true,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        customBodyRender: (value, dataIndex, updateValue) => {
                          if (value != undefined) {
                            // compruebo que no sea el usuario logueado
                            return (
                              <>
                                <div className={classes.contained}>
                                  {loadingReporte == value ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <Button
                                      aria-label="edit"
                                      size="medium"
                                      color="primary"
                                      //component={Link}
                                      //to={API_URL.concat(value)}
                                      download="How-to-download-file.pdf"
                                      onClick={() => {
                                        onDownload(
                                          value,
                                          dataIndex.rowData[0],
                                          dataIndex.rowData[29],
                                        );
                                      }}
                                    >
                                      <DownloadIcon fontSize="small" />
                                    </Button>
                                  )}
                                </div>
                              </>
                            );
                          }

                          /*      else{
                               return (
                <Button aria-label="edit" size="medium"
                  color='primary'
                  //component={Link} 
                  //to={API_URL.concat(value)}
                  download="How-to-download-file.pdf"
                  // abro el modal, guardo el ebeln para editar y selecciono que columna voy a cambiar para la base de datos

                  onClick={() => { setOpenSubirArchivo(true); setEbeln(dataIndex.rowData[23]); setColumna('doc_url2') }}
                >
                  <UploadFileIcon fontSize="small" />
                </Button>)
                             } */
                        },
                      },
                    },

                    {
                      name: "Costo",
                      options: {
                        filter: true,
                        display: mostrarCosto,
                        viewColumns: mostrarCosto,
                        filterList:
                          filterListTropa[20] && filterListTropa[20].length
                            ? filterListTropa[20]
                            : null,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                        filterType: "custom",
                        customFilterListOptions: {
                          render: (v) => {
                            if (v[0]) {
                              return `Costo hasta:${v[0]}`;
                            }
                            return [];
                          },
                          update: (filterList, filterPos, index) => {
                            filterList[index].splice(filterPos, 1);
                            return filterList;
                          },
                        },
                        filterOptions: {
                          logic(value, filters) {
                            if (filters.length) return filters < value;
                            return false;
                          },
                          display: (
                            filterList,
                            onChange,
                            index,
                            column,
                            filterData,
                          ) => (
                            <div>
                              <FormLabel>Costo</FormLabel>
                              <FormGroup row>
                                <Slider
                                  defaultValue={filterList[index][0]}
                                  onChange={(event, newValue) => {
                                    filterList[index][0] =
                                      filterData[index][newValue];
                                    onChange(filterList[index], index, column);
                                  }}
                                  valueLabelFormat={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  getAriaValueText={(value) =>
                                    valueLabelFormat(filterData[index][value])
                                  }
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={filterData[index].length - 1}
                                />
                              </FormGroup>
                            </div>
                          ),
                        },
                      },
                    },
                    {
                      name: "ejercicio",
                      options: {
                        filter: true,
                        display: false,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                    {
                      name: "status",
                      options: {
                        filter: false,
                        display: false,
                        setCellProps: () => ({
                          style: { textAlign: "center" },
                        }),
                        setCellHeaderProps: () => ({
                          className: classes.centeredHeader,
                        }),
                      },
                    },
                  ]}
                ></MUIDataTable>
              </Grid>
            )}
            {mostrarTropa && dataTableTropa.length > 0 && Tropas.length > 1 && (
              <Grid item lg={12} style={{ marginTop: "20px" }}>
                {/* <InformeTotal
                      value={Tropas}
                      start_date={fechaStart}
                      end_date={fechaEnd}
                      especie={filtroEspecie}
                      >
                    </InformeTotal> */}
                {isLoadingInfo ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    //href="#/app/infGeneral"
                    disabled={(Tropas.length < 2) ? true : false}
                    onClick={() => imprimirInformeGeneral()}
                  >
                    Imprimir Informe General
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
          {/* <Modal
            hideBackdrop
            open={openSubirArchivo}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className={classes.Modal}>
              <Dropzone
                classNames={classes.dropZone}
                styles={{
                  dropzone: { overflow: "hidden", margin: "10px 0px" },
                  submitButton: {
                    padding: "15px",
                    fontSize: "1.2em",
                  },
                }}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                onSubmit={handleSubmit}
                accept="image/*,.pdf"
                inputContent="Arrastra tus archivos o hace click aqui"
                submitButtonContent="Guardar Datos"
                inputWithFilesContent="Sumar otro archivo"
              />

              <Button
                variant="contained"
                color="primary"
                size="large"
                //onClick={()=>{deleteDOC()}}
                className={classes.buttonModal}
              >
                Guardar
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setOpenSubirArchivo(false);
                }}
                className={classes.buttonModal}
              >
                Cerrar
              </Button>
            </Box>
          </Modal> */}
          <Modal
            hideBackdrop
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className={classes.Modal}>
              ¿Esta seguro que quiere borrar este documento?
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  UserService.putCertificadoTropa([], ebeln, columna);
                  setOpen(false);
                  getDataTropa();
                }}
                className={classes.buttonModal}
              >
                Borrar
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setOpen(false);
                }}
                className={classes.buttonModal}
              >
                Cerrar
              </Button>
            </Box>
          </Modal>
          <Modal
            hideBackdrop
            open={openConfirmMail}
            onClose={handleCloseopenConfirmMail}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className={classes.Modal}>
              <h2 id="child-modal-description">{mensaje}</h2>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonModal}
                onClick={() => sendEmail(IndexRows, dataEmails)}
              >
                Confirmar
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCloseopenConfirmMail}
                className={classes.buttonModal}
              >
                Cancelar
              </Button>
            </Box>
          </Modal>
          {/* </Widget> */}
        </Grid>
      </Grid >
    </>
  );
}
