import { makeStyles, Theme } from "@material-ui/core/styles";import { darken, lighten } from '@mui/material/styles';

export default makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,

  },
/*   image:{
    height:'mi'
    width:
  } */


}))