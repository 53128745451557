import Skeleton from '@mui/material/Skeleton';
import { Grid } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import useStyles from "./styles";
import { useMediaQuery } from "@material-ui/core";

const SkeletonInforme = () => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const skeletonCabTablesHeight = 200;
    const skeletonRomTablesHeight = 300
    const skeletonMidTablesHeight = 120
    const skeletonButtonsWidth = isSmallScreen ? 80 : 150;
    const skeletonButtonsHeight = 50;
    const skeletonTextWidth = isSmallScreen ? 200 : 400;
    var classes = useStyles();

    return (
        <div id="reporte">
            <DialogTitle
                className={classes.overflow}
                id="responsive-dialog-title"
            >

                <Grid container lg={12} md={12} sm={12} xs={12} style={{ alignItems: 'center', justifyContent: 'flex-start', display: !isSmallScreen ? 'flex' : '' }}>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={skeletonTextWidth} />
                </Grid>

            </DialogTitle>
            <DialogContent
                className={classes.overflow}
            >
                <Grid container>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                            display: "flex",
                            flexDirection: isSmallScreen ? "column" : "row",
                        }}
                        spacing={2}
                    >
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box sx={{ width: "100%" }}>
                                <Skeleton variant="rounded" height={skeletonCabTablesHeight} />
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ width: "97%" }}>
                                <Skeleton variant="rounded" height={skeletonCabTablesHeight} />
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box sx={{ width: "100%" }}>
                                <Skeleton variant="rounded" height={skeletonCabTablesHeight} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '40px' }}>
                        <Skeleton variant="rounded" height={skeletonMidTablesHeight} />
                    </Grid>
                    {/* </MuiThemeProvider> */}

                    <Grid item lg={12} xs={12}>
                        <Grid container sx={{ marginTop: 3 }} spacing={1} >
                            <Grid item lg={12} style={{
                                display: "flex",
                                flexDirection: isSmallScreen ? "column" : "row",
                            }}
                                spacing={1}
                            >
                                <Skeleton variant="rounded" width={skeletonButtonsWidth} height={skeletonButtonsHeight} />
                                <Skeleton variant="rounded" width={skeletonButtonsWidth} height={skeletonButtonsHeight} style={{ marginLeft: '5px' }} />
                            </Grid>

                            <Grid item lg={4} xs={12}>
                                <Skeleton variant="rounded" height={skeletonRomTablesHeight} />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <Skeleton variant="rounded" height={skeletonRomTablesHeight} />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <Skeleton variant="rounded" height={skeletonRomTablesHeight} />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item lg={12} xs={12} style={{ marginTop: '40px' }}>
                        <Skeleton variant="rounded" height={skeletonMidTablesHeight} />
                    </Grid>
                </Grid>
            </DialogContent>
        </div>
    )
}

export default SkeletonInforme;