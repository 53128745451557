import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({
  a: {
    padding: '2px 3px',
    margin: '3px',
    fontSize: '0.7rem'
  },
  confirm: {
    margin: '15px',

  },
  title: {
    margin: '50px'
  },
  buttonModal: {
    margin: '10px'
  },
  contained: {
    maxWidth: '100%',

  },
  // back: {
  //   backgroundColor: 'rgb(35, 35, 45)'
  // },
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    boxShadow: 1,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center',
    border: '3px solid ' + theme.palette.borderColor,
  },
  Info: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    boxShadow: 1,
    pt: 2,
    px: 4,
    pb: 3,
    border: '3px solid ' + theme.palette.borderColor,
    fontSize: '1.2rem',
    padding: '10px',
  },
  chip: {
    color: theme.palette.text.primary,

    //backgroundColor: theme.palette.background.custom,
  },
  textField: {
    color: theme.palette.text.primary,
  },
  root: {
    border: '1px solid ' + theme.palette.borderColor,
    '&:hover': {
      border: '2px solid ' + theme.palette.primary.main,
    },
    '&$expanded': {
      border: '2px solid ' + theme.palette.primary.main,
    },
    '&$checked': {
      border: '2px solid ' + theme.palette.primary.main
    },
    '&$selected': {
      border: '2px solid ' + theme.palette.primary.main
    },
    '&$focused': {
      border: '2px solid ' + theme.palette.primary.main
    },
    '&$focusVisible' : {
      backgroundColor: theme.palette.primary.main,
    },
  },
  tag: {
    backgroundColor: theme.palette.primary.main + ' !important',
  },
  input: {
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  listbox: {
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  checked: {},
  selected: {},
  expanded: {},
  focused: {},
  focusVisible: {},
}));
