import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MUIDataTable from "mui-datatables";
import UserService from "../../context/UserService";
import { Grid } from "@mui/material";
import useStyles from "./styles";
import { DataGrid } from "@mui/x-data-grid";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import logoWhite from "../../images/logo_dark.png";
import logoBlack from "../../images/RP_Logotipo.svg";
import Box from "@mui/material/Box";
import { CircularProgress } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import tinycolor from "tinycolor2";
import UserStateContext from "../../context/UserContext";
import MarcaComercialAgrup from "../MarcaComercialAgrup/MarcaComercialAgrup";
import { useState, memo } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SkeletonInforme from "./SkeletonInforme";
import {
  createMuiTheme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";


const ResponsiveDialog = memo(function ResponsiveDialog(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  //var rol = localStorage.getItem("permissions");
  const { user } = React.useContext(UserStateContext);
  var classes = useStyles();
  const [FechaHoy, setFechaHoy] = React.useState(
    moment().format("YYYY-MM-DD HH:mm:ss"),
  );
  const [open, setOpen] = React.useState(false);
  const [valorTropa, setValorTropa] = React.useState();
  const [especie, setEspecie] = React.useState();
  const [start_date, setStart_date] = React.useState();
  const [end_date, setEnd_date] = React.useState();
  const [ebeln, setEbeln] = React.useState();
  const [faeStatus, setFaeStatus] = React.useState();

  const [establecimiento, setEstablecimiento] = React.useState();
  const [usuario, setUsuario] = React.useState();
  const [remitente, setRemitente] = React.useState();

  const theme = props.theme;

  const { darkMode } = React.useContext(UserStateContext);

  // data tables
  const [dataSuperior, setDataSuperior] = React.useState([]);
  const [dataGarron, setDataGarron] = React.useState([]);
  const [dataGarronOrdenado, setDataGarronOrdenado] = React.useState([]);
  // data
  const [dataGolpes, setDataGolpes] = React.useState([]);
  const [dataFinal, setFataFinal] = React.useState([]);
  const [especies, setEspecies] = React.useState([]);
  const [ocultarBottones, setOcultarBottones] = React.useState(true);
  var [isLoadingInfo, setIsLoadingInfo] = React.useState(false);

  // Marcas comerciales agrupadas
  const [marcasAgrupadasExpo, setMarcasAgrupadasExpo] = useState({});
  const [marcasAgrupadasConsumo, setMarcasAgrupadasConsumo] = useState({});
  const [flagMarcaAgrupada, setFlagMarcaAgrupada] = useState(
    user.permissions.faena_marcas_can_view,
  );
  const [mostrarMarcas, setMostrarMarcas] = useState(false);
  const [mostrarMarcasConsumo, setMostrarMarcasConsumo] = useState(false);
  const [mostrarMarcasExpo, setMostrarMarcasExpo] = useState(false);
  const [tropaToSearch, setTropaToSearch] = useState("");


  // definimos las tablas
  const [cabeceraFaenaR, setCabeceraFaenaR] = useState([]);
  const [cabeceraProcedenciaR, setCabeceraProcedenciaR] = useState([]);
  const [cabeceraDocumentacionR, setCabeceraDocumentacionR] = useState([]);
  const [cabeceraFaenaC, setCabeceraFaenaC] = useState([]);
  const [cabeceraProcedenciaC, setCabeceraProcedenciaC] = useState([]);
  const [cabeceraDocumentacionC, setCabeceraDocumentacionC] = useState([]);

  const [resumenTropa, setResumenTropa] = useState([]);
  const [resumenRomaneo1, setResumenRomaneo1] = useState([]);
  const [resumenRomaneo2, setResumenRomaneo2] = useState([]);
  const [resumenRomaneo3, setResumenRomaneo3] = useState([]);
  const [resumenGolpes, setResumenGolpes] = useState([]);
  const [resumenFinal, setResumenFinal] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [tropaOriginal, setTropaOriginal] = useState(null);



  const orderByTropaAsc = (a, b) => {
    let tropa_a = a.tropa.split('/')
    let tropa_b = b.tropa.split('/')
    let first = parseInt(tropa_a[0] + tropa_b[1])
    let second = parseInt(tropa_b[0] + tropa_b[1])
    if (first < second) {
      return -1;
    }
    if (first > second) {
      return 1;
    }
    return 0;
  }

  const [ebelnList, setEbelnList] = useState(() => {
    const el = JSON.parse(localStorage.getItem("ebelnList"));
    return el ? el.sort(orderByTropaAsc) : [];
  });

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const imprimirInformeGeneral = () => {
    setIsLoadingInfo(true);
    UserService.ImprimirInformeGeneral(
      valorTropa,
      start_date,
      end_date,
      ebeln,
    ).then((response) => {
      setIsLoadingInfo(false);
    });
  };

  const handleClickOpen = async (event) => {
    setOpen(true);
    setIsLoading(true);
    setMarcasAgrupadasConsumo({})
    setMarcasAgrupadasExpo({})
    await UserService.getInforme(
      valorTropa,
      start_date,
      end_date,
      especie,
      ebeln,
      flagMarcaAgrupada,
    ).then(
      (response) => {
        if (response.status == 200) {
          let dataServidor = response.data;
          const garronSeparado = sliceIntoChunks(
            dataServidor.dataFaena,
            dataServidor.dataFaena.length / 3,
          );
          //setDataGarronOrdenado(garronSeparado);
          llenar_romaneos(garronSeparado);
          llenar_tablas(dataServidor.dataCabecera, garronSeparado, dataServidor.dataGolpes, dataServidor.dataFinal);
          //setDataSuperior(dataServidor.dataCabecera);
          //setDataGolpes(dataServidor.dataGolpes);
          //setFataFinal(dataServidor.dataFinal);
          setDataGarron(dataServidor.dataFaena);
          if (flagMarcaAgrupada) {
            if (response.data.marcas != null) {
              if ("Exportacion" in response.data.marcas) {
                setMarcasAgrupadasExpo(response.data.marcas.Exportacion);
              }
              if ("Consumo" in response.data.marcas) {
                setMarcasAgrupadasConsumo(response.data.marcas.Consumo);
              }
            }
          }
          setIsLoading(false);
        }
      },
      (error) => { setIsLoading(false) },
    );
  };

  const cargar_data = (ebeln, tropa) => {
    setIsLoading(true);
    setMarcasAgrupadasConsumo({})
    setMarcasAgrupadasExpo({})
    UserService.getInforme(
      tropa,
      start_date,
      end_date,
      especie,
      ebeln,
      flagMarcaAgrupada,
    ).then(
      (response) => {
        if (response.status == 200) {
          let dataServidor = response.data;
          const garronSeparado = sliceIntoChunks(
            dataServidor.dataFaena,
            dataServidor.dataFaena.length / 3,
          );
          //setDataGarronOrdenado(garronSeparado);
          llenar_romaneos(garronSeparado);
          llenar_tablas(dataServidor.dataCabecera, garronSeparado, dataServidor.dataGolpes, dataServidor.dataFinal);
          //setDataSuperior(dataServidor.dataCabecera);
          //setDataGolpes(dataServidor.dataGolpes);
          //setFataFinal(dataServidor.dataFinal);
          setDataGarron(dataServidor.dataFaena);
          if (flagMarcaAgrupada) {
            if (response.data.marcas != null) {
              if ("Exportacion" in response.data.marcas) {
                setMarcasAgrupadasExpo(response.data.marcas.Exportacion);
              }
              if ("Consumo" in response.data.marcas) {
                setMarcasAgrupadasConsumo(response.data.marcas.Consumo);
              }
            }
          }
          setIsLoading(false);
        }
      },
      (error) => { setIsLoading(false); },
    );
  }

  const clearStates = () => {
    setCabeceraFaenaC([])
    setCabeceraFaenaR([])
    setCabeceraDocumentacionC([])
    setCabeceraDocumentacionR([])
    setCabeceraProcedenciaC([])
    setCabeceraProcedenciaR([])
    setResumenTropa([])
    setResumenGolpes([])
    setResumenFinal([])
    setEspecies([])
    setFaeStatus(null)
    setEstablecimiento(null)
    setUsuario(null)
    setRemitente(null)

  }

  const ordenarPorGarron = () => {
    setDataGarronOrdenado([]);
    const data = dataGarron.sort((a, b) =>
      parseInt(a.numgarr) > parseInt(b.numgarr)
        ? 1
        : parseInt(b.numgarr) > parseInt(a.numgarr)
          ? -1
          : 0,
    );
    //dataTropas.sort((a,b) => (parseInt(a.tropa) > parseInt(b.tropa)) ? 1 : ((parseInt(b.tropa) > parseInt(a.tropa)) ? -1 : 0))

    const garronSeparado = sliceIntoChunks(data, data.length / 3);
    llenar_romaneos(garronSeparado);
  };

  const ordenarPorPeso = () => {
    setDataGarronOrdenado([]);
    //const data = dataGarron.sort((a,b) => (parseInt(a.pesofae) > parseInt(b.pesofae)) ? 1 : ((parseInt(b.pesofae) > parseInt(a.pesofae)) ? -1 : 0))
    //dataTropas.sort((a,b) => (parseInt(a.tropa) > parseInt(b.tropa)) ? 1 : ((parseInt(b.tropa) > parseInt(a.tropa)) ? -1 : 0))
    const data = dataGarron.sort(
      (a, b) => a.espvacu.localeCompare(b.espvacu) || a.pesofae - b.pesofae,
    );
    const garronSeparado = sliceIntoChunks(data, data.length / 3);
    llenar_romaneos(garronSeparado);
  };

  const ordenarPorEspecie = () => {
    setDataGarronOrdenado([]);
    const data = dataGarron.sort((a, b) =>
      a.espvacu > b.espvacu ? 1 : b.espvacu > a.espvacu ? -1 : 0,
    );
    //dataTropas.sort((a,b) => (parseInt(a.tropa) > parseInt(b.tropa)) ? 1 : ((parseInt(b.tropa) > parseInt(a.tropa)) ? -1 : 0))

    const garronSeparado = sliceIntoChunks(data, data.length / 3);
    llenar_romaneos(garronSeparado);
  };


  const handleChangeTropa = (event) => {
    setEbeln(event.target.value)
    const tropa = ebelnList.filter((t) => t.ebeln == event.target.value);
    setTropaToSearch(tropa[0].tropa);
    setValorTropa(tropa[0].tropa);
    cargar_data(event.target.value, tropa[0].tropa);
  }

  const handleForwardTropa = () => {
    let index = ebelnList.findIndex((item) => (item.ebeln === ebeln));
    const nextIndex = index + 1;
    let ebelnSelected = ebelnList[nextIndex].ebeln
    let tropaSelected = ebelnList[nextIndex].tropa
    setEbeln(ebelnSelected);
    setTropaToSearch(tropaSelected);
    setValorTropa(tropaSelected);
    cargar_data(ebelnSelected, tropaSelected);
  }

  const handleBackwardTropa = () => {
    let index = ebelnList.findIndex((item) => (item.ebeln === ebeln));
    const nextIndex = index - 1;
    let ebelnSelected = ebelnList[nextIndex].ebeln
    let tropaSelected = ebelnList[nextIndex].tropa
    setEbeln(ebelnSelected);
    setTropaToSearch(tropaSelected);
    setValorTropa(tropaSelected);
    cargar_data(ebelnSelected, tropaSelected);
  }

  const disableForwardTropaButton = () => {
    let index = ebelnList.findIndex((item) => (item.ebeln === ebeln));
    if (ebelnList.length - 1 === index) {
      return true
    }
    return false
  }

  const disableBackwardTropaButton = () => {
    let index = ebelnList.findIndex((item) => (item.ebeln === ebeln));
    if (index === 0) {
      return true
    }
    return false
  }


  React.useEffect(() => {
    setValorTropa(props.value);
    setTropaOriginal(props.value);
    setStart_date(props.start_date);
    setEnd_date(props.end_date);
    setEspecie(props.especie);
    setEbeln(props.ebeln);
    setTropaToSearch(props.tropaToSearch);
    setOcultarBottones(true);
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const option = {
    filter: false,
    responsive: "verticalAlways",
    search: false,
    viewColumns: false,
    print: false,
    download: false,
    sort: false,
    pagination: false,
  };
  const optionSort = {
    filter: false,
    responsive: "verticalAlways",
    search: false,
    viewColumns: false,
    print: false,
    download: false,
    sort: false,
    pagination: false,
  };
  const optionsNormalRomaneo = {
    responsive: "standard",
    fixedSelectColumn: true,
    fixedHeader: true,
    filterType: "multiselect",
    pagination: false,
    sort: false,
    filter: false,
    hideFooter: true,

    textLabels: {
      body: {
        noMatch: "No hay datos",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtros de tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) Elegidas",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    },
    selectableRowsHideCheckboxes: true,
  };



  /*   columns={[ "Est. Faenador","Usu","Remit","Faena","Loc","Tropa"," Cab. Faena total","Playa emergencia","Muertas","Decomisos","Raz. Ppal."
    ,"Guia","DTA","Kg. vivos FR.","Kg. carne","Kg. decomiso","Rec.","Cuit Remitente","Renspa","Patente del chasis","Patente de acoplado"]}
    >
   */

  const rest = {
    calidad: {
      gen_clasif: {
        sub_clasif: "cabezas",
      },
    },
  };

  function DataGridTitle() {
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.tables.header,
          color: theme.palette.tables.headerText,
        }}
      >
        <Typography variant="h6">Procedencia</Typography>
      </Box>
    );
  }
  function DataGridTitle2() {
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.tables.header,
          color: theme.palette.tables.headerText,
        }}
      >
        <Typography variant="h6">Resumen Faena</Typography>
      </Box>
    );
  }
  function DataGridTitleDOCUMENTACION() {
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.tables.header,
          color: theme.palette.tables.headerText,
        }}
      >
        <Typography variant="h6">Documentación</Typography>
      </Box>
    );
  }

  const getCuota = (chilton, c481) => {
    if (chilton == "" && c481 == "") {
      return "N";
    }
    if (chilton == "H" && c481 == "N") {
      return "H";
    }
    if (chilton == "N" && c481 == "W") {
      return "W";
    }
    return "N";
  }

  const llenar_romaneos = (garronesOrdenado) => {
    setResumenRomaneo1([])
    setResumenRomaneo2([])
    setResumenRomaneo3([])

    let dataTableGarron0 = [];
    let dataTableGarron1 = [];
    let dataTableGarron2 = [];

    for (const [key, valor] of Object.entries(garronesOrdenado)) {
      if (key == 0) {
        for (const [key, value] of Object.entries(valor)) {
          dataTableGarron0.push([
            value["numgarr"],
            value["espvacu"],
            value["dientes"],
            value["tipific"] + "-" + Math.round(value["gordura"]),
            value["destcom"],
            // ((value["c481"] == null && value["chilton"] == null) || (value["c481"] == "" && value["chilton"] == ""))
            //   ? "N"
            //   : value["c481"] == "N" && value["chilton"] == "N"
            //     ? "N"
            //     : value["chilton"] == "H" && (value["c481"] == "N" || value["c481"] == "")
            //       ? "H"
            //       : value["chilton"] == "N" && value["c481"] == "W"
            //         ? "W"
            //         : (value["chilton"] == null || value["chilton"] == "") && value["c481"] == "W"
            //           ? "W"
            //           : "N",
            getCuota(value["chilton"], value["c481"]),
            parseInt(value["pesofae"]),
            parseInt(
              parseInt(value["gradoas"]) + parseInt(value["gradobi"]) == 0
                ? "0"
                : "1",
            ) +
            "," +
            parseInt(value["gradomo"] == 0 ? "0" : "1") +
            "," +
            parseInt(value["gradope"] == 0 ? "0" : "1"),
          ]);
        }
      } else if (key == 1) {
        for (const [key, value] of Object.entries(valor)) {
          dataTableGarron1.push([
            value["numgarr"],
            value["espvacu"],
            value["dientes"],
            value["tipific"] + "-" + Math.round(value["gordura"]),
            value["destcom"],
            // value["c481"] == null && value["chilton"] == null
            //   ? "N"
            //   : value["c481"] == "N" && value["chilton"] == "N"
            //     ? "N"
            //     : value["chilton"] == "H" && value["c481"] == "N"
            //       ? "H"
            //       : value["chilton"] == "N" && value["c481"] == "W"
            //         ? "W"
            //         : value["chilton"] == null && value["c481"] == "W"
            //           ? "W"
            //           : "W",
            getCuota(value["chilton"], value["c481"]),
            parseInt(value["pesofae"]),
            parseInt(
              parseInt(value["gradoas"]) + parseInt(value["gradobi"]) == 0
                ? "0"
                : "1",
            ) +
            "," +
            parseInt(value["gradomo"] == 0 ? "0" : "1") +
            "," +
            parseInt(value["gradope"] == 0 ? "0" : "1"),
          ]);
        }
      } else if (key == 2) {
        for (const [key, value] of Object.entries(valor)) {
          dataTableGarron2.push([
            value["numgarr"],
            value["espvacu"],
            value["dientes"],
            value["tipific"] + "-" + Math.round(value["gordura"]),
            value["destcom"],
            // value["c481"] == null && value["chilton"] == null
            //   ? "N"
            //   : value["c481"] == "N" && value["chilton"] == "N"
            //     ? "N"
            //     : value["chilton"] == "H" && value["c481"] == "N"
            //       ? "H"
            //       : value["chilton"] == "N" && value["c481"] == "W"
            //         ? "W"
            //         : value["chilton"] == null && value["c481"] == "W"
            //           ? "W"
            //           : "W",
            getCuota(value["chilton"], value["c481"]),
            parseInt(value["pesofae"]),
            parseInt(
              parseInt(value["gradoas"]) + parseInt(value["gradobi"]) == 0
                ? "0"
                : "1",
            ) +
            "," +
            parseInt(value["gradomo"] == 0 ? "0" : "1") +
            "," +
            parseInt(value["gradope"] == 0 ? "0" : "1"),
          ]);
        }
      }
    }
    setResumenRomaneo1(dataTableGarron0)
    setResumenRomaneo2(dataTableGarron1)
    setResumenRomaneo3(dataTableGarron2)
  }

  const llenar_tablas = (dataSuperior, dataGarronOrdenado, dataGolpes, dataFinal) => {
    clearStates();

    let establecimiento = (dataSuperior[0]["es_origen"] == null) ? "Frigorifico Rioplatense" : dataSuperior[0]["es_origen"]
    let usuario = (dataSuperior[0]["usuariodescver"] != null && dataSuperior[0]["usuariodescver"] != "") ? dataSuperior[0]["usuariodescver"] : null
    let remitente = (dataSuperior[0]["remitente"] != null && dataSuperior[0]["remitente"] != "") ? dataSuperior[0]["remitente"] : null

    // setear datos generales
    setEspecies(dataSuperior[0]["especies"]);
    setFaeStatus(dataSuperior[0]["status_faena"]);
    setEstablecimiento(establecimiento);
    setUsuario(usuario);
    setRemitente(remitente)

    const dataTableInt = [];

    const dataTableGolpes = [];
    const dataTableFinal = [];

    const columns = [];
    const rows = [];

    const columnsR = [];
    const rowsR = [];
    const columnsD = [];
    const rowsD = [];

    if (dataSuperior[0] != undefined) {
      if (!isSmallScreen) {
        columns.push({
          field: 1,
          headerName: "Remitente :",
          flex: 2,

          headerClassName: "header",
        });
        columns.push({
          field: 2,
          headerName: dataSuperior[0]["remitente"],
          flex: 2,

          headerClassName: "header",
        });

        columnsR.push({
          field: 1,
          headerName: "",
          flex: 1,
          headerClassName: "header",
          headerAlign: "center",
        });
        columnsR.push({
          field: 2,
          headerName: "Cantidad",
          flex: 2,
          headerClassName: "header",
          headerAlign: "center",
          align: "center",
        });
        columnsR.push({
          field: 3,
          headerName: "Kg. Total",
          flex: 1,
          headerClassName: "header",
          headerAlign: "center",
          align: "center",
        });
        columnsR.push({
          field: 4,
          headerName: "Kg. Prom",
          flex: 1,
          headerClassName: "header",
          headerAlign: "center",
          align: "center",
        });
        columnsD.push({
          field: 1,
          headerName: "Guia",
          flex: 1,
          headerClassName: "header",
        });
        columnsD.push({
          field: 2,
          headerName: dataSuperior[0]["n_guia"],
          flex: 1,
          headerClassName: "header",
          display: false,
        });

        /*  columns.push( { field: 3, headerName: 'Tropa', flex:1,headerClassName: 'header' })
         columns.push( { field: 4, headerName: dataSuperior[0]['tropa'], flex:1 ,headerClassName: 'header'})
       
         columns.push(  { field: 5, headerName: 'Guia', flex:1,headerClassName: 'header' })
         columns.push(  { field: 6, headerName: dataSuperior[0]['n_guia'], flex:1.5 ,headerClassName: 'header' })
         columns.push(  { field: 7, headerName: "Cuit Remitente",flex:2,headerClassName: 'header'  })
         columns.push(  { field: 8, headerName: dataSuperior[0]['cprod'], flex:1 ,headerClassName: 'header'}) */
      } else {
        columns.push({
          field: 1,
          headerName: "Remit :",
          flex: 1,
          headerClassName: "header",
        });
        columns.push({
          field: 2,
          flex: 1,
          headerName: dataSuperior[0]["remitente"],
          headerClassName: "header",
        });
        columnsR.push({
          field: 1,
          headerName: "",
          flex: 1,
          headerClassName: "header",
          headerAlign: "center",
        });
        columnsR.push({
          field: 2,
          headerName: "Cantidad",
          flex: 2,
          headerClassName: "header",
          headerAlign: "center",
          align: "center",
        });
        columnsR.push({
          field: 3,
          headerName: "Kg. Total",
          flex: 1,
          headerClassName: "header",
          headerAlign: "center",
          align: "center",
        });
        columnsR.push({
          field: 4,
          headerName: "Kg. Prom",
          flex: 1,
          headerClassName: "header",
          headerAlign: "center",
          align: "center",
        });
        columnsD.push({
          field: 1,
          headerName: "Guia",
          flex: 1,
          headerClassName: "header",
        });
        columnsD.push({
          field: 2,
          headerName: dataSuperior[0]["n_guia"],
          flex: 1,
          headerClassName: "header",
          display: false,
        });

        /* columns.push( { field: 3, headerName: 'Tropa', width:120,headerClassName: 'header' })
        columns.push( { field: 4, headerName: dataSuperior[0]['tropa'], width:120 ,headerClassName: 'header'})
      
        columns.push(  { field: 5, headerName: 'Guia', width:80,headerClassName: 'header' })
        columns.push(  { field: 6, headerName: dataSuperior[0]['n_guia'], width:80 ,headerClassName: 'header' })
        columns.push(  { field: 7, headerName: "Cuit Remit",width:100,headerClassName: 'header'  })
        columns.push(  { field: 8, headerName: dataSuperior[0]['cprod'], width:150 ,headerClassName: 'header'}) */
      }

      /* columns={[ "Est. Faenador","Usu","Remit","Faena","Loc","Tropa","Cab. Faena total","Playa emergencia","Muertas","Decomisos","Raz. Ppal."
                    ,"Guia","DTA","Kg. vivos FR.","Kg. carne","Kg. decomiso","Rec.","Cuit Remitente","Renspa","Patente del chasis","Patente de acoplado"]}>
       */
      rows.push({
        id: 1,
        1: "Consignatario",
        2: dataSuperior[0]["consignatario"]
        // 3: "Cab. Faena total",
        // 4: dataSuperior[0]["total_rese"],
        // 5: "DTA",
        // 6: dataSuperior[0]["NRODTA1"],
        // 7: "Renspa",
        // 8: dataSuperior[0]["renspa"],
      });
      rows.push({
        id: 2,
        1: "Localidad.",
        2: dataSuperior[0]["localidad"]
        // 3: "Playa emergencia",
        // 4: dataSuperior[0]["playaFae"],
        // 5: "Kg. vivos FR.",
        // 6:
        //   dataSuperior[0]["tot_peso"] + " / " + dataSuperior[0]["cab_ingresadas"],
        // 7: "Patente del chasis",
        // 8: dataSuperior[0]["pat_chasis"],
      });
      rows.push({
        id: 3,
        1: "Raza Principal",
        2: dataSuperior[0]["raza"]
        // 3: "Muertas",
        // 4: dataSuperior[0]["cab_muertas"],
        // 5: "Kg. carne",
        // 6:
        //   dataSuperior[0]["total_Kilos"] +
        //   " / " +
        //   (
        //     (parseInt(dataSuperior[0]["total_Kilos"]) /
        //       parseInt(dataSuperior[0]["total_rese"])) *
        //     2
        //   ).toFixed(2),
        // 7: "Patente de acoplado",
        // 8: dataSuperior[0]["pat_acop"],
      });
      rows.push({
        id: 4,
        1: "Especie",
        2: dataSuperior[0]["especies"]
        // 3: "Decomisos",
        // 4: Math.round(dataSuperior[0]["destcom"] / 2),
        // 5: "Kg. decomiso",
        // 6: dataSuperior[0]["kgDescom"],
      });
      // Relleno
      rows.push(
        {
          id: 5,
          1: "Establecimiento",
          2: establecimiento
          // 3: "Cab. Faena total",
          // 4: dataSuperior[0]["total_rese"],
          // 5: "DTA",
          // 6: dataSuperior[0]["NRODTA1"],
          // 7: "Renspa",
          // 8: dataSuperior[0]["renspa"],
        }
      );
      rows.push(
        {
          id: 6,
          1: "Ingreso / Faena",
          2: moment(dataSuperior[0]["recepcion"]).format('DD/MM/YY') + ' / ' + moment(dataSuperior[0]["fecha_faena"]).format('DD/MM/YY')
          // 3: "",
          // 4: "",
          // 5: "",
          // 6: "",
        }
      )
      //rows.push( { id:5, 1: '' ,2:'',3:"Raz. Ppal.",4:dataSuperior[0]['raza'],5:"Rec.",6:dataSuperior[0]['recepcion']})

      rowsR.push({
        id: 1,
        1: "Recepción",
        2: dataSuperior[0]["total_rese_ing"],
        3: dataSuperior[0]["tot_peso"],
        4: (
          parseInt(dataSuperior[0]["tot_peso"]) /
          parseInt(dataSuperior[0]["total_rese"])
        ).toFixed(2),
      });
      rowsR.push({
        id: 2,
        1: "Muertos",
        2: dataSuperior[0]["cab_muertas"],
        3: "",
        4: "",
      });
      rowsR.push({
        id: 3,
        1: "Playa Emergencia",
        2: dataSuperior[0]["playaFaeE"],
        3: dataSuperior[0]["pesoE"] ? dataSuperior[0]["pesoE"] : "",
        4:
          dataSuperior[0]["playaFaeE"] != "0"
            ? Math.round(
              parseInt(dataSuperior[0]["pesoE"]) /
              parseInt(dataSuperior[0]["playaFaeE"]),
            )
            : 0,
      });
      rowsR.push({
        id: 4,
        1: "Playa Faena",
        2: dataSuperior[0]["playaFaeF"],
        3: dataSuperior[0]["pesoF"] ? parseInt(dataSuperior[0]["pesoF"]) : "",
        4:
          dataSuperior[0]["playaFaeF"] != "0"
            ? Math.round(
              parseInt(dataSuperior[0]["pesoF"]) /
              parseInt(dataSuperior[0]["playaFaeF"]),
            )
            : "",
      });
      rowsR.push({
        id: 5,
        1: "Decomisos",
        2: Math.round(dataSuperior[0]["dest"] / 2),
        3: "",
        4: "",
      });
      rowsR.push({
        id: 6,
        1: "Total",
        2:
          (dataSuperior[0]["playaFaeF"] != null
            ? parseInt(dataSuperior[0]["playaFaeF"])
            : 0) +
          (dataSuperior[0]["playaFaeE"] != null
            ? parseInt(dataSuperior[0]["playaFaeE"])
            : 0),
        3: "",
        4: "",
      });
      // rowsR.push({
      //   id: 7,
      //   1: "Rinde",
      //   2: Math.round(dataSuperior[0]["Rinde"]).toFixed(2) + "%",
      //   3: "",
      //   4: "",
      // });

      rowsD.push({ id: 1, 1: "Dte", 2: dataSuperior[0]["dte"] });
      rowsD.push({ id: 2, 1: "Cuit Remitente", 2: dataSuperior[0]["cprod"] });
      rowsD.push({
        id: 3,
        1: "Cuit Consignatario",
        2: dataSuperior[0]["consig"] != null ? dataSuperior[0]["consig"] : "",
      });
      rowsD.push({ id: 4, 1: "Renspa", 2: dataSuperior[0]["renspa"] });
      rowsD.push({
        id: 5,
        1: "Patente Chasis",
        2: dataSuperior[0]["pat_chasis"],
      });
      rowsD.push({
        id: 6,
        1: "Patente Acoplado",
        2: dataSuperior[0]["pat_acop"],
      });
    }

    // poner data nomeclador
    /* for (const [key, value] of Object.entries(dataSuperior)) {
        if ( value){
    
       
          // marca Nomeclador 
          dataTableSuperior.push([value['es_origen'],value['usuariodescver'],value['remitente'],value['status'],value['localidad'],value['tropa'],
          value['total_rese'],'FALTA INFO',value['cab_muertas'],value['destcom'],'raza',value['n_guia'],value['NRODTA1'],value['tot_peso']+' / '+parseInt(value['tot_peso'])/parseInt(value['cab_ingresadas']).toFixed(2)
          , value['total_Kilos']+' / '+(parseInt(value['total_Kilos'])/parseInt(value['total_rese'])*2).toFixed(2),'falta',value['recepcion'],value['cprod'],
          ,value['renspa'],value['pat_chasis'],value['pat_acop']])
        }
      
      }
     */

    for (const [key, value] of Object.entries(dataSuperior)) {
      if (value) {
        // marca Nomeclador
        dataTableInt.push([
          value["tot_Regi"],
          parseInt(value["KgRecepcionFR"]).toFixed(1),
          (
            parseInt(value["total_Kilos"]) / parseInt(value["total_rese"])
          ).toFixed(2),
          value["total_Kilos"],
          dataSuperior[0]["Rinde"].toFixed(2) + "%",
          value["ratio_tipificacion"].toFixed(1),
          value["ratio_gordura"].toFixed(2),
        ]);
      }
    }



    for (const [key, value] of Object.entries(dataGolpes)) {
      if (value) {
        // marca Nomeclador
        dataTableGolpes.push([
          value["numgarr"],
          value["espvacu"],
          value["tipific"] + "-" + Math.round(value["gordura"]),
          value["destcom"],
          value["pesofae"],
          value["gradobi"],
          value["gradoas"],
          value["gradomo"],
          value["gradope"],
        ]);
      }
    }
    //columns={[ "Esp.","Tipif.","Reses","P Tot.","P Pro"]}

    for (const [key, value] of Object.entries(dataFinal)) {
      if (value) {
        // marca Nomeclador
        dataTableFinal.push([
          value["espvacu"],
          value["tipific"],
          parseInt(value["total_garrones"]),
          parseInt(value["total_peso"]),
          value["peso_prom"],
        ]);
      }
    }

    setCabeceraFaenaC(columnsR)
    setCabeceraFaenaR(rowsR)
    setCabeceraDocumentacionC(columnsD)
    setCabeceraDocumentacionR(rowsD)
    setCabeceraProcedenciaC(columns)
    setCabeceraProcedenciaR(rows)
    setResumenTropa(dataTableInt)
    setResumenGolpes(dataTableGolpes)
    setResumenFinal(dataTableFinal)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          variant="text"
          onClick={handleClickOpen}
          size="small"
          style={{
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            fontSize: "14px",
          }}
          className={classes.button}
        >
          {tropaOriginal}
        </Button>

        <Dialog
          fullScreen
          maxWidth={true}
          open={open}
          scroll="body"
          onClose={handleClose}
          className={classes.overflow}
          aria-labelledby="responsive-dialog-title"
        >
          <Grid container lg={12} md={12} sm={12} xs={12} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Toolbar>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <Button autoFocus color="inherit" onClick={handleClose}>
                  Cerrar
                </Button>
              </Toolbar>
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <IconButton
                onClick={handleBackwardTropa}
                disabled={disableBackwardTropaButton()}
              >
                <ArrowBackIcon
                  sx={{ color: theme.palette.primary.main }}
                  fontSize="large"
                />
              </IconButton>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={ebeln}
                  variant="standard"
                  size="large"
                  onChange={handleChangeTropa}
                >

                  {ebelnList.map((item) => (
                    <MenuItem value={item.ebeln} name={item.tropa}>{item.tropa}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                onClick={handleForwardTropa}
                disabled={disableForwardTropaButton()}
              >
                <ArrowForwardIcon
                  sx={{ color: theme.palette.primary.main }}
                  fontSize="large"
                />
              </IconButton>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              {" "}
            </Grid>
          </Grid>
          {isLoading ? (
            <SkeletonInforme />
          ) : (
            <>
              <div id="reporte">
                <DialogTitle
                  className={classes.overflow}
                  id="responsive-dialog-title"
                >

                  <Grid container lg={12} md={12} sm={12} xs={12} style={{ alignItems: 'center', justifyContent: 'flex-start', display: !isSmallScreen ? 'flex' : '' }}>
                    <img
                      src={darkMode == true ? logoBlack : logoWhite}
                      alt="Logo"
                      className={classes.logo}
                    />
                    {usuario ? (
                      <Typography variant="h4">
                        Tropa {valorTropa} - Establecimiento: {establecimiento} -{" "}
                        {usuario}
                      </Typography>
                    ) : (
                      <Typography variant="h4">
                        Tropa {valorTropa} - Establecimiento: {establecimiento}
                      </Typography>
                    )}
                    {faeStatus === "P" && (
                      <Typography variant="h3" style={{ color: "#f44336" }}>
                        &nbsp;- Parcial
                      </Typography>
                    )}
                  </Grid>

                </DialogTitle>
                <DialogContent
                  //theme={theme}
                  className={classes.overflow}
                >
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: isSmallScreen ? "column" : "row",
                      }}
                    >
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ width: "100%" }}>
                          <DataGrid
                            rows={cabeceraFaenaR}
                            columns={cabeceraFaenaC}
                            classes={{ columnHeaderTitle: classes.headerCell }}
                            components={{ Toolbar: DataGridTitle2 }}
                            pañosSize={11}
                            autoHeight
                            hideFooter={true}
                            rowHeight={25}
                            headerHeight={25}
                            sx={{
                              color: "inherit",
                              "& .MuiDataGrid-cell": {
                                border: 1,
                                borderColor: theme.palette.tables.tableBorderColor,
                                borderRight: 0,
                                borderTop: 0,
                                // add more css for customization
                              },
                              "& .MuiDataGrid-columnHeaderTitleContainer": {
                                borderColor: theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-columnHeaders": {
                                borderBottom:
                                  "1px solid " +
                                  theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-iconSeparator": {
                                color: theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-footerContainer": {
                                borderTop:
                                  "1px solid " +
                                  theme.palette.tables.tableBorderColor,
                                maxHeight: "0px",
                                minHeight: "0px",
                              },
                              border:
                                "2px solid " +
                                theme.palette.tables.tableBorderColor,
                            }}
                            onEstadisticas
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            rowsPerPañosOptions={[11]}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ width: "100%" }}>
                          <DataGrid
                            rows={cabeceraProcedenciaR}
                            columns={cabeceraProcedenciaC}
                            classes={{ columnHeaderTitle: classes.headerCell }}
                            components={{ Toolbar: DataGridTitle }}
                            pañosSize={11}
                            autoHeight
                            hideFooter={true}
                            rowHeight={25}
                            headerHeight={25}
                            sx={{
                              color: "inherit",
                              "& .MuiDataGrid-cell": {
                                border: 1,
                                borderColor: theme.palette.tables.tableBorderColor,
                                borderRight: 0,
                                borderTop: 0,
                                // add more css for customization
                              },
                              "& .MuiDataGrid-columnHeaderTitleContainer": {
                                borderColor: theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-columnHeaders": {
                                borderBottom:
                                  "1px solid " +
                                  theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-iconSeparator": {
                                color: theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-footerContainer": {
                                borderTop:
                                  "1px solid " +
                                  theme.palette.tables.tableBorderColor,
                                maxHeight: "0px",
                                minHeight: "0px",
                              },
                              border:
                                "2px solid " +
                                theme.palette.tables.tableBorderColor,
                            }}
                            onEstadisticas
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            rowsPerPañosOptions={[11]}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ width: "100%" }}>
                          <DataGrid
                            rows={cabeceraDocumentacionR}
                            columns={cabeceraDocumentacionC}
                            classes={{ columnHeaderTitle: classes.headerCell }}
                            components={{ Toolbar: DataGridTitleDOCUMENTACION }}
                            pañosSize={11}
                            autoHeight
                            rowHeight={25}
                            headerHeight={25}
                            //responsive
                            hideFooter={true}
                            sx={{
                              color: "inherit",
                              "& .MuiDataGrid-cell": {
                                border: 1,
                                borderColor: theme.palette.tables.tableBorderColor,
                                borderRight: 0,
                                borderTop: 0,
                                // add more css for customization
                              },
                              "& .MuiDataGrid-columnHeaderTitleContainer": {
                                borderColor: theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-columnHeaders": {
                                borderBottom:
                                  "1px solid " +
                                  theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-iconSeparator": {
                                color: theme.palette.tables.tableBorderColor,
                              },
                              "& .MuiDataGrid-footerContainer": {
                                borderTop:
                                  "1px solid " +
                                  theme.palette.tables.tableBorderColor,
                                maxHeight: "0px",
                                minHeight: "0px",
                              },
                              border:
                                "2px solid " +
                                theme.palette.tables.tableBorderColor,
                            }}
                            onEstadisticas
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            rowsPerPañosOptions={[11]}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    {/*  <MUIDataTable
              title={'Tabla intermedia'}
              data={dataTableSuperior}
              options={Object.assign(option, optionsNormal)}
              columns={[ "Est. Faenador","Usu","Remit","Faena","Loc","Tropa"," Cab. Faena total","Playa emergencia","Muertas","Decomisos","Raz. Ppal."
              ,"Guia","DTA","Kg. vivos FR.","Kg. carne","Kg. decomiso","Rec.","Cuit Remitente","Renspa","Patente del chasis","Patente de acoplado"]}>
              </MUIDataTable>
 */}
                    {/* <MuiThemeProvider theme={getMuiTheme()}> */}
                    {(Object.keys(marcasAgrupadasExpo).length !== 0 ||
                      Object.keys(marcasAgrupadasConsumo).length !== 0) &&
                      user.permissions.faena_marcas_can_view  && (
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginTop: "20px" }}
                        >
                          {Object.keys(marcasAgrupadasExpo).length !== 0 && (
                            <Button
                              onClick={() => {
                                setMostrarMarcasExpo(!mostrarMarcasExpo);
                                setMostrarMarcasConsumo(false);
                              }}
                              variant="contained"
                              color="primary"
                              disabled={
                                Object.keys(marcasAgrupadasExpo).length === 0
                              }
                              style={{
                                backgroundColor: theme.palette.background.custom,
                                color: theme.palette.primary.main,
                                border: mostrarMarcasExpo
                                  ? "2px solid" + theme.palette.primary.main
                                  : "",
                                marginRight:
                                  Object.keys(marcasAgrupadasConsumo).length !== 0
                                    ? "10px"
                                    : "0px",
                              }}
                            >
                              {" "}
                              {mostrarMarcasExpo ? "Ocultar Marcas Exportacion" : "Mostrar Marcas Exportacion"}
                            </Button>
                          )}
                          {Object.keys(marcasAgrupadasConsumo).length !== 0 && (
                            <Button
                              onClick={() => {
                                setMostrarMarcasConsumo(!mostrarMarcasConsumo);
                                setMostrarMarcasExpo(false);
                              }}
                              variant="contained"
                              color="primary"
                              disabled={
                                Object.keys(marcasAgrupadasConsumo).length === 0
                              }
                              style={{
                                backgroundColor: theme.palette.background.custom,
                                color: theme.palette.primary.main,
                                border: mostrarMarcasConsumo
                                  ? "2px solid" + theme.palette.primary.main
                                  : "",
                              }}
                            >
                              {" "}
                              {mostrarMarcasConsumo ? "Ocultar Marcas Consumo" : "Mostrar Marcas Consumo"}
                            </Button>
                          )}
                        </Grid>
                      )}
                    {(mostrarMarcasConsumo || mostrarMarcasExpo) &&
                      user.permissions.faena_marcas_can_view &&
                      (Object.keys(marcasAgrupadasExpo).length !== 0 ||
                        Object.keys(marcasAgrupadasConsumo).length !== 0) && (
                        <Grid
                          container
                          spacing={3}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ justifyContent: "center" }}
                        >
                          {((mostrarMarcasExpo && "Apto" in marcasAgrupadasExpo) ||
                            (mostrarMarcasConsumo &&
                              "Apto" in marcasAgrupadasConsumo)) && (
                              <Grid
                                className={classes.container}
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                              >
                                <MarcaComercialAgrup
                                  theme={theme}
                                  headerName={mostrarMarcasExpo ? "Novillo Apto" : "Apto"}
                                  data={
                                    mostrarMarcasExpo
                                      ? marcasAgrupadasExpo.Apto
                                      : marcasAgrupadasConsumo.Apto
                                  }
                                />
                              </Grid>
                            )}
                          {((mostrarMarcasExpo &&
                            "Rechazo" in marcasAgrupadasExpo) ||
                            (mostrarMarcasConsumo &&
                              "Rechazo" in marcasAgrupadasConsumo)) && (
                              <Grid
                                className={classes.container}
                                item
                                lg={5}
                                md={5}
                                sm={12}
                                xs={12}
                              >
                                <MarcaComercialAgrup
                                  theme={theme}
                                  headerName={mostrarMarcasExpo ? "Novillo Rechazo" : 'Rechazo'}
                                  data={
                                    mostrarMarcasExpo
                                      ? marcasAgrupadasExpo.Rechazo
                                      : marcasAgrupadasConsumo.Rechazo
                                  }
                                />
                              </Grid>
                            )}
                          {((mostrarMarcasExpo &&
                            "Revisar" in marcasAgrupadasExpo) ||
                            (mostrarMarcasConsumo &&
                              "Revisar" in marcasAgrupadasConsumo)) && (
                              <Grid
                                className={classes.container}
                                item
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                              >
                                <MarcaComercialAgrup
                                  theme={theme}
                                  headerName="Revizar"
                                  data={
                                    mostrarMarcasExpo
                                      ? marcasAgrupadasExpo.Revisar
                                      : marcasAgrupadasConsumo.Revisar
                                  }
                                />
                              </Grid>
                            )}
                          {((mostrarMarcasExpo && "Vaca" in marcasAgrupadasExpo) ||
                            (mostrarMarcasConsumo &&
                              "Vaca" in marcasAgrupadasConsumo)) && (
                              <Grid
                                className={classes.container}
                                item
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                              >
                                <MarcaComercialAgrup
                                  theme={theme}
                                  headerName="Vaca"
                                  data={
                                    mostrarMarcasExpo
                                      ? marcasAgrupadasExpo.Vaca
                                      : marcasAgrupadasConsumo.Vaca
                                  }
                                />
                              </Grid>
                            )}
                        </Grid>
                      )}

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <MUIDataTable
                        title={"Resumen de la Tropa"}
                        data={resumenTropa}
                        hideFooter
                        options={Object.assign(optionSort, optionsNormalRomaneo)}
                        columns={[
                          {
                            name: "Faena",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Vivo Prom.",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Carne Prom.",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Carne Total",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Rinde Compra",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Clasif. Ratio",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Grasa Ratio",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                        ]}
                      ></MUIDataTable>
                    </Grid>
                    {/* </MuiThemeProvider> */}

                    <Grid item lg={12} xs={12}>
                      <Grid container sx={{ marginTop: 3 }}>
                        {ocultarBottones && (
                          <Grid item lg={12}>
                            <Button
                              onClick={() => ordenarPorGarron()}
                              variant="contained"
                              color="primary"
                              style={{
                                backgroundColor: theme.palette.background.custom,
                                color: theme.palette.primary.main,
                              }}
                            >
                              {" "}
                              Ordenar Por Garron
                            </Button>
                            <Button
                              onClick={() => ordenarPorPeso()}
                              variant="contained"
                              color="primary"
                              style={{
                                backgroundColor: theme.palette.background.custom,
                                color: theme.palette.primary.main,
                              }}
                            >
                              {" "}
                              Ordenar Por Especie-Peso
                            </Button>
                            {/*   //<Button onClick={()=> ordenarPorPeso()} variant='contained' color='primary'> Ordenar Por Peso</Button> */}
                          </Grid>
                        )}

                        {/* <MuiThemeProvider theme={getMuiTheme()}> */}

                        <Grid item lg={4} xs={12}>
                          <MUIDataTable
                            title={"Romaneo "}
                            data={resumenRomaneo1}
                            hideFooter
                            options={Object.assign(
                              optionSort,
                              optionsNormalRomaneo,
                            )}
                            columns={[
                              {
                                name: "Garron",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Esp",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Dent",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Tip",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "DC",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Cuota",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Peso",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Golpes",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                      borderRight:
                                        "2px solid " +
                                        theme.palette.tables.romaneoBorder,
                                    },
                                  }),
                                  setCellHeaderProps: () => ({
                                    style: {
                                      borderRight:
                                        "2px solid " +
                                        theme.palette.tables.romaneoBorder,
                                    },
                                  }),
                                },
                              },
                            ]}
                          ></MUIDataTable>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <MUIDataTable
                            data={resumenRomaneo2}
                            title={" "}
                            hideFooter
                            options={Object.assign(
                              optionSort,
                              optionsNormalRomaneo,
                            )}
                            columns={[
                              {
                                name: "Garron",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                      borderLeft:
                                        "2px solid " +
                                        theme.palette.tables.romaneoBorder,
                                    },
                                  }),
                                  setCellHeaderProps: () => ({
                                    style: {
                                      borderLeft:
                                        "2px solid " +
                                        theme.palette.tables.romaneoBorder,
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Esp",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Dent",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Tip",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "DC",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Cuota",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Peso",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Golpes",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                      borderRight:
                                        "2px solid " +
                                        theme.palette.tables.romaneoBorder,
                                    },
                                  }),
                                  setCellHeaderProps: () => ({
                                    style: {
                                      borderRight:
                                        "2px solid " +
                                        theme.palette.tables.romaneoBorder,
                                    },
                                  }),
                                },
                              },
                            ]}
                          ></MUIDataTable>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <MUIDataTable
                            title={" "}
                            data={resumenRomaneo3}
                            hideFooter
                            options={Object.assign(
                              optionSort,
                              optionsNormalRomaneo,
                            )}
                            columns={[
                              {
                                name: "Garron",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                      borderLeft:
                                        "2px solid " +
                                        theme.palette.tables.romaneoBorder,
                                    },
                                  }),
                                  setCellHeaderProps: () => ({
                                    style: {
                                      borderLeft:
                                        "2px solid " +
                                        theme.palette.tables.romaneoBorder,
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Esp",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Dent",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Tip",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "DC",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Cuota",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Peso",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                              {
                                name: "Golpes",
                                options: {
                                  setCellProps: () => ({
                                    style: {
                                      textAlign: "center",
                                      alignItems: "center",
                                    },
                                  }),
                                },
                              },
                            ]}
                          ></MUIDataTable>
                        </Grid>

                        {/* </MuiThemeProvider> */}
                      </Grid>
                      {/* <MuiThemeProvider theme={getMuiTheme()}> */}

                      {resumenGolpes.length > 0 && (
                        <MUIDataTable
                          title={"Resumen Golpeados"}
                          data={resumenGolpes}
                          options={Object.assign(optionSort, optionsNormalRomaneo)}
                          columns={[
                            {
                              name: "Garrón",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                            {
                              name: "Especie",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                            {
                              name: "Tipificación",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                            {
                              name: "Destino comercial",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                            {
                              name: "Peso",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                            {
                              name: "Bife",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                            {
                              name: "Asado",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                            {
                              name: "Mocho",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                            {
                              name: "Pecho",
                              options: {
                                setCellProps: () => ({
                                  style: {
                                    textAlign: "center",
                                    alignItems: "center",
                                  },
                                }),
                              },
                            },
                          ]}
                        ></MUIDataTable>
                      )}
                      {/* </MuiThemeProvider> */}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      {/* <MuiThemeProvider theme={getMuiTheme()}> */}

                      <MUIDataTable
                        title={"Resumen Final"}
                        data={resumenFinal}
                        options={Object.assign(optionSort, optionsNormalRomaneo)}
                        columns={[
                          {
                            name: "Esp.",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Tipif.",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Reses",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Peso Total",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                          {
                            name: "Peso 1/2 Res",
                            options: {
                              setCellProps: () => ({
                                style: {
                                  textAlign: "center",
                                  alignItems: "center",
                                },
                              }),
                            },
                          },
                        ]}
                      ></MUIDataTable>
                      {/* </MuiThemeProvider> */}
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
              <Grid container justifyContent={"center"}>
                {isLoadingInfo ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: theme.palette.background.custom,
                      color: theme.palette.primary.main,
                    }}
                    onClick={() => imprimirInformeGeneral()}
                  >
                    Imprimir
                  </Button>
                )}
              </Grid>
            </>
          )}
        </Dialog>
      </ThemeProvider>
    </>
  );
})

export default ResponsiveDialog;
