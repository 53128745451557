import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  CircularProgress

} from "@material-ui/core";
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import Dropzone from 'react-dropzone-uploader'
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import moment from "moment";
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormBienAnimal from "../../components/formBienestAnimal/FormBienAnimal";
import FormDeclaracionGeneral from "../../components/FormDeclaracionGeneral/FormDeclaracionGeneral";
import FormGrainFeed from "../../components/formGrainFeed/FormGrainFeed";
import FormNatural from "../../components/formNatural/FormNatural";
import FormBienesTransp from "../../components/FormBienesTransp/FormBienesTransp";
import { styled } from '@mui/material/styles';
import FormOrganicRenspa from "../../components/FormOrganicRenspa/FormOrganicRenspa";
import FormOrganicTropa from "../../components/FormOrganicTropa/FormOrganicTropa";
import { useTheme } from "@material-ui/styles";
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';


const ToggleButton = styled(MuiToggleButton)(({ textColor, selectedColor, borderColor }) => ({
  color: 'inherit',
  borderColor: borderColor,
  borderRadius: '15px',
  textTransform: 'capitalize',
  maxHeight: '60px',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: textColor,
    backgroundColor: selectedColor,
  },
}));
function NuevoDoc(props) {
  var classes = useStyles();
  const theme = useTheme();
  const { register } = useContext(UserStateContext);

  var [tipo, setTipo] = useState('FormBienAnimal');

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState('');


  //var userDispatch = useUserDispatch();


  // local
  var [titulo, setTitulo] = useState("");
  var [descripcion, setDescripcion] = useState("");
  var [proveedor, setProveedor] = useState("");

  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [isLoading2, setIsLoading2] = useState(false);
  var [desplegables, setDespletables] = useState([]);
  var [sumarImg, setSumarImg] = React.useState(true);
  var [Image, setImage] = React.useState([]);
  const [fechaAlta, setFechaAlta] = useState()
  const [fechaVencimiento, setFechaVencimiento] = useState()
  const [alignment, setAlignment] = React.useState('FormBienAnimal');


  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleCheck = (value) => {
    /*   setTipo(value);
      if (value=='Proveedor'){
        clearState()
        setRol('4')
  
        setHide(true);
      }else{
        setHide(false);
   */
  }

  const setDate = (event) => {
    setFechaAlta(moment(event).format("YYYY-MM-DD"))
    setFechaVencimiento(moment(event).add(1, 'year').format("YYYY-MM-DD"))

    //console.log(fecha)
  }
  const setDateVencimiento = (event) => {
    setFechaVencimiento(moment(event).format("YYYY-MM-DD"))
    //console.log(fecha)
  }
  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {/* console.log(status, meta, file) */ }

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files, allFiles) => {

    const fotos = []
    let newData = {
      title: "",
      description: "",
      image_url: "",
    };

    files.map(f => fotos.push({
      title: f.meta.name,
      description: f.meta.type,
      image_url: f.meta.previewUrl,
      img: f.file
    }))

    //console.log(fotos)
    setImage(fotos)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false)
    allFiles.forEach(f => f.remove())
    //setDisableGuardar(false)

  }



  return (
    <>
      {/* <PageTitle title="Nuevo Documento" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>

          {/* <Widget disableWidgetMenu
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          > */}
          <div>
            <Typography color="secondary" className={classes.errorMessage}>
              {error}
            </Typography>
          </div>

          <div className={classes.form}>
            <Grid container spacing={2}>
              {!isSmallScreen && (<Grid item lg={12} xs={12} md={12} sm={12}>
                <InputLabel id="type">Formulario</InputLabel>
                <ToggleButtonGroup
                  value={alignment}
                  size={isSmallScreen ? "small" : "medium"}
                  exclusive
                  onChange={handleAlignment}
                >
                  <ToggleButton value="FormBienAnimal" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('FormBienAnimal') }}>Bienestar Animal Campo</ToggleButton>
                  <ToggleButton value="FormAnimalTransporte" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('FormAnimalTransporte') }}>Bienestar Animal Transportista</ToggleButton>
                  <ToggleButton value="DeclaracionGeneral" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('DeclaracionGeneral') }}>Declaración General</ToggleButton>
                  <ToggleButton value="Natural" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('Natural') }}>Natural</ToggleButton>
                  <ToggleButton value="GrainFed" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('GrainFed') }}>Grain Fed</ToggleButton>
                  <ToggleButton value="OrganicRenspa" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('OrganicRenspa') }}>Organic Renspa</ToggleButton>
                  <ToggleButton value="OrganicTropa" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('OrganicTropa') }}>Organic Tropa</ToggleButton>

                </ToggleButtonGroup>
              </Grid>)}
              {isSmallScreen && (
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Formulario
                  </InputLabel>
                  <Select
                    defaultValue={tipo}
                    onChange={(e) => { setTipo(e.target.value) }}
                    native
                    margin="normal"
                    inputProps={{
                      name: 'formulario',
                      id: 'uncontrolled-native',
                    }}
                    sx={{
                      color: theme.palette.text.primary,
                      icon: {
                        color: theme.palette.icons.color
                      }
                    }}
                  >
                    <option value={"FormBienAnimal"}>Bienestar Animal Campo</option>
                    <option value={"FormAnimalTransporte"}>Bienestar Animal Transportista</option>
                    <option value={"DeclaracionGeneral"}>Declaración General</option>
                    <option value={"Natural"}>Natural</option>
                    <option value={"GrainFed"}>Grain Fed</option>
                    <option value={"OrganicRenspa"}>Certificado Organico Renspa</option>
                    <option value={"OrganicTropa"}>Certificado Organico Tropa</option>
                  </Select>
                </FormControl>
              </Grid>
                )}
              {tipo == 'FormBienAnimal' &&
                <Grid item lg={12} xs={12} sm={6}>
                  <FormBienAnimal></FormBienAnimal>
                </Grid>
              }
              {tipo == 'GrainFed' &&
                <Grid item lg={12} xs={12} sm={6}>
                  <FormGrainFeed></FormGrainFeed>
                </Grid>
              }
              {tipo == 'Natural' &&
                <Grid item lg={12} xs={12} sm={6}>
                  <FormNatural></FormNatural>
                </Grid>
              }
              {tipo == 'DeclaracionGeneral' &&
                <Grid item lg={12} xs={12} sm={6}>
                  <FormDeclaracionGeneral></FormDeclaracionGeneral>
                </Grid>
              }
              {tipo == 'FormAnimalTransporte' &&
                <Grid item lg={12} xs={12} sm={6}>
                  <FormBienesTransp></FormBienesTransp>
                </Grid>
              }
              {tipo == 'OrganicRenspa' &&
                <Grid item lg={12} xs={12} sm={6}>
                  <FormOrganicRenspa></FormOrganicRenspa>
                </Grid>
              }
              {tipo == 'OrganicTropa' &&
                <Grid item lg={12} xs={12} sm={6}>
                  <FormOrganicTropa></FormOrganicTropa>
                </Grid>
              }
              {/* <Grid item container spacing={2} lg={12} xs={12} sm={12}>
                <Grid item lg={12} xs={12} sm={12}>
                  <TextField
                  autoFocus
                  id="Titulo"
                  variant="outlined"
                  label="Titulo"

                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={titulo}
                  onChange={e => setTitulo(e.target.value)}
                  margin="normal"
                  placeholder="Titulo"
                  type="text"
                  fullWidth
                  required/>
                </Grid>
              
                <Grid item lg={12} xs={12} sm={6}>
                  <TextField id="Descripción" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={descripcion}
                  onChange={e => setDescripcion(e.target.value)}
                  margin="normal"
                  placeholder="Descripción"
                  variant="outlined"
                  label="Descripción"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={12} xs={12} sm={12}>                
                    <InputLabel id="Proveedor">Proveedor*</InputLabel>
             
                    <Autocomplete
                      disablePortal
                      noOptionsText={'Sin opciones'}
                      id="combo-box-demo"
                      options={desplegables}
                      onChange={(e, value) =>{if (value && value.cuit!=undefined){setProveedor(value.cuit.toString()+'-'+value.label.toString())}else{setProveedor('')}}}
                      renderInput={(params) =>(
                         <TextField 
                         {...params} 
                          type="text"
                          variant="outlined"
                         
                            />)}
                    />
                    
                  </Grid> 
                  <Grid item lg={6} md={4} sm={12} xs={12}> 
                    <InputLabel id="type">Fecha de Alta</InputLabel>

                      <ResponsiveDatePickers
                      value={fechaAlta}
                      label="Date desktop"
                      inputFormat="dd-MMMM-yyyyy"
                      setValorFecha={setDate}
                      
                      ></ResponsiveDatePickers> 
                  </Grid>
                  <Grid item lg={6} md={4} sm={12} xs={12}> 
                    <InputLabel id="type">Fecha de Vencimiento</InputLabel>

                      <ResponsiveDatePickers
                      value={fechaVencimiento}
                      label="Date desktop"
                      inputFormat="dd-MMMM-yyyyy"
                      setValorFecha={setDateVencimiento}
                      ></ResponsiveDatePickers> 
                  </Grid>
                    {!sumarImg&& 
                  <Grid item lg={6} xs={12} sm={12}>
                  <FormControlLabel
                          value="consgi"
                          control={<AddCircleIcon/>}  
                          className={classes.control}

                          label="Cargar nuevos archivos"
                          onClick={()=>{setSumarImg(value=>!value)}}
                        />
                
                  </Grid> }
                  {sumarImg&& 

                  <Grid item lg={12} xs={12} sm={12}>
                  <Dropzone
                    classNames={classes.dropZone}
                    styles={{ dropzone: { overflow: 'hidden', margin:'10px 0px'}, submitButton: {padding:'15px',
                    fontSize: '1.2em',}   }}
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    onSubmit={handleSubmit}
                    accept="image/*,.pdf"
                    inputContent='Arrastra tus archivos o hace click aqui'
                    submitButtonContent="Guardar Datos"
                    inputWithFilesContent='Sumar otro archivo'
                      /> 
                  </Grid>  }
              </Grid>    
              <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12}>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    fullWidth

                    onClick={() =>
                      
                      UserService.postDocumento(titulo,descripcion,proveedor,fechaAlta,fechaVencimiento, Image)

                    } 

                    disabled={
                      titulo.length === 0 ||
                      descripcion.length === 0 ||
                      proveedor.length === 0 ||
                      Image.length === 0 

                    } 
                    size="large"
                    variant="contained"
                    color="primary"
                    
                    className={classes.createAccountButton}
                    component={Link} to={`/app/gestionDoc`}
                    >

                  GUARDAR
                  </Button> 
                </Grid>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    className={classes.createAccountButton}
                    size="large"
                    variant="contained"
                    color="primary" 
                    fullWidth
                    component={Link} to={`/app/usuariosDeCarga`}>
                  
                    CANCELAR
                  </Button>  
                </Grid>       
              </Grid>  */}
            </Grid>
          </div>
          {/* </Widget> */}
        </Grid>
      </Grid>
    </>
  );
}
export default NuevoDoc;