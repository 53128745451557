import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({
  MuiOutlinedInput: {
    notchedOutline: {
      borderColor: "white"

    },
  },
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    //justifyContent: "center",
    //alignItems: "center",
    //position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "black",
  },
  containerLogin: {
    width: "40%", display: "flex",
  },
  imgen: {
    marginTop: '50px',
    maxWidth: '320px',
    width: "100%"
  },
  logotypeContainer: {

    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  form: {
    maxWidth: '100%',
    //padding: '0 15px',
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: 'initial',
    fontWeight: 'bold'

  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    margin: "auto",
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
  inputField: {
    //border: 'solid',
    borderRadius: '25px',
    padding: '8%',
    marginBottom: '15px'
  },
  singin: {
    //marginTop: '30px',
    //marginBottom: '15px',
    color: '#ffffff',
    fontSize: 17,
    fontWeight: '600',
    marginLeft: '4px',
    justifyContent: 'left',
    alignItems: 'left',
    display: 'flex'
  },
  subtitle: {
    color: '#ffffff',
    fontSize: '1.2rem'
  },
  disabledButton: {
    backgroundColor: '#8c734b !important',
    color: 'rgba(255, 255, 255,0.6) !important'
  },

  pointer: {
    cursor: 'pointer'
  },
  textField: {
    //color: "white",
    borderColor: "#8c734b",
    '& :hover': {
      borderColor: '#8c734b'
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8c734b',
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#8c734b',
    },
  },
}));
