import React, { Route } from "react";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import authHeader from './auth-header';
import { Redirect } from "react-router-dom";
import Profile from "../pages/profile/profile";
import UserService from "./UserService";
import { Typography } from "@material-ui/core"; 

var UserStateContext = React.createContext();
//var UserDispatchContext = React.createContext();

const API_URL = window.config.API_URL;
const LOCAL_URL = window.config.LOCAL_URL;



export default UserStateContext;

function UserProvider({ children }) {


  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const [darkMode, setDarkMode] = useState(false);

  const [loading, setLoading] = useState(true);
  const [tipo, setTipo] = useState(true)

  const switchTheme = () => {
    setDarkMode(value => !value)
  }

  const currentTimestamp = Math.floor(Date.now() / 1000);
  const token = JSON.parse(localStorage.getItem("authTokens"));

  const decodedAccessToken = token ? jwt_decode(token.access) : null;

  if (decodedAccessToken && decodedAccessToken.exp < currentTimestamp) {
    signOut();
  }

  var landing = localStorage.getItem('landing_page')
  var user_ls = localStorage.getItem('user')
  if (!landing || !user_ls) {
    landing = '/login'
    localStorage.setItem('landing_page', landing)
  }



  const loginUser = async (email, password, history, setError, setIsLoading2, setErrorServidor, setErrorUsuario) => {
    setError(false);
    setIsLoading2(true);
    const response = await fetch(API_URL + "/auth/login/", {
      method: "POST",
      headers: {
        'Access-Control-Allow-Origin': LOCAL_URL,
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Allow": "GET, POST, PUT, DELETE, OPTIONS",
        'Access-Control-Allow-Headers': 'Content-Type',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        email,
        password
      })
    }).catch(error => {
      setErrorServidor(true);
      setErrorUsuario(true)
    })
    if (response) {
      const data = await response.json();
      if (!!email && !!password) {
        setTimeout(() => {
          if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwt_decode(data.access));
            const userdata = jwt_decode(data.access)
            localStorage.clear()
            localStorage.setItem("authTokens", JSON.stringify(data));
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("userData", JSON.stringify(userdata));

            localStorage.setItem("permissions", JSON.stringify(jwt_decode(data.access).rol));
            const landing_page = '/app/' + userdata.landing_page
            localStorage.removeItem('landing_page');
            localStorage.setItem('landing_page', landing_page);

            setTimeout(() => {
            if (userdata.last_login == 'None') {
              history.push('/password')
              // history.push('/app/home')
            } else if ((userdata.rol == 4 || userdata.rol == 7 || userdata.rol == 8)) {
              UserService.getcheckGeneral(userdata.cuit).then(
                response => {
                  if (response.status = 200 && (userdata.rol != 1 || userdata.rol != 2 || userdata.rol != 6 || userdata.rol != 3 || userdata.rol != 5)) {
                    if (response.data.data.length == 0 && userdata.rol == 4) {
                      history.push('/app/gestionDoc')
                    } else {
                      { history.push(landing_page) }
                    }
                  } else {
                    { history.push(landing_page) }
                  }
                })

            } else if (userdata.rol === 1 || userdata.rol === 2 || userdata.rol === 3) { history.push(landing_page) }
            else if (userdata.rol === 5 || userdata.rol === 6) { history.push(landing_page) }
            else if (userdata.rol === 9) { history.push(landing_page) }
            else { history.push(landing_page) }
            }, 2000);

          } else {
            setError(true);
            setIsLoading2(false);
          }
        }, 2000);
      }
    } else {
      setIsLoading2(false);
    }
  }



  const register = async (
    username,
    password,
    password2,
    email,
    first_name,
    last_name,
    cuit,
    razonS,
    rol,
    type,
    sector,
    legajo,
    bp,
    telefono,
    history,
    setIsLoading,
    setError,
    feedlot,
    Renspa,
    titular,
    Localidad,
    Partido,
    Provincia,
    Destino,
    usuario_faena=null,
    grupo_compras=null,
    almacen=null,

  ) => {
    setError(false);
    setIsLoading(true);
    if (type == 'proveedor') {
      setTipo(false)
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const response = await fetch(API_URL + "/auth/register/", {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${user.access}`,
        'Access-Control-Allow-Origin': LOCAL_URL,
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Allow": "GET, POST, PUT, DELETE, OPTIONS",
        'Access-Control-Allow-Headers': 'Content-Type',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
        password2,
        email,
        first_name,
        last_name,
        cuit,
        razonS,
        rol,
        type,
        sector,
        legajo,
        bp,
        telefono,
        tipo,
        feedlot,
        Renspa,
        titular,
        Localidad,
        Partido,
        Provincia,
        Destino,
        usuario_faena,
        grupo_compras,
        almacen
      })
    }).catch(error => {

    }).then(function (response) {
      if (response.status === 201) {
        setError(null)
        setIsLoading(false)
        //navigate('/app/tables')
        history.push('/app/tables')
        //return  <Redirect push to='/app/tables'/>
        //return redirect('/app/tables');

      } else if (response.status == 401) { signOut(history) }
      else if (response.status == 400) {
        //console.log(response)
        //setError(JSON.stringify(response.data)); setIsLoading(false)

        // response.text().then(text => {
        //   const data = JSON.parse(text);
        //   const errorMessages = Object.keys(data).map(key => {
        //     const items = data[key].map(item => <li>{item}</li>);
        //     return (
        //       <div>
        //         <Typography variant="h6">{key}</Typography>
        //         <ul>{items}</ul>
        //       </div>
        //     );
        //   });
        //   setError(errorMessages);
        //   setIsLoading(false);
        // });
        response.text().then(text => {
          const data = JSON.parse(text);
          //setError(JSON.stringify(data));
          setError(data);
          setIsLoading(false);
        });
      }

    })
      .then((data) => {
        
        /* if (items ){
          data=[]
          for (const [key, value] of Object.entries(items)){
            if ( key =='email'){
              data.push(
                <p>El email ya se encuentra registrado</p>
              )
            }else{
              data.push(
                <p>{value}</p>
              )
            }
          }
          setError(data)
        } */
      });

  }

  //funcion para editar usuario
  const editUser = async (
    id,
    username,
    password,
    password2,
    email,
    first_name,
    last_name,
    cuit,
    razonS,
    rol,
    type,
    sector,
    legajo,
    bp,
    telefono,
    is_active,
    enviarEmail,
    history,
    setIsLoading,
    setError,
    origen,
    feedlot,
    Renspa,
    titular,
    Localidad,
    Partido,
    Provincia,
    Destino,
    usuario_faena,
    grupo_compras=null,
    almacen=null,
    isFletero=false,
  ) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const response = await fetch(API_URL + `/auth/update_profile/${id}/`, {
      method: "PUT",
      headers: {
        'Authorization': `Bearer ${user.access}`,
        'Access-Control-Allow-Origin': LOCAL_URL,
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Allow": "GET, POST, PUT, DELETE, OPTIONS",
        'Access-Control-Allow-Headers': 'Content-Type',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
        password2,
        email,
        first_name,
        last_name,
        cuit,
        razonS,
        rol,
        type,
        sector,
        legajo,
        bp,
        telefono,
        is_active,
        enviarEmail,
        feedlot,
        Renspa,
        titular,
        Localidad,
        Partido,
        Provincia,
        Destino,
        usuario_faena,
        grupo_compras,
        almacen,
        isFletero
      })
    }).catch(error => {
    }).then(function (response) {
      if (response.status === 200) {
        setError(null)
        setIsLoading(false)
        //compruebo de donde viene
        if (origen == 'cambiarPass') {
          signOut(history)
        } else {
          history.push('/app/tables')
        }
        // 
      } else if (response.status == 401) { signOut(history) }
      else if (response.status == 500) {

      }
      else if (response.status == 400) {
        return response.json();
      }
    }).then((data) => {
      const items = data;

      if (items) {
        data = []
        for (const [key, value] of Object.entries(items)) {
          if (key == 'email') {
            data.push(
              <p>El email ya se encuentra registrado</p>
            )
          } else {
            data.push(
              <p>{value}</p>
            )
          }
        }
        setError(data)
      }
      return data

    });

    return response
  }
  const deleteUser = async (
    id,
    history,
  ) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const response = await fetch(API_URL + `/auth/delete_profile/${id}/`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${user.access}`,
        'Access-Control-Allow-Origin': LOCAL_URL,
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Allow": "GET, POST, PUT, DELETE, OPTIONS",
        'Access-Control-Allow-Headers': 'Content-Type',
        "Content-Type": 'application/json',
      }

    }).catch(error => {
      //console.log(error)
    })

      .then(function (response) {
        if (response.status == 401) { signOut(history) }
      })
  }

  const getPermissions = () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  }


  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    loginUser,
    register,
    editUser,
    deleteUser,
    getPermissions,
    setDarkMode,
    darkMode,
    switchTheme

  };
  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <UserStateContext.Provider value={contextData}>
      {loading ? null : children}
    </UserStateContext.Provider>
  );


}

/* function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
} */

export { UserProvider, signOut };

// ###########################################################








function signOut(history) {
  localStorage.clear();


  // window.location.href = '/login';
  if (history) {
    history.push('/login')
  } else {
    Redirect('/login');
    //return <Redirect to='/login'/>
  }

  // return 

}

