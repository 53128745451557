import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({
  a:{
    padding: '2px 3px',
    margin: '3px',
    fontSize: '0.7rem'
  },
  confirm:{
    margin: '15px',
    
  },
  title:{
    margin:'50px'
  },
 
  contained:{
    maxWidth:'100%',
  },
  Modal:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    padding:'10px',

    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: 1,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign:'center',
  },
  buttonModal:{
    margin:'10px'
  },
  
  rojo:{
    '& td': {color:'red'}
  },
 
  rojoCircle: {
    color: 'red',
    padding:0
  },
  verde: {
    color: 'green',
    padding:0

  },
  yellow: {
    color: 'yellow',
    padding:0

  },
  centeredHeader: {
    '& span': {
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight:'bold'
    },
  },
}));
