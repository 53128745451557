export  const fakeData = [
    
    {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "1", "usuario": "22", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "1", "usuario": "22", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "1", "usuario": "22", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "1", "usuario": "22", "usuverd": "40", "tropa": "70949800", "fecrec": "2022-10-02", "horarec": "0648", "establec": "1920", "proced": "GRAL ROCA", "cuitconsig": "30687310434", "consig": "INC SOCIEDAD ANONIMA", "cuitremit": "20102139152", "remitente": "SUC.DE ONDARCUHU OSCAR A.", "cabstock": 56, "cabcaicor": 0, "cabmucor": 0, "cabpie": 56, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 20660, "kgrec": 20660, "horastock": "0930", "dta": "23639925-7", "guia": "0236399257", "corral": 14, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "UE.HIL.USA.CH.SUIZA."}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70733300", "fecrec": "2022-10-02", "horarec": "1350", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 42, "cabcaicor": 0, "cabmucor": 0, "cabpie": 42, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 18660, "kgrec": 18660, "horastock": "0930", "dta": "23644834-7", "guia": "7108610", "corral": 13, "usuverddesc": "Inc", "programa": "ORGANIC", "destext": "REMATE.FERIA.CHINA"}, 
    {"ecstock": "2022-10-03", "tipostock": "1", "usuario": "22", "usuverd": "40", "tropa": "70733400", "fecrec": "2022-10-02", "horarec": "1410", "establec": "1920", "proced": "BENITO JUAREZ", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 36, "cabcaicor": 0, "cabmucor": 0, "cabpie": 36, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 16900, "kgrec": 16900, "horastock": "0930", "dta": "23644748-0", "guia": "7108589", "corral": 2, "usuverddesc": "Inc", "programa": "GRAIN FED", "destext": "UE.HIL.USA.CH"},
     {"ecstock": "2022-10-03", "tipostock": "1", "usuario": "22", "usuverd": "40", "tropa": "70733500", "fecrec": "2022-10-02", "horarec": "1410", "establec": "1920", "proced": "AZUL", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 4, "cabcaicor": 0, "cabmucor": 0, "cabpie": 4, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 1840, "kgrec": 1840, "horastock": "0930", "dta": "23644784-7", "guia": "7108596", "corral": 16, "usuverddesc": "Inc", "programa": "GRAIN FED", "destext": "C.481.USA.CHINA."}, 
     {"ecstock": "2022-10-03", "tipostock": "1", "usuario": "22", "usuverd": "40", "tropa": "70733600", "fecrec": "2022-10-02", "horarec": "1430", "establec": "1920", "proced": "DAIREAUX", "cuitconsig": "", "consig": "", "cuitremit": "30582522177", "remitente": "DELFINAGRO SOCIEDAD ANONIMA", "cabstock": 40, "cabcaicor": 0, "cabmucor": 0, "cabpie": 40, "cabcai": 0, "cabmu": 0, "espvac": "NO", "raza": "AA", "kgorig": 17150, "kgrec": 17150, "horastock": "0930", "dta": "23638441-1", "guia": "7106438", "corral": 43, "usuverddesc": "Inc", "programa": "GRAIN FED", "destext": "UE.HIL.USA.CH"}, 
     {"ecstock": "2022-10-03", "tipostock": "1", "usuario": "22", "usuverd": "40", "tropa": "70949900", "fecrec": "2022-10-02", "horarec": "1620", "establec": "1920", "proced": "DAIREAUX", "cuitconsig": "30709867381", "consig": "R. DURRIEU S R L", "cuitremit": "30615883499", "remitente": "ESTABLECIMIENTOS PEIREGRO S.A.", "cabstock": 43, "cabcaicor": 0, "cabmucor": 0, "cabpie": 43, "cabcai": 0, "cabmu": 0, "espvac": "VQ", "raza": "AA", "kgorig": 15440, "kgrec": 15440, "horastock": "0930", "dta": "23646536-5", "guia": "7108929", "corral": 12, "usuverddesc": "Inc", "programa": "NATURAL", "destext": "REMATE.FERIA.CHINA"}, {"ecstock": "2022-10-03", "tipostock": "3", "usuario": "40", "usuverd": "40", "tropa": "70950000", "fecrec": "2022-10-02", "horarec": "1820", "establec": "1920", "proced": "GRAL VILLEGAS", "cuitconsig": "30526554562", "consig": "PEDRO GENTA Y CIA SA", "cuitremit": "30716787768", "remitente": "FIDEICOMISO COWFUNDING", "cabstock": 51, "cabcaicor": 0, "cabmucor": 0, "cabpie": 51, "cabcai": 0, "cabmu": 0, "espvac": "VQ", "raza": "AA", "kgorig": 16800, "kgrec": 16800, "horastock": "0930", "dta": "23639627-4", "guia": "7106899", "corral": 42, "usuverddesc": "Inc", "programa": "NO ASIGNADO", "destext": "CAMPO.P.CHINA.USA."}, 
     ]

