import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({
    overflow: {
        overflowY:'initial'
      },
      button:{
        marginTop:'20px'
      },
      headerCell: {
        fontWeight: '800',
      },
      logo: {
        maxWidth: 100,
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        marginRight:30
      },
      createAccountButton:{
        marginRight:'50px',
        marginTop:'20px,',
        width:'100%',
        fontSize: '1.2em',

        
      },
      disabledButton:{
        backgroundcolor:'red',
        color:'red'
      },
      dropZone:{
        margin:4
      },
      control:{
        marginTop:'20px',
        marginLeft:'05px',
        marginBottom:'20px',

      },
      marginNormal:{
        marginTop: '16px',
        marginBottom: '8px'
      },
      
      


    }));