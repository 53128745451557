import React, { useState } from "react";
import { Grid, Button, ButtonBaseTypeMap, } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";/* import MUIDataTable from "mui-datatables";
import MenuItem from '@mui/material/MenuItem';  
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton'; */
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
// 
import DteList from "../../components/dteList/dteList";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  formButtons: {
    width: "50%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonSubmit: {
    padding: '15px',
    fontSize: '1.2em',
    marginTop: '17px'

  },
}))

export default function Dte() {

  const classes = useStyles();

  //const datatableData = []
  return (
    <>

      {/* <PageTitle title="Dte Compras" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>

          {/* <Widget disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          > */}
          <Grid container spacing={3}>

            <DteList></DteList>
          </Grid>
          {/* </Widget> */}
        </Grid>
      </Grid>

    </>
  );
}
