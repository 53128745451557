import { useEffect, useState, useContext, memo } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import UserService from "../../context/UserService";
import { ThemeProvider } from '@mui/material/styles';
import UserStateContext from "../../context/UserContext";
import Themes from "../../themes";
import useStyles from './styles';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


const ModalAsociacionFaenaInvernada = memo(function ModalAsociacionFaenaInvernada({ open, handleClose, idInvernada }) {
    const [isLoading, setIsLoading] = useState(false)
    const [responseError, setResponseError] = useState(null)
    const [dataCompraInvernada, setDataCompraInvernada] = useState([])
    const [comprasFaena, setComprasFaena] = useState([])
    const [selectedComprasFaena, setSelectedComprasFaena] = useState([])
    const [sendingRequest, setSendingRequest] = useState(false);
    const [requestResponse, setRequestResponse] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [comprasRelacionadas, setComprasRelacionadas] = useState([])
    const [deletingRelacion, setDeletingRelacion] = useState(false)
    const [relacionToDelete, setRelacionToDelete] = useState(null)
    const [deletionResponse, setDeletionResponse] = useState(null)
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [loadingComprasFaena, setLoadingComprasFaena] = useState(false)
    const { darkMode, user } = useContext(UserStateContext);
    const theme = darkMode ? Themes.default : Themes.light;
    const classes = useStyles();
    const permissions = user?.permissions;
    const canCreate = permissions.feedlot_can_create_relations === true;
    const canDelete = permissions.feedlot_can_delete_relations === true;

    const getComprasFaenaPendientes = async () => {
        setLoadingComprasFaena(true)
        try {
            const response = await UserService.getFaenaComprasByIdInvernada(idInvernada);
            if (response.status !== 200) {
                throw new Error("Error al obtener compras de faena")
            }
            setComprasFaena(response.data)  // lo seteamos igual, por mas que sea un empty array
        } catch (error) {
            setResponseError(error.message)
        } finally {
            setLoadingComprasFaena(false)
        }
    }

    const getComprasFaena = async () => {
        setIsLoading(true)
        setResponseError(null)
        let responses;
        try {
            responses = await Promise.all([UserService.getFaenaComprasByIdInvernada(idInvernada), UserService.getCompra(idInvernada), UserService.getComprasRelacionadas({ id_invernada: idInvernada })]);
            if (responses[0].status !== 200) {
                throw new Error("Error al obtener compras de faena")
            }
            if (responses[0].data.length !== 0) {
                setComprasFaena(responses[0].data)
            }

            if (responses[1].status !== 200) {
                throw new Error("Error al obtener datos de compra invernada")
            }
            setDataCompraInvernada(responses[1].data?.data)

            if (responses[2].status !== 200) {
                throw new Error("Error al obtener compras relacionadas")
            }
            if (responses[2].data.length !== 0) {
                setComprasRelacionadas(responses[2].data[0].compras_faena)
            }
        } catch (error) {
            setResponseError(error.message)
        } finally {
            setIsLoading(false)
        }

    }

    const handleSubmit = async (event) => {
        setSendingRequest(true)
        setRequestResponse(null)
        const data = {
            compra_invernada_id: idInvernada,
            compras_faena_ids: selectedComprasFaena
        }
        let response;
        try {
            response = await UserService.postFaenaCompra(data);
            if (response.status !== 201) {
                throw new Error("Error al asociar faena a invernada")
            }
            setRequestResponse({
                success: true,
                message: "Faena asociada a invernada correctamente"
            })
        } catch (error) {
            setRequestResponse({
                success: false,
                message: error.message
            });
        } finally {
            setSendingRequest(false);
            setOpenSnackbar(true)
        }

    }

    const handleDeleteRelacion = async (id_faena) => {
        setDeletingRelacion(true)
        setRelacionToDelete(id_faena)
        setDeletionResponse(null)
        try {
            const response = await UserService.deleteRelacionCompra(idInvernada, id_faena)
            if (response.status !== 204) {
                throw new Error("Error al eliminar relacion")
            }
            // look for the deleted relation and remove it from the list
            const updatedComprasRelacionadas = comprasRelacionadas.filter(compra => compra.id !== id_faena)
            setComprasRelacionadas(updatedComprasRelacionadas)
            getComprasFaenaPendientes() // volvemos a cargar las compras de faena pendientes
            setDeletionResponse({
                success: true,
                message: "Relacion eliminada correctamente"
            })
        } catch (error) {
            setDeletionResponse({
                success: false,
                message: error.message
            })
        } finally {
            setDeletingRelacion(false)
            setRelacionToDelete(null)
            setOpenModalDelete(true)
        }
    }

    const handleCloseModalDelete = () => {
        setOpenModalDelete(false)
    }

    const handleCloseModal = (noChanges=false) => {
        setSelectedComprasFaena([])
        setComprasFaena([])
        setComprasRelacionadas([])
        setOpenSnackbar(false)
        handleClose(noChanges)
    }

    useEffect(() => {
        if (open) {
            getComprasFaena()
        }
    }, [idInvernada, open])

    const createCompraFaenaDescription = (compra) => {
        const pattern = new RegExp('^\d{11}');
        const remitente = pattern.test(compra.remitente) ? compra.remitente.split('-')[1] : compra.remitente;
        return `Fecha: ${compra.fecha_de_faena} - Remitente: ${remitente} - Peso: ${compra.peso} - Precio: ${compra.precio}`
    }

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleClose}
                fullWidth={true}
            >
                <Box className={classes.modal}>
                    <div className={classes.title}>
                        <Typography variant="h3">Asociar Faena a Invernada</Typography>
                    </div>
                    {isLoading && (
                        <div className={classes.loading}>
                            <CircularProgress size={20} />
                        </div>
                    )}
                    {(responseError && !isLoading) &&
                        <Typography variant="subtitle2" className={classes.error}>{responseError}</Typography>}
                    {(!responseError && !isLoading) && (
                        <section id="modal-content">
                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                            <div>
                                <Typography variant="subtitle2" className={classes.subtitle}>Compra de Invernada:</Typography>
                                <p>
                                    <b>Fecha de carga: </b> {dataCompraInvernada.fecha_de_carga ? dataCompraInvernada.fecha_de_carga : "Pendiente"}
                                    <br />
                                    <b>Feedlot: </b> {dataCompraInvernada.feedlot?.split('-')[0]} <br />
                                    <b>Remitente: </b> {dataCompraInvernada.remitente} <br />
                                    <b>Cebezas: </b> {dataCompraInvernada.cabezas_compradas} <br />
                                </p>
                            </div>
                            {comprasRelacionadas?.length > 0 && (
                                <>
                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                                    <Typography variant="subtitle2" className={classes.subtitle}>Compras de Faena asociadas:</Typography>
                                    <List dense={true}>
                                        {comprasRelacionadas?.map((compra) => (
                                            <ListItem key={compra.id}
                                                secondaryAction={
                                                    (deletingRelacion && relacionToDelete === compra.id) ? (<CircularProgress size={20} />) :
                                                        (<IconButton edge="end" aria-label="delete" onClick={() => { handleDeleteRelacion(compra.id) }} disabled={!canDelete}>
                                                            {canDelete ? (
                                                            <DeleteIcon color="primary" />) : (<></>)}
                                                        </IconButton>)
                                                }
                                            >
                                                <ListItemText
                                                    primary={createCompraFaenaDescription(compra)}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            )}

                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                            {(comprasFaena?.length > 0 && canCreate) ? (
                                <div>
                                    <Typography variant="subtitle2" className={classes.subtitle}>Compras de Faena posibles de asociar:</Typography>
                                    <Select
                                        id="select-compra-faena-label"
                                        multiple
                                        name="compras_faena"
                                        placeholder={"Seleccione las compras de faena a asociar"}
                                        fullWidth={true}
                                        className={classes.select}
                                        value={selectedComprasFaena}
                                        onChange={(event) => setSelectedComprasFaena(event.target.value)}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {comprasFaena.map((compra) => (
                                            <MenuItem key={compra.id} value={compra.id}>
                                                <Checkbox checked={selectedComprasFaena.indexOf(compra.id) > -1} />
                                                <ListItemText className={classes.list_item} primary={createCompraFaenaDescription(compra)} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            ) : loadingComprasFaena ? (
                                <div className={classes.loading}>
                                    <CircularProgress size={20} />
                                </div>
                            ) : (
                                <div>
                                    <Typography variant="subtitle2" className={classes.error}>No hay compras de faena para asociar</Typography>
                                </div>
                            )}
                        </section>
                    )}
                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                    <section id="actions" className={classes.actions}>
                        <Button type="reset" onClick={() => (handleCloseModal(true))} style={{ marginRight: '3px' }}>Cancelar</Button>
                        <LoadingButton loading={sendingRequest} variant="contained" color="primary" onClick={handleSubmit} disabled={selectedComprasFaena.length === 0 || sendingRequest || !canCreate}>Asociar</LoadingButton>
                    </section>
                </Box>
            </Modal>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseModal} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert
                    onClose={handleCloseModal}
                    severity={requestResponse?.success ? "success" : "error"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {requestResponse?.message}
                </Alert>
            </Snackbar>
            <Snackbar open={openModalDelete} autoHideDuration={2000} onClose={handleCloseModalDelete} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert
                    onClose={handleCloseModalDelete}
                    severity={deletionResponse?.success ? "success" : "error"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {deletionResponse?.message}
                </Alert>
            </Snackbar>

        </ThemeProvider>
    )
})

export default ModalAsociacionFaenaInvernada