import React from "react";
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
  Button,
  Grid
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import UserService from "../../context/UserService";
import { es } from 'date-fns/locale'
import { useTheme } from "@material-ui/styles";
import { useEffect } from "react";

const styles = (theme) => ({
  ccalendarWrapper: {
    "& .rdrCalendarWrapper .rdrDateRangeWrapper": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary
    }
  },
  calendarPopover: {
    "& .rdrCalendarWrapper .rdrDateRangeWrapper": {
      backgroundColor: theme.palette.background.default + " !important",
      color: theme.palette.text.primary + " !important"
    }
  }
});



class DateRangePicker extends React.Component {
  dateFormat = "DD/MM/YY";
  state = {
    displayCalendar: false,
    value: '',
    inputValue: moment().format(this.dateFormat) + "  -  " + moment().format(this.dateFormat),
    anchorEl: null,
    fromDate: new Date(),
    toDate: new Date(),
    fechaMax: new Date()
  };

  componentDidMount() {
    //setValue((this.props.value));
    if (this.props.fechaInicial != '' && this.props.fechaFinal != '') {
      this.setState({ inputValue: moment(this.props.fechaInicial).format(this.dateFormat) + "  -  " + moment(this.props.fechaFinal).format(this.dateFormat) })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fechaInicial !== prevProps.fechaInicial || this.props.fechaFinal !== prevProps.fechaFinal) {
      const { fechaInicial, fechaFinal } = this.props;
      //console.log(fechaFinal, fechaInicial)
      if (fechaInicial && fechaFinal) {
        this.setState({ inputValue: moment(fechaInicial).format(this.dateFormat) + "  -  " + moment(fechaFinal).format(this.dateFormat) });
      }
    }
  }

  onAdornmentClick = e => {
    this.setState({ displayCalendar: true, anchorEl: e.currentTarget });
  };

  onInputChange = e => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = this.processInputValue(inputValue);
    //console.log(fromDate, toDate)
    this.setState({ inputValue: inputValue, fromDate: fromDate, toDate: toDate });
    this.props.setValorFecha({ startDate : fromDate, endDate : toDate })

  };
  isWeekend = (date) => {
    if (date) {

      const day = moment(date).day();

      return day === 0 || day === 6;
    }

  };
  onPopoverClose = (e, reason) => {
    this.setState({ displayCalendar: false, anchorEl: null });
  };

  onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;
    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;
    //console.log(startDate,endDate)

    let inputValue = "";
    if (startDate) inputValue += moment(startDate).format(this.dateFormat);
    if (endDate) inputValue += " - " + moment(endDate).format(this.dateFormat);

    this.setState({ fromDate: startDate, toDate: endDate, inputValue });
    this.props.setValorFecha({ startDate, endDate })

  };

  processInputValue(value) {
    let [fromDate, toDate] = value.split("-").map(elm => elm.trim());

    fromDate = moment(fromDate, this.dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, this.dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;
    return { fromDate, toDate };
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid item lg={12} md={12} sm={12} xs={12}>

          <div>
            <TextField
              //label="Selecciona Rango de Fechas"
              fullWidth
              value={this.state.inputValue}
              variant="outlined"

              onClick={this.onAdornmentClick}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.onAdornmentClick}>
                      <DateRangeIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={this.onInputChange}
            />

            <Popover
              open={this.state.displayCalendar}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              onClose={this.onPopoverClose}
              className={classes.calendarPopover}
              classes={{
                paper: classes.calendarWrapper
              }}
            >
              <div className={classes.calendarWrapper}>

                <DateRange
                  ranges={[
                    {
                      startDate: this.state.fromDate,
                      endDate: this.state.toDate,
                      key: "selection"
                    }
                  ]}
                  onChange={this.onSelectDateRanges}
                  staticRanges={undefined}
                  inputRanges={undefined}
                  maxDate={this.fechaMax}

                  showMonthAndYearPickers={true}
                  moveRangeOnFirstSelection={false}
                  showDateDisplay={false}
                  scroll={{ enabled: true }}
                  locale={es}
                />

              </div>
            </Popover>

          </div>
        </Grid>

      </>


    );
  }
}

export default withStyles(styles, { name: "DateRangePicker" })(DateRangePicker);
