import React, { useState,useContext } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  CircularProgress
}from "@material-ui/core";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import { Link } from "react-router-dom";

function EditUsuariosDeCarga(props) {
  var classes = useStyles();

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState('');
  
  
//accedo a la informacion de usuarios ya descargada.
const datatableData = JSON.parse(localStorage.getItem('dataTableUsuariodeCarga'));
// saco indice para la informacion de usuarios. 
const params = window.location.href
// numero de indice 
const idDataTable = params.split('/')[6]

  // local
  var [id, setId] = useState(datatableData[idDataTable]['id'])
  var [nameValue, setNameValue] = useState(datatableData[idDataTable]['username']);
  var [lastNameValue, setlastNameValue] = useState(datatableData[idDataTable]['last_name']);
  var [telefono, setTtelefono] = useState(datatableData[idDataTable]['telefono']);
  
  //manejo de tiempos del servidor
var [errorServidor, setErrorServidor] = useState(null);
var [isLoading2, setIsLoading2] = useState(false);


  return (
    <>
      <PageTitle title="Editar Contacto de Carga" />
      <Grid  container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>

          <Widget disableWidgetMenu
                
                bodyClass={classes.fullHeightBody}
                className={classes.card}
              >
            <div>
            <Typography color="secondary" className={classes.errorMessage}>
              {error}
            </Typography>
            </div>

            <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12} sm={12}>

                <Grid item lg={12} xs={12} sm={12}>
                  <TextField
                  autoFocus
                  id="name"
                  variant="outlined"
                  label="Nombre"

                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={nameValue}
                  onChange={e => setNameValue(e.target.value)}
                  margin="normal"
                  placeholder="Nombre"
                  type="text"
                  fullWidth
                  required/>
                </Grid>
              
                <Grid item lg={12} xs={12} sm={6}>
                  <TextField id="lastName" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={lastNameValue}
                  onChange={e => setlastNameValue(e.target.value)}
                  margin="normal"
                  placeholder="Apellido"
                  variant="outlined"
                  label="Apellido"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={12} xs={12} sm={12}>
                  <TextField id="telefono" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={telefono}
                  onChange={e => setTtelefono(e.target.value)}
                  margin="normal"
                  placeholder="telefono"
                  variant="outlined"
                  label="telefono"
                  type="text"
                  fullWidth/>
                </Grid>  
              </Grid>    
              <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12}>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    fullWidth

                    onClick={() =>
                      
                      UserService.putContactoDeCarga(
                        id,
                        nameValue,
                        lastNameValue,
                        telefono,
                        setIsLoading,
                        setError,
                      ) 
                    }

                    disabled={
                      lastNameValue.length === 0 ||
                      nameValue.length === 0 ||
                      telefono.length === 0
                    } 
                    size="large"
                    variant="contained"
                    color="primary"
                    component={Link} to={`/app/usuariosDeCarga`}

                    className={classes.createAccountButton}
                    >
                  GUARDAR
                  </Button> 
                </Grid>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    className={classes.createAccountButton}
                    size="large"
                    variant="contained"
                    color="primary" 
                    fullWidth

                    component={Link} to={`/app/usuariosDeCarga`}>
                  
                    CANCELAR
                  </Button>  
                </Grid>       
              </Grid>      
            </Grid>    
            </div>  
          </Widget>  
        </Grid>  
      </Grid>   
    </>    
  );
}
export default EditUsuariosDeCarga;