import { makeStyles, Theme } from "@material-ui/core/styles";
import { darken, lighten } from "@mui/material/styles";

export const useStyles = makeStyles((theme) => ({
  totales: {
    "& td": {
      backgroundColor: theme.palette.totals.total, //'#0064c7',
      color: theme.palette.text.primary,
      fontWeight: "bold",
      // border: '0px',
      // borderButtom: '2px solid ' + theme.palette.tables.tableBorderColor,
      // borderTop: '2px solid ' + theme.palette.tables.tableBorderColor,
    },
  },
}));
