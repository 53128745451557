import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({
    overflow: {
        overflowY:'initial'
      },
      button:{
        marginTop:'20px'
      },
      headerCell: {
        fontWeight: '800',
      },
      logo: {
        maxWidth: 100,
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        marginRight:30
      },
      createAccountButton:{
        marginRight:'50px',
        marginTop:'20px,',
        width:'100%',
        fontSize: '1.2em',

        
      },
      control:{
        margin:2
      },
      disabledButton:{
        backgroundcolor:'red',
        color:'red'
      },
      dropZone:{
        margin:4
      },
      control:{
        marginTop:'20px',
        marginLeft:'05px',
        marginBottom:'20px',

      },
      marginNormal:{
        marginTop: '16px',
        marginBottom: '8px'
      },
      marginNormal3:{
        marginTop: '20px',
        marginBottom: '8px'
      },
      Top:{
        marginBottom: '16px'
      },
      Modal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        padding:'10px',
    
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: 1,
        pt: 2,
        px: 4,
        pb: 3,
        textAlign:'center',
      },
      buttonModal:{
        margin:'10px'
      },
      
      


    }));