import React, { useState, useContext, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useMediaQuery } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";

export default function EstadisticasTable(props) {
  var [estadisticas, setEstadisticas] = useState(props.onEstadisticas);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const columns = [];
  const rows = [];
  if (isSmallScreen) {
    columns.push(
      { field: 1, headerName: "", width: 80 },
      { field: 2, headerName: "Ult.Trop", width: 80 },
      { field: 3, headerName: " 6 meses", width: 60 },
      { field: 4, headerName: " 3 años", width: 60 },
    );

    rows.push(
      { id: 1, 1: "R.Conf" },
      { id: 2, 1: "R.Gord" },
      { id: 3, 1: "Golp" },
      { id: 4, 1: "R %" },
      { id: 5, 1: "STAND" },
      { id: 6, 1: "CHOICE" },
      { id: 7, 1: "SELECT" },
      { id: 8, 1: "PRIME" },
      { id: 9, 1: "G.dor" },
      { id: 10, 1: "Á.Odbife" },
      { id: 11, 1: "TCab" },
    );
  } else {
    columns.push(
      { field: 1, headerName: "", width: 120 },

      { field: 2, headerName: "Última Tropa", width: 120 },
      { field: 3, headerName: "Últimos 6 meses", width: 120 },
      { field: 4, headerName: " Últimos 3 años", width: 120 },
    );

    rows.push(
      { id: 1, 1: "R.Conf" },
      { id: 2, 1: "R.Gord" },
      { id: 3, 1: "Golp" },
      { id: 4, 1: "R %" },
      { id: 5, 1: "STAND" },
      { id: 6, 1: "CHOICE" },
      { id: 7, 1: "SELECT" },
      { id: 8, 1: "PRIME" },
      { id: 9, 1: "G.dor" },
      { id: 10, 1: "Á.Odbife" },
      { id: 11, 1: "TCab" },
    );
  }

  useEffect(() => {
    setEstadisticas(props.onEstadisticas);
  }, [props.onEstadisticas]);

  let index = 2;

  for (const [key, value] of Object.entries(estadisticas)) {
    //console.log(key, value);
    if (
      key == "dataEstadistica" ||
      key == "dataEstadistica6Meses" ||
      key == "dataEstadistica3Años"
    ) {
      let data = value[0];
      if (data != undefined) {
        rows[0][index] =
          data["tipificacion"] != null ? data["tipificacion"].toFixed(2) : 0;
        rows[1][index] =
          data["gordura"] != null ? data["gordura"].toFixed(2) : 0;
        rows[2][index] =
          data["golpeados"] != null ? data["golpeados"].toFixed(0) : 0;
        rows[3][index] =
          data["rinde"] != null ? (data["rinde"] * 100).toFixed(0) : 0;
        rows[4][index] =
          data["marbling"]["STANDARD"] != null
            ? data["marbling"]["STANDARD"]
            : 0;
        rows[5][index] =
          data["marbling"]["CHOICE"] != null ? data["marbling"]["CHOICE"] : 0;
        rows[6][index] =
          data["marbling"]["SELECT"] != null ? data["marbling"]["SELECT"] : 0;
        rows[7][index] =
          data["marbling"]["PRIME"] != null ? data["marbling"]["PRIME"] : 0;
        rows[8][index] =
          data["grasaDorsal"] != null ? data["grasaDorsal"].toFixed(2) : 0;
        rows[9][index] =
          data["areaOjoBife"] != null ? data["areaOjoBife"].toFixed(2) : 0;
        rows[10][index] =
          data["cabezas"] != null ? data["cabezas"].toFixed() : 0;
        index++;
      } else {
        rows[0][index] = 0;
        rows[1][index] = 0;
        rows[2][index] = 0;
        rows[3][index] = 0;
        rows[4][index] = 0;
        rows[5][index] = 0;
        rows[6][index] = 0;
        rows[7][index] = 0;
        rows[8][index] = 0;
        rows[9][index] = 0;
        rows[10][index] = 0;
        index++;
      }
    }

    //datatableData.push(dataEstadistica)
  }
  //{ id:1, 1: 'Ratio Conformación', ultima: '1', meses: 222, años: 35 },

  return (
    <div style={{ height: isSmallScreen ? "650px" :"75vh", width: isSmallScreen ? "100%" : "80%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        //pañosSize={11}
        onEstadisticas
        autoHeight={true}
        autoWidth={true}
        disableVirtualization
        hideFooter={true}
        disableSelectionOnClick
        hideFooterPagination
        hideFooterSelectedRowCount
        sx={{
          m: "0px",
          color: "inherit",
        }}
        //rowsPerPañosOptions={[11]}
      />
    </div>
  );
}
