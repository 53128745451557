import React, { useState } from "react";
import {

  CircularProgress
} from "@material-ui/core";


export const optionsNormal = {
  responsive: 'standard',
  fixedSelectColumn: true,
  fixedHeader: true,
  filterType: 'multiselect',
  pagination: true,
  sort: false,
  filter: false,

  textLabels: {
    body: {
      noMatch: "No hay datos",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Próxima Página",
      previous: "Página anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtros de tabla",
    },
    filter: {
      all: "TODOS",
      title: "FILTROS",
      reset: "REINICIAR",
    },
    viewColumns: {
      title: "Mostrar columnas",
      titleAria: "Mostrar/Ocultar columnas de tabla",
    },
    selectedRows: {
      text: "Fila(s) Elegidas",
      delete: "Borrar",
      deleteAria: "Borrar filas seleccionadas",
    },
  },
  selectableRowsHideCheckboxes: true,
};


export const optionsNormal2 = {
  responsive: 'standard',
  fixedSelectColumn: true,
  fixedHeader: true,
  filterType: 'multiselect',
  sort: false,
  filter: false,

  textLabels: {
    body: {
      noMatch: "No hay datos",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Próxima Página",
      previous: "Página anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtros de tabla",
    },
    filter: {
      all: "TODOS",
      title: "FILTROS",
      reset: "REINICIAR",
    },
    viewColumns: {
      title: "Mostrar columnas",
      titleAria: "Mostrar/Ocultar columnas de tabla",
    },
    selectedRows: {
      text: "Fila(s) Elegidas",
      delete: "Borrar",
      deleteAria: "Borrar filas seleccionadas",
    },
  },
};

export const optionsNormal3 = {
  responsive: 'standard',
  fixedSelectColumn: true,
  fixedHeader: true,
  search: false,
  viewColumns: false,
  print: false,
  filter: false,
  download: false,
  sort: false,
  pagination: false,
  draggableColumns: false,
  textLabels: {
    body: {
      noMatch: "No hay datos",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Próxima Página",
      previous: "Página anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtros de tabla",
    },
    filter: {
      all: "TODOS",
      title: "FILTROS",
      reset: "REINICIAR",
    },
    viewColumns: {
      title: "Mostrar columnas",
      titleAria: "Mostrar/Ocultar columnas de tabla",
    },
    selectedRows: {
      text: "Fila(s) Elegidas",
      delete: "Borrar",
      deleteAria: "Borrar filas seleccionadas",
    },
  },
  selectableRowsHideCheckboxes: true,
};

export const Preview = ({ meta }) => {
  const { name, percent, status } = meta
  return (
    <>

      <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
        {name}, {Math.round(percent)}%, Subiendo.....
        <CircularProgress size={26} />

      </span>

    </>
  )
}