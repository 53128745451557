import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  CircularProgress,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function NuevoUsuarioFeedlot(props) {
  var classes = useStyles();
  const { register } = useContext(UserStateContext);
  const { editUser } = useContext(UserStateContext);

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState("");

  //var userDispatch = useUserDispatch();
  // local
  //titular
  var [nameValue, setNameValue] = useState("");
  var [lastNameValue, setlastNameValue] = useState("");
  var [telefono, setTtelefono] = useState("");
  var [cuit, setCUIT] = useState("");

  var [tipo, setTipo] = React.useState("FeedLot");
  var [legajo, setLegajo] = useState("");
  var [rol, setRol] = useState("8");
  var [sector, setSector] = useState("");
  var [bp, setBp] = useState("");
  var [razonSocial, setRazonSocial] = useState("");
  var [email, setEmail] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [passwordValue2, setPassword2Value] = useState("");
  var [telefono, setTelefono] = useState(0);
  //nuevos feedlot
  var [feedlot, setFeedlot] = useState(null);
  var [Renspa, setRenspa] = useState([]);
  var [titular, setTitular] = useState("");
  var [Localidad, setLocalidad] = useState("");
  var [Partido, setPartido] = useState("");
  var [Provincia, setProvincia] = useState("");
  var [Destino, setDestino] = useState("");

  const [almacenes, setAlmacenes] = useState([]);
  const [almacen, setAlmacen] = useState("");

  const [feedlotDesp, setFeedlotDesp] = useState([]);
  var [id, setId] = useState("");
  var [active, setActive] = useState();
  var [enviarEmail, setEnviarEmail] = useState(false);

  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [isLoading2, setIsLoading2] = useState(false);

  useEffect(() => {
    // busco listado de contactos de carga
    UserService.getFeedLot().then(
      (response) => {
        if (response.status == 200) {
          setFeedlotDesp(response.data);
        }
      },
      (error) => {
        if (error.response.status == 401) {
        }
      },
    );
    UserService.getAlmacenes().then(
      (response) => {
        if (response.status == 200) {
          setAlmacenes(response.data);
        }
      },
      (error) => {
        if (error.response.status == 401) {
        }
      },
      )
  }, []);

  useEffect(() => {
    let userInfo = props.value;
    if (userInfo != undefined) {
      let feedlot_instance = userInfo["feedlot"];
      setId(userInfo["id"]);
      setNameValue(userInfo["username"]);
      setlastNameValue(userInfo["last_name"]);
      setTipo(userInfo["type"]);
      if (userInfo["telefono"] != undefined) {
        setTelefono(userInfo["telefono"]);
      } else {
        setTelefono(0);
      }

      setLegajo(userInfo["legajo"]);
      setRol(userInfo["rol"]);
      setSector(userInfo["sector"]);
      setBp(userInfo["sector"]);
      setRazonSocial(userInfo["razonS"]);
      setCUIT(userInfo["cuit"]);
      setEmail(userInfo["email"]);
      setActive(userInfo["is_active"]);

      setFeedlot(feedlot_instance?.id);
      //setRenspa(userInfo["Renspa"].split("-"));
      setTitular(feedlot_instance?.titular);
      setLocalidad(feedlot_instance?.localidad);
      setPartido(feedlot_instance?.partido);
      setProvincia(feedlot_instance?.provincia);
      //setDestino(userInfo["Destino"]);
      // if (userInfo['almacen']) {
      //   setAlmacen(userInfo['almacen'][0].codigo)
      // }
    }
  }, []);

  const putUser = async (history) => {
    const valRespa = Renspa.length > 1 ? Renspa.join("-") : Renspa[0];

    const response = await editUser(
      id,
      nameValue,
      passwordValue,
      passwordValue2,
      email,
      nameValue,
      lastNameValue,
      cuit,
      razonSocial,
      rol,
      tipo,
      sector,
      legajo,
      bp,
      telefono,
      active,
      enviarEmail,
      history,
      setIsLoading,
      setError,
      "",
      feedlot,
      valRespa,
      titular,
      Localidad,
      Partido,
      Provincia,
      Destino,
      "",
      null,
      almacen
    );
    if ((response.status = 200)) {
      history.push(`/app/tables`);
    }
  };
  return (
    <>
      <Grid alignContent="center" container spacing={1} lg={12} xs={12} sm={12}>
        {/* <Grid item lg={6} xs={12} sm={12}>
          <InputLabel id="type">Feedlot</InputLabel>
          <Select
            labelId="type"
            variant="outlined"
            id="Feedlot"
            value={feedlot}
            label="Feedlot"
            onChange={(e)=>{setFeedlot(e.target.value)}}
            fullWidth
          >
              {feedlotDesp.map(link => {
              return <MenuItem value={link.Destino+'-'+link.Renspa}>{link.Destino+'-'+link.Renspa}</MenuItem>}
              
              )}
          </Select>
        </Grid> */}
        {error && (
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {Object.keys(error).map((key) => {
                const items = error[key].map((item) => <li>{item}</li>);
                return (
                  <Alert
                    severity="error"
                    variant="outlined"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setError(null);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    <AlertTitle>
                      <strong>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </strong>
                    </AlertTitle>
                    <ul>{items}</ul>
                  </Alert>
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid item alignItems="center" lg={6} xs={12} sm={12}>
          <TextField
            id="cuit"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={cuit}
            onChange={(e) => {
              setCUIT(e.target.value);
              setEmail(e.target.value + "@" + e.target.value + ".com");
            }}
            margin="normal"
            variant="outlined"
            label="CUIT"
            placeholder="CUIT"
            type="number"
            className={classes.cuitInput}
            fullWidth
            required
            onInput={(e) => {
              //setear longitud maxima del cuit
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 11);
            }}
          />
        </Grid>

        <Grid item lg={6} md={12} xs={12} sm={12}>
          <TextField
            id="Titular"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={titular}
            onChange={(e) => setTitular(e.target.value)}
            margin="normal"
            placeholder="Titular"
            variant="outlined"
            label="Titular"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item lg={6} xs={12} sm={12}>
          <TextField
            id="Nombre"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
            margin="normal"
            placeholder="Nombre"
            variant="outlined"
            label="Nombre"
            type="text"
            fullWidth
          />
        </Grid>
        {/* <Grid item lg={6} md={12} xs={12} sm={12}>
          <InputLabel>Renspa</InputLabel>

          <Select
            labelId="type"
            variant="outlined"
            id="Renspa"
            value={Renspa}
            label="Renspa"
            onChange={(e) => {
              setRenspa(e.target.value);
            }}
            renderValue={(selected) => selected.join("-")}
            fullWidth
            multiple
          >
            {feedlotDesp.map((val) => {
              return (
                <MenuItem key={val.Renspa} value={val.Renspa}>
                  <Checkbox
                    color="primary"
                    checked={Renspa.indexOf(val.Renspa) > -1}
                  />
                  <ListItemText primary={val.Renspa} />
                </MenuItem>
              );
            })}
          </Select>
        </Grid> */}
        <Grid item lg={6} xs={12} sm={12}>
          <TextField
            id="Localidad"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={Localidad}
            onChange={(e) => setLocalidad(e.target.value)}
            margin="normal"
            placeholder="Localidad"
            variant="outlined"
            label="Localidad"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item lg={6} xs={12} sm={12}>
          <TextField
            id="Partido"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={Partido}
            onChange={(e) => setPartido(e.target.value)}
            margin="normal"
            placeholder="Partido"
            variant="outlined"
            label="Partido"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item lg={6} xs={12} sm={12}>
          <TextField
            id="Provincia"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={Provincia}
            onChange={(e) => setProvincia(e.target.value)}
            margin="normal"
            placeholder="Provincia"
            variant="outlined"
            label="Provincia"
            type="text"
            fullWidth
          />
        </Grid>
        {/* <Grid item lg={6} xs={12} sm={12}>
          <InputLabel>Destino</InputLabel>
          <Select
            labelId="type"
            variant="outlined"
            id="Destino"
            value={Destino}
            placeholder="Destino"
            label="Destino"
            onChange={(e) => {
              setDestino(e.target.value);
            }}
            fullWidth
          >
            <MenuItem value="INVERNADA UE">INVERNADA UE</MenuItem>
            <MenuItem value="INVERNADA UE 481">INVERNADA UE 481</MenuItem>
          </Select>
        </Grid> */}
        {/* <Grid container item lg={12} md={12} xs={12} sm={12}>
          <Grid item lg={6} md={12} xs={12} sm={12}>
            <InputLabel>Almacen</InputLabel>

            <Select
              labelId="type"
              variant="outlined"
              id="almacen"
              value={almacen}
              label="Almacen"
              onChange={(e) => {
                setAlmacen(e.target.value);
              }}
              fullWidth
            >
              {almacenes?.map((val) => {
                return (
                  <MenuItem key={val.codigo} value={val.codigo}>
                    {`${val.codigo} - ${val.descripcion}`}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            id="contraseña"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            margin="normal"
            variant="outlined"
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="contraseña2"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordValue2}
            onChange={(e) => setPassword2Value(e.target.value)}
            margin="normal"
            variant="outlined"
            label="Repita Contraseña"
            placeholder="Repita Contraseña"
            type="password"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid
        alignContent="center"
        container
        spacing={1}
        lg={12}
        xs={12}
        sm={12}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item lg={3} xs={12} sm={12}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
            <Button
              fullWidth
              onClick={() => {
                setIsLoading(true);

                if (id != "") {
                  putUser(props.history);
                } else {
                  const valRespa =
                    Renspa.length > 1 ? Renspa.join("-") : Renspa[0];

                  register(
                    nameValue,
                    passwordValue,
                    passwordValue2,
                    email,
                    nameValue,
                    lastNameValue,
                    cuit,
                    razonSocial,
                    rol,
                    tipo,
                    sector,
                    legajo,
                    bp,
                    telefono,
                    props.history,
                    setIsLoading,
                    setError,
                    feedlot,
                    valRespa,
                    titular,
                    Localidad,
                    Partido,
                    Provincia,
                    Destino,
                    null,
                    null,
                    almacen
                  );
                }
              }}
              disabled={
                nameValue.length === 0 ||
                cuit.length === 0 ||
                (passwordValue !== passwordValue2)
              }
              size="large"
              variant="contained"
              color="primary"
              className={classes.createAccountButton}
              //component={Link} to={`/app/tables`}
            >
              Guardar
            </Button>
          )}
        </Grid>
        <Grid item lg={3} xs={12} sm={12}>
          <Button
            className={classes.createAccountButton}
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={`/app/tables`}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default withRouter(NuevoUsuarioFeedlot);
