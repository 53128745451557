import { memo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useStyles } from "./styles";
import {
  Typography,
} from "@material-ui/core";

const MarcaComercialAgrup = ({ theme, headerName, data }) => {
  const classes = useStyles();

  function DataGridTitle() {
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.tables.header,
          color: theme.palette.tables.headerText,
        }}
      >
        <Typography variant="h6">{headerName}</Typography>
      </Box>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "Calidad",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
    },
  ];
  const rows = [];

  Object.keys(data).forEach((key) => {
    columns.push({
      field: key,
      headerName: key,
      flex: 1,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
    });
    Object.keys(data[key]).forEach((subKey) => {
      const rowIndex = rows.findIndex((r) => r.id === subKey);
      if (rowIndex === -1) {
        const row = { id: subKey };
        row[key] = data[key][subKey];
        rows.push(row);
      } else {
        rows[rowIndex][key] = data[key][subKey];
      }
    });
  });

  // totales
  const sumRow = { id: "Total" };
  columns.forEach((column) => {
    if (column.field !== "id") {
      sumRow[column.field] = rows.reduce(
        (acc, row) => acc + (row[column.field] || 0),
        0,
      );
    }
  });
  rows.push(sumRow);

  return (
    <Box
      sx={{
        width: "100%",
        "& .TOTALES": {
          backgroundColor: theme.palette.totals.total,
          fontWeight: "bold",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        data={data}
        components={{ Toolbar: DataGridTitle }}
        pañosSize={11}
        autoHeight
        hideFooter={true}
        rowHeight={25}
        headerHeight={25}
        sx={{
          color: "inherit",
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: theme.palette.tables.tableBorderColor,
            borderRight: 0,
            borderTop: 0,
            // add more css for customization
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            borderColor: theme.palette.tables.tableBorderColor,
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid " + theme.palette.tables.tableBorderColor,
          },
          "& .MuiDataGrid-iconSeparator": {
            color: theme.palette.tables.tableBorderColor,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "1px solid " + theme.palette.tables.tableBorderColor,
            maxHeight: "0px",
            minHeight: "0px",
          },
          border: "2px solid " + theme.palette.tables.tableBorderColor,
        }}
        onEstadisticas
        hideFooterPagination
        hideFooterSelectedRowCount
        rowsPerPañosOptions={[11]}
        getCellClassName={(params) => {
          // `super-app-theme--${params.row.Categoria}`

          if (params.row.id === "Total") {
            return "TOTALES";
          }
        }}
      />
    </Box>
  );
};

export default memo(MarcaComercialAgrup);
