import React from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

export const CustomSearchButton = ({ fechaStart, fechaEnd, onClick, color, margin }) => {

  const handleOnClick = () => {
    onClick(fechaStart, fechaEnd);
  };

  return (
    <Tooltip title="Buscar">
      <IconButton onClick={handleOnClick} variant="contained" sx={{margin: margin}}>
        <SearchIcon sx={{ color: color}} fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};
