
import {
    YAxis,
    XAxis,
    Legend,
    Tooltip, BarChart, Bar, ResponsiveContainer, LabelList, ComposedChart, Line
  } from "recharts";
  import useStyles from "./styles";
  
  
  const CabezasAgrupadoUsuario = ({ data, colors }) => {
      const classes = useStyles();
  
  
  
      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className={classes.tooltip}>
              <p className={classes.tooltipLabel}>{`${label}`}</p>
              {payload.map(pay => {
                return <p style={{ color: pay.stroke }} className="label">{`${pay.name} : ${pay.value}`}</p>
              })}
    
              {/*         <p className="desc">Anything you want can be displayed here.</p>
     */}      </div>
          );
        }
    
        return null;
      };
  
      //console.log(data)
      
      return (
        <ResponsiveContainer width="100%" minWidth={500} height={350}>
        <ComposedChart
                  data={data}
                  width="100%" minWidth={500} height={350}
                >
                  <XAxis dataKey="nombreMes" domain={["dataMin", "dataMax + 1"]} />
                  <YAxis interval="preserveEnd" domain={['auto', 'auto']} />
  
                  
                    
                  <Bar
                    type="linear"
                    dataKey="FR-Exportación"
                    stroke={colors[1]}
                    stackId={1}
                    fill={colors[1]}
  
                    strokeWidth={2}
                    dot={{
                      stroke: colors[1],
                      strokeWidth: 2,
                      fill: colors[1],
                    }}
                    >
                    <LabelList
                      dataKey="FR-Exportación"
                      position="inside"
                      fontWeight="bold"
                      padding='10px'
                    />
                   
                  </Bar>
                  <Bar
                    type="linear"
                    dataKey="FR-Otros Países"
                    stroke={colors[2]}
                    stackId={1}
                    fill={colors[2]}
  
                    strokeWidth={2}
                    dot={{
                      stroke: colors[2],
                      strokeWidth: 2,
                      fill: colors[2],
                    }}
                    >
                    <LabelList
                      dataKey="FR-Otros Países"
                      position="inside"
                      fontWeight="bold"
                      padding='10px'
                    />
                    
                  </Bar>

                  <Bar
                    type="linear"
                    dataKey="Inc"
                    stroke={colors[3]}
                    stackId={1}
                    fill={colors[3]}
  
                    strokeWidth={2}
                    dot={{
                      stroke: colors[3],
                      strokeWidth: 2,
                      fill: colors[3],
                    }}
                    >

                  </Bar>
                  <Bar
                    type="linear"
                    dataKey="FR-Consumo"
                    stroke={colors[4]}
                    stackId={1}
                    fill={colors[4]}
  
                    strokeWidth={2}
                    dot={{
                      stroke: colors[4],
                      strokeWidth: 2,
                      fill: colors[4],
                    }}
                    >
                    
                  </Bar>
                  <Bar
                    type="linear"
                    dataKey="FR-Carne Cocida"
                    stroke={colors[5]}
                    stackId={1}
                    fill={colors[5]}
  
                    strokeWidth={2}
                    dot={{
                      stroke: colors[5],
                      strokeWidth: 2,
                      fill: colors[5],
                    }}
                    >
                    
                  </Bar>
                  <Bar
                    type="linear"
                    dataKey="FR-41"
                    stroke={colors[6]}
                    stackId={1}
                    fill={colors[6]}
  
                    strokeWidth={2}
                    dot={{
                      stroke: colors[6],
                      strokeWidth: 2,
                      fill: colors[6],
                    }}
                    >
                    
                  </Bar>
                  <Line
                    type="monotone"
                    dataKey="Cabezas"
                    stroke={colors[1]}
                    strokeWidth={2}
                    dot={{
                      stroke: colors[1],
                      strokeWidth: 2,
                      fill: colors[1],
                    }}
                  />
                  
                  <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
                  <Legend />
                </ComposedChart>
                </ResponsiveContainer>
  
      )
  }
  
  export default CabezasAgrupadoUsuario