import React, { useState, useContext, useEffect, memo } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  Tooltip,
  TextField, Link,
  InputLabel, ListItemText,
  Typography, useMediaQuery,
  FormControlLabel, Checkbox
} from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import { Autocomplete } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import UserService from '../../context/UserService';
import useStyles from "./styles";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UserStateContext from '../../context/UserContext';
import Box from '@mui/material/Box';
import Dropzone from 'react-dropzone-uploader'
import { set } from 'date-fns';
import ResponsiveDatePickers from "../dateRange/datePicker";
import moment from "moment";
import { useTheme } from "@material-ui/styles";

import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import FormInvOrigen from "../formInvOrigen/FormInvOrigen";
import FormInvDestino from "../formInvDestino/FormInvDestino";
import FormAjustes from "../formAjustes/FormAjustes";

const ToggleButton = styled(MuiToggleButton)(({ textColor, selectedColor, borderColor }) => ({
  color: 'inherit',
  borderColor: borderColor,
  borderRadius: '15px',
  maxHeight: '60px',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: textColor,
    backgroundColor: selectedColor,
  },
}));

const EditCompraInv =  memo(function EditCompraInv({dataOriginal, idCompra, ...props}) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
  var classes = useStyles();
  var rol = localStorage.getItem('permissions')
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const datosCompra = dataOriginal.find((element) => element.id === idCompra);

  // local

  const [id, setID] = useState(null);
  let [dataForm, setDataForm] = useState("");
  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  const { user } = useContext(UserStateContext);
  const permissions = user.permissions;
  const isInterno = user.type === 'Rioplatense'
  const isFeedlot = user.feedlot_id !== null
  const isContactoCarga = user.contacto_carga_id !== null

  var [tipo, setTipo] = useState(() => {
    if (permissions.feedlot_can_edit_origen === true) {
      return('Origen')
    } else if (permissions.feedlot_can_edit_destino === true) {
      return('Destino')
    } else if (permissions.feedlot_can_edit_ajustes === true) {
      return('Ajustes')
    }
  });

  //manejo de tiempos del servidor
  const [errorServidor, setErrorServidor] = useState(null);
  const [mostrarToogle, setMostrarToogle] = useState(true)
  const [alignment, setAlignment] = useState(tipo);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleHasBeenEdited = () => {
    setHasBeenEdited(true);
  }

  useEffect(() => {
    setDataForm(props.dataRow)

    // if (isContactoCarga) {
    //   setMostrarToogle(false)
    //   setTipo('Origen')
    // } else if (isFeedlot) {
    //   setMostrarToogle(false)
    //   setTipo('Destino')
    // }

  }, []);



  const handleClickOpen = (event) => { setOpen(true); };


  const handleClose = () => {
    if (hasBeenEdited) {
      props.cargarData();
    }
    setOpen(false);
  };


  return (
    <>
    <Tooltip title="Editar">
      <IconButton variant="text" color='primary' size="small" onClick={handleClickOpen}>
        <EditIcon color='primary' style={{ color: theme.palette.primary.main }} />
      </IconButton>
    </Tooltip>
      <UserStateContext.Consumer>
        {({ darkMode }) => (
          darkMode == true ? (
            <Dialog
              fullScreen
              maxWidth={true}
              open={open}
              scroll='body'
              onClose={handleClose}
              className={classes.overflow}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                style: {
                  '& .MuiPaperRoot': { bgcolor: "pink", maxWidth: "revert", width: 1200 },
                  "&$.MuiOutlinedInputRoot.MuiDisabled": {
                    borderColor: theme.palette.tables.tableBorderColor,
                  },
                  '&$.MuiDisabled': {borderColor: theme.palette.tables.tableBorderColor + '!important'},
                  color: theme.palette.text.primary, borderColor: theme.palette.tables.tableBorderColor,

                  '&$.MuiPaperRoot.MuiDialogPaper': {
                    backgroundColor: theme.palette.background.main//'rgb(35, 35, 45)',

                  },
                  backgroundColor: theme.palette.background.main//'rgb(35, 35, 45)',



                },
              }}
            >
              {/* <Toolbar>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <Button autoFocus color="inherit" onClick={handleClose}>
                  Cerrar
                </Button>
                <Grid container>


                </Grid>

              </Toolbar> */}


              <DialogContent
                //theme={theme}
                className={classes.overflow}
                sx={{ overflowY: 'initial' }}
              >

                <Typography color="secondary" className={classes.errorMessage}>
                  {errorServidor}
                </Typography>
                <Box sx={{ height: '24rem', width: '100%', }}>
                  <Grid container spacing={2}>
                    {/* <Grid item lg={12}>
                      <Typography variant='h5'>
                        Editar Documento
                      </Typography>
                    </Grid> */}

                    <Grid item spacing={2} lg={12} xs={12} sm={12} md={12}>
                      {mostrarToogle &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <InputLabel id="type">Tipo de Formulario</InputLabel>
                          <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                          >
                            {permissions.feedlot_can_edit_origen && (
                            <ToggleButton value="Origen" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('Origen') }}>Origen</ToggleButton>
                            )}
                             {permissions.feedlot_can_edit_destino && (
                            <ToggleButton value="Destino" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('Destino') }}>Destino</ToggleButton>
                            )}
                            {permissions.feedlot_can_edit_ajustes && (
                            <ToggleButton value="Ajustes" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('Ajustes') }}>Ajustes</ToggleButton>
                            )}
                          </ToggleButtonGroup>
                        </Grid>


                      }



                      {(tipo == 'Origen' && permissions.feedlot_can_edit_origen) &&
                        <Grid item lg={12} xs={12} sm={12} md={12}>
                          <FormInvOrigen
                            datosCompra={datosCompra}
                            cerrar={handleClose}
                            servidor={props.cargarData}
                            handleHasBeenEdited={handleHasBeenEdited}
                            hasBeenEdited={hasBeenEdited}
                            compraId={idCompra}
                          ></FormInvOrigen>
                        </Grid>
                      }
                      {(tipo == 'Destino' && permissions.feedlot_can_edit_destino) &&
                        <Grid item lg={12} xs={12} sm={12} md={12}>
                          <FormInvDestino
                            cerrar={handleClose}
                            servidor={props.cargarData}
                            datosCompra={datosCompra}
                            handleHasBeenEdited={handleHasBeenEdited}
                            hasBeenEdited={hasBeenEdited}
                            compraId={idCompra}
                          ></FormInvDestino>
                        </Grid>
                      }
                      {(tipo == 'Ajustes' && permissions.feedlot_can_edit_ajustes) &&
                        <Grid item lg={12} xs={12} sm={12} md={12}>
                          <FormAjustes
                            cerrar={handleClose}
                            servidor={props.cargarData}
                            datosCompra={datosCompra}
                            handleHasBeenEdited={handleHasBeenEdited}
                            hasBeenEdited={hasBeenEdited}
                            compraId={idCompra}
                          ></FormAjustes>
                        </Grid>
                      }


                    </Grid>


                  </Grid>

                </Box>
              </DialogContent>

            </Dialog>) : (
            <Dialog
              fullScreen
              maxWidth={true}
              open={open}
              scroll='body'
              onClose={handleClose}
              className={classes.overflow}
              aria-labelledby="responsive-dialog-title"

            >
              {/* <Toolbar>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <Button autoFocus color="inherit" onClick={handleClose}>
                  Cerrar
                </Button>
                <Grid container>


                </Grid>

              </Toolbar> */}


              <DialogContent
                //theme={theme}
                className={classes.overflow}
                sx={{ overflowY: 'initial' }}
              >

                <Typography color="secondary" className={classes.errorMessage}>
                  {errorServidor}
                </Typography>
                <Box sx={{
                  height: '24rem', width: '100%', /* "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: 'blue',
        }, */
                  /*  '& .MuiFormLabel-root.Mui-disabled': {
                     color: 'blue',
                   }, */

                }}>
                  <Grid container spacing={2}>
                    {/* <Grid item lg={12}>
                      <Typography variant='h5'>
                        Editar Documento
                      </Typography>
                    </Grid> */}

                    <Grid container spacing={2}>
                      {mostrarToogle &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <InputLabel id="type">Tipo de Formulario</InputLabel>
                          <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                          >
                            {permissions.feedlot_can_edit_origen && (
                            <ToggleButton value="Origen" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('Origen') }}>Origen</ToggleButton>
                            )}
                            {permissions.feedlot_can_edit_destino && (
                            <ToggleButton value="Destino" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('Destino') }}>Destino</ToggleButton>
                            )}
                            {permissions.feedlot_can_edit_ajustes && (
                            <ToggleButton value="Ajustes" aria-label="left aligned" borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} onClick={() => { setTipo('Ajustes') }}>Ajustes</ToggleButton>
                            )}
                          </ToggleButtonGroup>
                        </Grid>


                      }
                      {(tipo == 'Origen' && permissions.feedlot_can_edit_origen) &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormInvOrigen
                            servidor={props.cargarData}
                            datosCompra={datosCompra}
                            cerrar={handleClose}
                            handleHasBeenEdited={handleHasBeenEdited}
                            hasBeenEdited={hasBeenEdited}
                            compraId={idCompra}
                          ></FormInvOrigen>
                        </Grid>
                      }
                      {(tipo == 'Destino' && permissions.feedlot_can_edit_destino) &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormInvDestino
                            cerrar={handleClose}
                            servidor={props.cargarData}
                            datosCompra={datosCompra}
                            handleHasBeenEdited={handleHasBeenEdited}
                            hasBeenEdited={hasBeenEdited}
                            compraId={idCompra}
                          ></FormInvDestino>
                        </Grid>
                      }
                      {(tipo == 'Ajustes' && permissions.feedlot_can_edit_ajustes) &&
                        <Grid item lg={12} xs={12} sm={6}>
                          <FormAjustes
                            cerrar={handleClose}
                            servidor={props.cargarData}
                            datosCompra={datosCompra}
                            handleHasBeenEdited={handleHasBeenEdited}
                            hasBeenEdited={hasBeenEdited}
                            compraId={idCompra}
                          ></FormAjustes>
                        </Grid>
                      }


                    </Grid>


                  </Grid>



                </Box>
              </DialogContent>

            </Dialog>)
        )}
      </UserStateContext.Consumer>

    </>
  );
})

export default EditCompraInv;


