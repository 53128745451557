import React, { useContext } from "react";
import {
  Route,
  Switch,
  //Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
/* import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react' */

//icons
/* import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'
 */
// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
/*import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps"; */
/* import Icons from "../../pages/icons";
import Charts from "../../pages/charts"; */
import Usuarios from "../../pages/users";
import Stock from "../../pages/stock/Stock";
import Romaneo from "../../pages/romaneo/Romaneo";
import HomeIndex from "../../pages/homeIndex/HomeIndex";
import Compras from "../../pages/compras/Compras";
import NuevasCompras from "../../pages/nuevasCompras/nuevasCompras";
import Tables from "../../pages/tables/Tables";
import EditCompra from "../../pages/editCompra/EditCompra";
import UsuariosDeCarga from "../../pages/usuariosDeCarga/UsuariosDeCarga";
import NuevoUsuarioDeCarga from "../../pages/nuevoUsuarioDeCarga/NuevoUsuarioDeCarga";
import EditUsuariosDeCarga from "../../pages/editUsuariosDeCarga/EditUsuariosDeCarga";
import Dte from "../../pages/dte/Dte";
import EsatdoCompras from "../../pages/estadoCompras/EsatdoCompras";
import Checklist from "../../pages/checkList/Checklist";
import GestionDoc from "../../pages/GestionDoc/GestionDoc";
import NuevoDoc from "../../pages/nuevoDoc/NuevoDoc";
import PruebaInformeGeneal from "../../pages/pruebaInformGen/PruebaInformeGeneal";
import FeedLot from "../../pages/feedlot/FeedLot";
import Cashflow from "../../pages/cashflow/Cashflow";
// context
import { useLayoutState } from "../../context/LayoutContext";
//import UserList from "../userList/userList";
import Editar from "../../pages/editar/Edit";
import Profile from "../../pages/profile/profile";
import UserStateContext from "../../context/UserContext";



function Layout(props) {
  var classes = useStyles();
  var rol = localStorage.getItem('permissions')
  const user = useContext(UserStateContext);

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} darkmode={props} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>

              {/* <Route path="/app/Feedlot" component={FeedLot} /> */}
              

            {(user.user.permissions.usuarios_can_view) &&
              <Route path="/app/tables" component={Tables} />}

            {(user.user.permissions.stock_can_view) &&
              <Route path="/app/stock" component={Stock} />}

            {(user.user.permissions.faena_can_view) &&
              <Route path="/app/Romaneo" component={Romaneo} />}

            {(user.user.permissions.usuarios_can_edit) &&
              <Route path="/app/edit" component={Editar} />}

            {(user.user.permissions.usuarios_can_view) &&
              <Route path="/app/users" component={Usuarios} />}

            {(user.user.permissions.compras_can_create) &&
              <Route path="/app/nuevasCompras" component={NuevasCompras} />}
            {(user.user.permissions.compras_can_edit) &&
              <Route path="/app/editCompra" component={EditCompra} />}

            {(user.user.permissions.compras_can_view) &&
            <Route path="/app/estadoCompras" component={EsatdoCompras} />}

            {/* {(rol == 6 || rol == 1 || rol == 2) &&
              <Route path="/app/usuariosDeCarga" component={UsuariosDeCarga} />}
            {(rol == 6 || rol == 1 || rol == 2) &&
              <Route path="/app/nuevoUsuariosDeCarga" component={NuevoUsuarioDeCarga} />}
            {(rol == 6 || rol == 1 || rol == 2) &&
              <Route path="/app/editUsuariosDeCarga" component={EditUsuariosDeCarga} />} */}
            
            {(user.user.permissions.dte_can_view) &&
              <Route path="/app/dte" component={Dte} />}
            
            {(user.user.permissions.checklist_can_view) &&
              <Route path="/app/checkList" component={Checklist} />}
            
            {(user.user.permissions.gestionDoc_can_view) && 
              <Route path="/app/gestionDoc" component={GestionDoc} />
            }
            {(user.user.permissions.gestionDoc_can_view) &&
            <Route path="/app/nuevoDoc" component={NuevoDoc} />
            }

            {(user.user.permissions.dashboard_can_view) &&

              <Route path="/app/dashboard" component={Dashboard} />}


            {(user.user.permissions.feedlot_can_view) &&
              <Route path="/app/Feedlot" component={FeedLot} />}

            {(user.user.permissions.cashflow_can_view) && 
              <Route path="/app/Cashflow" component={Cashflow} />
            }


            {<Route path="/app/profile" component={Profile} />}















          </Switch>

        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
