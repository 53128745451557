import React, { useState, useContext, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  //InputBase,
  Menu,
  MenuItem,
  //Fab,


} from "@material-ui/core";
import Switch from '@mui/material/Switch';
import UserStateContext from "../../context/UserContext";
import { useLocation } from 'react-router-dom';


import { UserProvider } from "../../context/UserContext";

import Stack from '@mui/material/Stack';
import {
  Menu as MenuIcon,
  //MailOutline as MailIcon,
  //NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  //Search as SearchIcon,
  //Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import { Link } from "react-router-dom";
import logoWhite from "../../images/RP_Logo.svg";//"../../images/logo.png";
// styles
import useStyles from "./styles";
import jwt_decode from "jwt-decode";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';

// components
import { Typography } from "../Wrappers";
import Notification from "../Notification/Notification";
//import UserAvatar from "../UserAvatar/UserAvatar";
import UserService from "../../context/UserService";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { signOut } from "../../context/UserContext";
import { useTheme } from "@material-ui/styles";
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid'
import { useMediaQuery } from "@material-ui/core";

/* const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];
*/
const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];




export default function Header(props) {
  var classes = useStyles();
  const theme = useTheme();
  //var rol = localStorage.getItem('permissions')
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  var username = JSON.parse(localStorage.getItem("userData"))
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(UserStateContext)

  const rol = user.rol
  // landing

  const landing = (rol === 1 || rol === 2 || rol === 3) ? '/app/estadoCompras' : (rol === 5 || rol === 6 || rol === 3) ? "/app/dashboard" : (rol === 4) ? "/app/gestionDoc" : "/app/Feedlot"

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  // local
  //var [mailMenu, setMailMenu] = useState(null);
  //var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  //var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  //var [isSearchOpen, setSearchOpen] = useState(false);

  const { setDarkMode } = useContext(UserStateContext);
  const { darkMode } = useContext(UserStateContext);
  const saveThemeUser = (val) => {
    if (val == true) {
      //darkMode
      UserService.putThemeUser(username.id, 'light')
      const user = username
      user.theme = 'light'
      localStorage.setItem('userData', JSON.stringify(user))


    } else {
      //lightmode
      UserService.putThemeUser(username.id, 'dark')
      const user = username
      user.theme = 'dark'
      localStorage.setItem('userData', JSON.stringify(user))



    }
  }

  useEffect(() => {
    if (username.theme == 'dark') {
      //darkMode
      setDarkMode(true)

    } else {
      //lightmode
      setDarkMode(false)

    }

  }, []);


  // titulo en funcion de la pagina
  const titles = [
    { path: "/app/tables", dest: "Usuarios" },
    { path: "/app/users", dest: "Nuevo Usuario" },
    { path: "/app/profile", dest: "Perfil" },
    { path: "/app/nuevasCompras", dest: "Nueva Compra" },
    { path: "/app/editCompra/", dest: "Editar Compra" },
    { path: "/app/dte", dest: "Dte Compras" },
    { path: "/app/estadoCompras", dest: "Compras" },
    { path: "/app/checkList", dest: "Checklist" },
    { path: "/app/gestionDoc", dest: "Gestión Documental" },
    { path: "/app/nuevoDoc", dest: "Nuevo Documento" },
    { path: "/app/dashboard", dest: "Dashboard" },
    { path: "/app/Feedlot", dest: "Feedlot" },
    { path: "/app/romaneo", dest: "Faena" },
    { path: "/app/stock", dest: "Stock Corrales" },
    { path: "/app/edit/", dest: "Editar Usuario" }
  ]

  const getTitulo = (path) => {
    const titulo = titles.find(title => path.includes(title.path))
    return titulo ? titulo.dest : "";
  }



  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={2} justifyContent='space-between' style={{ alignItems:'center', display:'flex' }}>
          <Grid item lg={3} md={3} sm={3} xs={3} >
            <div style={{ display: 'flex', marginBottom: '4px' }}>
              <div style={{ verticalAlign: 'center', alignItems: 'center', display: 'flex' }}>
                <IconButton
                  color="inherit"
                  onClick={() => toggleSidebar(layoutDispatch)}
                  className={classNames(
                    classes.headerMenuButtonSandwich,
                    classes.headerMenuButtonCollapse,
                  )}
                >
                  {layoutState.isSidebarOpened ? (
                    <ArrowBackIcon
                      classes={{
                        root: classNames(
                          classes.headerIcon,
                          classes.headerIconCollapse,
                        ),
                      }}
                    />
                  ) : (
                    <MenuIcon
                      classes={{
                        root: classNames(
                          classes.headerIcon,
                          classes.headerIconCollapse,
                        ),
                      }}
                    />
                  )}
                </IconButton>
              </div>
              <div >
                <Link onClick={() => { history.push(landing) }} href={landing}>
                  <img src={logoWhite} alt="Logo" className={classes.logo} width="60px" height="50px" />
                </Link>
              </div>
            </div>

          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6} textAlign="center">
            {/* <div style={{ display: 'flex', alignItems: 'center', flexGrow: 15, justifyContent: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}> */}
            <Typography variant={isSmallScreen ? "h4" : "h2"} className={classes.titulo}>{getTitulo(location.pathname)}</Typography>
            {/* </div>
            </div> */}

          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3} textAlign="right" style={{display: 'flex', alignItems: 'center'}}>
            {/* <div className={classes.grow} /> */}
            <div className={classes.grow} style={{ display: 'inline-block', color: theme.palette.tables.headerText }}>
              
              {!isSmallScreen && (
                <IconButton
                onClick={() => {setDarkMode(value => !value); saveThemeUser(darkMode)}}
              >
                {darkMode ? <LightModeIcon style={{ color: theme.palette.primary.main }} /> : <DarkModeIcon style={{ color: theme.palette.primary.main }} />}
              </IconButton>
              )}

              {!isSmallScreen && (
              <Typography
                classes={classes.font}
                size="small"
                variant="p" weight="small">
                {(rol != 4) ? ((username['username'] != null ? username['username'] : '') + ' ' + (username['last_name'] != null ? username['last_name'] : '')) : (username['razonS'])}

              </Typography>
              )}



              <IconButton
                aria-haspopup="true"
                color="inherit"
                className={classes.headerMenuButton}
                aria-controls="profile-menu"
                onClick={e => setProfileMenu(e.currentTarget)}
              >
                <AccountIcon classes={{ root: classes.headerIcon }} />
              </IconButton>
            </div>
            <Menu
              id="notifications-menu"
              open={Boolean(notificationsMenu)}
              anchorEl={notificationsMenu}
              onClose={() => setNotificationsMenu(null)}
              className={classes.headerMenu}
              disableAutoFocusItem
            >
              {notifications.map(notification => (
                <MenuItem
                  key={notification.id}
                  onClick={() => setNotificationsMenu(null)}
                  className={classes.headerMenuItem}
                >
                  <Notification {...notification} typographyVariant="inherit" />
                </MenuItem>
              ))}
            </Menu>
            <Menu
              id="profile-menu"
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              onClose={() => setProfileMenu(null)}
              className={classes.headerMenu}
              classes={{ paper: classes.profileMenu }}
              disableAutoFocusItem
            >
              {/* <div className={classes.profileMenuUser}>
                <Typography variant="h4" weight="medium">
                  {(rol == 7) ? (username['cuit']) : (rol == 8) ? (username['cuit']) : (username['email'])}
                </Typography>

              </div> */}
              <MenuItem
                open={Boolean(profileMenu)}
                onClick={() => setProfileMenu(null)}

                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,

                )}
                component={Link} to={`/app/profile`}

              >
                <AccountIcon color='primary' className={classes.profileMenuIcon} /> Perfil
              </MenuItem>
              {isSmallScreen && (
              <MenuItem
                open={Boolean(profileMenu)}
                onClick={() => setProfileMenu(null)}

                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,

                )}

              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <LightModeIcon style={{ color: theme.palette.primary.main }} />

                  <Switch checked={darkMode} size="small" style={{ color: theme.palette.primary.main, backgroundColor: theme.palette.background.custom }} onChange={() => { setDarkMode(value => !value); saveThemeUser(darkMode) }}></Switch>

                  <DarkModeIcon style={{ color: theme.palette.primary.main }} />
                </Stack>
              </MenuItem>
              )}
              {/*<MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Tasks
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Messages
            </MenuItem> */}
              {/* <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(props.history)}
            >
              Salir
            </Typography>
          </div> */}
              <MenuItem
                onClick={() => signOut(props.history)}

                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,

                )}

              >
                <LogoutIcon style={{ color: theme.palette.primary.main }} className={classes.profileMenuIcon} /> Salir
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
