import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({
  a: {
    padding: '2px 3px',
    margin: '3px',
    fontSize: '0.7rem'
  },
  confirm: {
    margin: '15px',

  },
  title: {
    margin: '50px'
  },
  buttonModal: {
    margin: '10px'
  },
  contained: {
    maxWidth: '100%',
  },
  Modal: {

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: theme.palette.background.paper,

    border: '2px solid #000',
    boxShadow: 1,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center',
  },
  centerContent: {
    textAlign: 'center',
    justifyContent: 'center'
  }

}));
