import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({


  overflow: {
    overflowY: 'initial'
  },
  button: {
    marginTop: '20px',
    color: theme.palette.primary.main,
    bgcolor: theme.palette.primary.main
  },
  headerCell: {
    fontWeight: '800',
  },
  logo: {
    maxWidth: 100,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    marginRight: 30
  },
}));