import React, { useState,useContext, useEffect} from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  Fade,
  FormControlLabel,
  FormGroup,Checkbox,
  CircularProgress
}from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import moment from "moment";
import Dropzone from 'react-dropzone-uploader'
import { useHistory } from 'react-router';
import DownloadIcon from '@mui/icons-material/Download';
import { Preview } from "../variables/variables";

function FormBienAnimal(props) {
  var user = JSON.parse((localStorage.getItem('userData')))
  const history = useHistory();
  var classes = useStyles();
  var rol = localStorage.getItem('permissions')

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState('');
  const [openSubirArchivo,setOpenSubirArchivo]= React.useState(false);
  const [ tipoCertificado, setTipoCertificado]= useState('')
  var [email, setEmail] = useState(()=>{if(user.email!=null && user.rol==4){return user.email}else{ return ''}});


  
  //var userDispatch = useUserDispatch();
  var [fechaAlta, setFechaAlta] = useState(moment().format('YYYY-MM-DD'))
  var [fechaVencimiento,setFechaVencimiento] = useState(moment().add(1,'year').format('YYYY-MM-DD'))


  // local
  var [cuit, setCuit]= useState();
  var [cuit, setCuit]= useState(()=>{if(user.cuit!=null){return user.cuit}else{ return ''}});
  var [nombreCompania, setNombreCompania] = useState(()=>{if(user.razonS!=null){return user.razonS}else{ return ''}});

  var [localidad, setLocalidad] = useState("");
  var [provincia, setProvincia] = useState("");
  var [politicaBienestar, setPoliticaBienestar] = useState("");
  var [descPoliticaBienestar, setDescPoliticaBienestar] = useState("");
  var [capacitacion, setCapacitacion]= useState("");
  var [fechaCapacitacion, setFechaCapacitacion]= useState();
  var [manga, setManga]= useState("");
  var [corral, setCorral]= useState("");
  var [rampa, setRampa]= useState("");
  var [manejoAnimales, setManejoAnimales]= useState("");
  var [descripcionManejoAnimales, setDescripcionManejoAnimales]= useState("");
  var [manejoCarga, setManejoCarga]= useState("");
  var [descripcionManejoCarga, setDescripcionManejoCarga]= useState("");
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [posicionUsuario, setPosicionUsuario] = useState('');
  
  // si es editar o post
  var [edit, setEdit] = useState(false)
  var [ID, setID]= useState('')



  
  //manejo de tiempos del servidor
  let [errorServidor, setErrorServidor] = useState(null);
  let [isLoading2, setIsLoading2] = useState(false);
  let [desplegables, setDespletables] = useState([]);
  let [sumarImg,setSumarImg]= React.useState(false);
  let [Image, setImage] = React.useState([]);
  var [valorInicial, setValorInicial]= useState('');

  //checkbox
  const [declaracion,setDeclaracion]=useState(false)
  const [declaracionI,setDeclaracionI]=useState(false)


  const setDate = (event) => {
    setFechaAlta(moment(event).format("YYYY-MM-DD"))   
    setFechaVencimiento(moment(event).add(1,'year').format("YYYY-MM-DD"))  
  }


  const setDateCapacitacion = (event) => {
    setFechaCapacitacion(moment(event).format("YYYY-MM-DD"))   
    //console.log(fecha)
  }
  const getUploadParams = ({ file, meta }) => { return { url: 'https://httpbin.org/post' } }
    
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {   
    if (status === 'headers_received') {

    setImage(file)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false) }
  
  }
  
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files,allFiles) => {

    const fotos = []
    let newData = {  title: "",
    description: "",
    image_url: "", };
  
    files.map(f => fotos.push({
      title: f.meta.name,
    description: f.meta.type,
    image_url: f.meta.previewUrl,
    img:f.file
    }))

    setImage(fotos)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false)

    allFiles.forEach(f => f.remove())
    //setDisableGuardar(false)

  }
  const handleClose = () => {setOpenSubirArchivo(false);};

  //funcion para guardar doc
  const guardarDocumento = async ()=>{
    setErrorServidor(false)
    setError('')
    if (edit==false){
      await UserService.postBienestarAnimales(fechaAlta,fechaVencimiento,cuit,nombreCompania,provincia,localidad,politicaBienestar,descPoliticaBienestar,
        capacitacion,fechaCapacitacion,manga,corral,rampa,manejoAnimales,descripcionManejoAnimales,manejoCarga,
        descripcionManejoCarga,email,declaracion,'xx',1,'FormBienAnimal', nombreUsuario, posicionUsuario).then(
          response=>{
  
            if(response.status==201){
              history.push('/app/gestionDoc')
              props.actualizar()
              props.cerrar()
              
            }
            if(response.status==403){
              setErrorServidor(true)
              setError(<p>Ya hay cargado un documento con el CUIT-RENSPA todavia esta vigente</p>)}
            },
          error=>{  
            if(error.response.status==403){
              setErrorServidor(true)
              setError(<p>Ya hay cargado un documento con el CUIT todavia esta vigente</p>)
            }else{
              setErrorServidor(true)
              setError(<p>Por favor complete todos los campos</p>)
            }
            
          
  
          }
        )
    }else{
      await UserService.putBienestarAnimales(ID,fechaAlta,fechaVencimiento,cuit,nombreCompania,provincia,localidad,politicaBienestar,descPoliticaBienestar,
        capacitacion,fechaCapacitacion,manga,corral,rampa,manejoAnimales,descripcionManejoAnimales,manejoCarga,
        descripcionManejoCarga,email,declaracion,'xx',1,'FormBienAnimal', nombreUsuario, posicionUsuario).then(
          response=>{
  
            if(response.status==200){
              history.push('/app/gestionDoc')
              props.actualizar()
              props.cerrar()

            }
            },
          error=>{
            let errors=[]
            const data = JSON.parse(error.request.response)
            for (const [key, value] of Object.entries(data)){
              if ( key =='cuitProveedor'){
                errors.push(
                  <p>El formulario ya se encuentra cargado, solamente se puede cargar 1 documento por CUIT.</p>
                )
              }else{
              errors.push(
                <p>{key} : {value}</p>
              )}}
            setErrorServidor(true)
            setError(errors)
  
          }
        )

    }
    
  }

  //Genero PDF
  const imprimirPDF = ()=>{
    let form_data = new FormData();

    const info = ({'fecha_de_alta':fechaAlta,fechaVencimiento,cuit,nombreCompania,provincia,localidad,politicaBienestar,descPoliticaBienestar,
      'capacitacionPersonal':capacitacion,'fecha_de_capacitacionPersonal':fechaCapacitacion,manga,'corralCierre':corral,rampa,manejoAnimales,descripcionManejoAnimales,manejoCarga,descripcionManejoCarga,'email':'ariel@ariel.com',doc_url:'','codigo':'xx','revision':1
      ,'titulo':'FormBienAnimal'})

      for (const [key, value] of Object.entries(info)) {
        form_data.append(key,value)
      }

      UserService.generarPDF(form_data,'FormBienAnimal')
  }

  useEffect(() => {
    //setCuit(user.cuit)
    //listado proveedores
    UserService.getListProveedor().then(
      response => {
      if(response.status==200){
        const dataTropaConsig = response.data.users.map(a =>({cuit: a.cuit, label:a.razonS + '-' + a.cuit,email:a.email, id:a.id, key:a.id}) )
        setDespletables(dataTropaConsig) 
        let dataInicial = props.dataServidor
        if (dataInicial!=undefined){
          setID(dataInicial['id'])
          setFechaAlta(dataInicial['fecha_de_alta'])
          setFechaVencimiento(dataInicial['fecha_de_vencimiento'])
          setFechaCapacitacion(dataInicial['fecha_de_capacitacionPersonal'])
          setNombreCompania(dataInicial['nombreCompania'])
          setEmail(dataInicial['email'])
          setCuit(dataInicial['cuitProveedor'])
          setLocalidad(dataInicial['localidad'])
          setProvincia(dataInicial['provincia'])
          setPoliticaBienestar(dataInicial['politicaBienestar'])
          setDescPoliticaBienestar(dataInicial['descPoliticaBienestar'])
          setCapacitacion(dataInicial['capacitacionPersonal'])
          setManga(dataInicial['manga'])
          setCorral(dataInicial['corralCierre'])
          setRampa(dataInicial['rampa'])
          setManejoAnimales(dataInicial['rampa'])
          setDescripcionManejoAnimales(dataInicial['descripcionManejoAnimales'])
          setManejoCarga(dataInicial['manejodeCarga'])
          setDescripcionManejoCarga(dataInicial['descripcionManejoCarga'])   
          setEdit(true)
          setDeclaracion(dataInicial['firmo'])
          //defino la declaracion inicial para luego
          setDeclaracionI(dataInicial['firmo'])
          setNombreUsuario(dataInicial['nombre_usuario'])
          setPosicionUsuario(dataInicial['posicion_usuario'])
          const index = dataTropaConsig.findIndex(x=>x.label===dataInicial['nombreCompania'])

          setValorInicial(dataTropaConsig[index])
        }
      }
      },
      error => {
        if(error.response.status==401){}
        }
      );
      
     

  }, []);
   
  const handleChangeProveedor = (e, value) => {
    if (value && value.cuit!=undefined) {
      setNombreCompania(value.label.split('-')[0]);
      setCuit(value.cuit.toString());
      setEmail(value.email.toString())
    } else {
      setNombreCompania('');
      setCuit('');
      setEmail('')}
    }
  
  return (
    <>
      
            <div className={classes.marginNormal}>
            <Fade in={errorServidor}>
                  <Typography color="secondary" className={classes.errorMessage}>
                      {error}
                  </Typography>
                </Fade> 
            </div>

            <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item container spacing={2} lg={12} xs={12} sm={12}>
              <Grid item lg={12} md={12} sm={12} xs={12}> 
                <Typography variant="h4" align="center" gutterBottom>
                 Bienestar Animal Campo
                </Typography>
              </Grid> 
              {rol !=4 && (
              <Grid item lg={6} md={6} sm={12} xs={12}> 
              <InputLabel id="type">Fecha de Carga</InputLabel>

                      <ResponsiveDatePickers
                      value={fechaAlta}
                      
                      label="Date desktop"
                      inputFormat="dd-MMMM-yyyyy"
                      setValorFecha={setDate}
                      
                      ></ResponsiveDatePickers> 
              </Grid>
              )}
                  {rol!=4 ? ( 
                    
                <Grid item lg={6} xs={12} sm={12}>                
                  <InputLabel id="razonSocial">Proveedor</InputLabel>
                  <Autocomplete
                    disablePortal
                    noOptionsText={'Sin opciones'}
                    id="combo-box-demo"
                    options={desplegables}

                    value={nombreCompania}
                    //autoSelect={true}
                    clearOnBlur={false} 

                    error={nombreCompania==''?true:false}

                    onChange={handleChangeProveedor}
                    renderInput={(params) =>(
                      <TextField 
                      {...params} 
                        type="text"
                        error={nombreCompania==''?true:false}

                        variant="outlined"
                      
                          />)}
                  />
                  
                </Grid> ):(
                  <Grid container item lg={12} md={12} xs={12} sm={12}>
                  <Grid item lg={6} xs={12} sm={12}>
                  <TextField
                  autoFocus
                  id="razonSocial"
                  variant="outlined"
                  label="Razon Social"
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={nombreCompania}
                  error={nombreCompania==''?true:false}

                  onChange={e => setNombreCompania(e.target.value)}
                  margin="normal"
                  disabled={rol==4?true:false}
                  placeholder="Razon Social"
                  type="text"
                  fullWidth
                  required/>
                </Grid>
                  </Grid>
                )}
              
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="Localidad" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={localidad}
                  error={localidad==''?true:false}

                  onChange={e => setLocalidad(e.target.value)}
                  margin="normal"
                  placeholder="Localidad"
                  variant="outlined"
                  label="Localidad"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="Provincia" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={provincia}
                  error={provincia==''?true:false}

                  onChange={e => setProvincia(e.target.value)}
                  margin="normal"
                  placeholder="Provincia"
                  variant="outlined"
                  label="Provincia"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={4} xs={12} sm={6}>
                  <InputLabel id="politica">1.	Posee una politica/protocolos en bienestar animal? </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"

                    id="sector"
                    value={politicaBienestar}
                    error={politicaBienestar==''?true:false}

                    label="Completar"
                    onChange={(e)=>{setPoliticaBienestar(e.target.value)}}
                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                   <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                  <TextField id="descPoliticaBienestar" 
                  InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={descPoliticaBienestar}
                  error={descPoliticaBienestar==''?true:false}
                  InputLabelProps={{ shrink: true }}

                  onChange={e => setDescPoliticaBienestar(e.target.value)}
                  margin="normal"
                 // placeholder="Breve descripción de sus fundamentos"
                  variant="outlined"
                  label="Breve descripción de sus fundamentos"
                  type="text"
                  className={classes.marginNormal3}

                  fullWidth/>

                </Grid>  
                <Grid item lg={4} xs={12} sm={6}>
                  <InputLabel id="capacitacion">2.	Capacitaciones del personal?  </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"

                    id="sector"
                    value={capacitacion}
                    error={capacitacion==''?true:false}

                    label="Completar"
                    onChange={(e)=>{setCapacitacion(e.target.value)}}
                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                    <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                  <div  >
                  <InputLabel id="type" >Fecha de Capacitacion</InputLabel>

                    <ResponsiveDatePickers
                        value={fechaCapacitacion}
                        label="Date desktop"
                        inputFormat="dd-MMMM-yyyyy"
                        setValorFecha={setDateCapacitacion}
                        ></ResponsiveDatePickers> 
                  </div>
                </Grid> 
                <Grid item container  lg={6} xs={12} sm={6}>
                  <InputLabel id="capacitacion"                      
                  className={classes.Top}
                  >3.	Registros de mantenimiento de instalaciones en el campo:  </InputLabel>
                  <Grid container lg={6}>
                    <InputLabel id="capacitacion">a.	Manga de Trabajo:  </InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      margin="normal"

                      id="sector"
                      value={manga}
                      label="Completar"
                      error={manga==''?true:false}

                      onChange={(e)=>{setManga(e.target.value)}}
                      fullWidth
                      required
                    // disabled={compradorEst()}
                     className={classes.marginNormal}
                    
                    >
                      <MenuItem value={'SI'}>Si</MenuItem>
                      <MenuItem value={'NO'}>No</MenuItem>
                    </Select>
                    <InputLabel id="capacitacion">b.	Corral de encierre :  </InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      margin="normal"

                      id="sector"
                      value={corral}
                      error={corral==''?true:false}

                      label="Completar"
                      onChange={(e)=>{setCorral(e.target.value)}}
                      fullWidth
                      required
                    // disabled={compradorEst()}
                    className={classes.marginNormal}
                    
                    >
                      <MenuItem value={'SI'}>Si</MenuItem>
                      <MenuItem value={'NO'}>No</MenuItem>
                    </Select>
                    <InputLabel id="capacitacion">c.	Rampa de carga de camiones   </InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      margin="normal"

                      id="sector"
                      value={rampa}
                      error={rampa==''?true:false}

                      label="Completar"
                      onChange={(e)=>{setRampa(e.target.value)}}
                      fullWidth
                      required
                    // disabled={compradorEst()}
                      className={classes.marginNormal}
                    >
                      <MenuItem value={'SI'}>Si</MenuItem>
                      <MenuItem value={'NO'}>No</MenuItem>
                    </Select>
                  </Grid>  
                </Grid> 
                <Grid item lg={6} xs={12} sm={6}>
                  <InputLabel id="capacitacion">4.	El manejo previo a la carga de los animales la considera adecuada?  </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"

                    id="sector"
                    value={manejoAnimales}
                    error={manejoAnimales==''?true:false}

                    label="Completar"
                    onChange={(e)=>{setManejoAnimales(e.target.value)}}
                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                                    
                    <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                  <TextField id="capacitacion" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={descripcionManejoAnimales}
                  onChange={e => setDescripcionManejoAnimales(e.target.value)}
                  margin="normal"
                  label="Breve Descripción:"
                  variant="outlined"
                  error={descripcionManejoAnimales==''?true:false}
                  InputLabelProps={{ shrink: true }}

                  type="text"
                  fullWidth/>
                </Grid> 
                <Grid item lg={12} xs={12} sm={6}>
                  <InputLabel id="capacitacion">5.	El manejo en la carga la considera adecuada?   </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"

                    id="sector"
                    value={manejoCarga}
                    label="Completar"
                    onChange={(e)=>{setManejoCarga(e.target.value)}}
                    error={manejoCarga==''?true:false}

                    
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                    <MenuItem value={'SI'}>Si</MenuItem>
                    <MenuItem value={'NO'}>No</MenuItem>
                  </Select>
                  <TextField id="capacitacion" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={descripcionManejoCarga}
                  onChange={e => setDescripcionManejoCarga(e.target.value)}
                  margin="normal"
                  placeholder="Breve Descripción:"
                  variant="outlined"
                  label="Breve Descripción:"
                  InputLabelProps={{ shrink: true }}
                  error={descripcionManejoCarga==''?true:false}

                  type="text"
                  fullWidth/>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="nombre_usuario" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={nombreUsuario}
                    error={nombreUsuario==''?true:false}

                    onChange={e => setNombreUsuario(e.target.value)}
                    margin="normal"
                    placeholder="Nombre"
                    variant="outlined"
                    label="Nombre"
                    type="text"
                    fullWidth/>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="posicion_usuario" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={posicionUsuario}
                    error={posicionUsuario==''?true:false}

                    onChange={e => setPosicionUsuario(e.target.value)}
                    margin="normal"
                    placeholder="Cargo que ocupa en la empresa"
                    variant="outlined"
                    label="Cargo"
                    type="text"
                    fullWidth/>
                </Grid> 
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="email_usuario" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={email}
                    error={email==''?true:false}

                    onChange={e => setEmail(e.target.value)}
                    margin="normal"
                    placeholder="Email"
                    variant="outlined"
                    label="Email"
                    type="text"
                    fullWidth/>
                </Grid>
                <Grid>
                <p><strong>COMPROMISO.</strong></p>
                <p><span >Al completar y fimar este formulario declaro que el campo se compromete con la comunicaci&oacute;n y capacitaci&oacute;n de su personal en Bienestar animal, as&iacute; mismo la producci&oacute;n esta basada en respetar y cuidar los derechos de los animales.como por ejemplo:</span></p>
                <p><strong>Especialmente:</strong></p>
                <ul>
                <li><span > Asegurar el suministro de alimento apropiado para mantener una adecuada salud y bienestar, atendiendo a las necesidades fisiol&oacute;gicas.</span></li>
                <li><span > Brindar una provisi&oacute;n de agua de calidad y en cantidad suficiente para satisfacer sus requerimientos fisiol&oacute;gicos.</span></li>
                <li><span > Minimizar el riesgo de lesiones o enfermedades en los procedimientos de manejo.</span></li>
                <li><span > Si son confinados se proveer&aacute; del espacio suficiente para que se puedan acostar, parar, estirar sus extremidades y desarrollar patrones normales de comportamiento.</span></li>
                <li><span > Se manejar&aacute;n en instalaciones y con equipos adecuados que minimicen el estr&eacute;s.</span></li>
                <li><span > Se proveer&aacute;n tratamientos sanitarios adecuados, lo cual puede incluir el sacrificio humanitario si fuera necesario.</span></li>
                <li><span > Se minimizara el riesgo de predaci&oacute;n en todas las etapas del ciclo productivo.</span></li>
                <li><span > Ante una situaci&oacute;n de disminuci&oacute;n brusca y repentina de la disponibilidad de forraje/pasturas se acceder&aacute; a fuentes suplementarias de alimento, por lo cual es necesario contar con un plan de contingencia para emergencias clim&aacute;ticas.</span></li>
                <li><span > Se evitaran procedimientos que impliquen sufrimiento a menos que sea estrictamente necesario y, en ese caso, extremar las precauciones para que sea lo menos intenso y prolongado posible.</span></li>
                </ul>
                </Grid>
                <Grid item lg={12} xs={12} sm={12}>
                  <FormControlLabel
                      value="consgi"
                      control={<Checkbox color="primary"       checked={declaracion}/>}
                      className={classes.control}

                      onClick={()=>{setDeclaracion(value => !value)}}

                      label="Al guardar declaro bajo juramento que los datos consignados en la presente declaracion jurada son veridicos y me hago responsable respecto de la veracidad de los mismos"
                    />
                </Grid>
                {/* <Grid item lg={10} xs={12} sm={12}>
                  <p>Al guardar declaro bajo juramento que los datos consignados en la presente declaracion jurada son veridicos y me hago responsable respecto de la veracidad de los mismos</p>
                </Grid> */}
                {/* <Grid item lg={6} xs={12} sm={12}>
                  <FormControlLabel
                    value="consgi"
                    control={<DownloadIcon/>}  
                    className={classes.control}

                    label="Descargar Documento"
                    onClick={()=>{imprimirPDF()}}
                  />
          
                  </Grid>
        

                  {!sumarImg&& 
                  <Grid item lg={6} xs={12} sm={12}>
                  <FormControlLabel
                  value="consgi"
                  control={<AddCircleIcon/>}  
                  className={classes.control}

                  label="Cargar Certificado"

                  onClick={()=>{setSumarImg(value=>!value)}}
                />
                
                  </Grid> }
                  {sumarImg&& 

                  <Grid item lg={12} xs={12} sm={12}>
                  <Dropzone
                    classNames={classes.dropZone}
                    styles={{ dropzone: { overflow: 'hidden', margin:'10px 0px'}, submitButton: {padding:'15px',
                    fontSize: '1.2em',}   }}
                    /* getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    onSubmit={handleSubmit}
                    PreviewComponent={Preview} 
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}


                    accept="image/*,.pdf"
                    inputContent='Arrastra tus archivos o hace click aqui'
                   /*  submitButtonContent="Guardar Datos"
                    inputWithFilesContent='Sumar otro archivo' 
                      /> 
                  </Grid>  }  */}
              </Grid>    
              <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12} style={{display:'flex', justifyContent: 'center'}}>
                <Grid item lg={3} xs={12} sm={12} >
                  <Button
                    fullWidth

                     onClick={() => //UserService.generatePDF()

                      guardarDocumento()

                      

                     /*(fecha_de_alta,fecha_de_vencimiento,cuitProveedor,nombreCompania,provincia,localidad,politicaBienestar,descPoliticaBienestar,
                        capacitacionPersonal,fecha_de_capacitacionPersonal,
                        manga,corralCierre,rampa,manejoDeAnimales,descripcionManejoAnimales,manejodeCarga,descripcionManejoCarga, email, doc_url) */
                    }  
/* 
                    disabled={
                      titulo.length === 0 ||
                      descripcion.length === 0 ||
                      proveedor.length === 0 ||
                      Image.length === 0 

                    }  */
                    size="large"
                    variant="contained"
                    color="primary"
                    
                    className={classes.createAccountButton}
                    //component={Link} to={`/app/gestionDoc`}
                    disabled={
                      declaracion === false ||
                      cuit.length === 0 ||
                      localidad.length === 0 ||
                      provincia.length === 0 ||
                      politicaBienestar.length === 0 ||
                      capacitacion.length === 0 ||
                      manejoCarga.length === 0 || 
                      nombreUsuario.length === 0 ||
                      posicionUsuario.length === 0
                    }
                    >

                  Guardar
                  </Button> 
                </Grid>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    className={classes.createAccountButton}
                    size="large"
                    variant="contained"
                    color="primary" 
                    fullWidth
                    component={Link} to={`/app/gestionDoc`}
                    onClick={props.cerrar}>
                  
                    Cancelar
                  </Button>  
                </Grid>   
                  
              </Grid>   
              
            </Grid>    
            </div>  
          
    </>    
  );
}
export default FormBienAnimal;