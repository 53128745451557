import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  TextField,
  Fade,

} from "@material-ui/core";
import { withRouter } from "react-router-dom";
/* import classnames from "classnames";
 */
// styles
import useStyles from "./styles";

// logo
/* import google from "../../images/google.svg";
import logoDark from "../../images/logo_dark.png"; */
import logoWhite from "../../images/RP_Logotipo.svg";//"../../images/logo.png";

//passwordWiev
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { styled } from '@mui/material/styles';

// icons
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

// context
//import { useUserDispatch,loginUser} from "../../context/UserContext";
import UserStateContext from "../../context/UserContext";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from '@mui/material';
import tinycolor from 'tinycolor2'

const theme = createTheme({
  palette: {
    primary: {
      main: '#8c734b',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      hint: "#B9B9B9",
      black: 'black',
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '15px',
        maxHeight: '50px',
        '&:hover $notchedOutline': {
          borderColor: tinycolor('#8c734b').lighten().toHexString()
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: '15px',
        maxHeight: '50px',
        'text-transform': 'initial',
        'font-weight': 'bold'
      }
    }
  },
});


function Login(props) {
  var classes = useStyles();
  // global


  // local
  var [isLoading2, setIsLoading2] = useState(false);
  var [error, setError] = useState(null);
  var [errorServidor, setErrorServidor] = useState(null);
  var [errorUsuario, setErrorUsuario] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  /* var [nameValue, setNameValue] = useState(""); */
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [toggle_password, setToggle_password] = useState(false)
  var [cuit, setcuit] = useState("");
  const togglePasswordHide = () => {
    setToggle_password(toggle_password => !toggle_password)
  };


  const handleChange = (e) => {
    if (!isValidEmail(e.target.value)) {
      //setError(true);
      setLoginValue(e.target.value)
      setcuit(e.target.value + '@' + e.target.value + '.com')


    } else {
      setLoginValue(e.target.value)
      setError(false);

    }
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const { loginUser } = useContext(UserStateContext);


  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isValidEmail(loginValue)) {
      loginUser(
        cuit,
        passwordValue,
        props.history,
        setError,
        setIsLoading2,
        setErrorServidor,
        setErrorUsuario)
    } else {
      loginUser(
        loginValue,
        passwordValue,
        props.history,
        setError,
        setIsLoading2,
        setErrorServidor,
        setErrorUsuario)
    }

  }

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === "Enter") {
        document.getElementById("submit").click();

      }

    }, true)
  }, []);


  return (


    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        {/* <Grid item xs="auto" alignItems="right"> */}


        {/* </Grid> */}
        <div className={classes.form}>


          {activeTabId === 0 && (


            <React.Fragment>

              <Grid className={classes.inputField}
                sm={12}
              >
                <form onSubmit={handleSubmit} >
                  <ThemeProvider theme={theme}>

                    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                      <img src={logoWhite} alt="Logo" className={classes.imgen} />
                    </div>
                    {/* <div className={classes.singin}>Iniciar Sesión</div> */}
                    <TextField
                      id="email"
                      variant="outlined"
                      label="Email o Cuit"

                      required
                      InputProps={{
                        classes: {
                          input: classes.textField,
                          notchedOutline: classes.textField,
                        },
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        //   </InputAdornment>
                        // ),
                      }}
                      value={loginValue}
                      onChange={handleChange}
                      margin="normal"
                      placeholder=""
                      type="text"
                      fullWidth
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />

                    <TextField
                      id="password"
                      variant="outlined"
                      label="Contraseña"

                      required
                      InputProps={{
                        classes: {
                          input: classes.textField,
                          notchedOutline: classes.textField,

                        },

                        endAdornment: (
                          <InputAdornment
                            className={classes.pointer}
                            position="end"> {
                              toggle_password ? (
                                <Visibility
                                  style={{ color: '#666666' }}
                                  className="cursor_pointer"
                                  onClick={() => {
                                    togglePasswordHide()

                                  }
                                  }
                                />
                              ) : (
                                <VisibilityOff
                                  style={{ color: '#666666' }}
                                  onClick={() => {
                                    togglePasswordHide()

                                  }
                                  }
                                />
                              )
                            }
                          </InputAdornment>
                        ),
                      }}
                      value={passwordValue}
                      onChange={e => setPasswordValue(e.target.value)}
                      //autoComplete="new-password"
                      margin="normal"

                      placeholder=""
                      type={toggle_password ? "text" : "password"}
                      fullWidth
                    />

                    <div className={classes.formButtons}>
                      {isLoading2 ? (
                        <CircularProgress size={26} className={classes.loginLoader} />
                      ) : (
                        <Button
                          disabled={
                            loginValue.length === 0 || passwordValue.length === 0
                          }
                          classes={{ disabled: classes.disabledButton }}
                          //style={{ backgroundColor: '#8c734b' }}
                          type="submit"
                          variant="contained"
                          color="primary"
                          id='submit'
                          //fullWidth
                          size='large'
                        >
                          Ingresar
                        </Button>
                      )}

                    </div>
                  </ThemeProvider>
                </form>
              </Grid>

              <Fade in={errorServidor}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Ocurrió un error al verificar su usuario y/o contraseña
                </Typography>
              </Fade>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  El usuario y/o contraseña ingresados son incorrectos
                </Typography>
              </Fade>

              <Fade in={errorUsuario}>
                <Typography color="secondary" className={classes.errorMessage}>
                  El usuario y/o contraseña ingresados son incorrectos
                </Typography>
              </Fade>
              <Tabs
                value={activeTabId}
                onChange={(e, id) => setActiveTabId(id)}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {/*   <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="New User" classes={{ root: classes.tab }} /> */}


              </Tabs>
            </React.Fragment>
          )}


        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
