import * as React from 'react';
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField, Link,
  InputLabel, ListItemText,
  Typography, useMediaQuery,
  FormControlLabel, Checkbox
} from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import UserService from '../../context/UserService';
import useStyles from "./styles";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UserStateContext from '../../context/UserContext';
import Box from '@mui/material/Box';
import Dropzone from 'react-dropzone-uploader'
import { set } from 'date-fns';
import { useTheme } from "@material-ui/styles";
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


export default function EditStock(props) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

  var classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState('')

  const [fecha, setFecha] = React.useState('')

  const [valorTropa, setValorTropa] = React.useState('')
  const [corral, setCorral] = React.useState('')
  const [corralI, setCorralI] = React.useState('')
  const [KGvivo, setKgVivo] = React.useState('')
  const [proc, setProc] = React.useState('')


  const [cabezas, setCabezas] = React.useState('')
  const [cabezasI, setCabezasI] = React.useState('')

  const [especie, setEspecie] = React.useState([])
  const [raza, setRaza] = React.useState([])
  const [programa, setPrograma] = React.useState()
  const [caja, setCaja] = React.useState([])
  const [aptitud, setAptitud] = React.useState([])
  const [usuarioverd, setUsuarioVerd] = React.useState('')


  const [conf, setConf] = React.useState([])
  const [Gordura, setGordura] = React.useState([])
  const [obs, setObs] = React.useState('')
  const [Image, setImage] = React.useState([]);
  const [sumarImg, setSumarImg] = React.useState(true);
  const [disableGuardar, setDisableGuardar] = React.useState(false);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState({ status: '', message: '' });
  const [done, setDone] = React.useState(false);

  var [error, setError] = React.useState(null);

  React.useEffect(() => {
    //console.log(props.dataRow)
    setId(props.dataRow[0])

    setFecha(props.dataRow[1]);
    setValorTropa(props.dataRow[2]);
    setCorral(props.dataRow[3]);
    // declaro el valor inicial para comparrlo con el final 
    setCorralI(props.dataRow[3]);
    setCabezas(props.dataRow[4]);
    // declaro el valor inicial para comparrlo con el final 
    setCabezasI(props.dataRow[4]);
    setUsuarioVerd(props.dataRow[5])
    setPrograma(props.dataRow[8] != undefined ? props.dataRow[8] : '')

    setCaja(props.dataRow[9] != undefined ? props.dataRow[9] : '')
    setKgVivo(props.dataRow[11] != undefined ? props.dataRow[11] : 0)
    setAptitud(props.dataRow[12] != undefined ? props.dataRow[12] : '')

    setProc((props.dataRow[13] != undefined && props.dataRow[13] != '') ? props.dataRow[13] : 'SinData')
    setEspecie(props.dataRow[14].length > 0 ? props.dataRow[14].split('/') : [])
    setRaza(props.dataRow[15].length > 0 ? props.dataRow[15].split('/') : [])
    setConf(props.dataRow[16].length > 0 ? props.dataRow[16].split('/') : [])
    setGordura(props.dataRow[17].length > 0 ? props.dataRow[17].split('/') : [])
    setObs(props.dataRow[18] != undefined ? props.dataRow[18] : '');



  }, []);

  const handleOpenDrop = (value) => { setOpen(true) }

  const handleCloseDrop = () => setOpen(false);
  //var tropa = localStorage.getItem('filterList')


  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => { setDisableGuardar(true) /* console.log(status, meta, file) */ }

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files, allFiles) => {
    const fotos = []
    files.map(f => fotos.push({
      title: f.meta.name,
      description: f.meta.type,
      image_url: f.meta.previewUrl,
      img: f.file
    }))
    const fecha_foto = moment(fecha, "DD/MM/YY").format("YYYY-MM-DD");
    try {
      const response = await UserService.postImagen(valorTropa, fotos, fecha_foto)
      if (response.status == 201) {
        setOpenSnack(true)
        setSnackbarData({ status: 'success', message: 'Imagenes cargadas correctamente' })
        allFiles.forEach(f => f.remove())
      } else {
        throw new Error('Error al cargar las imagenes')
      }
    } catch (error) {
      setOpenSnack(true)
      setSnackbarData({ status: 'error', message: error.message })
    }
    setSumarImg(false)
    //setDisableGuardar(false)
  }

  const handleCloseSnack = () => {
    setOpenSnack(false);
    setSnackbarData({ status: '', message: '' });
    if (done) {
      setOpen(false);
    }
  }

  const handleClickOpen = (event) => { setOpen(true); };

  const guardarStock = async (history) => {
    setDone(false)
    const valEspecie = especie.length > 1 ? especie.join('/') : especie[0]
    const valRaza = raza.length > 1 ? raza.join('/') : raza[0]
    const valConf = conf.length > 1 ? conf.join('/') : conf[0]
    const valGordura = Gordura.length > 1 ? Gordura.join('/') : Gordura[0]
    const img = Image.length > 0 ? 'si' : null
    const status = caja == '' ? 'Pendiente Caja' : 'Realizado'

    const fecha_argumentos = fecha.split('/')
    const fecha_foto = moment(fecha, "DD/MM/YY").format("YYYY-MM-DD");
    const fecha_corregida = '20' + fecha_argumentos[2] + '-' + fecha_argumentos[1] + '-' + fecha_argumentos[0]

    if (props.origen == 'editar') {
      try {
        const response = await UserService.putStock(id, fecha_corregida, valorTropa, corral, cabezas, status, valEspecie, valRaza, valConf, valGordura, obs, programa, caja, aptitud, img, false, usuarioverd)
        if (response.status == 200) {
          //if (Image.length > 0) { await UserService.postImagen(valorTropa, Image, fecha_foto) }
          props.cargarData()
          //setOpen(false)
          setOpenSnack(true)
          setSnackbarData({ status: 'success', message: 'Stock actualizado correctamente' })
          setDone(true)
        } else {
          throw new Error('Error al actualizar el stock')
        }
      } catch (error) {
        setOpenSnack(true)
        setSnackbarData({ status: 'error', message: error.message })
      }
    } else {
      //console.log(obs)
      let response = []
      // modificacion de valores de cabezas 
      if (cabezasI != cabezas || corralI != corral) {
        response = await UserService.postStock(fecha_corregida, valorTropa, corral, KGvivo, proc, cabezas, status, valEspecie, valRaza, valConf, valGordura, obs, programa, caja, aptitud, img, true, usuarioverd)
      } else {
        response = await UserService.postStock(fecha_corregida, valorTropa, corral, KGvivo, proc, cabezas, status, valEspecie, valRaza, valConf, valGordura, obs, programa, caja, aptitud, img, false, usuarioverd)
      }
      try {
        if (response.status == 201) {
          //if (Image.length > 0) { await UserService.postImagen(valorTropa, Image, fecha_foto) }
          props.cargarData()
          //setOpen(false)
          setDone(true)
        } else {
          throw new Error('Error al guardar el stock')
        }
      } catch (error) {
        setOpenSnack(true)
        setSnackbarData({ status: 'error', message: error.message })
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <IconButton variant="text" color='primary' size='small' onClick={handleClickOpen}>
        <EditIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
      </IconButton>
      <>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseSnack}>
          <Alert
            onClose={handleCloseSnack}
            severity={snackbarData?.status}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbarData?.message}
          </Alert>
        </Snackbar>
      </>
      <UserStateContext.Consumer>
        {({ darkMode }) => (
          darkMode == true ? (
            <Dialog
              fullScreen
              maxWidth={true}
              open={open}
              scroll='body'
              onClose={handleClose}
              className={classes.overflow}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                style: {
                  '& .MuiPaper-root': { bgcolor: "pink", maxWidth: "revert", width: 1200 },
                  "&$.MuiOutlinedInput-root.Mui-disabled": {
                    borderColor: "white",
                  },
                  color: 'white', borderColor: "white",

                  '&$.MuiPaper-root-MuiDialog-paper': {
                    backgroundColor: theme.palette.background.main//'rgb(35, 35, 45)',

                  },
                  backgroundColor: theme.palette.background.main//'rgb(35, 35, 45)',



                },
              }}
            >
              <Toolbar>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <Button autoFocus color="inherit" onClick={handleClose}>
                  Cerrar
                </Button>
                <Grid container>


                </Grid>

              </Toolbar>


              <DialogContent
                //theme={theme}
                className={classes.overflow}
                sx={{ overflowY: 'initial' }}
              >

                <Typography color="secondary" className={classes.errorMessage}>
                  {error}
                </Typography>
                <Box sx={{
                  height: '24rem', width: '100%', /* "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: 'blue',
        }, */
                  /*  '& .MuiFormLabel-root.Mui-disabled': {
                     color: 'blue',
                   }, */

                }}>
                  <Grid container spacing={2}>
                    <Grid item lg={12}>
                      <Typography variant='h5'>
                        Editar Stock Tropa {valorTropa}
                      </Typography>
                    </Grid>

                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="Fecha"
                        disabled

                        value={fecha}
                        //onChange={e => setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="Fecha"
                        placeholder="Fecha"
                        type="Fecha"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="Tropa"
                        disabled

                        value={valorTropa}
                        //onChange={e => setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="Tropa"
                        placeholder="Tropa"
                        type="Tropa"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="Corral"

                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },

                          sx: {
                            color: 'red'

                          }
                        }}
                        onChange={e => setCorral(e.target.value)}

                        value={corral}
                        margin="normal"
                        variant="outlined"
                        label="Corral"
                        placeholder="Corral"
                        type="number"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="Cabezas"

                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={cabezas}
                        onChange={e => setCabezas(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="Cabezas"
                        placeholder="Cabezas"
                        type="number"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="KG-vivo-Promedio"

                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={KGvivo}
                        onChange={e => setKgVivo(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="KG vivo Promedio"
                        placeholder="KG vivo Promedio"
                        type="number"
                        fullWidth
                        disabled
                        required />
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="Procedencia"
                        disabled
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={proc}
                        onChange={e => setProc(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="Procedencia"
                        placeholder="Procedencia"
                        type="text"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <InputLabel id="type">Especie</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={especie}
                        variant="outlined"

                        multiple
                        fullWidth
                        label="especie"
                        renderValue={(selected) => selected.join(', ')}
                        onChange={(e) => {
                          setEspecie(e.target.value)
                          //dataIndex.rowData[112]= e.target.value

                        }}
                      >

                        <MenuItem value={'NO'}><Checkbox color="primary" checked={especie.indexOf('NO') > -1} />
                          <ListItemText primary={'Novillo'} /></MenuItem>
                        <MenuItem value={'VAQ'}><Checkbox color="primary" checked={especie.indexOf('VAQ') > -1} />
                          <ListItemText primary={'Vaquillona'} /></MenuItem>
                        <MenuItem value={'VA'}><Checkbox color="primary" checked={especie.indexOf('VA') > -1} />
                          <ListItemText primary={'Vaca'} /></MenuItem>
                        <MenuItem value={'MEJ'}><Checkbox color="primary" checked={especie.indexOf('MEJ') > -1} />
                          <ListItemText primary={'Macho entero joven'} /></MenuItem>
                        <MenuItem value={'TOR'}><Checkbox color="primary" checked={especie.indexOf('TOR') > -1} />
                          <ListItemText primary={'Toro'} /></MenuItem>
                        <MenuItem value={'NT'}><Checkbox color="primary" checked={especie.indexOf('NT') > -1} />
                          <ListItemText primary={'Novillito'} /></MenuItem>

                      </Select>
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <InputLabel id="type">Raza</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        variant="outlined"
                        fullWidth

                        renderValue={(selected) => selected.join(', ')}
                        value={raza}
                        label="Raza"
                        onChange={(e) => {
                          setRaza(e.target.value)
                          //dataIndex.rowData[16]= e.target.value

                        }}
                      ><MenuItem value={'AA'}>
                          <Checkbox color="primary" checked={raza.indexOf('AA') > -1} />
                          <ListItemText primary={'Angus'} />
                        </MenuItem>
                        <MenuItem value={'MZ'}> <Checkbox color="primary" checked={raza.indexOf('MZ') > -1} />
                          <ListItemText primary={'Mestizo'} />
                        </MenuItem>
                        <MenuItem value={'CZA'}> <Checkbox color="primary" checked={raza.indexOf('CZA') > -1} />
                          <ListItemText primary={'Cruza'} />
                        </MenuItem>
                        <MenuItem value={'HA'}> <Checkbox color="primary" checked={raza.indexOf('HA') > -1} />
                          <ListItemText primary={'Holando'} />
                        </MenuItem>
                        <MenuItem value={'CT'}> <Checkbox color="primary" checked={raza.indexOf('CT') > -1} />
                          <ListItemText primary={'Cuartino'} />
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <InputLabel id="type">Conformacion</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        variant="outlined"
                        fullWidth

                        renderValue={(selected) => selected.join(', ')}
                        value={conf}
                        label="conformacion"
                        onChange={(e) => {
                          setConf(e.target.value)
                        }}
                      ><MenuItem value={'Especial'}>
                          <Checkbox color="primary" checked={conf.indexOf('Especial') > -1} />
                          <ListItemText primary={'Especial'} />
                        </MenuItem>
                        <MenuItem value={'MB'}> <Checkbox color="primary" checked={conf.indexOf('MB') > -1} />
                          <ListItemText primary={'Muy Bueno'} />
                        </MenuItem>
                        <MenuItem value={'Bueno'}> <Checkbox color="primary" checked={conf.indexOf('Bueno') > -1} />
                          <ListItemText primary={'Bueno'} />
                        </MenuItem>
                        <MenuItem value={'Regular'}> <Checkbox color="primary" checked={conf.indexOf('Regular') > -1} />
                          <ListItemText primary={'Regular'} />
                        </MenuItem>
                        <MenuItem value={'Malo'}> <Checkbox color="primary" checked={conf.indexOf('Malo') > -1} />
                          <ListItemText primary={'Malo'} />
                        </MenuItem>
                        <MenuItem value={'Conserva'}> <Checkbox color="primary" checked={conf.indexOf('Conserva') > -1} />
                          <ListItemText primary={'Conserva'} />
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <InputLabel id="type">Gordura</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        variant="outlined"
                        fullWidth

                        renderValue={(selected) => selected.join(', ')}
                        value={Gordura}
                        label="Gordura"
                        onChange={(e) => {
                          setGordura(e.target.value)
                        }}
                      >
                        <MenuItem value={'Flaco'}>
                          <Checkbox color="primary" checked={Gordura.indexOf('Flaco') > -1} />
                          <ListItemText primary={'Flaco'} />
                        </MenuItem>
                        <MenuItem value={'Justo'}> <Checkbox color="primary" checked={Gordura.indexOf('Justo') > -1} />
                          <ListItemText primary={'Justo'} />
                        </MenuItem>
                        <MenuItem value={'Bueno'}> <Checkbox color="primary" checked={Gordura.indexOf('Bueno') > -1} />
                          <ListItemText primary={'Bueno'} />
                        </MenuItem>
                        <MenuItem value={'MB'}> <Checkbox color="primary" checked={Gordura.indexOf('MB') > -1} />
                          <ListItemText primary={'Muy Bueno'} />
                        </MenuItem>
                        <MenuItem value={'Gordo'}> <Checkbox color="primary" checked={Gordura.indexOf('Gordo') > -1} />
                          <ListItemText primary={'Gordo'} />
                        </MenuItem>
                        <MenuItem value={'MG'}> <Checkbox color="primary" checked={Gordura.indexOf('MG') > -1} />
                          <ListItemText primary={'Muy Gordo'} />
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="Observaciones"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={obs}
                        onChange={(e) => { setObs(e.target.value) }}
                        margin="normal"
                        variant="outlined"
                        label="Observaciones"
                        placeholder="Observaciones"
                        type="Observaciones"
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <InputLabel id="type">Caja</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        fullWidth
                        //renderValue={(selected) => selected.join(', ')}
                        value={caja}
                        label="Caja"
                        onChange={(e) => {
                          setCaja(e.target.value)
                          //dataIndex.rowData[16]= e.target.value

                        }}
                      ><MenuItem value={'Negra'}>
                          Negra
                        </MenuItem>
                        <MenuItem value={'Estandar'}>Estandar
                        </MenuItem>
                        <MenuItem value={'Estandar'}>Gold
                        </MenuItem>

                      </Select>
                    </Grid>
                  </Grid>
                  {!sumarImg &&


                    <Grid item lg={6} xs={12} sm={12}>
                      <FormControlLabel
                        value="consgi"
                        control={<AddCircleIcon />}
                        className={classes.control}

                        label="Cargar nuevos archivos"
                        onClick={() => { setSumarImg(value => !value) }}
                      />
                      {/* <InputLabel id="type">Sumar Imagen</InputLabel>
                <Checkbox label="Activo" color="primary" onChange={()=>{setSumarImg(value=>!value)}}/> */}
                    </Grid>}
                  {sumarImg &&
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                      <Dropzone
                        classNames={classes.dropZone}
                        styles={{
                          dropzone: {
                            color: theme.palette.primary.main,
                            overflow: 'hidden',
                            margin: '10px 0px',
                            borderColor: theme.palette.tables.tableBorderColor
                          },
                          submitButton: {
                            padding: '15px',
                            fontSize: '1.2em',
                          },
                          preview: {
                            color: theme.palette.primary.main
                          },
                          inputLabel: {
                            color: theme.palette.primary.main
                          }
                        }}
                        getUploadParams={getUploadParams}
                        //onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="image/*,audio/*,video/*"
                        inputContent='Arrastra tus archivos o hace click aqui'
                        submitButtonContent="Confirmar Archivos"
                        inputWithFilesContent='Sumar otro archivo'
                      />
                    </Grid>}



                  <Grid alignContent='center' justify='center' container spacing={1} lg={12} xs={12} sm={12}>
                    <Grid item lg={3} xs={12} sm={12}>

                      <Button
                        onClick={() => { guardarStock(fecha, valorTropa, corral, cabezas, especie, raza, conf, Gordura, obs) }}

                        /*   disabled={
                            email.length === 0
                          }  */
                        //component={Link} to={`/app/tables`}
                        disabled={disableGuardar === true || raza.length === 0
                          || especie.length === 0
                          || Gordura.length === 0
                          || conf.length === 0}

                        size="large"
                        variant="contained"
                        color="primary"

                        className={classes.createAccountButton}

                      >
                        GUARDAR
                      </Button>
                    </Grid>
                    <Grid item lg={3} xs={12} sm={12}>

                      <Button
                        className={classes.createAccountButton}
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpen(false)}
                      >

                        CANCELAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>

            </Dialog>) : (
            <Dialog
              fullScreen
              maxWidth={true}
              open={open}
              scroll='body'
              onClose={handleClose}
              className={classes.overflow}
              aria-labelledby="responsive-dialog-title"

            >
              <Toolbar>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>

                <Button autoFocus color="inherit" onClick={handleClose}>
                  Cerrar
                </Button>
                <Grid container>


                </Grid>

              </Toolbar>


              <DialogContent
                //theme={theme}
                className={classes.overflow}
                sx={{ overflowY: 'initial' }}
              >

                <Typography color="secondary" className={classes.errorMessage}>
                  {error}
                </Typography>
                <Box sx={{
                  height: '24rem', width: '100%', /* "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: 'blue',
        }, */
                  /*  '& .MuiFormLabel-root.Mui-disabled': {
                     color: 'blue',
                   }, */

                }}>
                  <Grid container spacing={2}>
                    <Grid item lg={12}>
                      <Typography variant='h5'>
                        Editar Stock Tropa {valorTropa}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <TextField
                        id="fecha"
                        disabled

                        value={fecha}
                        //onChange={e => setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="fecha"
                        placeholder="Fecha"
                        type="fecha"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        id="tropa"
                        disabled

                        value={valorTropa}
                        //onChange={e => setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="tropa"
                        placeholder="Tropa"
                        type="tropa"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        id="corral"
                        disabled
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },

                          sx: {
                            color: 'red'

                          }
                        }}
                        value={corral}
                        margin="normal"
                        variant="outlined"
                        label="corral"
                        placeholder="corral"
                        type="corral"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        id="Cabezas"
                        disabled
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={cabezas}
                        // onChange={e => setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="Cabezas"
                        placeholder="Cabezas"
                        type="Cabezas"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel id="type">Especie</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={especie}
                        variant="outlined"

                        multiple
                        fullWidth
                        label="especie"
                        renderValue={(selected) => selected.join(', ')}
                        onChange={(e) => {
                          setEspecie(e.target.value)
                          //dataIndex.rowData[16]= e.target.value

                        }}
                      >

                        <MenuItem value={'NO'}><Checkbox color="primary" checked={especie.indexOf('NO') > -1} />
                          <ListItemText primary={'Novillo'} /></MenuItem>
                        <MenuItem value={'VAQ'}><Checkbox color="primary" checked={especie.indexOf('VAQ') > -1} />
                          <ListItemText primary={'Vaquillona'} /></MenuItem>
                        <MenuItem value={'VA'}><Checkbox color="primary" checked={especie.indexOf('VA') > -1} />
                          <ListItemText primary={'Vaca'} /></MenuItem>
                        <MenuItem value={'MEJ'}><Checkbox color="primary" checked={especie.indexOf('MEJ') > -1} />
                          <ListItemText primary={'Macho entero joven'} /></MenuItem>
                        <MenuItem value={'TOR'}><Checkbox color="primary" checked={especie.indexOf('TOR') > -1} />
                          <ListItemText primary={'Toro'} /></MenuItem>
                        <MenuItem value={'NT'}><Checkbox color="primary" checked={especie.indexOf('NT') > -1} />
                          <ListItemText primary={'Novillito'} /></MenuItem>

                      </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel id="type">Raza</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        variant="outlined"
                        fullWidth

                        renderValue={(selected) => selected.join(', ')}
                        value={raza}
                        label="Raza"
                        onChange={(e) => {
                          setRaza(e.target.value)
                          //dataIndex.rowData[16]= e.target.value

                        }}
                      ><MenuItem value={'AA'}>
                          <Checkbox color="primary" checked={raza.indexOf('AA') > -1} />
                          <ListItemText primary={'Angus'} />
                        </MenuItem>
                        <MenuItem value={'MZ'}> <Checkbox color="primary" checked={raza.indexOf('MZ') > -1} />
                          <ListItemText primary={'Mestizo'} />
                        </MenuItem>
                        <MenuItem value={'XX'}> <Checkbox color="primary" checked={raza.indexOf('XX') > -1} />
                          <ListItemText primary={'Cruza'} />
                        </MenuItem>
                        <MenuItem value={'HA'}> <Checkbox color="primary" checked={raza.indexOf('HA') > -1} />
                          <ListItemText primary={'Holando'} />
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel id="type">Conformacion</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        variant="outlined"
                        fullWidth

                        renderValue={(selected) => selected.join(', ')}
                        value={conf}
                        label="conformacion"
                        onChange={(e) => {
                          setConf(e.target.value)
                        }}
                      ><MenuItem value={'Especial'}>
                          <Checkbox color="primary" checked={conf.indexOf('Especial') > -1} />
                          <ListItemText primary={'Especial'} />
                        </MenuItem>
                        <MenuItem value={'MB'}> <Checkbox color="primary" checked={conf.indexOf('MB') > -1} />
                          <ListItemText primary={'Muy Bueno'} />
                        </MenuItem>
                        <MenuItem value={'Bueno'}> <Checkbox color="primary" checked={conf.indexOf('Bueno') > -1} />
                          <ListItemText primary={'Bueno'} />
                        </MenuItem>
                        <MenuItem value={'Regular'}> <Checkbox color="primary" checked={conf.indexOf('Regular') > -1} />
                          <ListItemText primary={'Regular'} />
                        </MenuItem>
                        <MenuItem value={'Malo'}> <Checkbox color="primary" checked={conf.indexOf('Malo') > -1} />
                          <ListItemText primary={'Malo'} />
                        </MenuItem>
                        <MenuItem value={'Conserva'}> <Checkbox color="primary" checked={conf.indexOf('Conserva') > -1} />
                          <ListItemText primary={'Conserva'} />
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel id="type">Gordura</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        variant="outlined"
                        fullWidth

                        renderValue={(selected) => selected.join(', ')}
                        value={Gordura}
                        label="Gordura"
                        onChange={(e) => {
                          setGordura(e.target.value)
                        }}
                      >
                        <MenuItem value={'Flaco'}>
                          <Checkbox color="primary" checked={Gordura.indexOf('Flaco') > -1} />
                          <ListItemText primary={'Flaco'} />
                        </MenuItem>
                        <MenuItem value={'Justo'}> <Checkbox color="primary" checked={Gordura.indexOf('Justo') > -1} />
                          <ListItemText primary={'Justo'} />
                        </MenuItem>
                        <MenuItem value={'Bueno'}> <Checkbox color="primary" checked={Gordura.indexOf('Bueno') > -1} />
                          <ListItemText primary={'Bueno'} />
                        </MenuItem>
                        <MenuItem value={'MB'}> <Checkbox color="primary" checked={Gordura.indexOf('MB') > -1} />
                          <ListItemText primary={'Muy Bueno'} />
                        </MenuItem>
                        <MenuItem value={'Gordo'}> <Checkbox color="primary" checked={Gordura.indexOf('Gordo') > -1} />
                          <ListItemText primary={'Gordo'} />
                        </MenuItem>
                        <MenuItem value={'MG'}> <Checkbox color="primary" checked={Gordura.indexOf('MG') > -1} />
                          <ListItemText primary={'Muy Gordo'} />
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="Observaciones"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={obs}
                        onChange={(e) => { setObs(e.target.value) }}
                        margin="normal"
                        variant="outlined"
                        label="Observaciones"
                        placeholder="Observaciones"
                        type="Observaciones"
                        fullWidth
                        required />
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                      <InputLabel id="type">Caja</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        fullWidth
                        //renderValue={(selected) => selected.join(', ')}
                        value={caja}
                        label="Caja"
                        onChange={(e) => {
                          setCaja(e.target.value)
                          //dataIndex.rowData[16]= e.target.value

                        }}
                      ><MenuItem value={'Negra'}>
                          Negra
                        </MenuItem>
                        <MenuItem value={'Estandar'}>Estandar
                        </MenuItem>
                        <MenuItem value={'Estandar'}>Gold
                        </MenuItem>

                      </Select>
                    </Grid>
                  </Grid>                              {!sumarImg &&

                    <Grid item lg={6} xs={12} sm={12}>
                      <FormControlLabel
                        value="consgi"
                        control={<AddCircleIcon />}
                        className={classes.control}


                        label="Cargar nuevos archivos"
                        onClick={() => { setSumarImg(value => !value) }}
                      />
                      {/* <InputLabel id="type">Cargar nuevos archivos </InputLabel>
                <Checkbox label="Activo" color="primary" onChange={()=>{setSumarImg(value=>!value)}}/> */}
                    </Grid>}
                  {sumarImg &&

                    <Grid item lg={12} md={12} xs={12} sm={12}>
                      <Dropzone
                        classNames={classes.dropZone}
                        styles={{
                          dropzone: {
                            color: theme.palette.primary.main,
                            overflow: 'hidden',
                            margin: '10px 0px',
                            borderColor: theme.palette.tables.tableBorderColor
                          },
                          submitButton: {
                            padding: '15px',
                            fontSize: '1.2em',
                          },
                          preview: {
                            color: theme.palette.primary.main
                          },
                          inputLabel: {
                            color: theme.palette.primary.main
                          }
                        }}
                        getUploadParams={getUploadParams}
                        //onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        accept="image/*,audio/*,video/*"
                        inputContent='Arrastra tus archivos o hace click aqui'
                        submitButtonContent="Guardar Datos"
                        inputWithFilesContent='Sumar otro archivo'
                      />
                    </Grid>}


                  <Grid alignContent='center' justify='center' container spacing={1} lg={12} xs={12} sm={12}>
                    <Grid item lg={3} xs={12} sm={12}>

                      <Button
                        onClick={() => { guardarStock(fecha, valorTropa, corral, cabezas, especie, raza, conf, Gordura, obs) }}

                        /*   disabled={
                            email.length === 0
                          }  */
                        //component={Link} to={`/app/tables`}
                        disabled={disableGuardar === true || raza.length === 0
                          || especie.length === 0
                          || Gordura.length === 0
                          || conf.length === 0}

                        size="large"
                        variant="contained"
                        color="primary"

                        className={classes.createAccountButton}
                      >
                        GUARDAR
                      </Button>
                    </Grid>
                    <Grid item lg={3} xs={12} sm={12}>

                      <Button
                        className={classes.createAccountButton}
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpen(false)}
                      >

                        CANCELAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>

            </Dialog>)
        )}
      </UserStateContext.Consumer>

    </>
  );
}


