import React, { useEffect, useState } from "react";

//MATERIAL UI IMPORTS
import { Grid } from "@mui/material";
import {
  InputLabel,
  CircularProgress,
  Fade,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//ICONS
import { CustomSearchButton } from "../../components/search-button/search-button";

//STYLES
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./styles.css"; // Ruta al archivo CSS

//DATE PICKER IMPORT
import ResponsiveDatePickers from "../../components/dateRangePicker/dateRangePicker";
import moment from "moment";

//DINAMICA TOTAL TABLE IMPORTS
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableFooter from "@material-ui/core/TableFooter";
import UserService from "../../context/UserService";

const CashflowList = () => {
  const [dataTableOcPendientes, setDataTableOcPendientes] = useState([]);

  // controlamos si hay un error
  const [error, setError] = useState(false);


  // estado para flagear si hubo respuesta por parte del backend para manejar el renderizado (o no) de la tabla
  const [dataRespuesta, setDataRespuesta] = useState(true);
  
  //LOADING SETTER
  const [isLoading2, setIsLoading2] = useState(false);

  const dataParaTabla = (data) => {
    const arrayData = data.map((item) => {
      return [
        item["ebeln"],
        item["tropa"],
        item["vencimiento"],
        item["vendedor"],
        item["cabezas"],
        item["fecha_faena"],
        item["pend_factura"],
        item["pend_pago"],
        item["tot_factura"],
        item["tot_pagado"],
        item["total_hacienda"],
        item["grouped_vencimiento"],
        item["grouped_faena"],
      ];
    });
    return arrayData;
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: "gray",
    },
    table: {
      minWidth: 1000,
    },
    subtotales: {
      "& td": {
        backgroundColor: theme.palette.totals.subtotal,
        fontWeight: "bold",
      },
      "& th": {
        backgroundColor: theme.palette.totals.subtotal,
        fontWeight: "bold",
      },
    },
    totales: {
      "& td": {
        backgroundColor: theme.palette.totals.total,
        fontWeight: "bold",
      },
      "& th": {
        backgroundColor: theme.palette.totals.total,
        fontWeight: "bold",
      },
    },
  }));

  const classesTable = useStyles();

  const StyledTableCell = withStyles((theme) => ({
    footer: {
      padding: "10px",
      border: "2px solid",
      borderColor: theme.palette.tables.tableBorderColor,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  // primero agrupamos por vencimiento
  const cantidadPorVencimiento = dataTableOcPendientes.reduce((acc, row) => {
    const key = row[2];
    console.log(row);
    if (!acc[key]) {
      acc[key] = { cantidad: 0, faenas: {} };
    }
    acc[key].cantidad += 1;
    return acc;
  }, {});

  // calculamos subtotales por vencimiento y faena para utilizar despues
  const cantidadPorVencimientoFaena = dataTableOcPendientes.reduce(
    (acc, row) => {
      console.log(row);
      const key = `${row[2]}_${row[5]}`;
      if (!acc[key]) {
        acc[key] = {
          cantidad: 0,
          totalCabezas: 0,
          totalPagado: 0,
          totalFactura: 0,
          totalPendFactura: 0,
          totalPendPagado: 0,
        };
      }
      acc[key].cantidad += 1;
      acc[key].totalCabezas += row[4];
      acc[key].totalPagado += row[9];
      acc[key].totalPendPagado += row[7];
      acc[key].totalFactura += row[8];
      acc[key].totalPendFactura += row[6];
      return acc;
    },
    {},
  );

  // por cada vencimiento, le agregamos sus fechas de faena como keys y un array con las filas que corresponden a esa fecha
  dataTableOcPendientes.forEach((row) => {
    const vencimiento = row[2];
    const faena = row[5];
    if (!cantidadPorVencimiento[vencimiento].faenas[faena]) {
      cantidadPorVencimiento[vencimiento].faenas[faena] = [];
    }
    cantidadPorVencimiento[vencimiento].faenas[faena].push(row);
  });

  // apend the subtotales
  Object.keys(cantidadPorVencimientoFaena).forEach((key) => {
    const [vencimiento, faena] = key.split("_");
    const row = cantidadPorVencimientoFaena[key];
    //['5100042558', '15271/00', '2023-11-13', 'GANANOR PUJOL S.A.', 44, '2023-10-30', 196.46, 0, 21225408.25, 21225408.25, 21225600, false, false]
    const totalRow = [
      "",
      "",
      vencimiento,
      `Subtotal ${moment(faena).format("DD/MM/YY")}`,
      row.totalCabezas,
      faena,
      row.totalPendFactura,
      row.totalPendPagado,
      row.totalFactura,
      row.totalPagado,
      "",
      "",
      "",
    ];
    cantidadPorVencimiento[vencimiento].faenas[faena].push(totalRow);
  });

  let rows = [];
  let usedVencimientos = new Set(); // tracking de cuando ya se uso un vencimiento para no renderizarlo
  const groupedRows2 = Object.keys(cantidadPorVencimiento).map((key, index) => {
    const vencimiento = key;
    const cantidadFechasFaena = Object.keys(cantidadPorVencimiento[key].faenas)
      .length;
    const cantidadVencimiento =
      cantidadPorVencimiento[key].cantidad + cantidadFechasFaena; // + subtotales de faena (1 por cada fecha)
    const faenas = cantidadPorVencimiento[key].faenas;

    const rowsForFaena = Object.keys(faenas).map((faena) => {
      const cantidadPorFaena = faenas[faena].length;
      faenas[faena].forEach((row, subindex) => {
        const isSubtotal = row[3].includes("Subtotal");
        const fila = (
          <StyledTableRow
            key={`${vencimiento}_${faena}_${subindex}`}
            className={isSubtotal ? classesTable.subtotales : ""}
          >
            {!usedVencimientos.has(vencimiento) ? (
              <StyledTableCell
                rowSpan={cantidadVencimiento}
                component="th"
                scope="row"
                align="center"
              >
                {vencimiento}
              </StyledTableCell> /* vencimiento */
            ) : (
              <StyledTableCell style={{ display: "none" }}></StyledTableCell>
            )}
            {subindex === 0 ? (
              <StyledTableCell
                rowSpan={cantidadPorFaena}
                component="th"
                scope="row"
                align="center"
              >
                {faena}
              </StyledTableCell> /* dia de faena */
            ) : (
              <StyledTableCell style={{ display: "none" }}></StyledTableCell>
            )}
            <StyledTableCell align={isSubtotal ? "left" : "right"}>
              {row[3]}
            </StyledTableCell>
            {/* vendedor */}
            <StyledTableCell align="center">{row[1]}</StyledTableCell>
            {/* tropa */}
            <StyledTableCell align="center">{row[4]}</StyledTableCell>
            {/* cabezas */}
            <StyledTableCell align="right">
              {row[8].toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS",
              })}
            </StyledTableCell>
            {/* total factura */}
            <StyledTableCell align="right">
              {row[6].toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS",
              })}
            </StyledTableCell>
            {/* pendiente factura */}
            <StyledTableCell align="right">
              {row[9].toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS",
              })}
            </StyledTableCell>
            {/* total pagado */}
            <StyledTableCell align="right">
              {row[7].toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS",
              })}
            </StyledTableCell>
            {/* pendiente pagado */}
          </StyledTableRow>
        );
        if (!usedVencimientos.has(vencimiento)) {
          usedVencimientos.add(vencimiento);
        }
        rows.push(fila);
      });
    });
  });

  function calcularTotales(dataTableOcPendientes) {
    const totales = dataTableOcPendientes.reduce(
      (acc, row) => {
        acc.totalCabezasFinales += Number(row[4]);
        acc.totalFacturaFinales += Number(row[8]);
        acc.totalPendFacturaFinales += Number(row[6]);
        acc.totalPagadoFinales += Number(row[9]);
        acc.totalPendPagadoFinales += Number(row[7]);
        return acc;
      },
      {
        totalCabezasFinales: 0,
        totalFacturaFinales: 0,
        totalPendFacturaFinales: 0,
        totalPagadoFinales: 0,
        totalPendPagadoFinales: 0,
      },
    );

    return totales;
  }

  // Uso de la función para calcular los totales
  const totales = calcularTotales(dataTableOcPendientes);

  const theme = useTheme();
  //MEDIA QUERY SETTING
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  //DATE SETTINGS
  const [mes, setMes] = useState(() => {
    return "";
  });
  const [week, setWeek] = useState(() => {
    return "";
  });
  const handleFechaChange = () => {
    setMes("");
    setWeek("");
  };

  const [fechaStart, setFechaStart] = useState(() => {
    return moment().add(-7, "days").format("YYYY-MM-DD");
  });
  const [fechaEnd, setFechaEnd] = useState(() => {
    return moment().add(7, "days").format("YYYY-MM-DD");
  });

  const setDateFilter = (event) => {
    setMes("");
    setWeek("");
    setFechaEnd(moment(event.endDate).format("YYYY-MM-DD"));
    setFechaStart(moment(event.startDate).format("YYYY-MM-DD"));
  };

  async function getInitialData() {
    setIsLoading2(true);
    setError(false);
    try {
      let response = await UserService.getOcPendientes(fechaStart, fechaEnd);
      if (response.status == 200) {
        if (response.data.length == 0) {
          setDataRespuesta(false);
        } else {
          setDataRespuesta(true);
          let info = dataParaTabla(response.data);
          setDataTableOcPendientes(info);
        }
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setIsLoading2(false);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  const cargarDataFiltro = async () => {
    setIsLoading2(true);
    setError(false);
    setDataTableOcPendientes([]);
    await UserService.getOcPendientes(fechaStart, fechaEnd).then(
      (response) => {
        if (response.status == 200) {
          if (response.data.length == 0) {
            setDataRespuesta(false);
          } else {
            let info = dataParaTabla(response.data);
            setDataTableOcPendientes(info);
            setDataRespuesta(true);
          }
        }
      },
      (error) => {
        if (error.response.status == 401) {
          console.log(error);
        }
      },
    );
    setIsLoading2(false);
  };

  return (
    <>
      <Grid
        item
        lg={12}
        sm={12}
        style={{ display: "table", tableLayout: "fixed", width: "100%" }}
      >
        <span>
          <h1 className="headingTableTitle">Listado Dinámica total</h1>
        </span>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item spacing={2} lg={3} md={6} sm={12} xs={12}>
            <InputLabel id="type" style={{ padding: "10px" }}>
              Seleccione Rango de Fechas
            </InputLabel>
            <ResponsiveDatePickers
              setValorFecha={setDateFilter}
              fechaInicial={fechaStart}
              fechaFinal={fechaEnd}
              onChange={handleFechaChange}
            ></ResponsiveDatePickers>
          </Grid>
          <Grid item>
            {isLoading2 ? (
              <CircularProgress size={26} style={{ marginTop: "20px" }} />
            ) : (
              <React.Fragment>
                <CustomSearchButton
                  onClick={cargarDataFiltro}
                  margin={{ marginTop: matches ? "30px" : "0px" }}
                  color={theme.palette.primary.main}
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        {!dataRespuesta && !error && !isLoading2 && (
          <Fade in={!dataRespuesta}>
            <Typography
              color="secondary"
              className={`${classesTable.errorMessage} ${classesTable.buttonSubmit}`}
            >
              No hay información en el servidor
            </Typography>
          </Fade>
        )}
        {!isLoading2 && error && (
          <Fade in={error}>
            <Typography
              color="secondary"
              className={`${classesTable.errorMessage} ${classesTable.buttonSubmit}`}
            >
              Hubo un error al cargar los datos. Por favor, intente nuevamente
            </Typography>
          </Fade>
        )}
        {dataRespuesta && !error && !isLoading2 && (
          <div className={classesTable.root}>
            <TableContainer component={Paper}>
              <Grid
                item
                style={{
                  display: "table",
                  tableLayout: "fixed",
                  width: "100%",
                }}
              >
                <Table
                  className={classesTable.table}
                  aria-label="customized table"
                >
                  <Table
                    className={classesTable.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Vencimiento</StyledTableCell>
                        <StyledTableCell>Día de faena</StyledTableCell>
                        <StyledTableCell>Vendedor</StyledTableCell>
                        <StyledTableCell>Tropa</StyledTableCell>
                        <StyledTableCell>Cabezas</StyledTableCell>
                        <StyledTableCell>Total Factura</StyledTableCell>
                        <StyledTableCell>
                          Pendiente&nbsp;Factura
                        </StyledTableCell>
                        <StyledTableCell>Total&nbsp;Pagado</StyledTableCell>
                        <StyledTableCell>Pendiente&nbsp;Pagado</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                    <TableFooter className={classesTable.totales}>
                      <TableRow>
                        <StyledTableCell>Totales</StyledTableCell>
                        <StyledTableCell colSpan={3}></StyledTableCell>
                        <StyledTableCell align="center">
                          {totales.totalCabezasFinales}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {totales.totalFacturaFinales.toLocaleString("es-AR", {
                            style: "currency",
                            currency: "ARS",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {totales.totalPendFacturaFinales.toLocaleString(
                            "es-AR",
                            {
                              style: "currency",
                              currency: "ARS",
                            },
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {totales.totalPagadoFinales.toLocaleString("es-AR", {
                            style: "currency",
                            currency: "ARS",
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {totales.totalPendPagadoFinales.toLocaleString(
                            "es-AR",
                            {
                              style: "currency",
                              currency: "ARS",
                            },
                          )}
                        </StyledTableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Table>
              </Grid>
            </TableContainer>
          </div>
        )}
      </Grid>
    </>
  );
};

export default CashflowList;
