import React, { useState, useEffect } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  IconButton,
  ButtonBaseTypeMap,
  MuiThemeProvider,
  FormLabel,
  ListItemText,
  FormControl,
  Slider,
  createMuiTheme,
  FormControlLabel,
  CircularProgress,
  Fade,
  TextField,
  FormGroup,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import Autocomplete from "@mui/material/Autocomplete";

import { useTheme } from "@material-ui/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  Label,
  LabelList,
  CartesianGrid,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
//import UserStateContext from "../../context/UserContext";
//import jwt_decode from "jwt-decode";
import UserService from "../../context/UserService";
import ResponsiveDatePickers from "../../components/dateRangePicker/dateRangePicker";
import moment from "moment";
import { optionsNormal3 } from "../../components/variables/variables";
import { signOut } from "../../context/UserContext";
import { Card, CardContent } from "@material-ui/core";
import CabezasAgrupadoCategoria from "./components/CabezasAgrupadoCategoria";
import CabezasAgrupadoUsuario from "./components/CabezasAgrupadoUsuario";
import { CustomSearchButton } from "../../components/search-button/search-button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { set } from "date-fns";

const ToggleButton = styled(MuiToggleButton)(
  ({ textColor, selectedColor, borderColor }) => ({
    color: "inherit",
    borderColor: borderColor,
    borderRadius: "15px",
    maxHeight: "60px",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: textColor,
      backgroundColor: selectedColor,
    },
  }),
);

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();

  //const rol = localStorage.getItem("permissions");

  const [alignment, setAlignment] = useState(["left", "left", "left"]);

  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  const [fechaStart, setFechaStart] = useState(() => {
    if (
      localStorage.getItem("fechaStart") != undefined &&
      localStorage.getItem("fechaStart") != "undefined"
    ) {
      return moment(localStorage.getItem("fechaStart")).format("YYYY-MM-DD");
    } else {
      return moment().format("YYYY-MM-DD");
    }
  });
  const [fechaEnd, setFechaEnd] = useState(() => {
    if (
      localStorage.getItem("fechaEnd") != "undefined" &&
      localStorage.getItem("fechaEnd") != undefined
    ) {
      return moment(localStorage.getItem("fechaEnd")).format("YYYY-MM-DD");
    } else {
      return moment().format("YYYY-MM-DD");
    }
  });
  const [autocompleteRemitente, setAutocompleteRemitente] = useState([]);
  const [autocompleteConsig, setAutocompleteConsig] = useState([]);
  const [filtroEspecie, setFiltroEspecie] = useState([]);
  const [despEspecie, setDespEspecie] = useState([]);
  const [remitente, setRemitente] = useState("");
  const [consignatario, setConsignatario] = useState("");
  var [isLoading2, setIsLoading2] = useState(false);
  var [errorServidor, setErrorServidor] = useState(null);

  // cabezas
  let [dataCabezas, setDataCabezas] = useState([]);
  let [ticksCabezas, setTicksCabezas] = useState([]);

  // PesoProm
  let [dataPesoProm, setDataPesoProm] = useState([]);
  let [ticksPesoProm, setTicksPesoProm] = useState([]);

  //rindeHistorico
  let [dataRindeHistoric, setDataRindeHistorico] = useState([]);

  let [dataGolpeados, setDataGolpeados] = useState([]);

  //rindeHistorico
  let [dataPie, setDataPie] = useState([]);

  let [dataParamentros, setDataParametros] = useState([]);

  //agrupado por categoria o usuario
  const [grouping, setGrouping] = useState("none");

  const [filtroUsuarioFaena, setFiltroUsuarioFaena] = useState([]);
  const [despUsuarioFaena, setDespUsuarioFaena] = useState([]);

  //Ocultar o mostrar filtros
  const [showFilters, setShowFilters] = useState(false);

  const isXsOrSm = useMediaQuery(theme.breakpoints.down("md"));

  // Filtro button alligment
  const handleAlignment = (e, i) => {
    if (e.target.value !== null) {
      let temp = alignment.map((i) => i);
      temp[i] = e.target.value;
      setAlignment(temp);
    }
  };

  const handleGroupingChange = (event, newGrouping) => {
    if (newGrouping !== null) {
      setGrouping(newGrouping);
    }
  };

  const handlefiltroEspecie = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroEspecie(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const setDate = (event) => {
    setFechaEnd(moment(event.endDate).format("YYYY-MM-DD"));
    setFechaStart(moment(event.startDate).format("YYYY-MM-DD"));
  };

  // Style Card para kpi
  const cardStyle = {
    border: "1px solid " + theme.palette.tables.tableBorderColor,
    textAlign: "center",
    borderRadius: "8px",
    marginBottom: "10px",
    backgroundColor: theme.palette.background.custom,
    color: theme.palette.tables.headerText,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: `0 5px 10px ${theme.palette.grey[300]}`,
    },
  };

  const colors = [
    theme.palette.dashboard.default,
    theme.palette.dashboard.uno,
    theme.palette.dashboard.dos,
    theme.palette.dashboard.tres,
    theme.palette.dashboard.cuatro,
    theme.palette.dashboard.cinco,
    theme.palette.dashboard.seis,
  ];

  // cargo los estados de los filtros

  useEffect(() => {
    /*  if ( rol==4){
       setMostrarTropa(true)
      getDataTropa()
   
     } */
    /* const dataTropa = localStorage.getItem('Tropa')
    if (dataTropa){
      setdataTropa(JSON.parse(dataTropa))
    }
    const dataRomaneo = localStorage.getItem('Romaneo')
    if ( dataRomaneo){
      setValor(JSON.parse(dataRomaneo))
    } */

    // if (rol == 4 || rol == 7 || rol == 8 || rol == 9) {
    //   signOut(props.history);
    // }

    UserService.getFiltros().then(
      (response) => {
        if (response.status == 200) {
          setDespEspecie(response.data.dataEspecie);
          setDespUsuarioFaena(response.data.dataUsuariosFaena);
        }
      },
      (error) => {
        setErrorServidor(true);
      },
    );
    UserService.getListConsigRemitTropa().then(
      (response) => {
        if (response.status == 200) {
          const dataTropaConsig = response.data.dataConsig.map((a, index) => {
            if (a.rs_consig != null)
              return { cuit: index, label: a.rs_consig.toString() };
          });
          const dataTropaRemit = response.data.dataREmitente.map((a, index) => {
            if (a.rs_produc != null)
              return { cuit: index, label: a.rs_produc.toString() };
          });
          const dataC = dataTropaConsig.filter(function (element) {
            return element !== undefined;
          });
          const dataR = dataTropaRemit.filter(function (element) {
            return element !== undefined;
          });
          setAutocompleteConsig(dataC);
          setAutocompleteRemitente(dataR);
        }
      },
      (error) => {
        setErrorServidor(true);
      },
    );

    let dataChartCabezas = JSON.parse(localStorage.getItem("dataCabezas"));
    let dataChartPesoProm = JSON.parse(localStorage.getItem("dataPesoProm"));
    let dataChartRindeHistorico = JSON.parse(
      localStorage.getItem("dataRindeHistorico"),
    );
    let dataChartMarbling = JSON.parse(localStorage.getItem("dataPieMarbling"));
    let dataChartGolpeados = JSON.parse(localStorage.getItem("dataGolpeados"));
    let dataChartParametros = JSON.parse(
      localStorage.getItem("dataParamentros"),
    );

    let especies = JSON.parse(localStorage.getItem("filtroEspecie"));

    especies != undefined ? setFiltroEspecie(especies) : setFiltroEspecie([]);
    let dataFiltroConsig = localStorage.getItem("consignatario");
    let dataFiltroRemit = localStorage.getItem("remitente");
    /*  if (dataFiltroConsig!='null'&& dataFiltroConsig!=null){
       setConsignatario(dataFiltroConsig)
     }
     if (dataFiltroRemit!='null'&& dataFiltroRemit!=null){
       setRemitente(dataFiltroRemit)
     } */

    setDataCabezas(dataChartCabezas);
    setDataPesoProm(dataChartPesoProm);

    setDataRindeHistorico(dataChartRindeHistorico);
    dataChartMarbling != undefined
      ? setDataPie(dataChartMarbling)
      : setDataPie([]);
    //setDataPie(dataChartMarbling)
    setDataGolpeados(dataChartGolpeados);
    dataChartParametros != undefined
      ? setDataParametros(dataChartParametros)
      : setDataParametros([]);
    //setDataParametros(dataChartParametros)
  }, []);

  const cargarData = () => {
    setIsLoading2(true);
    setErrorServidor(false);
    // borro las variables del storage
    localStorage.removeItem("dataCabezas");
    localStorage.removeItem("dataPesoProm");
    localStorage.removeItem("dataRindeHistorico");
    localStorage.removeItem("dataPieMarbling");
    localStorage.removeItem("dataGolpeados");
    localStorage.removeItem("dataParamentros");
    localStorage.removeItem("fechaStart");
    localStorage.removeItem("fechaEnd");

    //
    localStorage.removeItem("filtroEspecie");
    localStorage.removeItem("remitente");
    localStorage.removeItem("consignatario");

    setDataCabezas([]);
    setDataPesoProm([]);
    setDataRindeHistorico([]);
    setDataPie([]);
    setDataGolpeados([]);
    setDataParametros([]);
    UserService.getDashboard(
      fechaStart,
      fechaEnd,
      filtroEspecie,
      remitente,
      consignatario,
      grouping,
      filtroUsuarioFaena,
    ).then(
      (response) => {
        setIsLoading2(false);
        if (response.status == 200) {
          let dataChartsCabezas = response.data.dataCabezas;
          setDataCabezas(dataChartsCabezas);
          //setTicksCabezas(dataChartsCabezas.map(function(x) {return x.totalCabezas;}).sort(function(a, b) {return b.localeCompare(a)}))
          //setTicksCabezas([...dataChartsCabezas.filter(x => x.totalCabezas)])

          let dataChartPesoProm = response.data.dataPesoProm;
          setDataPesoProm(dataChartPesoProm);

          let dataChartRindeHistorico = response.data.dataRindeHist;
          setDataRindeHistorico(dataChartRindeHistorico);

          let dataChartPieMarbling = response.data.dataMarbling[0]["Marbling"];
          //let dataChartPieMarbling = []
          setDataPie(dataChartPieMarbling);

          let dataChartGolpeados = response.data.dataGolpeados;
          const merged = dataChartGolpeados.reduce(
            (r, { nombreMes, Golpeados }) => {
              const key = `${nombreMes}`;
              if (!r[key]) {
                r[key] = { nombreMes, Golpeados: 0 };
              }
              r[key]["nombreMes"] = nombreMes;

              r[key]["Golpeados"] += parseInt(Golpeados);

              return r;
            },
            {},
          );
          const ordenado = [];
          for (const [key, valor] of Object.entries(merged)) {
            ordenado.push(valor);
          }
          setDataGolpeados(ordenado);

          // let dataChartParametros = response.data.dataParametros
          // let arrayParamentros = []
          // for (const [key, value] of Object.entries(dataChartParametros)) {
          //   arrayParamentros.push(['Ratio Gordura',value['ratio_gordura']])
          //   arrayParamentros.push(['Ratio Tipificación',value['ratio_tipificacion']])
          //   arrayParamentros.push(['Edad Promedio',value['edadProm']])
          //   arrayParamentros.push(['Grasa Dorsal',value['grasa_Dorsal']])

          // }

          let dataChartParametros = response.data.dataParametros;
          let arrayParamentros = [];

          for (const [key, value] of Object.entries(dataChartParametros)) {
            arrayParamentros.push({
              name: "Ratio Gordura",
              value: value["ratio_gordura"],
            });
            arrayParamentros.push({
              name: "Ratio Tipificación",
              value: value["ratio_tipificacion"],
            });
            arrayParamentros.push({
              name: "Edad Promedio",
              value: value["edadProm"],
            });
            arrayParamentros.push({
              name: "Grasa Dorsal",
              value: value["grasa_Dorsal"],
            });
            arrayParamentros.push({
              name: "AYG",
              value: value["AYG"],
            });
          }

          setDataParametros(arrayParamentros);
          //defino variables locales
          localStorage.setItem(
            "dataCabezas",
            JSON.stringify(dataChartsCabezas),
          );
          localStorage.setItem(
            "dataPesoProm",
            JSON.stringify(dataChartPesoProm),
          );
          localStorage.setItem(
            "dataRindeHistorico",
            JSON.stringify(dataChartRindeHistorico),
          );
          localStorage.setItem(
            "dataPieMarbling",
            JSON.stringify(dataChartPieMarbling),
          );
          localStorage.setItem(
            "dataGolpeados",
            JSON.stringify(dataChartGolpeados),
          );
          localStorage.setItem(
            "dataParamentros",
            JSON.stringify(arrayParamentros),
          );

          localStorage.setItem("fechaStart", fechaStart);
          localStorage.setItem("fechaEnd", fechaEnd);

          localStorage.setItem("filtroEspecie", JSON.stringify(filtroEspecie));
          localStorage.setItem("remitente", remitente);
          localStorage.setItem("consignatario", consignatario);
        } else {
          setErrorServidor(true);
        }
      },
      (error) => {
        setIsLoading2(false);
        setErrorServidor(true);
        if (error.response.status == 401) {
          signOut(props.history);
        }
      },
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          <p className={classes.tooltipLabel}>{`${label}`}</p>
          {payload.map((pay) => {
            return (
              <p
                style={{ color: pay.stroke }}
                className="label"
              >{`${pay.name} : ${pay.value}`}</p>
            );
          })}
          {/*         <p className="desc">Anything you want can be displayed here.</p>
           */}{" "}
        </div>
      );
    }

    return null;
  };

  const handlefiltroUsuarioFaena = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroUsuarioFaena(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };


  return (
    <>
      {/* <PageTitle title="Dashboard"  /> */}

      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            customStyle={{
              backgroundColor: theme.palette.dashboard.backgroundWidget,
            }}
          >
            <Grid container alignItems="center" spacing={2}>
              {!showFilters ? (
                <>
                  <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={2} md={4} sm={12} xs={12}>
                      <InputLabel id="type">
                        Selecciona Rango de Fechas
                      </InputLabel>

                      <ResponsiveDatePickers
                        fechaInicial={fechaStart}
                        fechaFinal={fechaEnd}
                        setValorFecha={setDate}
                      ></ResponsiveDatePickers>
                    </Grid>
                    <Grid
                      container
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      justify={isXsOrSm && "center"}
                      alignItems={isXsOrSm && "center"}
                    >
                      <IconButton
                        onClick={() => {
                          setShowFilters(!showFilters);
                        }}
                      >
                        <FilterListIcon
                          sx={{ color: theme.palette.primary.main }}
                          fontSize="large"
                        ></FilterListIcon>
                      </IconButton>
                      {isLoading2 ? (
                        <CircularProgress
                          size={26}
                          style={{ marginTop: "20px" }}
                        />
                      ) : (
                        // <Button

                        //   className={classes.buttonSubmit}
                        //   onClick={() => cargarData()}
                        //   variant="contained"
                        //   color="primary"
                        //   fullWidth
                        // >
                        //   Buscar
                        // </Button>
                        <>
                          <CustomSearchButton
                            onClick={cargarData}
                            color={theme.palette.primary.main}
                          />
                          {errorServidor && (
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              alignItems="center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography color="secondary">
                                No hay informacion en el servidor
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={2}
                >
                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    spacing={2}
                  >
                    <Grid item lg={2} md={4} sm={12} xs={12}>
                      <InputLabel id="type">
                        Selecciona Rango de Fechas
                      </InputLabel>

                      <ResponsiveDatePickers
                        fechaInicial={fechaStart}
                        fechaFinal={fechaEnd}
                        setValorFecha={setDate}
                      ></ResponsiveDatePickers>
                    </Grid>
                    {errorServidor && (
                      <Grid
                        item
                        lg={2}
                        md={4}
                        sm={12}
                        xs={12}
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography color="secondary">
                          No hay informacion en el servidor
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item lg={2} md={6} sm={12} xs={12}>
                    <InputLabel id="Especie">Especie </InputLabel>
                    <Select
                      labelId="Especie"
                      multiple
                      id="Especie"
                      input={<OutlinedInput label="Especie" />}
                      value={filtroEspecie}
                      onChange={handlefiltroEspecie}
                      renderValue={(selected) => selected.join(", ")}
                      fullWidth
                    >
                      {despEspecie.map((val) => {
                        return (
                          <MenuItem key={val.especie} value={val.especie}>
                            <Checkbox
                              color="primary"
                              checked={filtroEspecie.indexOf(val.especie) > -1}
                            />
                            <ListItemText primary={val.especie} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item lg={2} md={6} sm={12} xs={12}>
                    <InputLabel id="type">Consignatario</InputLabel>
                    <Autocomplete
                      disablePortal
                      noOptionsText={"Sin opciones"}
                      //getOptionLabel={(option) => option.label}
                      id="combo-box-demo"
                      options={autocompleteConsig}
                      onChange={(e, value) => {
                        value && value.label != undefined
                          ? setConsignatario(value.label.toString())
                          : setConsignatario("");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} type="text" variant="outlined" />
                      )}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} sm={12} xs={12}>
                    <InputLabel id="combo-box-demo">Remitente</InputLabel>
                    <Autocomplete
                      disablePortal
                      noOptionsText={"Sin opciones"}
                      id="combo-box-demo"
                      options={autocompleteRemitente}
                      onChange={(e, value) => {
                        value && value.label != undefined
                          ? setRemitente(value.label.toString())
                          : setRemitente("");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} type="text" variant="outlined" />
                      )}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} sm={12} xs={12}>
                    <InputLabel id="type">Usuario de faena</InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      multiple
                      id="Marcacomercial"
                      value={filtroUsuarioFaena}
                      label="Marca Comercial"
                      onChange={handlefiltroUsuarioFaena}
                      renderValue={(selected) => selected.join(", ")}
                      fullWidth
                    >
                      {despUsuarioFaena.map((val) => {
                        return (
                          <MenuItem
                            key={val.usuariodescver}
                            value={val.usuariodescver}
                          >
                            <Checkbox
                              color="primary"
                              checked={
                                filtroUsuarioFaena.indexOf(val.usuariodescver) >
                                -1
                              }
                            />
                            <ListItemText primary={val.usuariodescver} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={6}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justify="center"
                    style={{ marginTop: "10px" }}
                  >
                    <ToggleButtonGroup
                      value={grouping}
                      exclusive
                      fullWidth
                      onChange={handleGroupingChange}
                    >
                      <ToggleButton
                        value="category"
                        aria-label="left aligned"
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.toggleButtons.color}
                        selectedColor={theme.palette.toggleButtons.background}
                        classes={{ selected: classes.selected }}
                      >
                        Categoria
                      </ToggleButton>
                      <ToggleButton
                        value="user_faena"
                        aria-label="left aligned"
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.toggleButtons.color}
                        selectedColor={theme.palette.toggleButtons.background}
                        classes={{ selected: classes.selected }}
                      >
                        Usuario
                      </ToggleButton>
                      <ToggleButton
                        value="none"
                        aria-label="left aligned"
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.toggleButtons.color}
                        selectedColor={theme.palette.toggleButtons.background}
                        classes={{ selected: classes.selected }}
                      >
                        No Agrupado
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={4}
                    sm={12}
                    xs={12}
                    justify={isXsOrSm && "center"}
                    alignItems={isXsOrSm && "center"}
                    style={{
                      marginTop: "10px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setShowFilters(!showFilters);
                      }}
                    >
                      <FilterListIcon
                        sx={{ color: theme.palette.primary.main }}
                        fontSize="large"
                      ></FilterListIcon>
                    </IconButton>
                    {isLoading2 ? (
                      <CircularProgress
                        size={26}
                        style={{ marginTop: "20px" }}
                      />
                    ) : (
                      // <Button

                      //   className={classes.buttonSubmit}
                      //   onClick={() => cargarData()}
                      //   variant="contained"
                      //   color="primary"
                      //   fullWidth
                      // >
                      //   Buscar
                      // </Button>
                      <>
                        <CustomSearchButton
                          onClick={cargarData}
                          color={theme.palette.primary.main}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Widget>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            customStyle={{
              backgroundColor: theme.palette.dashboard.backgroundWidget,
            }}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  colorBrightness="secondary"
                >
                  Historico Cabezas
                </Typography>
              </div>
            }
          >
            {grouping === "category" ? (
              <CabezasAgrupadoCategoria data={dataCabezas} colors={colors} />
            ) : grouping === "user_faena" ? (
              <CabezasAgrupadoUsuario data={dataCabezas} colors={colors} />
            ) : (
              <ResponsiveContainer width="100%" minWidth={500} height={350}>
                <BarChart width={200} height={100} data={dataCabezas}>
                  <XAxis
                    dataKey="nombreMes"
                    domain={["dataMin", "dataMax + 1"]}
                  />
                  <YAxis interval="preserveEnd" domain={["auto", "auto"]} />
                  <Bar
                    type="linear"
                    dataKey="Cabezas"
                    stroke={theme.palette.dashboard.default}
                    fill={theme.palette.dashboard.default}
                    strokeWidth={2}
                  >
                    <LabelList
                      dataKey="Cabezas"
                      position="inside"
                      fontWeight="bold"
                      padding="10px"
                    />
                  </Bar>
                  <Legend />
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip />}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Widget>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            customStyle={{
              backgroundColor: theme.palette.dashboard.backgroundWidget,
            }}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  colorBrightness="secondary"
                >
                  Peso Promedio
                </Typography>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
              <BarChart data={dataPesoProm}>
                <XAxis
                  dataKey="nombreMes"
                  domain={["dataMin", "dataMax + 1"]}
                />
                <YAxis interval="preserveEnd" domain={["auto", "auto"]} />

                <Bar
                  type="linear"
                  dataKey="PesoTotProm"
                  stroke={theme.palette.dashboard.default}
                  fill={theme.palette.dashboard.default}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.default,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.default,
                  }}
                />
                <Bar
                  type="linear"
                  dataKey="MEJ"
                  stroke={theme.palette.dashboard.uno}
                  fill={theme.palette.dashboard.uno}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.uno,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.uno,
                  }}
                />
                <Bar
                  type="linear"
                  dataKey="NO"
                  stroke={theme.palette.dashboard.dos}
                  fill={theme.palette.dashboard.dos}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.dos,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.dos,
                  }}
                />
                <Bar
                  type="linear"
                  dataKey="NT"
                  stroke={theme.palette.dashboard.tres}
                  fill={theme.palette.dashboard.tres}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.tres,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.tres,
                  }}
                />
                <Bar
                  type="linear"
                  dataKey="TOR"
                  stroke={theme.palette.dashboard.cuatro}
                  fill={theme.palette.dashboard.cuatro}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.cuatro,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.cuatro,
                  }}
                />
                <Bar
                  type="linear"
                  dataKey="VA"
                  stroke={theme.palette.dashboard.cinco}
                  fill={theme.palette.dashboard.cinco}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.cinco,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.cinco,
                  }}
                />
                {/* <Bar
                  type="linear"
                  dataKey="VAQ"
                  stroke={theme.palette.line1.secondary}
                  fill={theme.palette.line1.secondary}

                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.line1.secondary,
                    strokeWidth: 2,
                    fill: theme.palette.line1.secondary,
                  }}
                />  */}
                <Bar
                  type="linear"
                  dataKey="VQ"
                  stroke={theme.palette.dashboard.seis}
                  fill={theme.palette.dashboard.seis}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.seis,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.seis,
                  }}
                />
                <Tooltip
                  cursor={{ fill: "transparent" }}
                  content={<CustomTooltip />}
                />
                <Legend />
              </BarChart>
              {/*   <LineChart width={200} height={100} data={dataCabezas}>
              <XAxis dataKey="mes" interval="preserveEnd" />
              <YAxis interval="preserveEnd" />
              <Line
                  type="linear"
                  dataKey="totalCabezas"
                  label='Cabezas'
                  stroke={theme.palette.warning.main}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.warning.dark,
                    strokeWidth: 2,
                    fill: theme.palette.warning.main,
                  }}
                />
                <Legend />
                <Tooltip />


            </LineChart> */}
            </ResponsiveContainer>
          </Widget>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            customStyle={{
              backgroundColor: theme.palette.dashboard.backgroundWidget,
            }}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  colorBrightness="secondary"
                >
                  Rinde Historico
                </Typography>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
              {/* <BarChart
                data={dataRindeHistoric}
              >
                <YAxis
                  tickLine={false}
                />
                <XAxis
                  dataKey="nombreMes"
                  tickLine={false}
                  domain={["dataMin", "dataMax + 1"]}
                />
                <Bar
                  type="linear"
                  dataKey="Rinde"
                  stroke={theme.palette.warning.main}
                  fill={theme.palette.warning.main}

                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.warning.dark,
                    strokeWidth: 2,
                    fill: theme.palette.warning.main,
                  }}
                />
                <Tooltip  cursor={{fill: 'transparent'}}content={<CustomTooltip />} />

                <Legend />
              </BarChart> */}
              <LineChart
                data={dataRindeHistoric}
                margin={{
                  top: 20,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="nombreMes" tickLine={false} />
                <YAxis
                  domain={[
                    (dataMin) => Math.min(dataMin, 0.5),
                    (dataMax) => Math.max(dataMax, 0.7),
                  ]}
                  tickCount={5}
                  tickLine={false}
                  tickFormatter={(tick) => `${tick.toFixed(2)}`}
                />
                <CartesianGrid
                  vertical={false}
                  strokeDasharray="3 3"
                  horizontal={false}
                />
                <Line
                  type="monotone"
                  dataKey="Rinde"
                  stroke={theme.palette.dashboard.default}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.default,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.default,
                  }}
                  label={({ value, index }) => `${value.toFixed(2)}`}
                />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
        {/* <Grid item lg={6} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
                  Parámetros de calidad
                </Typography>

              </div>
            }
          >

            <MUIDataTable
                title={''}
                data={dataParamentros}
                options={optionsNormal3}
                  columns={['Parametro','Valor']}>

                  </MUIDataTable>

          </Widget>
        </Grid> */}
        <Grid item lg={6} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            customStyle={{
              backgroundColor: theme.palette.dashboard.backgroundWidget,
            }}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  colorBrightness="secondary"
                >
                  Parámetros de Calidad
                </Typography>
              </div>
            }
          >
            <Grid container spacing={3}>
              {dataParamentros.map((parametro, index) => (
                <Grid item key={index} lg={4} md={6} xs={12}>
                  <Card style={cardStyle}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {parametro.value}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {parametro.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Widget>
        </Grid>

        <Grid item lg={6} md={4} sm={12} xs={12}>
          <Widget
            title="Marbling"
            disableWidgetMenu
            className={classes.card}
            sx={{ color: theme.palette.text.primary }}
            customStyle={{
              backgroundColor: theme.palette.dashboard.backgroundWidget,
            }}
          >
            <Grid container spacing={6}>
              <Grid item lg={12} xs={12}>
                {/* <div className={classes.mainChartHeaderLabels}>
              {dataPie.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography  className={classes.mainChartLegentElement}  >
                        &nbsp;{name}&nbsp;:
                      </Typography>
                      <Typography  className={classes.mainChartLegentElement} color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))}
                  
              </div>  */}
                <ResponsiveContainer width="100%" height={400}>
                  {/* <PieChart width={500} height={500}>
                    <Pie
                      data={dataPie}
                      //outerRadius={160}
                      dataKey="value"
                      label
                    >
                      {dataPie.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          // fill={theme.palette[entry.color].main}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip />

                  </PieChart> */}
                  <PieChart width={500} height={500}>
                    <Pie data={dataPie} dataKey="value" label>
                      {dataPie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                      {dataPie.map((entry, index) => (
                        <Label
                          key={`label-${index}`}
                          position="outside"
                          style={{ fill: entry.color }}
                          content={({ value }) => `${entry.name}: ${value}`}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                      iconType="circle"
                      payload={dataPie.map((entry) => ({
                        id: entry.name,
                        type: "circle",
                        color: entry.color,
                        value: `${entry.name}: ${entry.value}`,
                      }))}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              {/* <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {dataPie.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 12 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{value}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid> */}
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            customStyle={{
              backgroundColor: theme.palette.dashboard.backgroundWidget,
            }}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  colorBrightness="secondary"
                >
                  Evolución Golpeados
                </Typography>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
              <BarChart data={dataGolpeados}>
                <YAxis tickLine={false} />
                <XAxis
                  dataKey="nombreMes"
                  domain={["dataMin", "dataMax + 1"]}
                  tickLine={false}
                />
                <Bar
                  type="linear"
                  dataKey="Golpeados"
                  stroke={theme.palette.dashboard.default}
                  fill={theme.palette.dashboard.default}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.dashboard.default,
                    strokeWidth: 2,
                    fill: theme.palette.dashboard.default,
                  }}
                >
                  <LabelList
                    dataKey="Golpeados"
                    position="inside"
                    fontWeight="bold"
                    padding="10px"
                  />
                </Bar>

                <Tooltip
                  cursor={{ fill: "transparent" }}
                  content={<CustomTooltip />}
                />

                <Legend />
              </BarChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
        {/* {mock.bigStat.map(stat => (
          <Grid item md={4} sm={6} xs={12} key={stat.product}>
            <BigStat {...stat} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Widget
            title="Support Requests"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableWidget}
          >
            <Table data={mock.table} />
          </Widget>
        </Grid> x */}
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
