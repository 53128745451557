import React, { useState,useEffect,useContext  } from "react";
import MUIDataTable from "mui-datatables";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import useStyles from "./styles";
import UserService from "../../context/UserService";
import { Link } from "react-router-dom";
import { Button, MuiThemeProvider, createMuiTheme,  } from "@material-ui/core";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { signOut } from "../../context/UserContext";
import { Grid } from "@mui/material";
import jwt_decodde from "jwt-decode";

import { optionsNormal } from "../variables/variables";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'


// estilo modal
/* const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign:'center',

}; */
const breakpoints = createBreakpoints({})
const getMuiTheme = () =>
      createMuiTheme({
       
        overrides: {
          MUIDataTableBodyCell: {
            root: {
              minWidth: 'max-content',
              minHeight:'1rem',
              Width: 'auto',
              padding:'0px 3px 0px 3px',
              alignSelf:'center'
            }
          },
          MuiTableRow:{
            root:
            {
              verticalAlign: 'baseline'
            }
          },
          MuiPaper:{
            root:{
              color:'#fff',
              backgroundColor:'rgb(35, 35, 45)'
            },},
          MuiTableCell: {
            
            head: {
                fontWeight: "bold",
                color:'#fff',
                backgroundColor:'rgb(35, 35, 45)'
            },
            body:{
              color:'#fff'
            }
        },
        MuiIconButton:{
          root:{color:'#fff',}
        },
        MUIDataTableHeadCell:{
          fixedHeader:{
            backgroundColor:'rgb(35, 35, 45)'

          },
        },
        MuiTablePagination:{
          toolbar:{
            color: 'white'
          }
         
        },
         
          MUIDataTableToolbar: {
            
            filterPaper:{

              [breakpoints.down("xs")]: {
                  maxWidth: '100%'
              }
            },
            actions: {
              display: "flex",
              flex: "initial",
              // move all icons to the right
              "& > span, & > button": {
                order: 99
              },
              // target the custom toolbar icon
              "& > span:last-child, & > button:last-child": {
                order: 1
              },
              // target any icon
              "& > span:nth-child(4), & > button:nth-child(4)": {
                order: 2
              }
            }
          }
        },
        shadows: ['none']


      });

export default function UsuariosDeCargaList(props) {
 
//clases de usuario
  var classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = (value) => {
    setMensaje(`Confirma borrar el usuario:\n ${dataTables[value]['username']}`)
    setFila(value)
    setOpen(true)}


  const handleClose = () => setOpen(false);
  const [dataTables, setdataTables] = useState('')
  const [mensaje, setMensaje]= useState('')
  const [fila, setFila]=useState()

  //borrar usuario
  const borrarUsuario = async(value,history)=>{
    const id = dataTables[fila]['id']
    await UserService.deleteContactodeCarga(id)
    await setTimeout(5000)
    setOpen(false)
    servidorGet()
  }
  var rol = localStorage.getItem('permissions')
  var user = JSON.parse(localStorage.getItem('userData')).user_id

  // Saco la info del servidor nuevamente 
  const servidorGet=()=>{
    UserService.getListContactoDeCarga().then(
      response => {setdataTables(response.data.data);},
      error => {if(error.response.status==401){signOut(props.history)}});
  }

  useEffect(() => {
    UserService.getListContactoDeCarga().then(
      response => {setdataTables(response.data.data);},
      error => {if(error.response.status==401){signOut(props.history)}});
    }, []);
  
    let datatableData = []
        
    for (const [key, value] of Object.entries(dataTables)) {
      datatableData.push([value['username'],value['last_name'],value['telefono'],key])
      }
    localStorage.setItem("dataTableUsuariodeCarga", JSON.stringify(dataTables));
     
    return (

     <>
     
        <MUIDataTable
        data={datatableData}
        options={optionsNormal}
        responsive= "scroll"
        columns={[ "Nombre","Apellido","Numero",{
          name: "Acciones",
          options: {
            filter: true,
            /* sort: false,
            empty: false, */
            setCellHeaderProps: () => ({ style: { minWidth: "120px"}}),
            setCellProps: () => ({ style: { minWidth: "120px"}}),

            customBodyRender: (value, dataIndex, rowIndex) => {
              if (datatableData.length>0){
                // compruebo que no sea el usuario logueado
                if (dataTables[value]['id'] != user ){
                  return (
                    <>
                    <div className={classes.contained}>
                    {(rol== 6 || rol== 1 )  &&
                      <IconButton aria-label="edit" size="medium"
                        color='primary'
                        component={Link} to={`/app/editUsuariosDeCarga/${value}`
                        }>
                        <EditIcon color='primary' fontSize="small" />
                      </IconButton>}
                      {(rol== 6 || rol== 1 )  &&
                      <IconButton aria-label="edit" size="medium"
                        color='primary'
                        onClick={()=>handleOpen(value)}>
                        <DeleteIcon color='primary' fontSize="small" />
                      </IconButton> }
                      </div>
                  <Modal
                      hideBackdrop
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="child-modal-title"
                      aria-describedby="child-modal-description"
                    >
                      <Box 
                      className={classes.Modal}

                      >
                        <h2 id="child-modal-description">
                        {mensaje}

                        </h2>
                        <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            className={classes.buttonModal}
                            onClick={()=>borrarUsuario(value, props.history)}
                            >Confirmar
                          </Button>
                        
                          <Button
                              variant="contained"
                              color="primary"
                              size='large'
                              onClick={handleClose}
                              className={classes.buttonModal}
                              >Cancelar</Button>
                      </Box>
                    </Modal>
                  
                  </>)
                }
               
              }
               
                
                
                  
                
            }
          }
        },
      ]}
       
      />
      </>

    )
    
}
