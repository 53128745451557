import React, { useState,useContext, useEffect} from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  Fade,
  FormControlLabel,
  CircularProgress
}from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import moment from "moment";
import Dropzone from 'react-dropzone-uploader'
import { useHistory } from 'react-router';
import { Preview } from "../variables/variables";

function FormOrganicTropa(props) {
  var user = JSON.parse((localStorage.getItem('userData')))
  var rol = localStorage.getItem('permissions')

  const history = useHistory();
  var classes = useStyles();

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState('');
  
  
  //var userDispatch = useUserDispatch();
  var [fechaAlta, setFechaAlta] = useState(moment().format('YYYY-MM-DD'))
  var [fechaVencimiento,setFechaVencimiento] = useState('')


  // local
  var [titulo, setTitulo]= useState('Organic-Tropa');
  var [cuit, setCuit]= useState(()=>{if(user.cuit!=null){return user.cuit}else{ return ''}});
  var [razonS, setRazonS] = useState(()=>{if(user.razonS!=null){return user.razonS}else{ return ''}});
  var [direccion, setDireccion] = useState("NA");
  var [renspa, setRenspa] = useState("");
  var [tropa, setTropa] = useState("");

  var [provincia, setProvincia] = useState("NA");
  var [telefono, setTelefono] = useState("NA");
  var [email, setEmail] = useState(()=>{if(user.email!=null){return user.email}else{ return ''}});
  var [desplRenspa, setDesplRenspa]= useState([])
  var [desplegables, setDespletables] = useState([])
  var [desplegablesTropa, setDesplegablesTropa] = useState([])

  var [valorInicial, setValorInicial]= useState('');

  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [sumarImg,setSumarImg]= React.useState(true);
  var [Image, setImage] = React.useState([]);

  var [edit,setEdit]= useState(false);
  var [ID,setID]= useState();

  const setDate = (event) => {
    setFechaAlta(moment(event).format("YYYY-MM-DD"))   
    setFechaVencimiento(moment(event).add(1,'year').format("YYYY-MM-DD"))  
  }

 //get Renspa
 const getRenspa = (cuit)=>{
  UserService.getRenspa(cuit).then(
    rensponse=>{
      //console.log(rensponse)
      //console.log(rensponse.data)

      setDesplRenspa(rensponse.data)
    }
  );
  UserService.getTropasPrograma(cuit).then(
    rensponse=>{
      setDesplegablesTropa(rensponse.data)
    }
  )

  
 }

 const toast = (innerHTML,clase) => {
  const el = document.getElementById('toast')
  el.innerHTML = innerHTML
  el.style = clase
}

 const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
    
 // called every time a file's `status` changes
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file,remove }, status) => {   
    if (status === 'headers_received') {

     toast(`${meta.name} Subido!`,'color:#05f105;font-size:1.2rem')
 
    setImage(file)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false) }
    else if (status === 'aborted') {
     toast(`${meta.name}, Error en la subida del archivo...`,'color:red;font-size:1.2rem')
   }
  
  }
  
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files,allFiles) => {

    const fotos = []
    let newData = {  title: "",
    description: "",
    image_url: "", };
  
    files.map(f => fotos.push({
      title: f.meta.name,
    description: f.meta.type,
    image_url: f.meta.previewUrl,
    img:f.file
    }))

    //console.log(fotos)
    setImage(fotos)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false)
    allFiles.forEach(f => f.remove())
    //setDisableGuardar(false)

  }
  
  //funcion para guardar doc
  const guardarDocumento = async ()=>{
    setErrorServidor(false)
    setError('')
    if(edit==false)
    { await UserService.postDocumento(titulo,cuit,razonS,direccion,renspa,provincia,telefono,email,fechaAlta,fechaVencimiento,true,tropa,Image).then(
      response=>{

        if(response.status==201){
          history.push('/app/gestionDoc')
          
        }
        if(response.status==403){
          setErrorServidor(true)
          setError(<p>Ya hay cargado un documento con el CUIT-RENSPA todavia esta vigente</p>)}
        },
      error=>{
          setErrorServidor(true)
          setError(<p>Por favor complete todos los campos</p>)
        
  
      }

      
    )
    }else{
      await UserService.putDocumento(ID,titulo,cuit,razonS,direccion,renspa,provincia,telefono,email,fechaAlta,fechaVencimiento,true,tropa,Image).then(
        response=>{
  
          if(response.status==200){
            history.push('/app/gestionDoc')
            
          }
          },
        error=>{
          setErrorServidor(true)
          setError(<p>Por favor complete todos los campos</p>)
  
        }
      )

    }
   
    /* await UserService.postBienestarAnimales(fechaAlta,fechaVencimiento,cuit,nombreCompania,provincia,localidad,politicaBienestar,descPoliticaBienestar,
      capacitacion,fechaCapacitacion,manga,corral,rampa,manejoAnimales,descripcionManejoAnimales,manejoCarga,
      descripcionManejoCarga,'ariel@ariel.com','','xx',1,'FormBienAnimal').then(
        response=>{

          if(response.status==201){
            history.push('/app/gestionDoc')
            
          }
          },
        error=>{
          let errors=[]

          const data = JSON.parse(error.request.response)
          for (const [key, value] of Object.entries(data)){
            if ( key =='cuitProveedor'){
              errors.push(
                <p>El formulario ya se encuentra cargado, solamente se puede cargar 1 documento por CUIT.</p>
              )
            }else{
            errors.push(
              <p>{key} : {value}</p>
            )}}
          setErrorServidor(true)
          setError(errors)

        }
      ) */
  }
  
  
  useEffect(() => {
    if (user.cuit!=null){
      //setCuit(user.cuit)
      UserService.getRenspa(user.cuit).then(
        rensponse=>{
          //console.log(rensponse)
          setDesplRenspa(rensponse.data)
        }
      );
      UserService.getTropasPrograma(user.cuit).then(
        rensponse=>{
          setDesplegablesTropa(rensponse.data)
        }
      )


    }


  

    }, []);


    useEffect(() => {
        // busco listado de proveedores
   UserService.getListProveedor().then(
    response => {
    if(response.status==200){
      const dataTropaConsig = response.data.users.map(a =>({cuit: a.cuit, label:a.razonS + '-' + a.cuit ,email:a.email}) )
      setDespletables(dataTropaConsig) 
    }
    },
    error => {
      if(error.response.status==401){}
      }
    );


    let dataInicial = props.dataServidor
    if (dataInicial!=undefined){
      setID(dataInicial['id'])

      setFechaAlta(dataInicial['fecha_de_alta'])
      setFechaVencimiento(dataInicial['fecha_de_vencimiento'])
      //setNombreCompania(dataInicial['nombreCompania'])
      setCuit(dataInicial['cuit_productor'])
      setRazonS(dataInicial['razonS'])
      setDireccion(dataInicial['direccion'])
      setRenspa(dataInicial['renspa'])
      setProvincia(dataInicial['ubicacion'])
      setTelefono(dataInicial['telefono'])
      setEmail(dataInicial['email'])
      UserService.getRenspa(dataInicial['cuit_productor']).then(
        rensponse=>{
          //console.log(rensponse)
          setDesplRenspa(rensponse.data)
        })
        setEdit(true)

        const index = desplegables.findIndex(x=>x.label===dataInicial['razonS'])
        setValorInicial(desplegables[index])
    }
    }, []);

    const handleChangeProveedor = (e, value) => {
      if (value && value.cuit!=undefined) {
        //setNombreCompania(value.label.split('-')[0]);
        setCuit(value.cuit.toString());
        setEmail(value.email.toString())
      } else {
        //setNombreCompania('');
        setCuit('');
        setEmail('')}
      }
  
  return (
    <>
      
            <div className={classes.marginNormal}>
            <Fade in={errorServidor}>
                  <Typography color="secondary" className={classes.errorMessage}>
                      {error}
                  </Typography>
                </Fade> 
            </div>

            <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item container spacing={2} lg={12} xs={12} sm={12}>
              {false && 
              <Grid item lg={6} md={6} sm={12} xs={12}> 
                <InputLabel id="type">Fecha de Carga</InputLabel>
                  <ResponsiveDatePickers
                  value={fechaAlta}
                  label="Date desktop"
                  inputFormat="dd-MMMM-yyyyy"
                  setValorFecha={setDate}
                  
                  ></ResponsiveDatePickers> 
              </Grid>}
              <Grid item container spacing={2} lg={12} xs={12} sm={12}>
              {rol!=4 ? ( 

                  <Grid item lg={6} xs={12} sm={12}>                
                    <InputLabel id="razonSocial">Proveedor</InputLabel>

                    <Autocomplete
                      disablePortal
                      noOptionsText={'Sin opciones'}
                      id="combo-box-demo"
                      options={desplegables}
                      value={razonS}

                      onChange={handleChangeProveedor}
                      renderInput={(params) =>(
                        <TextField 
                        {...params} 
                          type="text"
                          variant="outlined"
                        
                            />)}
                    />
                    
                  </Grid> ):(
                    <Grid item lg={6} xs={12} sm={12}>
                    <TextField
                    autoFocus
                    disabled
                    id="razonSocial"
                    variant="outlined"
                    label="Razon Social"
                    InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={razonS}
                    onChange={e => setRazonS(e.target.value)}
                    margin="normal"
                    placeholder="Razon Social"
                    type="text"
                    fullWidth
                    required/>
                  </Grid>

                  )}
                
              {false && <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="Dirección" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={direccion}
                  onChange={e => setDireccion(e.target.value)}
                  margin="normal"
                  placeholder="Dirección"
                  variant="outlined"
                  label="Dirección"
                  type="text"
                  fullWidth/>
                </Grid> } 
                {false &&(
                <Grid item lg={6} xs={12} sm={6}>
                  <InputLabel id="Renspa">Renspa </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"

                    id="sector"
                    value={renspa}
                    label="Completar"
                    onChange={(e)=>{setRenspa(e.target.value);setTropa('-')}}
                    fullWidth
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                    {desplRenspa.map(value=>{
                      return <MenuItem value={value.renspa}>{value.renspa}</MenuItem>

                    })}
                  </Select>
                </Grid>  )}
                

                <Grid item lg={6} xs={12} sm={6}>
                  <InputLabel id="Tropa">Tropa </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"

                    id="sector"
                    value={tropa}
                    label="Completar"
                    onChange={(e)=>{setTropa(e.target.value);setRenspa('-')}}
                    fullWidth
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                    {desplegablesTropa.map(value=>{
                      return <MenuItem value={value.tropa}>Tropa: {value.tropa} - Fecha Faena: {value.fechaFaena} - Dte: {value.dtes} </MenuItem>

                    })}
                  </Select>
                </Grid>  
                {false && 
                <>
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="Provincia|Localidad" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={provincia}
                  onChange={e => setProvincia(e.target.value)}
                  margin="normal"
                  placeholder="Provincia | Localidad"
                  variant="outlined"
                  label="Provincia | Localidad"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="TelefonoContacto" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={telefono}
                  onChange={e => setTelefono(e.target.value)}
                  margin="normal"
                  placeholder="Telefono de contacto"
                  variant="outlined"
                  label="Telefono de contacto"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="email" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  margin="normal"
                  placeholder="Email"
                  variant="outlined"
                  label="Email"
                  type="email"
                  fullWidth/>
                </Grid> </> }
              </Grid>    
              <Grid item lg={12} xs={12} sm={12}>

              <div id="toast"></div>
              </Grid>
              {!sumarImg&& 
                  <Grid item lg={6} xs={12} sm={12}>
                  <FormControlLabel
                          value="consgi"
                          control={<AddCircleIcon/>}  
                          className={classes.control}

                          label="Cargar Certificado"

                          onClick={()=>{setSumarImg(value=>!value)}}
                        />
                
                  </Grid> }
                  {sumarImg&& 

                  <Grid item lg={12} xs={12} sm={12}>

                  <Dropzone
                    classNames={classes.dropZone}
                    styles={{ dropzone: { overflow: 'hidden', margin:'10px 0px'}, submitButton: {padding:'15px',
                    fontSize: '1.2em',}   }}
                    /* getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    onSubmit={handleSubmit} */
                    PreviewComponent={Preview}
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}


                    accept="image/*,.pdf"
                    inputContent='Arrastra tus archivos o haz click aqui'
                   /*  submitButtonContent="Guardar Datos"
                    inputWithFilesContent='Sumar otro archivo' */
                      /> 
                  </Grid>  }
              </Grid>    
              
              <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12} style={{display:'flex', justifyContent: 'center'}}>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    fullWidth

                     onClick={() => 
                      //UserService.generatePDF()
                      guardarDocumento(props.history)
                    }  
                    disabled={
                      
                      tropa.length === 0 ||
                      Image.length===0
                    
                                          } 

             
                    size="large"
                    variant="contained"
                    color="primary"
                    
                    className={classes.createAccountButton}
                    //component={Link} to={`/app/gestionDoc`}
                    >

                  Guardar
                  </Button> 
                </Grid>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    className={classes.createAccountButton}
                    size="large"
                    variant="contained"
                    color="primary" 
                    fullWidth
                    component={Link} to={`/app/gestionDoc`}
                    onClick={props.cerrar}
                    >
                  
                    Cancelar
                  </Button>  
                </Grid>       
              </Grid>      
            </Grid>    
            </div>  
          
    </>    
  );
}
export default FormOrganicTropa;