import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { IconButton } from "@mui/material";
import PrintIcon from "@material-ui/icons/Print";
import logoWhite from "../../images/logo.png";



export default function CustomToolbar({ data, columns,titulo }) {
  const download = () => {
    const img = new Image();
    const doc = new jsPDF('landscape');
    var finalY = doc.lastAutoTable.finalY || 10;

    doc.autoTable({
      //startY: finalY + 20,
      columns:columns,
      autoSize : true,
      pageBreak:'auto',
      bodyStyles: {
        fontSize: 8
      },
      headStyles:{
        fillColor:'blue'
      },
      styles: {  fontSize:8,
        lineWidth : 0.2 ,
        lineColor:'black',
        fontStyle:'bold',
        cellPadding:0.2,
        halign:'center',
        valign:'middle'
    }, // Cells in first column centered and green
      body: data,
      

      didDrawPage: function (data) {
        doc.setFontSize(20);
        doc.setTextColor('40');
        //doc.text(titulo, data.settings.margin.left + 0, 20);
      },
      didParseCell: function (data) {
        if(data.section === 'body' && data.row.raw[1] ==undefined){
            let cells= data.row.cells;
            
            for (const [key, value] of Object.entries(cells)) {
                //console.log(value)
               
                value.styles.fillColor='blue'
                value.styles.textColor='White'

                
            }
        }
    }
    });
    doc.save(`${titulo}.pdf`);
  };
  return (
    <div>
      <IconButton onClick={download}>
        <PrintIcon></PrintIcon>
      </IconButton>
    </div>
  );
}
