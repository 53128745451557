
import React from "react";
import { Grid } from "@material-ui/core";
import CashflowList from "../../components/cashflowList/CashflowList";

export default function Cashflow() {

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={3}>

            <CashflowList></CashflowList>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}
