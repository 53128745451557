import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import jwt_decode from "jwt-decode";

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useStyles from "./styles";

import UserService from "../../context/UserService";
//modal stylio
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign: 'center',

};

// funcion General para editar campos
function Profile(props) {
  var classes = useStyles();
  const { editUser } = useContext(UserStateContext);

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);

  // local
  var [id, setId] = useState('');
  var [nameValue, setNameValue] = useState('');
  var [lastNameValue, setlastNameValue] = useState('');
  var [tipo, setTipo] = useState('');
  var [legajo, setLegajo] = useState('');
  var [rol, setRol] = useState('');
  var [sector, setSector] = useState('');
  var [bp, setBp] = useState('');
  var [razonSocial, setRazonSocial] = useState('');
  var [cuit, setCUIT] = useState('');
  var [email, setEmail] = useState('');
  var [passwordValue, setPasswordValue] = useState("");
  var [passwordValue2, setPassword2Value] = useState("");
  var [active, setActive] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  var [telefono, setTelefono] = useState(0);
  var [enviarEmail, setEnviarEmail] = useState(false)
  var [hideEmail, setHideEmail] = useState(true)
  const [hide, setHide] = useState(false);

  var [feedlot, setFeedlot] = useState(null);
  var [Renspa, setRenspa] = useState("");
  var [titular, setTitular] = useState("");
  var [Localidad, setLocalidad] = useState("");
  var [Partido, setPartido] = useState("");
  var [Provincia, setProvincia] = useState("");
  var [Destino, setDestino] = useState("");

  // info de usuario
  var user = JSON.parse(localStorage.getItem('userData'))

  const nombreCompleto = () => {
    if (nameValue && lastNameValue && tipo == 'Rioplatense')
      return (nameValue + "  " + lastNameValue)
    else if (!nameValue || !lastNameValue)
      return (email)
    else if (nameValue && lastNameValue && tipo == 'Proveedor')
      return (razonSocial)
    else if (nameValue && lastNameValue && tipo == 'Proveedor')
      return (razonSocial)

  }

  useEffect(() => {
    UserService.getUserInfo(user['user_id']).then(
      response => {
        //(response.data[0])
        setId(user['user_id'])
        setNameValue(response.data[0]['username'])
        setlastNameValue(response.data[0]['last_name'])
        setTipo(response.data[0]['type'])
        if (response.data[0]['telefono'] != undefined) {
          setTelefono(response.data[0]['telefono'])
        }
        if (response.data[0]['type'] == 'Proveedor') {
          setHide(true);

        } else {
          setHide(false);

        }
        if (response.data[0]['rol'] == 7 || response.data[0]['rol'] == 8) {
          setHideEmail(false)
        }

        setLegajo(response.data[0]['legajo'])
        setRol(response.data[0]['rol'])
        setSector(response.data[0]['sector'])
        setBp(response.data[0]['sector'])
        setRazonSocial(response.data[0]['razonS'])
        setCUIT(response.data[0]['cuit'])
        setEmail(response.data[0]['email'])
        setActive(response.data[0]['is_active'])

        setFeedlot(response.data[0]['feedlot']["id"])
        setRenspa(response.data[0]['Renspa'])
        setTitular(response.data[0]['titular'])
        setLocalidad(response.data[0]['Localidad'])
        setPartido(response.data[0]['Partido'])
        setProvincia(response.data[0]['Provincia'])
        setDestino(response.data[0]['Destino'])



      },
      error => {
      }
    );
    if (user.last_login == null) {
      setOpen(true)
    }
  }, []);


  const handleCheck = (event) => {
    setTipo(event.target.value);

    if (event.target.value == 'Proveedor') {
      setHide(true);
      setRol('4')

    } else {
      setHide(false);

    }

  };



  return (
    <>
      <PageTitle title="Perfil" />
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title={nombreCompleto()}
            disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div>
              <Typography color="secondary" className={classes.errorMessage}>
                {error}
              </Typography>
            </div>
            <div className={classes.form}>

              <Grid container spacing={2}>


                {!hide && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoFocus
                      id="name"
                      variant="outlined"
                      label="Nombre"

                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={nameValue}
                      onChange={e => setNameValue(e.target.value)}
                      margin="normal"
                      placeholder="Nombre"
                      type="text"
                      fullWidth
                      required />
                  </Grid>)}
                {!hide && (
                  <Grid item xs={12} sm={6}>
                    <TextField id="lastName" InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                      value={lastNameValue}
                      onChange={e => setlastNameValue(e.target.value)}
                      margin="normal"
                      placeholder="Apellido"
                      variant="outlined"
                      label="Apellido"
                      type="text"
                      fullWidth />
                  </Grid>)}

                {hideEmail && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="email"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Email"
                      placeholder="Email"
                      type="email"
                      fullWidth
                      required />
                  </Grid>
                )}
                {hide && (
                  <Grid item lg={6} xs={12} sm={6}>
                    <TextField
                      id="razonS"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={razonSocial}
                      onChange={e => setRazonSocial(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Razón Social"
                      placeholder="Razón Social"
                      type="text"
                      disabled
                      fullWidth />
                  </Grid>)}

                {(!hide && hideEmail) && (
                  <Grid item lg={6} xs={12} sm={6}>
                    <TextField
                      id="legajo"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={legajo}
                      onChange={e => setLegajo(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Legajo"
                      placeholder="Legajo"
                      type="text"
                      fullWidth />
                  </Grid>)}
                {hide && (
                  <Grid item lg={6} xs={12} sm={6}>
                    <TextField
                      id="vp"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={bp}
                      onChange={e => setBp(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="BP"
                      placeholder="BP"
                      disabled

                      type="text"
                      fullWidth />
                  </Grid>)}

                {hide && (
                  <Grid item lg={6} xs={12} sm={6}>
                    <TextField
                      id="cuit"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={cuit}
                      onChange={e => setCUIT(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="CUIT"
                      disabled
                      placeholder="CUIT"
                      type="text"
                      fullWidth />
                  </Grid>)}

                <Grid item xs={12} sm={6}>
                  <TextField id="contraseña" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                    value={passwordValue}
                    onChange={e => setPasswordValue(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    label="Contraseña"
                    placeholder="Contraseña"
                    type="password"
                    fullWidth
                    required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id="contraseña2" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                    value={passwordValue2}
                    onChange={e => setPassword2Value(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    label="Repita Contraseña"
                    placeholder="Repita Contraseña"
                    type="password"
                    fullWidth
                    required />
                </Grid>





                <Grid alignContent='center' justify="center" container spacing={1} lg={12} xs={12} sm={12}>
                  <Grid item lg={3} xs={12} sm={12}>
                    <Modal
                      hideBackdrop
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="child-modal-title"
                      aria-describedby="child-modal-description">
                      <Box className={classes.Modal}>


                        <h2 id="child-modal-description">
                          Por favor cambia tu contraseña y verifica los datos de tu cuenta.
                        </h2>
                        <Button
                          variant="contained"
                          color="primary"
                          size='large'
                          onClick={() => setOpen(false)}
                          className={classes.buttonModal}
                        >Cancelar</Button>
                      </Box>
                    </Modal>
                    <Button
                      onClick={() =>
                        editUser(
                          id,
                          nameValue,
                          passwordValue,
                          passwordValue2,
                          email,
                          nameValue,
                          lastNameValue,
                          cuit,
                          razonSocial,
                          rol,
                          tipo,
                          sector,
                          legajo,
                          bp,
                          telefono,
                          active,
                          enviarEmail,
                          props.history,
                          setIsLoading,
                          setError,
                          '',
                          feedlot,
                          Renspa,
                          titular,
                          Localidad,
                          Partido,
                          Provincia,
                          Destino,
                        )
                      }
                      component={Link} to={`/app/home`}

                      disabled={

                        email.length === 0
                      }
                      size="large"
                      variant="contained"
                      color="primary"

                      className={classes.createAccountButton}
                    >
                      GUARDAR
                    </Button>
                  </Grid>
                  <Grid item lg={3} xs={12} sm={12}>

                    <Button
                      className={classes.createAccountButton}
                      size="large"
                      variant="contained"
                      color="primary"


                      component={Link} to={`/app`}>

                      CANCELAR
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>

          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
export default Profile;