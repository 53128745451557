import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import useStyles from "./style";
import UserService from '../../context/UserService';

   


export default function CardImage(props) {
    const styles = {
        card: {
          maxWidth: 300,
          backgroundColor: "red"
        }
      };
      
      const API_URL= window.config.API_URL;

    const [imageURL,setImageUrl] = React.useState('')
    const [imageID,setImageID] = React.useState('')

    const classes = useStyles();
    const detelePhoto = ()=>{

       UserService.detelePhoto(imageID)
        const Imgdata = props.Imgdata
        const Index = Imgdata.findIndex(x=>x.id==imageID)
        console.log(Index)
        Imgdata.splice(Index, 1)
        props.stateImage(Imgdata)
    }
    React.useEffect (() => {
        const tropa = props.tropa
        
        setImageID(tropa.id)
        setImageUrl(tropa.image_url.replace("/api", ""))
        
        
    
      
    
      }, []);
    

  return (
    <Card
    classes={{root: classes.container} }
    sx={{}}
    >
      <CardMedia
        component="img"
        height="250"
        image={API_URL +imageURL}
        alt="green iguana"
        classes={classes.container }
      />
           
    
      <IconButton onClick={()=>{detelePhoto()}} classes={classes.container} >
            <DeleteIcon color='primary'/>
      </IconButton> 
    </Card>
  );
}