import { makeStyles, Theme } from "@material-ui/core/styles"; import { darken, lighten } from '@mui/material/styles';



export const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '1%',

  },
  containerIndicadores: {
    padding: '10px',
    marginTop: '20px',
    marginBottom: '10px',
    [theme.breakpoints.down("md")]: {
      marginTop: '10px',

      padding: '0px',

    },


  },

  desplegables: {
    backgroundColor: theme.palette.background.default
  },

  containerBACK: {
    //marginTop: '1%',
    backgroundColor: theme.palette.tables.header,//theme.palette.primary.main,
    color: theme.palette.text.primary,
    maxHeight: '8.3%',
    maxHeight: '8.3%',
    /*   [theme.breakpoints.down("sx")]: {
        width: "150%",
      }, */
    overflowY: 'hidden'

  },
  tablaResumen: {
    justifyContent: 'center'
  },
  containerBACK2: {
    height: '8.3%',
    marginTop: '30px',

    [theme.breakpoints.down("md")]: {
      marginTop: '10px',
      fontSize: '4px',

      padding: '0px',

    },

  },
  disabledButton: {
    backgroundColor: 'rgba(83, 109, 254,0.6) !important',
    color: 'rgba(255, 255, 255,0.6) !important'
  },
  containerLogin: {
    width: "40%", display: "flex",
  },
  imgen: {
    width: "100%"
  },
  buttonSubmit: {
    padding: '15px',
    fontSize: '1.2em',
    marginTop: '10px',
    textAlign: 'center',
    justifyContent: 'center'
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    //marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  buttonModal: {
    margin: '10px'
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  customLink: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  totalCell: {
    borderRight: '0px',
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '18px'
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold'
    },
  },
  LeftedHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
      fontWeight: 'bold'
    },
  },
  selected: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary
    }
  },
  autoSize: {
    display: 'inline-block',
  },
  totales: {
    '& td': {
      backgroundColor: theme.palette.totals.total,//'#0064c7',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: 15,
      // border: '0px',
      // borderTop: '2px solid ' + theme.palette.tables.tableBorderColor,
    },
    "& .MuiDataGrid-cell:nth-child(4)": {
      borderRight: "3px solid " + theme.palette.primary.main
    },
  },
  total_general: {
    '& td': {
      backgroundColor: theme.palette.totals.general,//'#0064c7',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 16,
      borderBottom: '5px solid ' + theme.palette.tables.tableBorderColor + ' !important',
    },
    '& td > div > div > a': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 16,
    },
    "& .MuiDataGrid-cell:nth-child(4)": {
      borderRight: "3px solid " + theme.palette.primary.main
    },
  },
  subtotales: {
    '& td': {
      backgroundColor: theme.palette.totals.subtotal,//'#0064c7',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      // border: '0px',
      // borderButtom: '2px solid ' + theme.palette.tables.tableBorderColor,
      // borderTop: '2px solid ' + theme.palette.tables.tableBorderColor,
    },
    "& .MuiDataGrid-cell:nth-child(4)": {
      borderRight: "3px solid " + theme.palette.primary.main
    },
  },
  maxRowHeight: {
    height: '10px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.totals.subtotal,
      color: theme.palette.text.primary
    }
  },
  zoomOut: {
    transform: 'scale(0.8)'
  },
  rojo: {
    color: theme.palette.spotlight.red,
    padding: 0
  },
  amarillo: {
    color: theme.palette.spotlight.yellow,
    padding: 0

  },
  verde: {
    color: theme.palette.spotlight.green
  },
  violeta: {
    color: theme.palette.info.main
  },
  success: {
    color: 'orange'
  },
  NameCell: {
    fontWeight: 900,
  },
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '50%',
    padding: '10px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid ' + theme.palette.tables.tableBorderColor,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center',
  },
  ModalEmail: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    maxWidth: '50%',
    padding: '10px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid ' + theme.palette.tables.tableBorderColor,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center',
  },
  ModalRespuestas: {
    position: 'absolute',
    top: '50%',
    display: 'inline-block',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    //maxWidth: '50%',
    padding: '10px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid ' + theme.palette.tables.tableBorderColor,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center',
  },
  textarea: {
    fontFamily: 'Lato',
    fontSize: '14px',
    width: '100%',
    borderRadius: '5px',
    transition: 'border-color 0.3s ease-in-out',
    border: '1px solid ' + theme.palette.primary.main,
    background: theme.palette.textarea.background,
    color: theme.palette.textarea.color,
    '&:focus': {
      borderColor: theme.palette.primary.main,
      border: '2px solid ' + theme.palette.primary.main
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      border: '2px solid ' + theme.palette.primary.main
    }
  },
  cabezas: {
    [theme.breakpoints.down("sm")]: {
      display: 'none',
    },
  },
  tablaDetalle: {
    textAlign: 'center',
    backgroundColor: 'red'
  }

}));
