import React, { useState, useEffect, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { withRouter } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TextField, ListItemText} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useStyles from "./styles";
import UserService from "../../context/UserService";
import { Button, CircularProgress, Fade } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import UserStateContext, { signOut } from "../../context/UserContext";
import { Grid } from "@mui/material";
import Chip from '@mui/material/Chip';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";
import ResponsiveDatePickers from "../../components/dateRangePicker/dateRangePicker";
import { CustomSearchButton } from "../../components/search-button/search-button";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from "@mui/material"
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';


const breakpoints = createBreakpoints({})

const optionsDtes = {
  responsive: 'standard',
  fixedSelectColumn: true,
  fixedHeader: true,
  filterType: 'multiselect',
  pagination: true,
  page: 0,
  sort: false,
  filter: true,

  textLabels: {
    body: {
      noMatch: "No hay datos",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Próxima Página",
      previous: "Página anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtros de tabla",
    },
    filter: {
      all: "TODOS",
      title: "FILTROS",
      reset: "REINICIAR",
    },
    viewColumns: {
      title: "Mostrar columnas",
      titleAria: "Mostrar/Ocultar columnas de tabla",
    },
    selectedRows: {
      text: "Fila(s) Elegidas",
      delete: "Borrar",
      deleteAria: "Borrar filas seleccionadas",
    },
  },
  selectableRowsHideCheckboxes: true,
};

function DteList(props) {
  //funcion para borrar usuario  
  const { deleteUser } = useContext(UserStateContext);
  const { darkMode } = useContext(UserStateContext);
  const theme = useTheme();
  const { user } = useContext(UserStateContext);
  const isAuthorizedToEdit = user.permissions.dte_can_edit === true

  //clases de stylos
  var classes = useStyles();

  var isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  var [isLoading, setIsLoading] = useState(false);
  var [errorServidor, setErrorServidor] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [openBorrar, setOpenBorrar] = React.useState(false);

  const [dataTables, setdataTables] = useState('')
  const [mensaje, setMensaje] = useState('')
  const [valor, setValor] = useState('')
  const [id, setId] = useState()
  const [valorInicialDte, setValorInicialDte] = React.useState();
  const [dte, setDte] = useState([])
  const [desplegables, setDespletables] = useState([])

  const [fechaDesde, setFechaDesde] = useState(moment().add(-10, 'days').format('YYYY-MM-DD'))
  const [fechaHasta, setFechaHasta] = useState(moment().add(2, 'days').format('YYYY-MM-DD'))

  const [fechaDesdeTropa, setFechaDesdeTropa] = useState(moment().add(-3, 'days').format('YYYY-MM-DD'))
  const [fechaHastaTropa, setFechaHastaTropa] = useState(moment().format('YYYY-MM-DD'))

  const [apiResponses, setApiResponses] = useState([{}]);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);

  const [tropasPendientesDte, setTropasPendientesDte] = useState([]);

  const [openInfo, setOpenInfo] = useState(false);

  const handleOpenInfo = () => {
    setOpenInfo(true);
  }

  const handleCloseInfo = () => {
    setOpenInfo(false);
  }

  // Fechas
  const setDate = (event) => {
    setFechaHasta(moment(event.endDate).format("YYYY-MM-DD"))
    setFechaDesde(moment(event.startDate).format("YYYY-MM-DD"))
  }

  // abrir modal
  const handleOpen = (value) => {

    const id_compra = value[0]
    const consignatario = value[1]
    const remitente = value[2]
    const cabezas = value[3]
    const dte = { value: value[0], label: value[0] + '- Consig: ' + (consignatario != null ? consignatario : '') + '- Remitente: ' + (remitente != null ? remitente : '') + '- Cabezas: ' + cabezas }

    setValorInicialDte(dte)
    /*   console.log(dte)
      console.log(desplegables)
      const pos = desplegables.findIndex(item => item.value === dte);
      console.log(pos) */

    // fila de la los datos para dsp buscar el ID
    setId(value[0])
    setMensaje(
      <p>
        Por favor seleccionar el o los dte para la compra {id_compra}: <br />
        <b>Consignatario:</b> {consignatario} <br />
        <b>Remitente:</b> {remitente} <br />
        <b>Cabezas:</b> {cabezas}<br />
      </p>)
    setOpen(true)

  }

  useEffect(() => {
    setFechaDesdeTropa(moment(fechaHasta).add(-3, 'days').format('YYYY-MM-DD'))
    setFechaHastaTropa(moment(fechaHasta).format('YYYY-MM-DD'))
  }, [fechaHasta])

  const handleOpenBorrar = (value) => {
    setDte(value[0])

    setId(value[1])

    setOpenBorrar(true)

  }
  const handleClose = () => setOpen(false);
  const handleCloseBorrar = () => setOpenBorrar(false);

  const handleCloseFeedback = (event, reason) => {
    setOpen(false);
    setOpenFeedback(false);
    servidorGET();
    setApiResponses([{}]);

  }

  //Asigno dte a compra
  const asignarDte = async (history) => {
    setOpenFeedback(false);
    setWaitingResponse(true);
    const dteArray = dte.map(a => ({ dte: a.value }))
    const consolidacion = dteArray.length > 1 ? true : false

    // promise all for each dte
    let responses = await Promise.all(dteArray.map((dte) => {
      return UserService.putSingleCompraDte(id, dte.dte, consolidacion)
        .catch(error => ({ error: true, message: error.message, dte: dte.dte, compra_id: id, status: error.response.status }))
    }))

    setApiResponses(responses.map((response) => {
      if (response.error) {
        return ({
          status: response.status,
          dte: response.dte,
          id_compra: response.compra_id,
          success: false
        })
      }
      return ({
        status: response.status,
        dte: response.data.dte,
        id_compra: response.data.compra_id,
        success: response.status === 201
      })
    }))

    setOpenFeedback(true)
    setWaitingResponse(false);
  }

  //Borro dte a compra
  const borrarDte = async (history) => {
    setWaitingResponse(true);
    const dteArray = dte
    for (let index = 0; index < dteArray.length; index++) {
      const element = dteArray[index];
      //console.log(element['dte'])
      let dte = element['dte']
      await UserService.dteDelete(dte).then(function (response) {
        if (response.status == 401) { signOut(history) }
      })

    }


    //funcion para borrar compra. Sino esta logueado hace signout. 

    // wait 0.5 seconds before sending the next request
    // pedir nuevamente la info del servidor
    servidorGET()
    setOpenBorrar(false)
    setWaitingResponse(false);

  }
  //var rol = localStorage.getItem('permissions')
  //var user = JSON.parse((localStorage.getItem('userData'))).user_id

  // Saco la info del servidor nuevamente 
  const servidorGET = () => {
    setIsLoading(true);
    setdataTables('')
    setDespletables([])
    setTropasPendientesDte([])
    // wait 0.5 seconds before sending the next request
    getDataTropasPendentes()
    Promise.all([
      UserService.getComprasList(undefined, undefined, 'Faena', fechaDesde, fechaHasta),
      UserService.getDte()
    ]).then(
      ([comprasResponse, dteResponse]) => {

        setdataTables(comprasResponse.data.data);

        if (dteResponse.status === 200) {
          const dataDte = dteResponse.data.map(a => ({
            value: a.dte,
            id: a.dte,
            label: a.dte + ' - Consig: ' + (a.consignatario != null ? a.consignatario : '') + ' - Remit: ' + (a.titular != null ? a.titular : '') + ' - Cabezas: ' + a.total_despachados + ' - Estado: ' + a.tipo_mov
          }));
          setDespletables(dataDte);
        }

        setIsLoading(false);
      }
    ).catch(error => {
      if (error.response.status === 401) {
        signOut(props.history);
        setErrorServidor(true);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getInfoInicial()

  }, [props.history]);

  const getInfoInicial = async () => {
    setIsLoading(true);
    try {
      getDataTropasPendentes()
      let response = await UserService.dteControl();
      // wait 0.5 seconds before sending the next request
      await new Promise(resolve => setTimeout(resolve, 500));
      if (response.status == 200) {
        let responses = await Promise.all(
          [
            UserService.getDte(),
            UserService.getComprasList(undefined, undefined, 'Faena', fechaDesde, fechaHasta),
          ])
        if (responses[0].status == 200) {
          const dataDte = responses[0].data.map(a => ({ value: a.dte, id: a.dte, label: a.dte + ' - Consig: ' + (a.consignatario != null ? a.consignatario : '') + ' - Remit: ' + (a.titular != null ? a.titular : '') + ' - Cabezas: ' + a.total_despachados + ' - Estado: ' + a.tipo_mov }))
          setDespletables(dataDte)
        }
        if (responses[1].status == 200) {
          setdataTables(responses[1].data.data
          );
        }
      }
    }
    catch (error) {
      signOut(props.history)
    }
    finally {
      setIsLoading(false);
    }
  }

  const getDataTropasPendentes = async () => {
    let response = await UserService.getTropasPendientesDte(fechaDesdeTropa, fechaHastaTropa)
    let newData = []
    for (let [key, value] of Object.entries(response.data)) {
      newData.push(
        [
          value['ebeln'],
          value['tropa'],
          value['usuario_verd'],
          value['fecha_rec'],
          value['faedate'],
          value['rs_produc'],
          value['rs_consig'],
          value['dte']
        ]
      )
    }
    setTropasPendientesDte(newData)
  }

  //array con la data separada  
  let CompasDte = []
  let ComprasSinDte = []


  for (const [key, value] of Object.entries(dataTables)) {
    if (value['dteList'] != null) {
      if (value['tipo_de_compra'] == 'Faena') {
        CompasDte.push([value['dteList'], value['id'], value['consignatario'], value['remitente'], value['cabezas_compradas'], value['fecha_de_compra'], value['fecha_de_faena'], key])
      } else {
        CompasDte.push([value['dteList'], value['id'], value['consignatario'], value['remitente'], value['cabezas_compradas'], value['fecha_de_compra'], value['fecha_de_carga'], key])
      }
    } else {
      if (value['tipo_de_compra'] == 'Faena') {
        ComprasSinDte.push([value['id'], value['consignatario'], value['remitente'], value['cabezas_compradas'], value['fecha_de_compra'], value['fecha_de_faena'], key])

      } else {
        ComprasSinDte.push([value['id'], value['consignatario'], value['remitente'], value['cabezas_compradas'], value['fecha_de_compra'], value['fecha_de_carga'], key])
      }
    }
  }
  //localStorage.setItem("datatableData", JSON.stringify(dataTables));

  return (
    <>
      <Grid container item spacing={2} lg={12} md={12} sm={12} xs={12} justifyContent={isSmallScreen && "center"}>
        <Grid item spacing={2} lg={2} md={6} sm={8} xs={8}>
          <ResponsiveDatePickers
            //value={fechaHasta}
            startDate={fechaDesde}
            endDate={fechaHasta}
            fechaInicial={fechaDesde}
            fechaFinal={fechaHasta}
            setValorFecha={setDate}
          ></ResponsiveDatePickers>
        </Grid>
        <Grid item spacing={2} lg={2} md={6} sm={3} xs={3}>
          {isLoading ?
            <CircularProgress size={26} className={classes.loginLoader} />
            :
            <>
              <CustomSearchButton
                onClick={servidorGET}
                color={theme.palette.primary.main}
              />
              <Tooltip title="Informacion">
                <IconButton aria-label="Informacion" onClick={handleOpenInfo}>
                  <HelpIcon style={{ color: theme.palette.primary.main }} />
                </IconButton>
              </Tooltip>
            </>
          }
          {errorServidor && (
            <div>
              <Fade in={errorServidor}>
                <Typography color="secondary" className={classes.errorMessage}>
                  No hay informacion en el servidor
                </Typography>
              </Fade>
            </div>
          )}
        </Grid>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>

        {/* <MuiThemeProvider theme={getMuiTheme()}>  */}
        <MUIDataTable
          title={
            <span>
              {"Listado de Compras sin Dte: "}
              <strong>{moment(fechaDesde).format("DD/MM/YY")}</strong>
              {" - "}
              <strong>{moment(fechaHasta).format("DD/MM/YY")}</strong>
            </span>
          }
          data={ComprasSinDte}
          options={optionsDtes}
          columns={[{
            name: "Id Compra",
            options: {
              setCellHeaderProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center', alignItems: 'center' } })
            }
          },
          {
            name: "Consignatario",
            options: {
              setCellHeaderProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              setCellProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
            }
          },
          {
            name: "Remitente",
            options: {
              setCellHeaderProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              setCellProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
            }
          },
          {
            name: "Cabezas",
            options: {
              setCellHeaderProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center' } })
            }
          }, {
            name: "Compra",
            options: {
              setCellHeaderProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } })
            }
          }, {
            name: "Carga",
            options: {
              setCellHeaderProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } })
            }
          }, {
            name: "Acciones",
            options: {
              filter: true,
              display: isAuthorizedToEdit,
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: { minWidth: "70px", maxWidth: "70px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "70px", maxWidth: "70px", textAlign: 'center' } }),

              customBodyRender: (value, tableMeta, updateValue) => {


                // compruebo que no sea el usuario logueado
                return (
                  <>
                    <div className={classes.contained}>
                      {(isAuthorizedToEdit) &&
                        <IconButton aria-label="edit" size="medium"
                          color='primary'
                          onClick={() => handleOpen(tableMeta.rowData)}
                          style={{ height: '22px', alignItems: 'center' }}
                        >
                          <EditIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main, }} />
                        </IconButton>}

                    </div>

                  </>)


              }
              //corchete de options de la ultima coluimna
            }
          },]} // aca terina Columns
        />
        {/* </MuiThemeProvider> */}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* <MuiThemeProvider theme={getMuiTheme()}> */}
        <MUIDataTable
          title={
            <span>
              {"Listado de Tropas Pendientes de Dte: "}
              <strong>{moment(fechaDesdeTropa).format("DD/MM/YY")}</strong>
              {" - "}
              <strong>{moment(fechaHastaTropa).format("DD/MM/YY")}</strong>
            </span>
          }
          data={tropasPendientesDte}
          options={optionsDtes}
          columns={[
            {
              name: "ebeln",
              options: {
                display: false,
                setCellHeaderProps: () => ({ style: { maxWidth: "60px", textAlign: 'center' } }),
                setCellProps: () => ({ style: { maxWidth: "60px", textAlign: 'center', alignItems: 'center' } })
              }
            },
            {
              name: "Tropa",
              options: {
                setCellHeaderProps: () => ({ style: { minWidth: "70px", maxWidth: "70px", textAlign: 'center' } }),
                setCellProps: () => ({ style: { minWidth: "70px", maxWidth: "70px", textAlign: 'center', alignItems: 'center' } })
              }
            },
            {
              name: "Usuario",
              options: {
                setCellHeaderProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center' } }),
                setCellProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center', alignItems: 'center' } })
              }
            },
            {
              name: "Recepcion",
              options: {
                setCellHeaderProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } }),
                setCellProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center', alignItems: 'center' } })
              }
            },
            {
              name: "Faena",
              options: {
                setCellHeaderProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } }),
                setCellProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center', alignItems: 'center' } })
              }
            },
            {
              name: "Productor",
              options: {
                setCellHeaderProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
                setCellProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
              }
            },
            {
              name: "Consignatario",
              options: {
                setCellHeaderProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
                setCellProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
              }
            },
            {
              name: "Dte",
              options: {
                setCellHeaderProps: () => ({ style: { minWidth: "95px", maxWidth: "95px", textAlign: 'center' } }),
                setCellProps: () => ({ style: { minWidth: "95px", maxWidth: "95px", textAlign: 'center', alignItems: 'center' } })
              }
            }
          ]}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* <MuiThemeProvider theme={getMuiTheme()}> */}
        <MUIDataTable
          title={
            <span>
              {"Listado de Compras con Dte: "}
              <strong>{moment(fechaDesde).format("DD/MM/YY")}</strong>
              {" - "}
              <strong>{moment(fechaHasta).format("DD/MM/YY")}</strong>
            </span>
          }
          data={CompasDte}
          options={optionsDtes}
          columns={[{
            name: "Dte",
            options: {
              filter: true,
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: { minWidth: "30px" } }),
              setCellProps: () => ({ style: { minWidth: "30px" } }),

              customBodyRender: (value, tableMeta, updateValue) => {
                // compruebo que no sea el usuario logueado
                return (
                  <>
                    <div className={classes.contained} style={{ justifyContent: 'center', display: 'flex' }}>
                      {value.map(val => val.dte).join(' - ')}
                    </div>
                  </>)
              }
              //corchete de options de la ultima coluimna
            }
          }, {
            name: "Id Compra",
            options: {
              setCellHeaderProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center', alignItems: 'center' } })
            }
          },
          {
            name: "Consignatario",
            options: {
              setCellHeaderProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              setCellProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
            }
          },
          {
            name: "Remitente",
            options: {
              setCellHeaderProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              setCellProps: () => (isSmallScreen && { style: { minWidth: "100px", maxWidth: "180px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } })
            }
          },
          {
            name: "Cabezas",
            options: {
              setCellHeaderProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "60px", maxWidth: "60px", textAlign: 'center' } })
            }
          },
          {
            name: "Compra",
            options: {
              setCellHeaderProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } })
            }
          },
          {
            name: "Carga",
            options: {
              setCellHeaderProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "85px", maxWidth: "85px", textAlign: 'center' } })
            }
          }, {
            name: "Acciones",
            options: {
              filter: true,
              display: isAuthorizedToEdit,
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: { minWidth: "70px", maxWidth: "70px", textAlign: 'center' } }),
              setCellProps: () => ({ style: { minWidth: "70px", maxWidth: "70px", textAlign: 'center' } }),
              customBodyRender: (value, tableMeta, updateValue) => {


                // compruebo que no sea el usuario logueado
                return (
                  <>
                    <div className={classes.contained}>
                      {(isAuthorizedToEdit) &&
                        <IconButton aria-label="edit" size="medium"
                          color='primary'
                          //onClick={() => handleOpen(tableMeta.rowData)}
                          style={{ height: '22px', alignItems: 'center' }}
                        >
                          <DeleteIcon aria-label="edit" size="medium"
                            color='primary'
                            style={{ color: theme.palette.primary.main, height: '22px', alignItems: 'center' }}
                            onClick={() => handleOpenBorrar(tableMeta.rowData)}

                          >
                            {/* <EditIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main, height: '22px', alignItems: 'center' }} /> */}
                          </DeleteIcon>
                        </IconButton>}
                    </div>
                  </>)
              }
              //corchete de options de la ultima coluimna
            }
          },]} // aca terina Columns
        />
        <Modal
          //hideBackdrop
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"

        >
          <Box className={classes.Info}
            sx={{
              backgroundColor: theme.palette.background.main,
              color: theme.palette.text.primary,
              borderColor: '3px solid ' + theme.palette.borderColor,
              width: '80%', // Adjust the width as needed
              height: '80%', // Adjust the height as needed
            }}
          >
            <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
              <h2 id="alert-dialog-title">Asignar Dte</h2>
            </div>
            <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
              {mensaje}
            </div>
            <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} justify='center' style={{ justifyContent: 'center', display: 'flex' }}>
              <Grid item spacing={2} lg={10} md={10} sm={12} xs={12}>

                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  noOptionsText={'Sin opciones'}
                  id="combo-box-demo"
                  //options={desplegables}
                  classes={classes}
                  variant="outlined"
                  options={desplegables}
                  sx={{
                    '& input': {
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.text.primary
                    }
                  }}

                  //sx={{input :{color:'white'}}}
                  // getOptionLabel={(option) => option.label}
                  //defaultValue={valorInicialDte[0].label}
                  getOptionLabel={(option) => option.label}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip size="small" classes={{ label: classes.chip }} variant="default" color="primary" label={option.id} {...getTagProps({ index })} />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}
                      style={{
                        backgroundColor: selected ? theme.palette.totals.subtotal : null
                      }}
                    >
                      {/* <Checkbox
                        checked={selectedDtes.has(option.id)}
                        style={{ marginRight: 8 }}
                      /> */}
                      <ListItemText primary={option.label} />
                    </li>
                  )}

                  onChange={(e, value) => { if (value) { setDte(value) } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      variant="standard"
                      color="primary"

                    />)}
                />
              </Grid>
            </Grid>
            <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
              {!waitingResponse ? (
                <Button
                  variant="contained"
                  color="primary"
                  size='large'
                  className={classes.buttonModal}
                  onClick={() => { asignarDte(props.history) }}
                >Confirmar
                </Button>
              ) : (
                <CircularProgress size={18} className={classes.loginLoader} />
              )}
              <Button
                variant="text"
                color="primary"
                size='large'
                disabled={waitingResponse}
                onClick={handleClose}
                className={classes.buttonModal}
              >Cancelar</Button>
            </div>
          </Box>
        </Modal>
        <Modal
          //hideBackdrop
          open={openBorrar}
          onClose={handleCloseBorrar}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box className={classes.Modal}
            sx={{
              backgroundColor: theme.palette.background.main,
              color: theme.palette.text.primary,
              borderColor: '3px solid ' + theme.palette.borderColor,
            }}
          >

            <h2 id="child-modal-description">
              ¿Esta seguro que quiere borrar los dte para la compra?
            </h2>
            {!waitingResponse ? (
              <Button
                variant="contained"
                color="primary"
                size='large'
                className={classes.buttonModal}
                onClick={() => { borrarDte(props.history) }}
              >Confirmar
              </Button>
            ) : (
              <CircularProgress size={18} className={classes.loginLoader} />
            )}
            <Button
              variant="text"
              color="primary"
              size='large'
              disabled={waitingResponse}
              onClick={handleCloseBorrar}
              className={classes.buttonModal}
            >Cancelar</Button>
          </Box>
        </Modal>
        {apiResponses.map((response, index) => {
          return (
            <Snackbar
              open={openFeedback}
              autoHideDuration={2000}
              onClose={handleCloseFeedback}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              style={{ bottom: `${index * 70}px` }} >
              {response.success === true ? (
                <Alert elevation={6} variant="filled" severity={"success"}>
                  <AlertTitle>{`Dte ${response.dte} asignado a ${response.id_compra}`}</AlertTitle>
                </Alert>
              ) : (
                <Alert elevation={6} variant="filled" severity={"error"}>
                  <AlertTitle>{`Ocurrio un error al asignar el dte ${response.dte} a la compra ${response.id_compra}`}</AlertTitle>
                </Alert>
              )}
            </Snackbar>)
        })}
        {/* </MuiThemeProvider>  */}
      </Grid >
      <Modal
        //hideBackdrop
        open={openInfo}
        onClose={handleCloseInfo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.Info}
          sx={{
            backgroundColor: theme.palette.background.main,
            color: theme.palette.text.primary,
            borderColor: '3px solid ' + theme.palette.borderColor,
          }}
        >
          <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <h2 id="alert-dialog-title">Modulo de asignacion de Dte</h2>
          </div>
          <p>
            - Tabla superior: Muestra las compras que aun no tienen un dte asignado. <br />
            - Tabla del medio: Muestra las tropas que aun no tienen un dte asignado. Se utiliza como guía. <br />
            - Tabla inferior: Muestra las compras que ya tienen un dte asignado. <br /><br />
            <b>Asignar Dte:</b> Para asignar un Dte a una compra, se debe seleccionar la compra (icono de lapiz) en la tabla superior y luego seleccionar el o los Dte en el desplegable de la ventana emergente. Puede escribir el número de Dte o la razon social del productor o remitente para ir filtrando la lista. Luego se debe presionar el botón "Confirmar". <br /><br />
            <b>Borrar asignacion:</b> Para borrar un Dte de una compra, se debe seleccionar la compra en la tabla inferior y luego presionar el botón "Borrar".
          </p>
          <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size='large'
              onClick={handleCloseInfo}
              className={classes.buttonModal}
            >Cerrar
            </Button>
          </div>
        </Box>
      </Modal>

    </>
  )

}
export default withRouter(DteList)