import React, { useContext, useState } from "react";
import { Grid, Button, ButtonBaseTypeMap, } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";/* import MUIDataTable from "mui-datatables";
import MenuItem from '@mui/material/MenuItem';  
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton'; */
// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
// 
import UserList from "../../components/userList/userList";
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { useTheme } from "@material-ui/styles";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import {
  Select,
  InputLabel,
} from "@material-ui/core";
import UserStateContext from "../../context/UserContext";

const ToggleButton = styled(MuiToggleButton)(({ textColor, selectedColor, borderColor, isSmallScreen }) => ({
  color: 'inherit',
  borderColor: borderColor,
  borderRadius: '15px',
  textTransform: 'capitalize',
  maxHeight: '60px',
  maxWidth: isSmallScreen && "85px", 
  overflow: isSmallScreen && 'hidden', 
  textOverflow: isSmallScreen && 'ellipsis', 
  whiteSpace: isSmallScreen && "nowrap", 
  textAlign: "center", 
  alignItems: 'center',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: textColor,
    backgroundColor: selectedColor,
  },
}));

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  formButtons: {
    width: "50%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonSubmit: {
    padding: '15px',
    fontSize: '1.2em',
    marginTop: '17px'

  },
}))

export default function Tables() {

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //const rol = JSON.parse(localStorage.getItem("permissions"));
  const [selectedFilter, setSelectedFilter] = useState("All");
  const { user } = useContext(UserStateContext)
  const [filterList, setFilterList] = useState([user.permissions.usuarios_can_create_intern ? 'Rioplatense' : "Proveedor"]);
  const [filtro, setFiltro] = useState(user.permissions.usuarios_can_create_intern ? 'Rioplatense' : "Proveedor");

  

  const handleFiltro = (e) => {

    setSelectedFilter(e.target.value);
    let filterList = [];
    if (e.target.value !== filtro) {
      setFilterList([e.target.value]);
      setFiltro(e.target.value)

    } else if (e.target.value == filtro) {
      setFilterList([])
      setFiltro('')

    }

    // Target the column to filter on.
    /* filteredCols[0].options.filterList = filterList;
    setCols(filteredCols); */

  }

  //const datatableData = []
  return (
    <>

      {/* <PageTitle title="Usuarios" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>

          {/* <Widget disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          > */}
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {(user.permissions.usuarios_can_create_intern || 
                user.permissions.usuarios_can_create_external
              ) && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                href="#/app/users"
                className={classes.buttonSubmit}
                startIcon={<PersonAddIcon style={{color : theme.palette.primary.main}}/>}
              >
                Nuevo usuario
              </Button>
              )}
              
              
            
            </Grid>
            {!isSmallScreen && (
            <Grid item lg={12} md={6} sm={12} xs={12}>
              <ToggleButtonGroup
                value={filtro}
                exclusive
                size="large"
                onChange={handleFiltro}
              >
                {user.permissions.usuarios_can_create_intern && (
                <ToggleButton value="Rioplatense" aria-label="left aligned" isSmallScreen={isSmallScreen} borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }} >Interno</ToggleButton>
                )}
                {user.permissions.usuarios_can_create_external && (
                <ToggleButton value="Proveedor" aria-label="left aligned" isSmallScreen={isSmallScreen} borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }}>Proveedor</ToggleButton>
                )}
                {/* {user.permissions.usuarios_can_create_external && (
                <ToggleButton value="Contacto_de_carga" aria-label="left aligned" isSmallScreen={isSmallScreen} borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }}>{isSmallScreen? 'C. Carga' : 'Contacto de Carga'}</ToggleButton>
                )}
                {user.permissions.usuarios_can_create_external && (
                <ToggleButton value="FeedLot" aria-label="left aligned" isSmallScreen={isSmallScreen} borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }}>Feedlot</ToggleButton>
                )} */}
                {user.permissions.usuarios_can_create_intern && (  
                <ToggleButton value="usuarioFaena" aria-label="left aligned" isSmallScreen={isSmallScreen} borderColor={theme.palette.tables.tableBorderColor} textColor={theme.palette.primary.main} selectedColor={theme.palette.background.custom} classes={{ selected: classes.selected }}>Usuario Faena</ToggleButton>
                )}
                </ToggleButtonGroup>
            </Grid>
            )}
            {isSmallScreen && (
              <Grid item lg={12} md={6} sm={12} xs={12} style={{marginTop: '15px'}}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Tipo de usuario
                  </InputLabel>
                  <Select
                    defaultValue={filtro}
                    onChange={(e) => { handleFiltro(e) }}
                    native
                    value={filtro}
                    margin="normal"
                    inputProps={{
                      name: 'tipo_usuario',
                      id: 'uncontrolled-native',
                    }}
                    sx={{
                      color: theme.palette.text.primary,
                      icon: {
                        color: theme.palette.icons.color
                      }
                    }}
                  >
                    {user.permissions.usuarios_can_create_intern && (<option value={"Rioplatense"}>Interno</option>)}
                    <option value={"Proveedor"}>Proveedor</option>
                    {/* <option value={"Contacto_de_carga"}>Contacto de Carga</option>
                    <option value={"FeedLot"}>Feedlot</option> */}
                    {user.permissions.usuarios_can_create_intern && (<option value={"usuarioFaena"}>Usuario de Faena</option>)}
                  </Select>
                </FormControl>
              </Grid>
                )}

          </Grid>
          <Grid item lg={12}>

            <UserList
              filtroUsuario={filterList}
            ></UserList>
          </Grid>

          {/* </Widget> */}
        </Grid>
      </Grid>

    </>
  );
}
