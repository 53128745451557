import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { useEffect, useState, memo, useContext } from "react";
import UserService from "../../context/UserService";
import { useTheme } from "@material-ui/styles";
import UserStateContext from "../../context/UserContext";

export default memo(function PreciosEstimadosTable({ year, week, tipo_compra, openSnackbar }) {
    const [categorias, setCategorias] = useState(null);
    const [destinos, setDestinos] = useState(null);
    const [precios, setPrecios] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const theme = useTheme()
    const { user } = useContext(UserStateContext);
    const canEdit = user.permissions.compras_indicadores_can_edit === true;

    const generalParams = {
        year: year,
        week: week,
        tipo_compra: tipo_compra
    }

    const getInitialData = async () => {
        setLoading(true);
        setError(null);

        try {
            const responses = await Promise.all([UserService.getPreciosEstimadosMeta(), UserService.getPreciosEstimados(generalParams)]);
            if (responses[0].status !== 200 || responses[1].status !== 200) {
                throw new Error('Ocurrio un error, vuelva a intentarlo');
            }
            setCategorias(responses[0].data.categorias);
            setDestinos(responses[0].data.destinos);
            setPrecios(responses[1].data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    const handleCellEdit = async ({ field, row, reason }, event) => {
        const destino = field;
        const categoria = row.id;
        const precio = event.target.value;
        const data = {
            ...generalParams,
            destino,
            categoria,
            precio
        }
        if (reason === GridCellEditStopReasons.cellFocusOut) {
            event.defaultMuiPrevented = true;
        }
        try {
            const response = await UserService.postPreciosEstimados(data);
            if (response.status !== 200) {
                throw new Error('Ocurrio un error, vuelva a intentarlo');
            }
            openSnackbar('Precio actualizado correctamente', 'success');
        } catch (error) {
            setError(error.message);
            openSnackbar(error.message, 'error');
        }
    }

    useEffect(() => {
        getInitialData();
    }, [year, week, tipo_compra]);

    // columns for the data grid
    const columns = destinos ? destinos.map(destino => (
        {
            field: `${destino.id}`,
            headerName: destino.descripcion,
            description: destino.descripcion,
            editable: canEdit,
            align: "center",
            //span: 3,
            width: 70,
            disableColumnMenu: true,
            disableReorder: true,
            editMode: 'doubleClick',
            valueFormatter: (params) => {
                if (!params.value) {
                    return '';
                }
                const valueFormatted = Number(params.value).toLocaleString();
                return `$ ${valueFormatted}`;
            },

        }
    )) : [];
    columns.unshift({
        field: 'categoria',
        headerName: 'Categoria',
        editable: false,
        width: 70,
        resizable: true,
        disableColumnMenu: true,
        disableReorder: true,
    })

    // rows for the data grid
    const rows = categorias ? categorias.map(categoria => (
        {
            id: `${categoria.id}`,
            categoria: categoria.descripcion,
        }
    )) : [];

    if (precios) {
        rows.forEach(row => {
            destinos.forEach(destino => {
                let price = precios.find(precio => precio.categoria?.toString() === row.id && precio.destino === destino.id)?.precio || null
                row[`${destino.id}`] = price;
            })
        })
    };


    return (

        <DataGrid
            rows={rows}
            columns={columns}
            onCellEditStop={handleCellEdit}
            autoHeight
            hideFooterPagination
            hideFooterSelectedRowCount
            disableSelectionOnClick
            hideFooter={true}
            rowHeight={25}
            sx={{
                color: "inherit",
                "& .MuiDataGrid-cell": {
                    border: 1,
                    borderColor:
                        theme.palette.tables.tableBorderColor,
                    borderRight: 0,
                    borderTop: 0,
                    // add more css for customization
                },
                "& .css-vcjdx3": {
                    backgroundColor: theme.palette.tables.header,
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                    justifyContent: "center",
                    borderColor:
                        theme.palette.tables.tableBorderColor,
                },
                "& .MuiDataGrid-columnHeaders": {
                    borderBottom:
                        "4px solid " +
                        theme.palette.tables.tableBorderColor,
                    fontSize: 15,
                    backgroundColor: theme.palette.tables.header,
                    color: theme.palette.tables.headerText,
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop:
                        "1px solid " +
                        theme.palette.tables.tableBorderColor,
                    maxHeight: "0px",
                    minHeight: "0px",
                },
                border:
                    "2px solid " +
                    theme.palette.tables.tableBorderColor,
            }}
            components={{
                NoRowsOverlay: () => (
                    <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {!loading ? 'No hay datos' : 'Cargando...'}
                    </Stack>
                ),
            }}
        />

    )
})