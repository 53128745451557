import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import NuevoUsuarioDeCarga from "../nuevoUsuarioDeCarga/NuevoUsuarioDeCarga";
import NuevoUsuarioFeedlot from "../nuevoUsuarioFeedlot/NuevoUsuarioFeedlot";
import NuevoUsuarioFaena from "../nuevoUsuarioFaena/NuevoUsuarioFaena";
import { useMediaQuery } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";

import { styled } from "@mui/material/styles";

const ToggleButton = styled(MuiToggleButton)(
  ({ textColor, selectedColor, borderColor, isSmallScreen }) => ({
    color: "inherit",
    borderColor: borderColor,
    borderRadius: "15px",
    textTransform: "capitalize",
    maxHeight: "60px",
    maxWidth: isSmallScreen && "85px",
    overflow: isSmallScreen && "hidden",
    textOverflow: isSmallScreen && "ellipsis",
    whiteSpace: isSmallScreen && "nowrap",
    textAlign: "center",
    alignItems: "center",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: textColor,
      backgroundColor: selectedColor,
    },
  }),
);

function Usuarios(props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  var classes = useStyles();
  const { register } = useContext(UserStateContext);
  const { user } = useContext(UserStateContext);

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState("");

  //var userDispatch = useUserDispatch();

  //var rol = localStorage.getItem("permissions");

  // local
  var [nameValue, setNameValue] = useState("");
  var [lastNameValue, setlastNameValue] = useState("");
  var [tipo, setTipo] = React.useState(() =>
    user.permissions.usuarios_can_create_intern ? "Rioplatense" : "Proveedor",
  );
  var [legajo, setLegajo] = useState("");
  var [rolU, setRol] = useState("5");
  var [sector, setSector] = useState("");
  var [bp, setBp] = useState("");
  var [razonSocial, setRazonSocial] = useState("");
  var [cuit, setCUIT] = useState("");
  var [email, setEmail] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [passwordValue2, setPassword2Value] = useState("");
  var [telefono, setTelefono] = useState(0);
  const [grupoCompras, setGrupoCompras] = useState(null);
  const [selectedGrupoCompras, setSelectedGrupoCompras] = useState(null);

  var [emailSelectData, setemailSelectData] = useState([]);
  const [alignment, setAlignment] = React.useState(() =>
    user.permissions.usuarios_can_create_intern ? "Rioplatense" : "Proveedor",
  );

  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [isLoading2, setIsLoading2] = useState(false);

  const [hide, setHide] = useState(false);

  const clearState = () => {
    setNameValue("");
    setlastNameValue("");
    setLegajo("");
    setRol("");
    setBp("");
    setRazonSocial("");
    setCUIT("");
    setEmail("");
    setPasswordValue("");
    setPassword2Value("");
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleCheck = (value) => {
    setTipo(value);
    if (value == "Proveedor") {
      clearState();
      setRol("4");
      setHide(true);
    } else if (value == "ContactoCarga") {
      clearState();
      setRol("7");
    } else if (value == "usuarioFaena") {
      clearState();
      setRol("9");
    } else {
      setHide(false);
    }
  };

  useEffect(() => {
    UserService.getGrupoCompras()
      .then((response) => {
        setGrupoCompras(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  const handleDisabled = () => {
    // <option value={"Rioplatense"}>Interno</option>
    // )}
    // <option value={"Proveedor"}>Proveedor</option>
    // <option value={"ContactoCarga"}>Contacto de Carga</option>
    // <option value={"FeedLot"}>Feedlot</option>
    // {rol == 6 && (
    //   <option value={"usuarioFaena"}>Usuario de Faena</option>
    if ((tipo == "Rioplatense") && (rolU == "1" || rolU == "2") && (selectedGrupoCompras === null)) {
      return true;
    }

    if (passwordValue.length === 0 || passwordValue2.length === 0) {
      return true;
    }
    if ((tipo == 'Proveedor' || tipo == 'ContactoCarga' || tipo == 'FeedLot') && (cuit.length === 0)) {
      return true;

    }
    return false;
  }

  const getDataProveedor = async (cuit) => {
    if (cuit.length !== 0) {
      setIsLoading2(true);
      setError("");
      UserService.getProveedor(cuit).then(
        (response) => {
          //cambios !!!!!!!!!
          const data = response.data;
          //const data = response.data.data[0]

          if (response.status == 200 && data.error == undefined) {
            setBp(data.bp);
            setRazonSocial(data.razon_social);
            setemailSelectData(data.emails);

            if (data.emails != undefined && data.emails.length == 1) {
              setEmail(data.emails[0]);
            }
            setIsLoading2(false);
          } else {
            setError(
              "Por favor verifica el cuit ingresado ó intenta nuevamente",
            );
            setIsLoading2(false);
            setBp("");
            setRazonSocial("");
            setemailSelectData([]);
          }
        },
        (error) => {},
      );
    } else {
      setError("Por favor verifica el cuit ingresado ó intenta nuevamente");
    }
  };
  const handleChangeSector = (e) => {
    setSector(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeRol = (e) => {
    setRol(e.target.value);
  };

  return (
    <>
      {/* <PageTitle title="Nuevo Usuario" /> */}
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* <Widget disableWidgetMenu
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          > */}
          {/* <div>
            <Typography color="secondary" className={classes.errorMessage}>
              {error}
            </Typography>
          </div> */}

          <div className={classes.form}>
            <Grid container spacing={2}>
              {!isSmallScreen && (
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: isSmallScreen ? "20px" : "" }}
                >
                  {/* <InputLabel id="type" style={{ marginBottom: '3px' }}>Tipo de Usuario</InputLabel> */}
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    size="large"
                    onChange={handleAlignment}
                  >
                    {user.permissions.usuarios_can_create_intern && (
                      <ToggleButton
                        value="Rioplatense"
                        aria-label="left aligned"
                        isSmallScreen={isSmallScreen}
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.primary.main}
                        selectedColor={theme.palette.background.custom}
                        classes={{ selected: classes.selected }}
                        onClick={() => {
                          handleCheck("Rioplatense");
                        }}
                      >
                        Interno
                      </ToggleButton>
                    )}
                    {user.permissions.usuarios_can_create_external && (
                    <ToggleButton
                      value="Proveedor"
                      aria-label="left aligned"
                      isSmallScreen={isSmallScreen}
                      borderColor={theme.palette.tables.tableBorderColor}
                      textColor={theme.palette.primary.main}
                      selectedColor={theme.palette.background.custom}
                      classes={{ selected: classes.selected }}
                      onClick={() => {
                        handleCheck("Proveedor");
                      }}
                    >
                      Proveedor
                    </ToggleButton>
                    )}
                    {/* {user.permissions.usuarios_can_create_external && (
                    <ToggleButton
                      value="ContactoCarga"
                      aria-label="left aligned"
                      isSmallScreen={isSmallScreen}
                      borderColor={theme.palette.tables.tableBorderColor}
                      textColor={theme.palette.primary.main}
                      selectedColor={theme.palette.background.custom}
                      classes={{ selected: classes.selected }}
                      onClick={() => {
                        handleCheck("ContactoCarga");
                      }}
                    >
                      {isSmallScreen ? "C. Carga" : "Contacto de Carga"}
                    </ToggleButton>
                    )}
                    {user.permissions.usuarios_can_create_external && (
                    <ToggleButton
                      value="FeedLot"
                      aria-label="left aligned"
                      isSmallScreen={isSmallScreen}
                      borderColor={theme.palette.tables.tableBorderColor}
                      textColor={theme.palette.primary.main}
                      selectedColor={theme.palette.background.custom}
                      classes={{ selected: classes.selected }}
                      onClick={() => {
                        handleCheck("FeedLot");
                      }}
                    >
                      FeedLot
                    </ToggleButton>
                    )} */}
                    {user.permissions.usuarios_can_create_intern && (
                      <ToggleButton
                        value="usuarioFaena"
                        aria-label="left aligned"
                        isSmallScreen={isSmallScreen}
                        borderColor={theme.palette.tables.tableBorderColor}
                        textColor={theme.palette.primary.main}
                        selectedColor={theme.palette.background.custom}
                        classes={{ selected: classes.selected }}
                        onClick={() => {
                          handleCheck("usuarioFaena");
                        }}
                      >
                        Usuario Faena
                      </ToggleButton>
                    )}
                  </ToggleButtonGroup>
                  {/*  <RadioGroup aria-label="tipo" row name="tipo" value={tipo} onChange={handleCheck}>
                    <FormControlLabel value="Rioplatense" control={<Radio icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>} />} label="Rioplatense" />
                    <FormControlLabel value="Proveedor" control={<Radio icon={<CheckBoxOutlineBlankIcon/>} checkedIcon={<CheckBoxIcon/>}  />} label="Proveedor" />
                  </RadioGroup>  */}
                </Grid>
              )}
              {isSmallScreen && (
                <Grid
                  item
                  lg={12}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Tipo de usuario
                    </InputLabel>
                    <Select
                      defaultValue={tipo}
                      onChange={(e) => {
                        handleCheck(e.target.value);
                      }}
                      native
                      value={tipo}
                      margin="normal"
                      inputProps={{
                        name: "tipo_usuario",
                        id: "uncontrolled-native",
                      }}
                      sx={{
                        color: theme.palette.text.primary,
                        icon: {
                          color: theme.palette.icons.color,
                        },
                      }}
                    >
                      {user.permissions.usuarios_can_create_intern && (
                        <option value={"Rioplatense"}>Interno</option>
                      )}
                      {user.permissions.usuarios_can_create_external && (
                      <option value={"Proveedor"}>Proveedor</option>
                      )}
                      {/* {user.permissions.usuarios_can_create_external && (
                      <option value={"ContactoCarga"}>Contacto de Carga</option>
                      )}
                      {user.permissions.usuarios_can_create_external && (
                      <option value={"FeedLot"}>Feedlot</option>
                      )} */}
                      {user.permissions.usuarios_can_create_intern&& (
                        <option value={"usuarioFaena"}>Usuario de Faena</option>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {error && (
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    {Object.keys(error).map((key) => {
                      const items = error[key].map((item) => <li>{item}</li>);
                      return (
                        <Alert
                          severity="error"
                          variant="outlined"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setError(null);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ mb: 2 }}
                        >
                          <AlertTitle>
                            <strong>
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </strong>
                          </AlertTitle>
                          <ul>{items}</ul>
                        </Alert>
                      );
                    })}
                  </Grid>
                </Grid>
              )}

              {tipo == "Rioplatense" && (
                <>
                  <Grid item lg={6} md={12} xs={12} sm={12}>
                    <TextField
                      id="email"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Email"
                      placeholder="Email"
                      type="email"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item lg={6} xs={12} sm={12}>
                    <InputLabel id="type">Sector</InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      id="sector"
                      value={sector}
                      label="Tipo de usuario"
                      onChange={handleChangeSector}
                      fullWidth
                    >
                      <MenuItem value={"Recursos Humanos"}>
                        Recursos Humanos
                      </MenuItem>
                      <MenuItem value={"SegHigiene"}>
                        Seguridad e Higiene
                      </MenuItem>
                      <MenuItem value={"Corrales"}>Corrales</MenuItem>
                      <MenuItem value={"Vigilancia"}>Vigilancia</MenuItem>
                      <MenuItem value={"Ventas Consumo"}>
                        Ventas Consumo
                      </MenuItem>
                      <MenuItem value={"Exportacion"}>Exportación</MenuItem>
                      <MenuItem value={"Compras de Hacienda"}>
                        Compras de Hacienda
                      </MenuItem>
                      <MenuItem value={"Compras Genenerales"}>
                        Compras Generales
                      </MenuItem>
                      <MenuItem value={"Impuestos"}>Impuestos</MenuItem>
                      <MenuItem value={"Administración"}>
                        Administración
                      </MenuItem>
                      <MenuItem value={"Gerencia"}>Gerencia</MenuItem>
                      <MenuItem value={"Sistemas"}>Sistemas</MenuItem>
                      <MenuItem value={"Producción"}>Producción</MenuItem>
                      <MenuItem value={"Pycp"}>PyCP</MenuItem>
                      <MenuItem value={"Calidad"}>Calidad</MenuItem>
                      <MenuItem value={"Control De Gestión"}>
                        Control de Gestión
                      </MenuItem>
                      <MenuItem value={"Rendering"}>Rendering</MenuItem>
                      <MenuItem value={"Marketing"}>Marketing</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item lg={6} md={12} xs={12} sm={12}>
                    <TextField
                      autoFocus
                      id="name"
                      variant="outlined"
                      label="Nombre"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={nameValue}
                      onChange={(e) => setNameValue(e.target.value)}
                      margin="normal"
                      placeholder="Nombre"
                      type="text"
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item lg={6} md={12} xs={12} sm={12}>
                    <TextField
                      id="lastName"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={lastNameValue}
                      onChange={(e) => setlastNameValue(e.target.value)}
                      margin="normal"
                      placeholder="Apellido"
                      variant="outlined"
                      label="Apellido"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="contraseña"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={passwordValue}
                      onChange={(e) => setPasswordValue(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Contraseña"
                      placeholder="Contraseña"
                      type="password"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="contraseña2"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={passwordValue2}
                      onChange={(e) => setPassword2Value(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Repita Contraseña"
                      placeholder="Repita Contraseña"
                      type="password"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={6} sm={6}>
                    <InputLabel id="type">Rol</InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      id="type"
                      value={rolU}
                      label="Rol"
                      onChange={handleChangeRol}
                      fullWidth
                    >
                      <MenuItem value={"1"}>Comprador full</MenuItem>
                      <MenuItem value={"2"}>Comprador estándar</MenuItem>
                      <MenuItem value={"3"}>Corrales</MenuItem>
                      {/* {hide && <MenuItem value={"4"}>Proveedor</MenuItem>} */}
                      <MenuItem value={"5"}>Interno</MenuItem>

                      <MenuItem value={"10"}>Interno con Compras</MenuItem>

                      <MenuItem value={"6"}>Administrador</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6}>
                    <TextField
                      id="legajo"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={legajo}
                      onChange={(e) => setLegajo(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Legajo"
                      placeholder="Legajo"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  {(tipo == "Rioplatense" && (rolU == "1" || rolU == "2" )) && (
                  <Grid item lg={6} md={12} xs={12} sm={12}>
                    <InputLabel id="gc">Grupo de Compras*</InputLabel>
                    <Select
                    labelId="gc"
                    id="gc-select"
                    label="Grupo de Compras"
                    variant="outlined"
                    error={selectedGrupoCompras === null}
                    fullWidth
                    value={selectedGrupoCompras}
                    onChange={(e) => {setSelectedGrupoCompras(e.target.value)}}
                    >
                      {grupoCompras && grupoCompras.map((grupo) => (
                        <MenuItem key={grupo.codigo} value={grupo.codigo}>{grupo.codigo} - {grupo.descripcion}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  )}
                  <Grid
                    alignContent="center"
                    justify="center"
                    container
                    spacing={1}
                    lg={12}
                    xs={12}
                    sm={12}
                  >
                    <Grid item lg={3} xs={12} sm={12}>
                      {isLoading ? (
                        <CircularProgress
                          size={26}
                          className={classes.loginLoader}
                        />
                      ) : (
                        <Button
                          fullWidth
                          onClick={() => {
                            setIsLoading(true);

                            register(
                              nameValue,
                              passwordValue,
                              passwordValue2,
                              email,
                              nameValue,
                              lastNameValue,
                              cuit,
                              razonSocial,
                              rolU,
                              tipo,
                              sector,
                              legajo,
                              bp,
                              telefono,
                              props.history,
                              setIsLoading,
                              setError,
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "", 
                              selectedGrupoCompras
                            );
                          }}
                          disabled={
                            // passwordValue.length === 0 ||
                            // passwordValue2.length === 0 ||
                            // email.length === 0 ||
                            // cuit.length === 0
                            handleDisabled()
                          }
                          size="large"
                          variant="contained"
                          color="primary"
                          className={classes.createAccountButton}
                        >
                          Guardar
                        </Button>
                      )}
                    </Grid>
                    <Grid item lg={3} xs={12} sm={12}>
                      <Button
                        className={classes.createAccountButton}
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        component={Link}
                        to={`/app/tables`}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {tipo == "Proveedor" && (
                <>
                  <Grid
                    container
                    item
                    alignItems="center"
                    spacing={1}
                    lg={6}
                    xs={12}
                    sm={12}
                  >
                    <Grid item alignItems="center" lg={6} xs={12} sm={12}>
                      <TextField
                        id="cuit"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={cuit}
                        onChange={(e) => setCUIT(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="CUIT"
                        placeholder="CUIT"
                        type="number"
                        className={classes.cuitInput}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item alignItems="center" lg={6} xs={12} sm={12}>
                      {isLoading2 ? (
                        <CircularProgress
                          size={26}
                          className={classes.loginLoader}
                        />
                      ) : (
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={cuit.length === 0}
                          onClick={() => {
                            getDataProveedor(cuit);
                          }}
                          className={classes.createAccountButton2}
                        >
                          Buscar
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item lg={6} md={12} xs={12} sm={12}>
                    <InputLabel id="type">Email</InputLabel>
                    <Select
                      labelId="type"
                      variant="outlined"
                      id="email"
                      value={email}
                      label="Email"
                      onChange={handleChangeEmail}
                      fullWidth
                    >
                      {emailSelectData.map((e, value) => {
                        return <MenuItem value={e}>{e}</MenuItem>;
                      })}
                    </Select>
                  </Grid>

                  <Grid item lg={6} md={12} xs={12} sm={12}>
                    <TextField
                      autoFocus
                      id="name"
                      variant="outlined"
                      label="Nombre"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={nameValue}
                      onChange={(e) => setNameValue(e.target.value)}
                      margin="normal"
                      placeholder="Nombre"
                      type="text"
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item lg={6} md={12} xs={12} sm={12}>
                    <TextField
                      id="lastName"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={lastNameValue}
                      onChange={(e) => setlastNameValue(e.target.value)}
                      margin="normal"
                      placeholder="Apellido"
                      variant="outlined"
                      label="Apellido"
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={6} xs={12} sm={6}>
                    <TextField
                      id="razonS"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={razonSocial}
                      onChange={(e) => setRazonSocial(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Razón Social"
                      disabled
                      placeholder="Razón Social"
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={6} xs={12} sm={6}>
                    <TextField
                      id="vp"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={bp}
                      onChange={(e) => setBp(e.target.value)}
                      margin="normal"
                      disabled
                      variant="outlined"
                      label="BP"
                      placeholder="BP"
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={12} xs={12} sm={6}>
                    <Grid item lg={6} xs={12} sm={12}>
                      <TextField
                        id="vp"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={telefono}
                        onChange={(e) => setTelefono(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        label="Teléfono"
                        placeholder="Teléfono"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="contraseña"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={passwordValue}
                      onChange={(e) => setPasswordValue(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Contraseña"
                      placeholder="Contraseña"
                      type="password"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="contraseña2"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={passwordValue2}
                      onChange={(e) => setPassword2Value(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      label="Repita Contraseña"
                      placeholder="Repita Contraseña"
                      type="password"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid
                    alignContent="center"
                    justify="center"
                    container
                    spacing={1}
                    lg={12}
                    xs={12}
                    sm={12}
                  >
                    <Grid item lg={3} xs={12} sm={12}>
                      {isLoading ? (
                        <CircularProgress
                          size={26}
                          className={classes.loginLoader}
                        />
                      ) : (
                        <Button
                          fullWidth
                          onClick={() => {
                            setIsLoading(true);

                            register(
                              nameValue,
                              passwordValue,
                              passwordValue2,
                              email,
                              nameValue,
                              lastNameValue,
                              cuit,
                              razonSocial,
                              rolU,
                              tipo,
                              sector,
                              legajo,
                              bp,
                              telefono,
                              props.history,
                              setIsLoading,
                              setError,
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                          
                            );
                          }}
                          disabled={
                            // passwordValue.length === 0 ||
                            // passwordValue2.length === 0
                            // email.length === 0 ||
                            // cuit.length === 0
                            handleDisabled()
                          }
                          size="large"
                          variant="contained"
                          color="primary"
                          className={classes.createAccountButton}
                        >
                          Guardar
                        </Button>
                      )}
                    </Grid>
                    <Grid item lg={3} xs={12} sm={12}>
                      <Button
                        className={classes.createAccountButton}
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        component={Link}
                        to={`/app/tables`}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* aca iria la anterior validacion */}

              {tipo == "ContactoCarga" && (
                <NuevoUsuarioDeCarga></NuevoUsuarioDeCarga>
              )}
              {tipo == "FeedLot" && <NuevoUsuarioFeedlot></NuevoUsuarioFeedlot>}
              {tipo == "usuarioFaena" && (
                <NuevoUsuarioFaena></NuevoUsuarioFaena>
              )}
            </Grid>
          </div>
          {/* </Widget> */}
        </Grid>
      </Grid>
    </>
  );
}
export default Usuarios;
