import React, { useState, useEffect, useRef, useContext } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  Person as AccountIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  CameraRear,
} from "@material-ui/icons";
import AlarmIcon from "@mui/icons-material/Alarm";
import SourceIcon from "@mui/icons-material/Source";
import GiteIcon from "@mui/icons-material/Gite";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import InventoryIcon from "@mui/icons-material/Inventory";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import SpeedIcon from "@mui/icons-material/Speed";

import PieChartIcon from "@mui/icons-material/PieChart";
// styles
import useStyles from "./styles";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
//import Dot from "./components/Dot";
import GradingIcon from "@mui/icons-material/Grading";
import AssignmentIcon from "@mui/icons-material/Assignment";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import UserStateContext from "../../context/UserContext";
//images
import meat from "../../images/meat.svg";

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  // rol de usuario
  var rol = localStorage.getItem("permissions");
  const user = useContext(UserStateContext);

  const structure = [
    // { id: 0, label: "Inicio", link: "/app/home", icon: <HomeIcon /> },
    {
      id: 2,
      label: "Dashboard",
      link: "/app/dashboard",
      icon: <PieChartIcon />,
      color: theme.palette.tables.headerText,
    }, //

    { id: 1, label: "Usuarios", link: "/app/tables", icon: <AccountIcon /> },

    /*  { id: 2, label: "Contactos de Carga", link: "/app/usuariosDeCarga", icon: <ContactPageIcon /> },//ex 5 */
    {
      id: 3,
      label: "Compras",
      link: "/app/estadoCompras",
      icon: <AlarmIcon />,
    }, // 4
    {
      id: 4,
      label: "Stock Corrales",
      link: "/app/stock",
      icon: <InventoryIcon />,
    }, // 2
    { id: 5, label: "Faena", link: "/app/romaneo", icon: <SourceIcon /> }, //
    { id: 6, label: "Dte", link: "/app/dte", icon: <PlaylistAddIcon /> }, //
    {
      id: 7,
      label: "CheckList",
      link: "/app/checkList",
      icon: <GradingIcon />,
    }, //
    {
      id: 8,
      label: "Gestión Doc.",
      link: "/app/gestionDoc",
      icon: <AssignmentIcon />,
    }, //
    { id: 9, label: "Feedlot", link: "/app/Feedlot", icon: <GiteIcon /> }, //
    { id: 10, label: "Cashflow", link: "/app/Cashflow", icon: <AttachMoneyIcon /> }, //
  ];

  // global
  var { isSidebarOpened } = useLayoutState();

  var layoutDispatch = useLayoutDispatch();
  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const toogle = () => {
    toggleSidebar(layoutDispatch);
  };
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;
    if (ref.current) {
      toogle();
    }
  };

  useEffect(() => {
    /* return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    }; */
  }, []);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div ref={ref} className={classes.mobileBackButton}>
        <IconButton>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List
        ref={ref}
        className={classes.sidebarList}
        onClick={handleClickOutside}
      >
        {structure.map((link) => {
          const isActive = location.pathname === link.link;

          // if (
          //   rol != 6 &&
          //   rol != 1 &&
          //   rol != 10 &&
          //   (rol != 2) & (rol != 5) &&
          //   (link.id == 1 || link.id == 2)
          // ) {
          //   return;
          // }

          // //corrales
          // else if (rol == 3 && link.id == 5) {
          //   return;
          // } else if (rol == 3 && link.id == 6) {
          //   return;
          // } else if (rol == 3 && link.id == 7) {
          //   return;
          // } else if (rol == 3 && link.id == 8) {
          //   return;
          // } else if (rol == 3 && link.id == 9) {
          //   return;
          // }

          // //proveedores
          // else if (rol == 4 && link.id == 9) {
          //   return;
          // } else if (rol == 4 && link.id == 7) {
          //   return;
          // } else if (rol == 4 && link.id == 4) {
          //   return;
          // } else if (rol == 4 && link.id == 6) {
          //   return;
          // } else if (rol == 4 && link.id == 3) {
          //   return;
          // }

          // //interno
          // else if (rol == 5 && link.id == 6) {
          //   return;
          // } else if (rol == 5 && link.id == 1) {
          //   return;
          // } 
          //  else if (rol == 5 && link.id == 9) {
          //   return;
          // } else if (rol == 5 && link.id == 3) {
          //   return;
          // }
          // //contacto de carga
          // else if (rol == 7 && link.id != 0 && link.id != 9) {
          //   return;
          // }
          // //feedlot
          // else if (rol == 8 && link.id != 0 && link.id != 9) {
          //   return;
          // }

          // //usuario de faena
          // else if (rol == 9 && link.id != 0 && link.id != 5) {
          //   return;}

          // //interno con compras
          // else if (rol == 10 && link.id == 6) {
          //   return;
          // } else if (rol == 10 && link.id == 1) {
          //   return;
          // } 
          //  else if (rol == 10 && link.id == 9) {
          //   return;
          // }
          // //acceso a home
          // else if (
          //   (rol == 1 || rol == 2 || rol == 6 || rol == 5) &&
          //   link.id == 0
          // ) {
          //   return;
          // } 
          if (!user.user.permissions.dashboard_can_view && link.id == 2) {
            return; 
          }
          else if (!user.user.permissions.usuarios_can_view  && link.id == 1) {

            return;
          }
          else if (!user.user.permissions.compras_can_view  && link.id == 3) {
            return;
          }
          else if (!user.user.permissions.stock_can_view  && link.id == 4) {
            return;
          } 
          else if (!user.user.permissions.faena_can_view  && link.id == 5) {


            return;
          } 
          else if (!user.user.permissions.dte_can_view  && link.id == 6) {
            return;
          } 

          else if (!user.user.permissions.checklist_can_view  && link.id == 7) {
            return;
          } 
          else if (!user.user.permissions.gestionDoc_can_view  && link.id == 8) {
            return;
          } 
          else if (!user.user.permissions.feedlot_can_view  && link.id == 9) {

            return;
          } 
          else if (!user.user.permissions.cashflow_can_view  && link.id == 10) {

            return;
          } 
          else
            return (
              <SidebarLink
                key={link.id}
                location={location}
                isActive={isActive}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            );
        })}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
      if (isSidebarOpened == true) {
        toggleSidebar(layoutDispatch);
      }
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
      toggleSidebar(layoutDispatch);
    }
  }

  function handleWindowOnClick() {
    setPermanent(false);
  }
}

export default withRouter(Sidebar);
