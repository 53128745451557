import React, { useState, useEffect, useContext } from "react";
import MUIDataTable from "mui-datatables";
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { withRouter } from "react-router-dom";


import useStyles from "./styles";
import UserService from "../../context/UserService";
import { Link } from "react-router-dom";
import { Button, MuiThemeProvider, createMuiTheme, Slider, FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import UserStateContext, { signOut } from "../../context/UserContext";
import { Grid } from "@mui/material";
import jwt_decodde from "jwt-decode";
import { useTheme } from "@material-ui/styles";

import { optionsNormal } from "../../components/variables/variables";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

/* const style ={
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35%',
  height:'30%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius:'30px',
  boxShadow: 24,
  p: 5,
  textAlign:'center',
}; */
// estilo modal
/* const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background',
  border: '2px solid #000',
  boxShadow: 1,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign:'center',
  
}; */
const breakpoints = createBreakpoints({})
const getMuiTheme = () =>
  createMuiTheme({

    overrides: {
      MUIDataTableBodyCell: {
        root: {
          minWidth: 'max-content',
          minHeight: '1.4rem',

          Width: 'auto',
          padding: '0px 3px 0px 3px',
          alignSelf: 'center'
        }
      },
      MuiPaper: {
        root: {
          color: '#fff',
          backgroundColor: 'rgb(35, 35, 45)'
        },
      },

      MuiIconButton: {
        root: { color: '#fff', }
      },

      MuiTableRow: {
        root:
        {
          verticalAlign: 'baseline'
        }
      },
      MuiIconButton: {
        root: { color: '#fff', }
      },

      MuiPaper: {
        root: {
          color: '#fff',
          backgroundColor: 'rgb(35, 35, 45)'
        },
      },
      MuiTableCell: {

        head: {
          fontWeight: "bold",
          color: '#fff',
          backgroundColor: 'rgb(35, 35, 45)'
        },
        body: {
          color: '#fff'
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: 'rgb(35, 35, 45)'

        },
      },
      MuiTablePagination: {
        toolbar: {
          color: 'white'
        }

      },
      MUIDataTableToolbar: {

        filterPaper: {

          [breakpoints.down("xs")]: {
            maxWidth: '100%'
          }
        },
        actions: {
          display: "flex",
          flex: "initial",
          // move all icons to the right
          "& > span, & > button": {
            order: 99
          },
          // target the custom toolbar icon
          "& > span:last-child, & > button:last-child": {
            order: 1
          },
          // target any icon
          "& > span:nth-child(4), & > button:nth-child(4)": {
            order: 2
          }
        }
      }
    },
    shadows: ['none']


  });

function UserList(props) {
  // funcion para poner nombre de roles
  function roles(val) {
    if (val == 1) {
      return 'Comprador Full';
    }
    else if (val == 2) {
      return 'Comprador Estandard';
    }
    else if (val == 3) {
      return 'Corrales';
    }
    else if (val == 4) {
      return 'Proveedor';
    }
    else if (val == 5) {
      return 'Interno';
    }
    else if (val == 6) {
      return 'Administrador';
    }
    else if (val == 7) {
      return 'Contacto de Carga';
    }
    else if (val == 8) {
      return 'Feedlot';
    } else if (val == 9) {
      return 'Usuario de Faena';
    } else if (val == 10) {
      return "Interno con Compras"
    }
  }


  function activo(val) {
    if (val == true) {
      return 'Activo';
    }
    else return 'Inactivo'
  }

  //funcion para borrar usuario  
  const { deleteUser } = useContext(UserStateContext);
  const { user } = useContext(UserStateContext);
  
  const theme = useTheme();

  //clases de usuario
  var classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = (rowData, value) => {


    if (rowData.indexOf('Feedlot') == -1 && rowData.indexOf('Contacto de Carga') == -1 && rowData.indexOf('Proveedor') == -1) {
      setMensaje(`Confirma borrar el usuario:\n ${rowData[0]}`)
    } else if (rowData.indexOf('Feedlot') != -1) {
      setMensaje(`Confirma borrar el usuario de CUIT:\n ${rowData[0]}`)

    } else if (rowData.indexOf('Contacto de Carga') != -1) {
      setMensaje(`Confirma borrar el usuario de CUIT:\n ${rowData[2]}`)

    } else if (rowData.indexOf('Proveedor') != -1) {
      setMensaje(`Confirma borrar el usuario de CUIT:\n ${rowData[4]}`)

    }
    setFila(value)
    setOpen(true)
  }
  const handleClose = () => setOpen(false);
  const [dataTables, setdataTables] = useState('')
  const [mensaje, setMensaje] = useState('')
  const [valor, setValor] = useState('')
  const [fila, setFila] = useState()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filtroTUsuario, setFiltroTUsuario] = useState('Rioplatense')

  //borrar usuario
  const borrarUsuario = async (value, history) => {
    await deleteUser(value, history)
    await setTimeout(5000)
    setOpen(false)
    servidorGET()
  }
  //var rol = localStorage.getItem('permissions')
  //var user = JSON.parse((localStorage.getItem('userData'))).user_id

  function valueLabelFormat(value) {
    return value;
  }
  // Saco la info del servidor nuevamente 
  const servidorGET = () => {
    UserService.getUserList(props.filtroUsuario).then(
      response => {
        setdataTables(response.data.data
        )
      })
  }

  useEffect(() => {
    UserService.getUserList(props.filtroUsuario).then(
      response => {
        setdataTables(response.data.data);
      },
      error => {
        if (error.response.status == 401) { signOut(props.history) }
      }
    );
    setFiltroTUsuario(props.filtroUsuario)
  }, [props.filtroUsuario]);

  let datatableData = []
  let datatableDataUsuariosCarga = []
  let datatableDataUsuaiosFeedlot = []
  let datatableDataProveedor = []
  let datatableDataUsuarioFaena = []
  for (const [key, value] of Object.entries(dataTables)) {
    if (value['type'] == 'Rioplatense') {
      datatableData.push([value['email'], value['username'], value['last_name'], roles(value['rol_id']), value['sector'], value['legajo'], activo(value['is_active']), value['id']])
    } else if (value['type'] == 'Proveedor') {
      datatableDataProveedor.push([value['email'], value['username'], value['last_name'], value['razonS'], value['cuit'], roles(value['rol_id']), value['bp'], activo(value['is_active']), value['telefono'], value['id']])

    } else if (value['type'] == 'Contacto_de_carga') {
      datatableDataUsuariosCarga.push([value['username'], value['last_name'], value['cuit'], roles(value['rol_id']), activo(value['is_active']), value['telefono'], value['id']])

    }

    else if (value['type'] == 'FeedLot') {
      datatableDataUsuaiosFeedlot.push([value['cuit'], value['titular'], value['Renspa'], value['username'], value['Localidad'], value['Partido'], value['Provincia'], value['Destino'], roles(value['rol_id']), activo(value['is_active']), value['id']])

    } else if (value['type'] == 'usuarioFaena') {
      datatableDataUsuarioFaena.push([value['username'], value['last_name'], value['email'], roles(value['rol_id']), activo(value['is_active']), value['id']])

    }
  }
  localStorage.setItem("datatableData", JSON.stringify(dataTables));

  return (
    /*       <MuiThemeProvider theme={getMuiTheme()}>
     */
    <>
      {filtroTUsuario == 'Rioplatense' &&

        <MUIDataTable
          data={datatableData}
          options={optionsNormal}

          columns={[{
            name: "Email",
            options: {
              filterType: 'multiselect',


            }
          }, {
            name: "Nombre",
            options: {
              filterType: 'multiselect',


            }
          }, {
            name: "Apellido",
            options: {
              filterType: 'multiselect',


            }
          }

            , {
            name: "Rol",
            options: {
              filterType: 'multiselect',
              options: {
                setCellProps: () => ({ style: { textAlign: 'center', maxWidth: "100px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              
              setCellHeaderProps: () => ({
                className: classes.centeredHeader,
                style: { maxWidth: "100px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" },
              }),
            }
              // customBodyRender: (value) => {
              //   if (value) {
              //     return (<div className={classes.centerContent}>{value}</div>)
              //   }
              // }

            }
          }
            , {
            name: "Sector",
            options: {
              filterType: 'multiselect',
              setCellProps: () => ({ style: { textAlign: 'center', maxWidth: "100px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              
              setCellHeaderProps: () => ({
                className: classes.centeredHeader,
                style: { maxWidth: "100px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" },
              }),
              // customBodyRender: (value) => {
              //   if (value) {
              //     return (<div className={classes.centerContent}>{value}</div>)
              //   }
              // }

            }
          }
            , {
            name: "Legajo",
            options: {
              filterType: 'custom',
              setCellProps: () => ({ style: { textAlign: 'center', maxWidth: "60px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              
              setCellHeaderProps: () => ({
                className: classes.centeredHeader,
                style: { maxWidth: "60px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" },
              }),
              // customBodyRender: (value) => {
              //   if (value) {
              //     return (<div className={classes.centerContent}>{value}</div>)
              //   }
              // },
              customFilterListOptions: {
                render: v => {
                  if (v[0]) {
                    return `${v[0]}`;
                  }
                  return [];
                },
                update: (filterList, filterPos, index) => {

                  filterList[index].splice(filterPos, 1);
                  return filterList;
                },
              },
              filterOptions: {
                logic(value, filters) {
                  if (filters.length) return !filters.includes(value.toString());
                  return false;
                },
                display: (filterList, onChange, index, column, filterData) => (
                  <div>
                    <FormLabel>Legajo</FormLabel>
                    <FormGroup row>
                      <Slider
                        defaultValue={filterList[index][0]}
                        onChange={(event, newValue) => {
                          filterList[index][0] = (filterData[6][newValue]);
                          onChange(filterList[index], index, column);
                        }}
                        valueLabelFormat={(value) => valueLabelFormat(filterData[6][value])}
                        getAriaValueText={(value) => valueLabelFormat(filterData[6][value])}
                        valueLabelDisplay="auto"

                        min={0}
                        max={(filterData[6].length) - 1}
                      />

                    </FormGroup>
                  </div>
                ),
              },

            }
          }
            //   , {
            //   name: "BP",
            //   options: {
            //     filterType: 'multiselect',

            //   }
            // }
            , {
            name: "Estado",
            options: {
              filterType: 'multiselect',
              setCellProps: () => ({ style: { textAlign: 'center', maxWidth: "60px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" } }),
              
              setCellHeaderProps: () => ({
                className: classes.centeredHeader,
                style: { maxWidth: "60px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap" },
              }),
            //   customBodyRender: (value) => {
            //     if (value) {
            //       return (<div className={classes.centerContent}>{value}</div>)
            //     }
            //   }

            // }
          }},
          // {
          //   name: "telefono",
          //   options: {
          //     filterType: 'multiselect',

          //   }
          // },
          {
            name: "Acciones",
            options: {
              filter: false,
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
              setCellProps: () => ({ style: { minWidth: "100px" } }),

              customBodyRender: (value, dataIndex, rowIndex) => {
                if (datatableData.length > 1) {
                  // compruebo que no sea el usuario logueado
                  if (value != user.user_id) {
                    return (
                      <>
                        <div className={classes.contained} style={{ justifyContent: 'center', textAlign: 'center' }}>
                          {(user.permissions.usuarios_can_create_intern) &&
                            <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                              color='primary'
                              component={Link} to={`/app/edit/${value}`
                              }>
                              <EditIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                            </IconButton>}
                          {(user.permissions.usuarios_can_create_intern) &&
                            <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                              color='primary'
                              onClick={() => handleOpen(dataIndex.rowData, value)}>
                              <DeleteIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                            </IconButton>}
                        </div>


                      </>)
                  }

                }





              }
            }
          },
          ]}

        />}
      {/* proveedor */}
      {filtroTUsuario == 'Proveedor' &&

        <MUIDataTable
          data={datatableDataProveedor}
          options={optionsNormal}

          columns={[{
            name: "Email",
            options: {
              filterType: 'multiselect',

            }
          }, {
            name: "Nombre",
            options: {
              filterType: 'multiselect',

            }
          }, {
            name: "Apellido",
            options: {
              filterType: 'multiselect',

            }
          }, {
            name: "Razon Social",
            options: {
              filterType: 'multiselect',

            }
          }, {
            name: "CUIT",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }
            , {

            name: "Rol",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }
            , {
            name: "BP",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }
            , {
            name: "Estado",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }, {
            name: "telefono",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          },
          {
            name: "Acciones",
            options: {
              filter: false,
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
              setCellProps: () => ({ style: { minWidth: "100px" } }),

              customBodyRender: (value, dataIndex, rowIndex) => {
                if (datatableDataProveedor.length > 1) {
                  // compruebo que no sea el usuario logueado
                  if (value != user.user_id) {
                    return (
                      <>
                        <div className={classes.contained} style={{ justifyContent: 'center', textAlign: 'center' }}>
                          {(user.permissions.usuarios_can_create_external) &&
                            <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                              color='primary'
                              component={Link} to={`/app/edit/${value}`
                              }>
                              <EditIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                            </IconButton>}
                          {(user.permissions.usuarios_can_create_external) &&
                            <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                              color='primary'
                              onClick={() => handleOpen(dataIndex.rowData, value)}>
                              <DeleteIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                            </IconButton>}
                        </div>


                      </>)
                  }

                }





              }
            }
          },
          ]}

        />}


      {/* feedlot */}
      {filtroTUsuario == 'FeedLot' &&

        <MUIDataTable
          data={datatableDataUsuaiosFeedlot}
          options={optionsNormal}

          columns={[
            {
              name: "Cuit",
              options: {
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            },


            {
              name: "Titular",
              options: {
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            }, {
              name: "Renspa",
              options: {
                display: false,
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            }, {
              name: "Nombre",
              options: {
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            }, {
              name: "Localidad",
              options: {
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            }
            , {
              name: "Partido",
              options: {
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            }

            , {
              name: "Provincia",
              options: {
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            }, {
              name: "Destino",
              options: {
                display: false,
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            }, {
              name: "Rol",
              options: {
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            }

            , {
              name: "Estado",
              options: {
                filterType: 'multiselect',
                customBodyRender: (value) => {
                  if (value) {
                    return (<div className={classes.centerContent}>{value}</div>)
                  }
                }

              }
            },
            {
              name: "Acciones",
              options: {
                filter: false,
                /* sort: false,
                empty: false, */
                setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
                setCellProps: () => ({ style: { minWidth: "100px" } }),

                customBodyRender: (value, dataIndex, rowIndex) => {
                  if (datatableDataUsuaiosFeedlot.length > 1) {
                    // compruebo que no sea el usuario logueado
                    if (value != user.user_id) {
                      return (
                        <>
                          <div className={classes.contained} style={{ justifyContent: 'center', textAlign: 'center' }}>
                            {(user.permissions.usuarios_can_create_external) &&
                              <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                                color='primary'
                                component={Link} to={`/app/edit/${value}`
                                }>
                                <EditIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                              </IconButton>}
                            {(user.permissions.usuarios_can_create_external) &&
                              <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                                color='primary'
                                onClick={() => handleOpen(dataIndex.rowData, value)}>
                                <DeleteIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                              </IconButton>}
                          </div>


                        </>)
                    }

                  }





                }
              }
            },
          ]}

        />}


      {/* Contacto de carga  */}

      {filtroTUsuario == 'Contacto_de_carga' &&
        <MUIDataTable
          data={datatableDataUsuariosCarga}
          options={optionsNormal}

          columns={[{
            name: "Nombre",
            options: {
              filterType: 'multiselect',

            }
          }, {
            name: "Apellido",
            options: {
              filterType: 'multiselect',

            }
          }, {
            name: "CUIT",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }

            , {
            name: "Rol",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }
            , {
            name: "Estado",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }, {
            name: "telefono",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          },
          {
            name: "Acciones",
            options: {
              filter: false,
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
              setCellProps: () => ({ style: { minWidth: "100px" } }),

              customBodyRender: (value, dataIndex, rowIndex) => {
                if (datatableDataUsuariosCarga.length > 1) {
                  // compruebo que no sea el usuario logueado
                  if (value != user.user_id) {
                    return (
                      <>
                        <div className={classes.contained} style={{ justifyContent: 'center', textAlign: 'center' }}>
                          {(user.permissions.usuarios_can_create_external) &&
                            <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                              color='primary'
                              component={Link} to={`/app/edit/${value}`
                              }>
                              <EditIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                            </IconButton>}
                          {(user.permissions.usuarios_can_create_external) &&
                            <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                              color='primary'
                              onClick={() => handleOpen(dataIndex.rowData, value)}>
                              <DeleteIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                            </IconButton>}
                        </div>


                      </>)
                  }

                }





              }
            }
          },
          ]}
        />}
        {filtroTUsuario == 'usuarioFaena' &&
        <MUIDataTable
          data={datatableDataUsuarioFaena}
          options={optionsNormal}

          columns={[{
            name: "Nombre",
            options: {
              filterType: 'multiselect',

            }
          }, {
            name: "Apellido",
            options: {
              filterType: 'multiselect',

            }
          }, {
            name: "Email",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }

            , {
            name: "Rol",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }
            , {
            name: "Estado",
            options: {
              filterType: 'multiselect',
              customBodyRender: (value) => {
                if (value) {
                  return (<div className={classes.centerContent}>{value}</div>)
                }
              }

            }
          }, 
          {
            name: "Acciones",
            options: {
              filter: false,
              /* sort: false,
              empty: false, */
              setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
              setCellProps: () => ({ style: { minWidth: "100px" } }),

              customBodyRender: (value, dataIndex, rowIndex) => {
                if (datatableDataUsuarioFaena.length > 1) {
                  // compruebo que no sea el usuario logueado
                  if (value != user.user_id) {
                    return (
                      <>
                        <div className={classes.contained} style={{ justifyContent: 'center', textAlign: 'center' }}>
                          {(user.permissions.usuarios_can_create_intern) &&
                            <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                              color='primary'
                              component={Link} to={`/app/edit/${value}`
                              }>
                              <EditIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                            </IconButton>}
                          {(user.permissions.usuarios_can_create_intern) &&
                            <IconButton aria-label="edit" size="small" style={{ color: theme.palette.primary.main }}
                              color='primary'
                              onClick={() => handleOpen(dataIndex.rowData, value)}>
                              <DeleteIcon color='primary' fontSize="small" style={{ color: theme.palette.primary.main }} />
                            </IconButton>}
                        </div>


                      </>)
                  }

                }





              }
            }
          },
          ]}
        />}
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className={classes.Modal}>
          <h2 id="child-modal-description">
            {mensaje}

          </h2>
          <Button
            variant="contained"
            color="primary"
            size='large'
            className={classes.buttonModal}
            onClick={() => borrarUsuario(fila, props.history)}
          >Confirmar
          </Button>

          <Button
            variant="contained"
            color="primary"
            size='large'
            onClick={handleClose}
            className={classes.buttonModal}
          >Cancelar</Button>
        </Box>
      </Modal>
    </>

    /*       </MuiThemeProvider>
     */
  )

}
export default withRouter(UserList)