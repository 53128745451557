import React, { useState,useContext, useEffect} from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  Fade,
  FormControlLabel,Checkbox,
  CircularProgress,
}from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import ResponsiveDatePickers from "../../components/dateRange/datePicker";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import moment from "moment";
import Dropzone from 'react-dropzone-uploader'
import { useHistory } from 'react-router';
import DownloadIcon from '@mui/icons-material/Download';
import { Preview } from "../variables/variables";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'
import Alert from '@mui/material/Alert';


function FormDeclaracionGeneral(props) {
  var user = JSON.parse((localStorage.getItem('userData')))
  const history = useHistory();
  var classes = useStyles();
  var rol = localStorage.getItem('permissions')
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState('');
  
  
  //var userDispatch = useUserDispatch();
  var [fechaAlta, setFechaAlta] = useState(moment().format('YYYY-MM-DD'))
  var [fechaVencimiento,setFechaVencimiento] = useState(moment().add(1,'year').format('YYYY-MM-DD'))
  var [valorInicial, setValorInicial]= useState('');


  // local
  var [titulo, setTitulo]= useState('Declaracion General');
  var [cuit, setCuit]= useState(()=>{if(user.cuit!=null){return user.cuit}else{ return ''}});
  var [razonS, setRazonS] = useState(()=>{if(user.razonS!=null){return user.razonS}else{ return ''}});
  var [direccion, setDireccion] = useState("");
  var [renspa, setRenspa] = useState("");
  var [provincia, setProvincia] = useState("");
  var [telefono, setTelefono] = useState("");
  var [email, setEmail] = useState(()=>{if(user.email!=null && user.rol==4){return user.email}else{ return ''}});
  var [desplRenspa, setDesplRenspa]= useState([])
  var [edit, setEdit] =useState(false)
  var [ID,setID]= useState();
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [posicionUsuario, setPosicionUsuario] = useState('');

  //manejo de tiempos del servidor
  let [errorServidor, setErrorServidor] = useState(null);

  let [isLoading2, setIsLoading2] = useState(false);
  let [desplegables, setDesplegables] = useState([]);
  let [sumarImg,setSumarImg]= React.useState(false);
  let [Image, setImage] = React.useState([]);


  const [declaracion,setDeclaracion]=useState(false)
  const [declaracionI,setDeclaracionI]=useState(false)

  const [missingRenspaError, setMissingRenspaError] = useState(false)


  const setDate = (event) => {
    setFechaAlta(moment(event).format("YYYY-MM-DD"))   
    setFechaVencimiento(moment(event).add(1,'year').format("YYYY-MM-DD"))  
  }

  const handleProvChange = (e, value) => {
    if (value && value.cuit!=undefined) {
      setRazonS(value.label.split('-')[0]);
      setCuit(value.cuit.toString());
      setEmail(value.email ? value.email.toString() : '');
      getRenspa(value.cuit.toString())
    } else { 
      setRazonS('');
      setRenspa('');
      setDesplRenspa([]);
      setCuit('');
      setEmail('')
    }
  }

  //get Renspa
  const getRenspa = (cuit)=>{
   setRenspa('');
   setDesplRenspa([]);
   setMissingRenspaError(false);
   UserService.getRenspa(cuit).then(
     rensponse=>{
       //console.log(rensponse)
       setDesplRenspa(rensponse.data)
       if (rensponse.data.length==1){
         setRenspa(rensponse.data[0].renspa);
       }
       if (rensponse.data.length == 0) {
        setMissingRenspaError(true);
       }
     }
   )
  }
  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
    
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {   
    if (status === 'headers_received') {

    setImage(file)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false) }
  
  }
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files,allFiles) => {

    const fotos = []
    let newData = {  title: "",
    description: "",
    image_url: "", };
  
    files.map(f => fotos.push({
      title: f.meta.name,
    description: f.meta.type,
    image_url: f.meta.previewUrl,
    img:f.file
    }))

    //console.log(fotos)
    setImage(fotos)
    //await UserService.postImagen(fotos)
    //setOpen(false)
    setSumarImg(false)
    allFiles.forEach(f => f.remove())
    //setDisableGuardar(false)

  }
  
//funcion para guardar doc
const guardarDocumento = async ()=>{
  setErrorServidor(false)
  setError('')
  if(edit==false)
  { await UserService.postDocumento(titulo,cuit,razonS,direccion,renspa,provincia,telefono,email,fechaAlta,fechaVencimiento,declaracion,'No Aplica',[], nombreUsuario, posicionUsuario).then(
    response=>{

      if(response.status==201){
        history.push('/app/gestionDoc')
        props.actualizar()

        props.cerrar()
        
      }
      if(response.status==403){
        setErrorServidor(true)
        setError(<p>Ya hay cargado un documento con el CUIT-RENSPA todavia esta vigente</p>)}
        if(response.status==400){
          setErrorServidor(true)
          setError(<p>Por favor complete todos los campos</p>)
        }
      },
     
    error=>{
        setErrorServidor(true)
        setError(<p>Por favor complete todos los campos</p>)
      

    }

    
  )
  }else{
    await UserService.putDocumento(ID,titulo,cuit,razonS,direccion,renspa,provincia,telefono,email,fechaAlta,fechaVencimiento,declaracion,'No Aplica',[], nombreUsuario, posicionUsuario).then(
      response=>{

        if(response.status==200){
          history.push('/app/gestionDoc')
          props.actualizar()

          props.cerrar()

        }
        },
      error=>{
        setErrorServidor(true)
        setError(<p>Por favor complete todos los campos</p>)

      }
    )

  }
 

}

const imprimirPDF = ()=>{
  let form_data = new FormData();

  const info = ({titulo,'cuit_productor':cuit,razonS,direccion,renspa,"ubicacion":provincia,telefono,email,
  'fecha_de_alta':fechaAlta,'fecha_de_vencimiento':fechaVencimiento,doc_url:''})


    for (const [key, value] of Object.entries(info)) {
      form_data.append(key,value)
    }

    UserService.generarPDF(form_data,titulo)
}
  
  useEffect(() => {
    if (user.cuit!=null){
      //setCuit(user.cuit)
      UserService.getRenspa(user.cuit).then(
        rensponse=>{
          //console.log(rensponse)
          setDesplRenspa(rensponse.data)
        }
        
      )
      setCuit(user.cuit);
      setEmail(user.email)

    }
    // list proveedores
    UserService.getListProveedor().then(
      response => {
      if(response.status==200){
        const dataTropaConsig = response.data.users.map(a =>({cuit: a.cuit, label:a.razonS + '-' + a.cuit,email:a.email}) )
        setDesplegables(dataTropaConsig) 
        let dataInicial = props.dataServidor
    if (dataInicial!=undefined){
      setFechaAlta(dataInicial['fecha_de_alta'])
      setFechaVencimiento(dataInicial['fecha_de_vencimiento'])
      //setNombreCompania(dataInicial['nombreCompania'])
      setCuit(dataInicial['cuit_productor'])
      setRazonS(dataInicial['razonS'])
      setDireccion(dataInicial['direccion'])
      setRenspa(dataInicial['renspa'])
      setProvincia(dataInicial['ubicacion'])
      setTelefono(dataInicial['telefono'])
      setEmail(dataInicial['email'])
      setNombreUsuario(dataInicial['nombre_usuario'])
      setPosicionUsuario(dataInicial['posicion_usuario'])
      UserService.getRenspa(dataInicial['cuit_productor']).then(
        rensponse=>{
          //console.log(rensponse)
          setDesplRenspa(rensponse.data)
        })
        setEdit(true)
        setDeclaracion(dataInicial['firmo'])
        setDeclaracionI(dataInicial['firmo'])
        setID(dataInicial['id'])

        const index = dataTropaConsig.findIndex(x=>x.label===dataInicial['razonS'])
        setValorInicial(dataTropaConsig[index])
      }
      }
      },
      error => {
        if(error.response.status==401){}
        }
      );

    let dataInicial = props.dataServidor
    if (dataInicial!=undefined){
      setFechaAlta(dataInicial['fecha_de_alta'])
      setFechaVencimiento(dataInicial['fecha_de_vencimiento'])
      //setNombreCompania(dataInicial['nombreCompania'])
      setCuit(dataInicial['cuit_productor'])
      setRazonS(dataInicial['razonS'])
      setDireccion(dataInicial['direccion'])
      setRenspa(dataInicial['renspa'])
      setProvincia(dataInicial['ubicacion'])
      setTelefono(dataInicial['telefono'])
      setEmail(dataInicial['email'])
      setNombreUsuario(dataInicial['nombre_usuario'])
      setPosicionUsuario(dataInicial['posicion_usuario'])
      UserService.getRenspa(dataInicial['cuit_productor']).then(
        rensponse=>{
          //console.log(rensponse)
          setDesplRenspa(rensponse.data)
        })
        setEdit(true)
        setDeclaracion(dataInicial['firmo'])
        setDeclaracionI(dataInicial['firmo'])
        setID(dataInicial['id'])

        const index = desplegables.findIndex(x=>x.label===dataInicial['razonS'])
        setValorInicial(desplegables[index])
      }


    }, []);
  
  return (
    <>
      
            <div className={classes.marginNormal}>
            <Fade in={errorServidor}>
                  <Typography color="secondary" className={classes.errorMessage}>
                      {error}
                  </Typography>
                </Fade> 
            </div>

            <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}> 
                <Typography variant="h4" align="center" gutterBottom>
                Declaracion Jurada del Productor
                </Typography>
                
                <p>&nbsp;</p>

                <p>Declaro que tengo conocimiento de la reglamentaci&oacute;n argentina para los animales destinados a la producci&oacute;n de alimentos para el consumo humano:</p>
                <ol>
                <li><strong>Resoluci&oacute;n 447/2004 de SENASA:</strong> En donde se proh&iacute;be el uso de productos veterinarios anabolizantes (promotores de crecimiento) en animales con destino a consumo humano.</li>
                <li><strong>Resoluci&oacute;n 1389/2003 de SENASA</strong>: En donde se proh&iacute;be el uso de prote&iacute;nas de origen animal como &uacute;nico ingrediente o en mezcla con otros productos, para la administraci&oacute;n con fines alimenticios o como suplementos (por ejemplo: Harina de carne y hueso).</li>
                <li>En ning&uacute;n momento de su crianza se utilizaron insumos de origen animal (vitaminas, minerales, etc.) como suplemento alimentario.</li>
                </ol>
                
              </Grid>
              {missingRenspaError && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Fade in={missingRenspaError}>
                  <Alert severity="error" variant="filled" onClose={() => {setMissingRenspaError(false)}}>El proveedor seleccionado no tiene Renspas definidos.</Alert>
                </Fade>
              </Grid>
              )}
              {rol != 4 && (
                <>
              <Grid item lg={6} md={6} sm={12} xs={12}> 
                <InputLabel id="type">Fecha de Carga</InputLabel>
                  <ResponsiveDatePickers
                  value={fechaAlta}
                  label="Date desktop"
                  inputFormat="dd-MMMM-yyyyy"
                  setValorFecha={setDate}
                  
                  ></ResponsiveDatePickers> 
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}> 
                <InputLabel id="type">Fecha de Vencimiento</InputLabel>
                  <ResponsiveDatePickers
                  value={fechaVencimiento}
                  label="Date desktop"
                  inputFormat="dd-MMMM-yyyyy"
                  setValorFecha={(e)=>{setFechaVencimiento(e)}}
                  
                  ></ResponsiveDatePickers> 
              </Grid>
              </>
              )}
              <Grid item container spacing={2} lg={12} xs={12} sm={12}>
                {rol!=4 ? ( 

                  <Grid item lg={6} xs={12} sm={12}>                
                    <InputLabel id="razonSocial">Proveedor</InputLabel>

                    <Autocomplete
                      noOptionsText={'Sin opciones'}
                      id="combo-box-demo"
                      options={desplegables}
                      //autoSelect={true}
                      value={razonS}
                      onChange={(e, value) =>{handleProvChange(e, value)}}
                      renderInput={(params) =>(
                        <TextField 
                        {...params} 
                          type="text"
                          variant="outlined"
                          error={razonS==''?true:false}

                            />)}
                    />
                    
                  </Grid> ):(
                    <Grid item lg={6} xs={12} sm={12}>
                    <TextField
                    autoFocus
                    id="razonSocial"
                    disabled
                    variant="outlined"
                    label="Razon Social"
                    InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={razonS}
                    error={razonS==''?true:false}

                    onChange={e => setRazonS(e.target.value)}
                    margin="normal"
                    placeholder="Razon Social"
                    type="text"
                    fullWidth
                    required/>
                  </Grid>

                  )}
              
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="Dirección" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={direccion}
                  error={direccion==''?true:false}

                  onChange={e => setDireccion(e.target.value)}
                  margin="normal"
                  placeholder="Dirección"
                  variant="outlined"
                  label="Dirección"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={4} xs={12} sm={6}>
                  <InputLabel id="Renspa">Renspa </InputLabel>
                  <Select
                    labelId="type"
                    variant="outlined"
                    margin="normal"
                    error={renspa==''?true:false}
                    
                    id="sector"
                    value={renspa}
                    label="Completar"
                    onChange={(e)=>{setRenspa(e.target.value)}}
                    fullWidth
                    required
                   // disabled={compradorEst()}
                   className={classes.marginNormal}
                  
                  >
                    {desplRenspa.map(value=>{
                      return <MenuItem value={value.renspa}>{value.renspa}</MenuItem>

                    })}
                  </Select>
                </Grid>  
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="Provincia|Localidad" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={provincia}
                  error={provincia==''?true:false}

                  onChange={e => setProvincia(e.target.value)}
                  margin="normal"
                  placeholder="Provincia | Localidad"
                  variant="outlined"
                  label="Provincia | Localidad"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="TelefonoContacto" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={telefono}
                  error={telefono==''?true:false}

                  onChange={e => setTelefono(e.target.value)}
                  margin="normal"
                  placeholder="Telefono de contacto"
                  variant="outlined"
                  label="Telefono de contacto"
                  type="text"
                  fullWidth/>
                </Grid>  
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="email" InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },}}
                  value={email}
                  error={email==''?true:false}

                  onChange={e => setEmail(e.target.value)}
                  margin="normal"
                  placeholder="Email"
                  variant="outlined"
                  label="Email"
                  type="email"
                  fullWidth/>
                </Grid>  
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="nombre_usuario" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={nombreUsuario}
                    error={nombreUsuario==''?true:false}

                    onChange={e => setNombreUsuario(e.target.value)}
                    margin="normal"
                    placeholder="Nombre"
                    variant="outlined"
                    label="Nombre"
                    type="text"
                    fullWidth/>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                  <TextField id="posicion_usuario" InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },}}
                    value={posicionUsuario}
                    error={posicionUsuario==''?true:false}

                    onChange={e => setPosicionUsuario(e.target.value)}
                    margin="normal"
                    placeholder="Cargo que ocupa en la empresa"
                    variant="outlined"
                    label="Cargo"
                    type="text"
                    fullWidth/>
                </Grid>
                <Grid item lg={12} xs={12} sm={12}>
                  <FormControlLabel
                      value="consgi"
                      control={<Checkbox color="primary"       checked={declaracion}/>}
                      className={classes.control}
                      onClick={()=>{setDeclaracion(value => !value)}}

                      label="Al guardar declaro bajo juramento que los datos consignados en la presente declaracion jurada son veridicos y me hago responsable respecto de la veracidad de los mismos"
                    />
                </Grid>
                {/* <Grid item lg={10} xs={12} sm={12}>
                  <p>Al guardar declaro bajo juramento que los datos consignados en la presente declaracion jurada son veridicos y me hago responsable respecto de la veracidad de los mismos</p>
                </Grid> */}
                {/* <Grid item lg={6} xs={12} sm={12}>
                  <FormControlLabel
                    value="consgi"
                    control={<DownloadIcon/>}  
                    className={classes.control}

                    label="Descargar Documento"
                    onClick={()=>{imprimirPDF()}}
                  />
          
                  </Grid>
        

                  {!sumarImg&& 
                  <Grid item lg={6} xs={12} sm={12}>
                  <FormControlLabel
                  value="consgi"
                  control={<AddCircleIcon/>}  
                  className={classes.control}

                  label="Cargar Certificado"

                  onClick={()=>{setSumarImg(value=>!value)}}
                />
                
                  </Grid> }
                  {sumarImg&& 

                  <Grid item lg={12} xs={12} sm={12}>
                  <Dropzone
                    classNames={classes.dropZone}
                    styles={{ dropzone: { overflow: 'hidden', margin:'10px 0px'}, submitButton: {padding:'15px',
                    fontSize: '1.2em',}   }}
                    /* getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    onSubmit={handleSubmit}
                    PreviewComponent={Preview} 
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}


                    accept="image/*,.pdf"
                    inputContent='Arrastra tus archivos o hace click aqui'
                   /*  submitButtonContent="Guardar Datos"
                    inputWithFilesContent='Sumar otro archivo' 
                      /> 
                  </Grid>  }  */}
              </Grid>    
              <Grid alignContent='center' container spacing={1} lg={12} xs={12} sm={12} style={{display:'flex', justifyContent: 'center'}}>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    fullWidth

                     onClick={() => 
                      //UserService.generatePDF()
                      guardarDocumento(props.history)
                      
                     
                     /*(fecha_de_alta,fecha_de_vencimiento,cuitProveedor,nombreCompania,provincia,localidad,politicaBienestar,descPoliticaBienestar,
                        capacitacionPersonal,fecha_de_capacitacionPersonal,
                        manga,corralCierre,rampa,manejoDeAnimales,descripcionManejoAnimales,manejodeCarga,descripcionManejoCarga, email, doc_url) */
                    }  

                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={
                      cuit.length === 0 ||
                      fechaAlta.length === 0 ||
                      fechaVencimiento.length === 0 ||
                      renspa.length === 0 ||
                      direccion.length === 0 ||
                      provincia.length === 0 ||
                      direccion.length === 0 ||
                      telefono.length === 0 ||
                      email.length === 0 || 
                      nombreUsuario.length === 0 || 
                      posicionUsuario.length === 0
                    }
                    className={classes.createAccountButton}
                    //component={Link} to={`/app/gestionDoc`}
                    >

                  Guardar
                  </Button> 
                </Grid>
                <Grid item lg={3} xs={12} sm={12}>
                  <Button
                    className={classes.createAccountButton}
                    size="large"
                    variant="contained"
                    color="primary" 
                    fullWidth
                    component={Link} to={`/app/gestionDoc`}
                    onClick={props.cerrar}

                    >
                  
                    Cancelar
                  </Button>  
                </Grid>       
              </Grid>      
            </Grid>    
            </div>  
          
    </>    
  );
}
export default FormDeclaracionGeneral;