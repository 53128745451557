import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MUIDataTable from "mui-datatables";
import UserService from '../../context/UserService';
import { Grid } from '@mui/material';
import { optionsNormal } from '../variables/variables';
import {Typography} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
//import classes from "./saltodePagina.scss";  
import Box from '@mui/material/Box';
import logoBlack from "../../images/logo_dark.png";
import useStyles from "./styles";


const breakpoints = createBreakpoints({})

const getMuiTheme = () =>
  createMuiTheme({
   
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          minWidth: 'max-content',
          Width: 'auto',
          padding:1,

          
        }
      },
 
    MUIDataTable:{      
      tableRoot:{
        border: '2px solid black',

      }
      
      
  },

    MuiTableCell: {
      head: {
          backgroundColor: "blue !important",
          color:'white',
          
      }
  },
    
      
      MUIDataTableHeadCell:{
      
        root:{
          fontWeight: "600",
          padding: '0 0 0 5px',

        }
      },

 
    },
    shadows: ['none']


  });
export default function FormatoGeneralReporte(props) {
  var classes = useStyles();

  //const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

   const [tropa, setTropa] = React.useState([]);

      // data tables
  const [dataSuperior, setDataSuperior] = React.useState([]);
  const [dataGarron, setDataGarron] = React.useState([]);
  const [dataGolpes, setDataGolpes] = React.useState([]);
  const [especie, setEspecie]=  React.useState()
  const [establecimiento, setEstablecimiento]=  React.useState()

  const [dataFinal, setFataFinal] = React.useState([]);
  const [usuario, setUsuario]=  React.useState()
  const [dataGarronOrdenado, setDataGarronOrdenado] = React.useState([]);
  const [especies, setEspecies] = React.useState([]);

  //funcion para dividir los garrones en 3. 
  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}
  React.useEffect(() => {
    setTropa(props.value)
    

    UserService.getInforme(props.value,props.start_date,props.end_date,'').then( response => {
      if (response.status==200){

        let dataServidor = response.data
        setDataSuperior(dataServidor.dataCabecera)
        setDataGolpes(dataServidor.dataGolpes)
        setFataFinal(dataServidor.dataFinal)
        setDataGarron(dataServidor.dataFaena)
        setEspecies([...new Set(dataServidor.dataCabecera[0]['especies'])])
        setEstablecimiento(()=>{
            if( dataServidor.dataCabecera[0]['es_origen']==null){return 'Frigorifico Rioplatense'}
            else {return dataServidor.dataCabecera[0]['es_origen']}
        })
        setUsuario(()=>{
          if(dataServidor.dataCabecera[0]['usuariodescver']){
            return dataServidor.dataCabecera[0]['usuariodescver']
          }
        })
        const garronSeparado = sliceIntoChunks(dataServidor.dataFaena,((dataServidor.dataFaena).length/3))
        setDataGarronOrdenado(garronSeparado)
      }
    },
    error => {
      }
  )

  }, []);

  const option = {
    filter: false,
    responsive: 'verticalAlways',
    search:false,
    viewColumns:false,
    print:false,
    download:false,
    sort: false
  }
  const optionSort = {
    filter: false,
    responsive: 'verticalAlways',
    search:false,
    viewColumns:false,
    print:false,
    download:false,
    sort: false,
    pagination:false
  }

  const dataTableSuperior= []
  const dataTableInt= []

  let dataTableGarron0= []
  let dataTableGarron1= []
  let dataTableGarron2= []
  const dataTableGolpes= []
  const dataTableFinal= []


  const columns = []
  const rows = []

  const columnsR = []
  const rowsR = []
  const columnsD = []
  const rowsD = []

function DataGridTitle() {
    return(
        <Box style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center",backgroundColor:'blue',color:'white'}}>
            <Typography variant="h6">Procedencia</Typography>
        </Box>
    )
}
function DataGridTitle2() {
  return(
      <Box style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center" ,backgroundColor:'blue',color:'white'}}>
          <Typography variant="h6">Resumen Faena</Typography>
      </Box>
  )
}
function DataGridTitleDOCUMENTACION() {
  return(
      <Box style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center" ,backgroundColor:'blue',color:'white'}}>
          <Typography variant="h6">Documentación</Typography>
      </Box>
  )
}

  if (dataSuperior[0]!=undefined ){
    
    columns.push( { field: 1, headerName: 'Remitente :',flex:2,headerClassName: 'header',})
    columns.push( { field: 2, headerName: dataSuperior[0]['remitente'], flex:2, headerClassName: 'header' })
  
    columnsR.push( { field: 1, headerName: '',flex:1,headerClassName: 'header',})
    columnsR.push( { field: 2, headerName:'Cantidad', flex:2, headerClassName: 'header' })
    columnsR.push( { field: 3, headerName: 'Kg. Total', flex:1, headerClassName: 'header' })
    columnsR.push( { field: 4, headerName: 'Kg. Prom', flex:1, headerClassName: 'header' })
    columnsD.push( { field: 1, headerName: 'Guia', flex:1, headerClassName: 'header' })
    columnsD.push( { field: 2, headerName: dataSuperior[0]['n_guia'], flex:1, headerClassName: 'header' ,display:false})
  
  
  
  
   /*  columns.push( { field: 3, headerName: 'Tropa', flex:1,headerClassName: 'header' })
    columns.push( { field: 4, headerName: dataSuperior[0]['tropa'], flex:1 ,headerClassName: 'header'})
  
    columns.push(  { field: 5, headerName: 'Guia', flex:1,headerClassName: 'header' })
    columns.push(  { field: 6, headerName: dataSuperior[0]['n_guia'], flex:1.5 ,headerClassName: 'header' })
    columns.push(  { field: 7, headerName: "Cuit Remitente",flex:2,headerClassName: 'header'  })
    columns.push(  { field: 8, headerName: dataSuperior[0]['cprod'], flex:1 ,headerClassName: 'header'}) */
    
    
  /* columns={[ "Est. Faenador","Usu","Remit","Faena","Loc","Tropa","Cab. Faena total","Playa emergencia","Muertas","Decomisos","Raz. Ppal."
                ,"Guia","DTA","Kg. vivos FR.","Kg. carne","Kg. decomiso","Rec.","Cuit Remitente","Renspa","Patente del chasis","Patente de acoplado"]}>
   */
    rows.push( { id:1, 1: 'Consignatario' ,2:dataSuperior[0]['usuariodescver'].toLocaleString('es'),3:"Cab. Faena total",4:dataSuperior[0]['total_rese'],5:"DTA",6:dataSuperior[0]['NRODTA1'], 7:"Renspa",8:dataSuperior[0]['renspa']})
    rows.push( { id:2, 1: 'Localidad.',2:dataSuperior[0]['localidad'],3:"Playa emergencia",4:dataSuperior[0]['playaFae'],5:"Kg. vivos FR.",6:dataSuperior[0]['tot_peso']+' / '+(dataSuperior[0]['cab_ingresadas']),7:"Patente del chasis",8:dataSuperior[0]['pat_chasis']})
    rows.push( { id:3, 1: 'Raza Principal',2:dataSuperior[0]['raza'],3:"Muertas",4:dataSuperior[0]['cab_muertas'],5:"Kg. carne",6:dataSuperior[0]['total_Kilos']+' / '+(parseInt(dataSuperior[0]['total_Kilos'])/parseInt(dataSuperior[0]['total_rese'])*2).toFixed(2),7:"Patente de acoplado",8:dataSuperior[0]['pat_acop']})
    rows.push( { id:4, 1: 'Especie' ,2:especies.toString(),3:"Decomisos",4:dataSuperior[0]['destcom'],5:"Kg. decomiso",6:dataSuperior[0]['kgDescom']})
    //rows.push( { id:5, 1: '' ,2:'',3:"Raz. Ppal.",4:dataSuperior[0]['raza'],5:"Rec.",6:dataSuperior[0]['recepcion']})
  
    rowsR.push( { id:1, 1: 'Recepción' ,2:dataSuperior[0]['total_rese'],3:dataSuperior[0]['tot_peso'],4:(parseInt(dataSuperior[0]['tot_peso'])/parseInt(dataSuperior[0]['total_rese'])).toFixed(2)})
    rowsR.push( { id:2, 1: 'Muertos' ,2:dataSuperior[0]['cab_muertas'],3:'',4:''})
    rowsR.push( { id:3, 1: 'Playa Emergencia' ,2:dataSuperior[0]['playaFaeE'],3:dataSuperior[0]['pesoE']?parseInt(dataSuperior[0]['pesoE']):'',4:dataSuperior[0]['pesoE']?Math.round(parseInt(dataSuperior[0]['pesoE'])/parseInt(dataSuperior[0]['playaFaeE'])):''})
    rowsR.push( { id:4, 1: 'Playa Faena' ,2:dataSuperior[0]['playaFaeF'],3:dataSuperior[0]['pesoF']?parseInt(dataSuperior[0]['pesoF']):'',4:dataSuperior[0]['pesoF']?Math.round(parseInt(dataSuperior[0]['pesoF'])/parseInt(dataSuperior[0]['playaFaeF'])):''})
    rowsR.push( { id:5, 1: 'Decomisos' ,2:dataSuperior[0]['dest'],3:'',4:''})
    rowsR.push( { id:6, 1: 'Total' ,2:(dataSuperior[0]['playaFaeF']!=null?parseInt(dataSuperior[0]['playaFaeF']):0)+(dataSuperior[0]['playaFaeE']!=null?parseInt(dataSuperior[0]['playaFaeE']):0),3:'',4:''})
    rowsR.push( { id:7, 1: 'Rinde' ,2:(dataSuperior[0]['Rinde']).toFixed(2),3:'',4:''})
  
    rowsD.push( { id:1, 1: 'Dte' ,2:dataSuperior[0]['dte']})
    rowsD.push( { id:2, 1: 'Cuit Remitente' ,2:(dataSuperior[0]['cprod'])})
    rowsD.push( { id:3, 1: 'Cuit Consignatario' ,2:(dataSuperior[0]['consig']!=null?dataSuperior[0]['consig']:'')})
    rowsD.push( { id:4, 1: 'Renspa' ,2:(dataSuperior[0]['renspa'])})
    rowsD.push( { id:5, 1: 'Patente Chasis' ,2:(dataSuperior[0]['pat_chasis'])})
    rowsD.push( { id:6, 1: 'Patente Acoplado' ,2:(dataSuperior[0]['pat_acop'])})
  
  
  
  
    
  
  }
 



  for (const [key, value] of Object.entries(dataSuperior)) {
    if ( value){

      // marca Nomeclador 
      dataTableInt.push([value['tot_Regi'],parseInt(value['KgRecepcionFR']).toFixed(1),
      (parseInt(value['total_Kilos'])/parseInt(value['total_rese'])).toFixed(2)
      ,value['total_Kilos']
      ,value['Rinde'].toFixed(2),(value['ratio_tipificacion']).toFixed(1),(value['ratio_gordura']).toFixed(2)])
    }
  }

  for (const [key, valor] of Object.entries(dataGarronOrdenado)) {
    if (key==0){
      for (const [key, value] of Object.entries(valor)) {
        dataTableGarron0.push([value['numgarr'],value['espvacu'],value['dientes'],
      value['tipific']+'-'+Math.round(value['gordura'])
      ,value['destcom'],
      (value['c481']==null && value['chilton']==null)?'N'
      :(value['c481']=='N' && value['chilton']=='N')?'N'
      :(value['chilton']=='H' && value['c481']=='N')?'H'
      :(value['chilton']=='N' && value['c481']=='W')?'W'
      :(value['chilton']==null && value['c481']=='W')?'W':'W'
      ,parseInt(value['pesofae']),(parseInt(value['gradoas'])+parseInt(value['gradobi']))+','+value['gradomo']+','+value['gradope']])
      }
      
    }else if ((key==1)){
      for (const [key, value] of Object.entries(valor)) {
        dataTableGarron1.push([value['numgarr'],value['espvacu'],value['dientes'],
      value['tipific']+'-'+Math.round(value['gordura'])
      ,value['destcom'],
      (value['c481']==null && value['chilton']==null)?'N'
      :(value['c481']=='N' && value['chilton']=='N')?'N'
      :(value['chilton']=='H' && value['c481']=='N')?'H'
      :(value['chilton']=='N' && value['c481']=='W')?'W'
      :(value['chilton']==null && value['c481']=='W')?'W':'W'
      ,parseInt(value['pesofae']),(parseInt(value['gradoas'])+parseInt(value['gradobi']))+','+value['gradomo']+','+value['gradope']])
      }

    }else if ((key==2)){
      for (const [key, value] of Object.entries(valor)) {
        dataTableGarron2.push([value['numgarr'],value['espvacu'],value['dientes'],
      value['tipific']+'-'+Math.round(value['gordura'])
      ,value['destcom'],
      (value['c481']==null && value['chilton']==null)?'N'
      :(value['c481']=='N' && value['chilton']=='N')?'N'
      :(value['chilton']=='H' && value['c481']=='N')?'H'
      :(value['chilton']=='N' && value['c481']=='W')?'W'
      :(value['chilton']==null && value['c481']=='W')?'W':'W'
      ,parseInt(value['pesofae']),(parseInt(value['gradoas'])+parseInt(value['gradobi']))+','+value['gradomo']+','+value['gradope']])
      }
    }
  }

  for (const [key, value] of Object.entries(dataGolpes)) {
    if (value){
      // marca Nomeclador 
      dataTableGolpes.push([value['numGar'],value['espVacu'],
      value['tipiFic_id']+'-'+Math.round(value['gordura_id'])
      ,value['destCom'],
      ,value['pesoFae'],value['gradoBi'],value['gradoAs'],value['gradoMo'],value['gradoPe']])
    }
  
  }
  //columns={[ "Esp.","Tipif.","Reses","P Tot.","P Pro"]}

  for (const [key, value] of Object.entries(dataFinal)) {
    if (value){
      // marca Nomeclador 
      dataTableFinal.push([value['espVacu'],value['tipific'],
      value['total_garrones']
      ,value['total_peso'],
      ,value['peso_prom']])
    }
  
  }  

    return( 
<>
        <Grid container spacing={1} >
          <Grid container>
          <img src={logoBlack} alt="Logo" className={classes.logo}/>
          <Typography variant='h4'>
          Tropa {tropa} - Establecimiento:{establecimiento} - {usuario}

          </Typography>
          </Grid>     
          <Grid container spacing={2}>
          <Grid item lg={12}>
            <Box sx={{ width: '49%'}}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  classes={{columnHeaderTitle: classes.headerCell }}
                  components={{Toolbar: DataGridTitle}}
                  pañosSize={11}
                  autoHeight 
                  hideFooter={true}
                  rowHeight={25}
                  headerHeight={25}
                  sx={{border: '2px solid black',}}
                  onEstadisticas
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  rowsPerPañosOptions={[11]}
                />

            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{width: '100%' }}>
              <DataGrid
                rows={rowsR}
                columns={columnsR}
                classes={{columnHeaderTitle: classes.headerCell }}
                components={{Toolbar: DataGridTitle2}}
                pañosSize={11}
                autoHeight 
                rowHeight={25}
                headerHeight={25}

                hideFooter={true}
                sx={{border: '2px solid black',}}
                onEstadisticas
                hideFooterPagination
                hideFooterSelectedRowCount
                rowsPerPañosOptions={[11]}
              />

            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{width: '100%' }}>
              <DataGrid
                rows={rowsD}
                columns={columnsD}
                classes={{columnHeaderTitle: classes.headerCell }}
                components={{Toolbar: DataGridTitleDOCUMENTACION}}
                pañosSize={11}
                autoHeight 
                rowHeight={25}
                headerHeight={25}

                hideFooter={true}
                sx={{border: '2px solid black',}}
                onEstadisticas
                hideFooterPagination
                hideFooterSelectedRowCount
                rowsPerPañosOptions={[11]}
              />

            </Box>
          </Grid>
         {/*  <MUIDataTable
              title={'Tabla intermedia'}
              data={dataTableSuperior}
              options={Object.assign(option, optionsNormal)}
              columns={[ "Est. Faenador","Usu","Remit","Faena","Loc","Tropa"," Cab. Faena total","Playa emergencia","Muertas","Decomisos","Raz. Ppal."
              ,"Guia","DTA","Kg. vivos FR.","Kg. carne","Kg. decomiso","Rec.","Cuit Remitente","Renspa","Patente del chasis","Patente de acoplado"]}>
              </MUIDataTable>
 */}          

          <Grid item lg={12}>
            <MuiThemeProvider theme={getMuiTheme()}>

              <MUIDataTable
              title={'Resumen'}
              data={dataTableInt}
              options={Object.assign(option, optionsNormal)}
              columns={[ "Faena","Vivo Prom.","Carne Prom.","Carne Total","Rinde Compra","Clasif. Ratio","Grasa Ratio"]}
              >
              </MUIDataTable>
            </MuiThemeProvider>
          </Grid>
          <Grid item lg={12}>
  
            <Grid container sx={{marginTop:3}} >
             
            <MuiThemeProvider theme={getMuiTheme()}>

              <Grid item lg={4}>
                <MUIDataTable
              title={'Romaneo '}
              data={dataTableGarron0}
              options={Object.assign(optionSort, optionsNormal)}
              columns={[ "Garr","Esp","Die","Tip","Dcom","Cuo","Peso","Golpes"]}
                >
              </MUIDataTable>
              </Grid>
              <Grid item lg={4}>
                <MUIDataTable
                data={dataTableGarron1}
                title={' '}

                options={Object.assign(optionSort, optionsNormal)}
                columns={[ "Garr","Esp","Die","Tip","Dcom","Cuo","Peso","Golpes"]}
                >
                </MUIDataTable>

              </Grid>
              <Grid item lg={4}>
                <MUIDataTable
                  title={' '}
                  data={dataTableGarron2}

                  options={Object.assign(optionSort, optionsNormal)}
                  columns={[ "Garr","Esp","Die","Tip","Dcom","Cuo","Peso","Golpes"]}
                  >
                  </MUIDataTable>

              </Grid>

            
            
            
            </MuiThemeProvider>
            </Grid>
            <MuiThemeProvider theme={getMuiTheme()}>

            <MUIDataTable
            title={'Tabla Golpes'}
            data={dataTableGolpes}
            options={Object.assign(option, optionsNormal)}
            columns={[ "Garrón","Especie","Tipificación","Destino comercial","Peso","Bife","Asado","Mocho","Pecho"]}
            >
            </MUIDataTable>
            </MuiThemeProvider>
          </Grid>
          <Grid item lg={12}>
            <MuiThemeProvider theme={getMuiTheme()}>

            <MUIDataTable
            title={'Tabla final'}
            data={dataTableFinal}
            options={Object.assign(option, optionsNormal)}
            columns={[ "Esp.","Tipif.","Reses","P Tot.","P Pro"]}
            >
            </MUIDataTable>
            </MuiThemeProvider>
          </Grid>  
            
          </Grid>   
        </Grid>

</>

    )

}
