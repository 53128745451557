import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  Fade,
  CircularProgress,
} from "@material-ui/core";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { withRouter } from "react-router-dom";
// styles
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function NuevoUsuarioDeCarga(props) {
  var classes = useStyles();
  const { register } = useContext(UserStateContext);

  //generals
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState("");
  const { editUser } = useContext(UserStateContext);

  //var userDispatch = useUserDispatch();

  // local
  var [nameValue, setNameValue] = useState("");
  var [lastNameValue, setlastNameValue] = useState("");
  var [cuit, setCUIT] = useState("");
  var [id, setId] = useState("");

  var [tipo, setTipo] = React.useState("Contacto_de_carga");
  var [legajo, setLegajo] = useState("");
  var [rol, setRol] = useState("7");
  var [sector, setSector] = useState("");
  var [bp, setBp] = useState("");
  var [razonSocial, setRazonSocial] = useState("");
  var [email, setEmail] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [passwordValue2, setPasswordValue2] = useState("");
  var [telefono, setTelefono] = useState(0);
  var [active, setActive] = useState();
  var [enviarEmail, setEnviarEmail] = useState(false);

  //manejo de tiempos del servidor
  var [errorServidor, setErrorServidor] = useState(null);
  var [isLoading2, setIsLoading2] = useState(false);
  useEffect(() => {
    /* UserService.getUserInfo(idDataTable).then(
      response => {
        setDataUser(response.data[0])
        //console.log(response.data[0])
        setId(idDataTable)
        setNameValue(response.data[0]['username'])
        setlastNameValue(response.data[0]['last_name'])
        setTipo(response.data[0]['type'])
        setAlignment(response.data[0]['type'])
        if( response.data[0]['telefono']!=undefined){
          setTelefono(response.data[0]['telefono'])
        }else{
          setTelefono(0)
        }      
        if (response.data[0]['type']=='Proveedor'){
          setHide(true);   
        }else{
          setHide(false);
    
        }
        setLegajo(response.data[0]['legajo'])
        setRol(response.data[0]['rol'])
        setSector(response.data[0]['sector'])
        setBp(response.data[0]['sector'])
        setRazonSocial(response.data[0]['razonS'])
        setCUIT(response.data[0]['cuit'])
        setEmail(response.data[0]['email'])
        setActive(response.data[0]['is_active'])

        

      },
      error => {
        }
      ); */
    let userInfo = props.value;
    console.log(userInfo);
    if (userInfo != undefined) {
      //console.log(response.data[0])
      setId(userInfo["id"]);
      setNameValue(userInfo["username"]);
      setlastNameValue(userInfo["last_name"]);
      setTipo(userInfo["type"]);
      if (userInfo["telefono"] != undefined) {
        setTelefono(userInfo["telefono"]);
      } else {
        setTelefono(0);
      }

      setLegajo(userInfo["legajo"]);
      setRol(userInfo["rol"]);
      setSector(userInfo["sector"]);
      setBp(userInfo["sector"]);
      setRazonSocial(userInfo["razonS"]);
      setCUIT(userInfo["cuit"]);
      setEmail(userInfo["email"]);
      setActive(userInfo["is_active"]);
    }
  }, []);

  const putUser = async (history) => {
    const response = await editUser(
      id,
      nameValue,
      passwordValue,
      passwordValue2,
      email,
      nameValue,
      lastNameValue,
      cuit,
      razonSocial,
      rol,
      tipo,
      sector,
      legajo,
      bp,
      telefono,
      active,
      enviarEmail,
      history,
      setIsLoading,
      setError,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    );
    if ((response.status = 200)) {
      history.push(`/app/tables`);
    }
  };
  return (
    <>
      <Grid alignContent="center" container spacing={1} lg={12} xs={12} sm={12}>
        {/* <Grid item lg={12} xs={12} sm={12}>
                  <div>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {error}
                  </Typography>
                  </div>


                </Grid> */}
        {error && (
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {Object.keys(error).map((key) => {
                const items = error[key].map((item) => <li>{item}</li>);
                return (
                  <Alert
                    severity="error"
                    variant="outlined"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setError(null);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    <AlertTitle>
                      <strong>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </strong>
                    </AlertTitle>
                    <ul>{items}</ul>
                  </Alert>
                );
              })}
            </Grid>
          </Grid>
        )}

        <Grid item lg={6} xs={12} sm={12}>
          <TextField
            autoFocus
            id="name"
            variant="outlined"
            label="Nombre"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
            margin="normal"
            placeholder="Nombre"
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid item alignItems="center" lg={6} xs={12} sm={12}>
          <TextField
            id="cuit"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={cuit}
            onChange={(e) => {
              setCUIT(e.target.value);
              setEmail(e.target.value + "@" + e.target.value + ".com");
            }}
            margin="normal"
            variant="outlined"
            label="CUIT"
            placeholder="CUIT"
            type="number"
            className={classes.cuitInput}
            fullWidth
            required
            onInput={(e) => {
              //setear longitud maxima del cuit
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 11);
            }}
          />
        </Grid>

        <Grid item lg={6} md={12} xs={12} sm={12}>
          <TextField
            id="lastName"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={lastNameValue}
            onChange={(e) => setlastNameValue(e.target.value)}
            margin="normal"
            placeholder="Apellido"
            variant="outlined"
            label="Apellido"
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid item lg={6} xs={12} sm={12}>
          <TextField
            id="telefono"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            margin="normal"
            placeholder="Teléfono"
            variant="outlined"
            label="Teléfono"
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="contraseña"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            margin="normal"
            variant="outlined"
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="contraseña2"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordValue2}
            onChange={(e) => setPasswordValue2(e.target.value)}
            margin="normal"
            variant="outlined"
            label="Repita Contraseña"
            placeholder="Repita Contraseña"
            type="password"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid
        alignContent="center"
        container
        spacing={1}
        lg={12}
        xs={12}
        sm={12}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item lg={3} xs={12} sm={12}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
            <Button
              fullWidth
              onClick={() => {
                setIsLoading(true);
                if (id != "") {
                  putUser(props.history);
                } else {
                  register(
                    nameValue,
                    passwordValue,
                    passwordValue2,
                    email,
                    nameValue,
                    lastNameValue,
                    cuit,
                    razonSocial,
                    rol,
                    tipo,
                    sector,
                    legajo,
                    bp,
                    telefono,
                    props.history,
                    setIsLoading,
                    setError,
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                  );
                }
              }}
              disabled={
                lastNameValue.length === 0 ||
                nameValue.length === 0 ||
                cuit.length === 0 ||
                passwordValue.length === 0 ||
                passwordValue2.length === 0
              }
              size="large"
              variant="contained"
              color="primary"
              className={classes.createAccountButton}
              //component={Link} to={`/app/tables`}
            >
              Guardar
            </Button>
          )}
        </Grid>
        <Grid item lg={3} xs={12} sm={12}>
          <Button
            className={classes.createAccountButton}
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={`/app/tables`}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default withRouter(NuevoUsuarioDeCarga);
