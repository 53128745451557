
import { Grid} from "@material-ui/core";

import ComprasInvList from "../../components/comprasInvList/ComprasInvList";

export default function FeedLot() {


  //const datatableData = []
  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={3}>
            <ComprasInvList/>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
