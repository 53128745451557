import React, { useState,useContext } from "react";
import {
  Grid,
  Button,
  TextField,
  CircularProgress
}from "@material-ui/core";


// styles
import UserStateContext from "../../context/UserContext";
import UserService from "../../context/UserService";
import useStyles from "./styles";
import { Link } from "react-router-dom";



function ContactoCarga({setOpenFeedbackContacto, setResponseErrorContacto, setContacto, ...props}) {
  var classes = useStyles();
  const { register } = useContext(UserStateContext);

  // local
  var [nameValue, setNameValue] = useState("");
  var [lastNameValue, setlastNameValue] = useState("");
  var [telefono, setTelefono] = useState('');
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(false);

  const handleChangeName = (e) => {
    setNameValue(e.target.value)
    }
  const handleChangeLastName= (e) => {
    setlastNameValue(e.target.value)
    }
  const handleChangeTelefono = (e) => {
    setTelefono(e.target.value)
    }    

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = {
        username: nameValue,
        last_name: lastNameValue,
        telefono: telefono,
      }
      const response = await UserService.postContactoDeCarga(data);
      if (response.status === 201) {
        setContacto(response.data.id);
        setCreated(true);
      } else {
        throw new Error("Error en la carga de contacto");
      }
    } catch (error) {
      setResponseErrorContacto(true);
    } finally {
      setLoading(false);
      setOpenFeedbackContacto(true);
    }
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <Grid item alignItems="center" lg={12} xs={12} sm={12}>
        <TextField id="Nombre" InputProps={{
          classes: {underline: classes.textFieldUnderline, input: classes.textField},}}
          value={nameValue}
          onChange={handleChangeName}
          margin="normal"
          variant="outlined"
          label="Nombre"
          placeholder="Nombre"
          type="text"
          fullWidth/>
        <TextField
          id="apellido"
          InputProps={{classes: { underline: classes.textFieldUnderline, input: classes.textField,},}}
          value={lastNameValue}
          onChange={handleChangeLastName}
          margin="normal"
          variant="outlined"
          label="Apellido"
          placeholder="Apellido"
          type="text"
          required
          fullWidth/>
        <TextField
          id="numeroTelefono"
          InputProps={{classes: {underline: classes.textFieldUnderline, input: classes.textField,},}}
          value={telefono}
          onChange={handleChangeTelefono}
          margin="normal"
          variant="outlined"
          label="Télefono"
          placeholder="Télefono"
          type="number"
          fullWidth
          />
        {loading ? (
          <CircularProgress size={26}/>
        ) : (
          <Button variant="contained" type="submit" disabled={created}>
          Crear
        </Button>
        )}
      </Grid>              
    </form>    
  );
}
export default ContactoCarga;